import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@yaris/core/data.service';
import { CoordinatesFormat, Layer, MSAObject } from '@yaris/core/domain';
import { PermissionsService } from '@yaris/core/permissions.service';
import { CoordinateService } from '@yaris/msa/mapbox/services/coordinate.service';
import flatpickr from 'flatpickr';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import { isNil } from 'lodash';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-msa-edit-polygon',
    templateUrl: './msa-edit-polygon.component.html',
    styleUrls: ['./msa-edit-polygon.component.sass'],
})
export class MsaEditPolygonComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: NgForm;
    msaObject: MSAObject;
    layer: Layer;
    coordinates: { type: string; coordinates: [number, number] }[];
    type: string;
    formError = '';
    errorcontrolObj = {
        'objectModel.DMS.Longitude.Deg': '',
        'objectModel.DMS.Longitude.Min': '',
        'objectModel.DMS.Longitude.Sec': '',
        'objectModel.DMS.Latitude.Deg': '',
        'objectModel.DMS.Latitude.Min': '',
        'objectModel.DMS.Latitude.Sec': '',
        'objectModel.DMm.Longitude.Deg': '',
        'objectModel.DMm.Longitude.Min': '',
        'objectModel.DMm.Latitude.Deg': '',
        'objectModel.DMm.Latitude.Min': '',
        'objectModel.Ddd.Longitude.Deg': '',
        'objectModel.Ddd.Latitude.Deg': '',
        'objectModel.index': '',
    };

    subscribedToFormChanges = false;
    longitudeHemisphereOptions: { label: string; value: 'E' | 'W' }[] = [];
    latitudeHemisphereOptions: { label: string; value: 'N' | 'S' }[] = [];
    pointhelper: number = 0;
    coordinatesFormatOptions: { label: string; value: CoordinatesFormat }[] = [];
    objectModel: {
        Layer: { label: string; value: string };
        Name: string;
        PositionUtc: string;
        ReferenceUtc: string;
        Color: string;
        Description: string;
        index: number;
        Opacity: number;
        LineOpacity: number;
        LineColor: string;
        LineWidth: number;
        LineDashed: boolean;
        Dynamic: boolean;
        DMS: {
            Longitude: {
                Deg: number;
                Min: number;
                Sec: number;
                Hemisphere: { label: string; value: 'E' | 'W' };
            };
            Latitude: {
                Deg: number;
                Min: number;
                Sec: number;
                Hemisphere: { label: string; value: 'N' | 'S' };
            };
        };
        DMm: {
            Longitude: {
                Deg: number;
                Min: number;
            };
            Latitude: {
                Deg: number;
                Min: number;
            };
        };
        Ddd: {
            Longitude: {
                Deg: number;
                Hemisphere: { label: string; value: 'E' | 'W' };
            };
            Latitude: {
                Deg: number;
                Hemisphere: { label: string; value: 'N' | 'S' };
            };
        };
        SOG: number;
        COG: number;
        CoordinatesFormat: { label: string; value: CoordinatesFormat };
    } = {
            Layer: undefined,
            Name: undefined,
            PositionUtc: undefined,
            ReferenceUtc: undefined,
            Color: undefined,
            Description: undefined,
            index: 0,
            Opacity: 100,
            LineOpacity: 100,
            LineColor: undefined,
            LineWidth: 1,
            LineDashed: false,
            Dynamic: false,
            DMS: {
                Longitude: {
                    Deg: undefined,
                    Min: undefined,
                    Sec: undefined,
                    Hemisphere: undefined,
                },
                Latitude: {
                    Deg: undefined,
                    Min: undefined,
                    Sec: undefined,
                    Hemisphere: undefined,
                },
            },
            DMm: {
                Longitude: {
                    Deg: undefined,
                    Min: undefined,
                },
                Latitude: {
                    Deg: undefined,
                    Min: undefined,
                },
            },
            Ddd: {
                Longitude: {
                    Deg: undefined,
                    Hemisphere: undefined,
                },
                Latitude: {
                    Deg: undefined,
                    Hemisphere: undefined,
                },
            },
            SOG: undefined,
            COG: undefined,
            CoordinatesFormat: { label: undefined, value: undefined },
        };

    private ngUnsubscribe = new Subject<void>();

    constructor(
        private injector: Injector,
        private dataService: DataService,
        private translateService: TranslateService,
        private permissionsService: PermissionsService,
        private coordinateService: CoordinateService,
    ) { }

    ngOnInit(): void {
        this.msaObject = this.injector.get('msaObject');
        this.layer = this.injector.get('layer');
        this.coordinates = [];
        this.objectModel.Name = this.msaObject.Properties.Name;
        this.objectModel.Description = this.msaObject.Properties.Description;
        this.objectModel.Color = this.msaObject.Color;
        this.objectModel.Opacity = this.msaObject.Opacity ? this.msaObject.Opacity * 100 : 100;
        this.objectModel.LineColor = this.msaObject.LineColor;
        this.objectModel.LineOpacity = this.msaObject.LineOpacity ? this.msaObject.LineOpacity * 100 : 100;
        this.objectModel.LineWidth = this.msaObject.LineWidth ? this.msaObject.LineWidth : 1;
        this.objectModel.LineDashed = !!this.msaObject.LineDashed;
        this.objectModel.Dynamic = this.msaObject.Properties?.Dynamic === 'true';
        if (this.objectModel.Dynamic) {
            this.objectModel.PositionUtc = this.msaObject.Properties.PositionUtc;
        } else {
            this.objectModel.PositionUtc = new Date().toISOString();
        }
        this.objectModel.ReferenceUtc = this.msaObject.Properties.ReferenceUtc;
        this.type = this.injector.get('type');

        this.longitudeHemisphereOptions = [
            { label: 'E', value: 'E' },
            { label: 'W', value: 'W' },
        ];
        this.latitudeHemisphereOptions = [
            { label: 'N', value: 'N' },
            { label: 'S', value: 'S' },
        ];

        if (!isNil(this.msaObject.Properties.COG)) {
            this.objectModel.COG = Number(this.msaObject.Properties.COG);
        }

        if (!isNil(this.msaObject.Properties.SOG)) {
            this.objectModel.SOG = Number(this.msaObject.Properties.SOG);
        }

        this.msaObject.Geometry.Coordinates[0].map((coordinate) => {
            this.coordinates.push({ type: 'Point', coordinates: coordinate });
        });
        if (this.type === 'Polygon') this.coordinates = this.coordinates.slice(0, this.coordinates.length - 1);
        this.objectModel.index = 0;
        this.transformPointToCoordinates(this.objectModel.index);

        this.coordinatesFormatOptions = Object.keys(CoordinatesFormat).map((k) => ({
            label: this.translateService.instant('PREFERENCES.COORDINATES.' + k.toUpperCase()),
            value: CoordinatesFormat[k],
        }));

        this.objectModel.CoordinatesFormat = this.coordinatesFormatOptions.find(
            (o) => o.value === this.permissionsService.getUser().Preferences.CoordinatesFormat,
        );
        if (this.objectModel.Dynamic) {
            this.objectModel.PositionUtc = this.msaObject.Properties.PositionUtc;
        } else {
            this.objectModel.PositionUtc = new Date().toISOString();
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onClickExpandDropDown(value: number) {
        Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
            e.style.width = value + 'rem';
        });
    }

    isDisabled() {
        return this.type === 'MultiLineString' ? this.coordinates.length < 3 : this.coordinates.length < 4;
    }

    cannotNavigateBack() {
        return this.objectModel.index <= 0;
    }
    cannotNavigateFurther() {
        return this.objectModel.index >= this.coordinates.length - 1;
    }

    addNewPoint() {
        this.transformCoordinatesToPoint(this.objectModel.index);
        this.coordinates.splice(this.objectModel.index + 1, 0, {
            type: this.coordinates[this.objectModel.index].type,
            coordinates: this.coordinates[this.objectModel.index].coordinates,
        });
        this.objectModel.index = this.objectModel.index + 1;
        this.transformPointToCoordinates(this.objectModel.index);
    }
    removeCurrentPoint() {
        if (!this.isDisabled()) {
            this.transformCoordinatesToPoint(this.objectModel.index);
            this.coordinates.splice(this.objectModel.index, 1);
            this.objectModel.index =
                this.objectModel.index === this.coordinates.length ? this.coordinates.length - 1 : this.objectModel.index;
            this.transformPointToCoordinates(this.objectModel.index);
        }
    }

    nextPoint() {
        if (this.pointhelper + 1 < this.coordinates.length && this.pointhelper + 1 >= 0) {
            this.pointhelper = this.objectModel.index;
            this.transformCoordinatesToPoint(this.objectModel.index);
            this.objectModel.index = this.pointhelper + 1;
            this.pointhelper = this.pointhelper + 1;
            this.transformPointToCoordinates(this.objectModel.index);
        }
    }
    previousPoint() {
        if (this.pointhelper - 1 >= 0 && this.pointhelper - 1 < this.coordinates.length) {
            this.pointhelper = this.objectModel.index;
            this.transformCoordinatesToPoint(this.objectModel.index);
            this.objectModel.index = this.pointhelper - 1;
            this.pointhelper = this.pointhelper - 1;
            this.transformPointToCoordinates(this.objectModel.index);
        }
    }

    transformPointToCoordinates(index: number) {
        const [lngDMS, latDMS] = this.coordinateService.DDToDMS(this.coordinates[index].coordinates);
        const [lngDMm, latDMm] = this.coordinateService.DDToDMm(this.coordinates[index].coordinates);
        const [lngDdd, latDdd] = this.coordinateService.DDToDdd(this.coordinates[index].coordinates);

        this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
        this.objectModel.DMS.Longitude.Min = lngDMS.min;
        this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
        this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
            (o) => o.value === lngDMS.hemisphere,
        );
        this.objectModel.DMS.Latitude.Deg = latDMS.deg;
        this.objectModel.DMS.Latitude.Min = latDMS.min;
        this.objectModel.DMS.Latitude.Sec = latDMS.sec;
        this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
            (o) => o.value === latDMS.hemisphere,
        );
        this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
        this.objectModel.DMm.Longitude.Min = lngDMm.min;
        this.objectModel.DMm.Latitude.Deg = latDMm.deg;
        this.objectModel.DMm.Latitude.Min = latDMm.min;
        this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
        this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
            (o) => o.value === lngDdd.hemisphere,
        );
        this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
        this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
            (o) => o.value === latDdd.hemisphere,
        );
    }
    transformCoordinatesToPoint(index: number) {
        let lng = undefined;
        let lat = undefined;

        if (this.objectModel.CoordinatesFormat.value == 'DMS') {
            lng = this.coordinateService.DMSToDD(
                [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
                this.objectModel.DMS.Longitude.Hemisphere.value,
            );
            lat = this.coordinateService.DMSToDD(
                [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
                this.objectModel.DMS.Latitude.Hemisphere.value,
            );
        } else if (this.objectModel.CoordinatesFormat.value == 'DM.m') {
            lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
            lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
        } else {
            lng = this.coordinateService.DddToDD(
                this.objectModel.Ddd.Longitude.Deg,
                this.objectModel.Ddd.Longitude.Hemisphere.value,
            );
            lat = this.coordinateService.DddToDD(
                this.objectModel.Ddd.Latitude.Deg,
                this.objectModel.Ddd.Latitude.Hemisphere.value,
            );
        }
        this.coordinates[index].coordinates = [lng, lat];
    }

    checkForInputErrors() {
        if (
            !this.subscribedToFormChanges &&
            this.form &&
            this.form.controls &&
            Object.keys(this.form.controls).length !== 0
        ) {
            this.listenToFormInputChanges();
            this.subscribedToFormChanges = true;
        }
    }

    listenToFormInputChanges() {
        const controlObj = {
            'objectModel.DMS.Latitude.Deg': {
                Min: 0,
                Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
            },
            'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
            'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
            'objectModel.DMS.Longitude.Deg': {
                Min: 0,
                Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
            },
            'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
            'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
            'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
            'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
            'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
            'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
            'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
            'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
            'objectModel.index': { Min: 0, Max: this.coordinates.length - 1 },
            'objectModel.SOG': { Min: 0 },
            'objectModel.COG': { Min: 0, Max: 359 },
        };
        Object.keys(controlObj).forEach((key) => {
            this.form.controls[key]?.valueChanges.subscribe((val) => {
                const controlObject = {
                    'objectModel.DMS.Latitude.Deg': {
                        Min: 0,
                        Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
                    },
                    'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
                    'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
                    'objectModel.DMS.Longitude.Deg': {
                        Min: 0,
                        Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
                    },
                    'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
                    'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
                    'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
                    'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
                    'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
                    'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
                    'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
                    'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
                    'objectModel.index': { Min: 0, Max: this.coordinates.length - 1 },
                    'objectModel.SOG': { Min: 0 },
                    'objectModel.COG': { Min: 0, Max: 359 },
                };
                if (isNaN(val)) {
                    this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.NUMBERREQUIRED');
                    this.form.controls[key].setErrors({ incorrect: true }); // <--- Set invalidNumber to true
                } else if (val < controlObject[key].Min) {
                    this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMIN', {
                        min: controlObject[key].Min,
                    });
                    this.form.controls[key].setErrors({ min: true });
                } else if (val > controlObject[key].Max) {
                    this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMAX', {
                        max: controlObject[key].Max,
                    });
                    this.form.controls[key].setErrors({ max: true });
                }
                // else if(key==="objectModel.index" &&){}
                else {
                    this.form.controls[key].setErrors(null);
                }
            });
        });
    }

    changedValue(event) {
        if (event >= 0 && event < this.coordinates.length && this.pointhelper != this.objectModel.index) {
            this.transformCoordinatesToPoint(this.pointhelper);
            this.pointhelper = event;
            this.transformPointToCoordinates(this.pointhelper);
        }
    }

    get pointError(): string {
        return this.errorcontrolObj['objectModel.radius'];
    }
    get DMSLatDegError(): string {
        return this.errorcontrolObj['objectModel.DMS.Latitude.Deg'];
    }
    get DMSLatMinError(): string {
        return this.errorcontrolObj['objectModel.DMS.Latitude.Min'];
    }
    get DMSLatSecError(): string {
        return this.errorcontrolObj['objectModel.DMS.Latitude.Sec'];
    }
    get DMSLngDegError(): string {
        return this.errorcontrolObj['objectModel.DMS.Longitude.Deg'];
    }
    get DMSLngMinError(): string {
        return this.errorcontrolObj['objectModel.DMS.Longitude.Min'];
    }
    get DMSLngSecError(): string {
        return this.errorcontrolObj['objectModel.DMS.Longitude.Sec'];
    }
    get DMmLatDegError(): string {
        return this.errorcontrolObj['objectModel.DMm.Latitude.Deg'];
    }
    get DMmLatMinError(): string {
        return this.errorcontrolObj['objectModel.DMm.Latitude.Min'];
    }
    get DMmLngDegError(): string {
        return this.errorcontrolObj['objectModel.DMm.Longitude.Deg'];
    }
    get DMmLngMinError(): string {
        return this.errorcontrolObj['objectModel.DMm.Longitude.Min'];
    }
    get DddLatDegError(): string {
        return this.errorcontrolObj['objectModel.Ddd.Latitude.Deg'];
    }
    get DddLngDegError(): string {
        return this.errorcontrolObj['objectModel.Ddd.Longitude.Deg'];
    }
    get cogError(): string {
        return this.errorcontrolObj['objectModel.COG'];
    }
    get sogError(): string {
        return this.errorcontrolObj['objectModel.SOG'];
    }

    get translatedType(): string {
        return this.type === 'Polygon'
            ? this.translateService.instant('MSA.MSAOBJECT.POLYGON')
            : this.type === 'MultiLineString'
                ? this.translateService.instant('MSA.MSAOBJECT.LINE')
                : this.translateService.instant('MSA.MSAOBJECT.CIRCLE');
    }

    get showFill(): boolean {
        return this.type !== 'MultiLineString';
    }

    checkForRepeatedPoints() {
        const tester = Array.from(new Set(this.coordinates));
        return tester.length === this.coordinates.length;
    }

    onSubmit() {
        this.transformCoordinatesToPoint(this.objectModel.index);

        if (!this.checkForRepeatedPoints()) {
            this.errorcontrolObj['objectModel.index'] = 'Please remove repeated points.';
            this.form.controls['objectModel.index'].setErrors({ incorrect: true }); // <--- Set invalidNumber to true
        } else {
            this.form.controls['objectModel.index'].setErrors(null);
        }

        if (this.form.invalid) {
            for (const i in this.form.controls) {
                this.form.controls[i].markAsTouched();
            }
            return;
        }

        const finalCoordinates = [];

        this.coordinates.forEach((lement) => {
            if (lement.type === 'Point') {
                finalCoordinates.push(lement.coordinates);
            }
        });

        if (this.type !== 'MultiLineString') finalCoordinates.push(finalCoordinates[0]);

        const propert: { key: string; value: string }[] = [];

        if (this.objectModel.Dynamic) {
            propert.push({ key: 'Dynamic', value: this.objectModel.Dynamic.toString() });
            propert.push({ key: 'PositionUtc', value: this.objectModel.PositionUtc });
            propert.push({ key: 'ReferenceUtc', value: this.objectModel.ReferenceUtc });
            propert.push({
                key: 'SOG',
                value: this.objectModel.SOG || this.objectModel.SOG == 0 ? String(this.objectModel.SOG) : undefined,
            });
            propert.push({
                key: 'COG',
                value: this.objectModel.COG || this.objectModel.COG == 0 ? String(this.objectModel.COG) : undefined,
            });
        }

        propert.push({ key: 'Description', value: this.objectModel.Description });

        const properties = {
            Provider: 'HUMAN',
            Name: this.objectModel.Name,
            ...propert.reduce((properties, pair) => {
                properties[pair.key] = pair.value;
                return properties;
            }, {}),
        };

        this.dataService
            .updateMSAObject({
                ...this.msaObject,
                Geometry: { Type: this.msaObject.Geometry.Type, Coordinates: [finalCoordinates] },
                Properties: properties,
                Color: this.objectModel.Color,
                Opacity: this.objectModel.Opacity / 100,
                LineOpacity: this.objectModel.LineOpacity / 100,
                LineColor: this.objectModel.LineColor,
                LineWidth: this.objectModel.LineWidth,
                LineDashed: this.objectModel.LineDashed,
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe({
                next: (msaObject) => this.injector.get('outputSubject').next(msaObject),
                error: (err) => (this.formError = err.error.error.type),
            });
    }

    onCoordinatesFormatChange(changes) {
        this.objectModel.CoordinatesFormat = changes.newFormat;
        this.updateCoordinates(changes.oldFormat.value, changes.newFormat.value);
    }

    updateCoordinates(oldFormat, newFormat) {
        let lng = undefined;
        let lat = undefined;

        if (oldFormat == 'DMS') {
            lng = this.coordinateService.DMSToDD(
                [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
                this.objectModel.DMS.Longitude.Hemisphere?.value,
            );
            lat = this.coordinateService.DMSToDD(
                [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
                this.objectModel.DMS.Latitude.Hemisphere?.value,
            );
        } else if (oldFormat == 'DM.m') {
            lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
            lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
        } else {
            lng = this.coordinateService.DddToDD(
                this.objectModel.Ddd.Longitude.Deg,
                this.objectModel.Ddd.Longitude.Hemisphere?.value,
            );
            lat = this.coordinateService.DddToDD(
                this.objectModel.Ddd.Latitude.Deg,
                this.objectModel.Ddd.Latitude.Hemisphere?.value,
            );
        }

        if (newFormat == 'DMS') {
            const [lngDMS, latDMS] = this.coordinateService.DDToDMS([lng, lat]);
            this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
            this.objectModel.DMS.Longitude.Min = lngDMS.min;
            this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
            this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
                (o) => o.value === lngDMS.hemisphere,
            );
            this.objectModel.DMS.Latitude.Deg = latDMS.deg;
            this.objectModel.DMS.Latitude.Min = latDMS.min;
            this.objectModel.DMS.Latitude.Sec = latDMS.sec;
            this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
                (o) => o.value === latDMS.hemisphere,
            );
        } else if (newFormat == 'DM.m') {
            const [lngDMm, latDMm] = this.coordinateService.DDToDMm([lng, lat]);
            this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
            this.objectModel.DMm.Longitude.Min = lngDMm.min;
            this.objectModel.DMm.Latitude.Deg = latDMm.deg;
            this.objectModel.DMm.Latitude.Min = latDMm.min;
        } else {
            const [lngDdd, latDdd] = this.coordinateService.DDToDdd([lng, lat]);
            this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
            this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
                (o) => o.value === lngDdd.hemisphere,
            );
            this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
            this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
                (o) => o.value === latDdd.hemisphere,
            );
        }
    }

    isRequired(coordinatesFormat) {
        return this.objectModel.CoordinatesFormat.value == coordinatesFormat;
    }

    openDatePicker(event) {
        const el = event.target.parentElement;

        this.openCustomHistoryStartDatePicker(el as HTMLElement)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((date) => {
                if (!date) {
                    return;
                }
                this.objectModel.PositionUtc = date.toISOString();
            });
    }

    private openCustomHistoryStartDatePicker(targetEl: HTMLElement): Observable<Date> {
        const date$ = new Subject<Date>();
        const offset = new Date().getTimezoneOffset();
        const pickerFp = flatpickr(targetEl, {
            enableTime: true,
            time_24hr: true,
            clickOpens: false,
            defaultDate: moment.utc(Date.now()).toDate(),
            plugins: [confirmDatePlugin({ showAlways: true, confirmIcon: '' })],
            enableSeconds: true,
            dateFormat: 'Z',
            onClose: () => {
                if (pickerFp.selectedDates.length === 0) {
                    return;
                }
                const date = moment(pickerFp.selectedDates[0]).subtract(offset, 'm').toDate();
                date$.next(date);
                date$.complete();
                setTimeout((_) => pickerFp.destroy());
            },
        });
        pickerFp.open();
        return date$.asObservable();
    }
}
