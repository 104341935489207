import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { TranslateService } from '@ngx-translate/core';
import { MsaManagePropertiesComponent } from '../msa-manage-properties/msa-manage-properties.component';
import { ModalService } from '@yaris/core/modal.service';

@Component({
  selector: 'msa-edit-properties',
  templateUrl: './msa-edit-properties.component.html',
  styleUrls: ['./msa-edit-properties.component.sass'],
})
export class MsaEditPropertiesComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  tagModel: {
    Name: string;
    Type: { label: string; value: string };
  } = { Name: undefined, Type: undefined };
  formError: any;
  tag;
  tagTypeOptions: { label: string; value: string }[];
  type: string;
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.tag = this.injector.get('tag');
    this.tagTypeOptions = [
      { label: 'System', value: 'System' },
      { label: 'Human', value: 'Human' },
    ];
    this.tagModel.Name = this.tag.label;
    this.tagModel.Type = this.tagTypeOptions.find((t) => t.label === this.tag.Type);
  }
  manageTags() {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGEPROPERTIES'),
      inputs: {},
      contentComponent: MsaManagePropertiesComponent,
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
    //this.tagModel.Type = this.type
    this.dataService
      .updateProperties({
        _id: this.tag._id,
        Value: this.tagModel.Name,
        Type: <any>this.tagModel.Type.value,
        Visible: this.tag.Visible,
      })
      .subscribe({
        next: (tag) => {
          this.injector.get('outputSubject').next(tag);
          this.manageTags();
        },
        error: (err) => {
          console.error('Error: ', err.error.type);
          console.error('Error: ', err);
          console.error('Error: ', err.error.error.type);
          if (err.error.error.type.includes('duplicate key error collection'))
            this.formError = this.translateService.instant('TAG.DUPLICATEKEYNAME');
          else this.formError = err.error.error.type;
        },
      });
  }
  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }
}
