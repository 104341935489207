import { Component, OnDestroy, OnInit, ViewChild, Injector } from '@angular/core';
import { DataService } from '@yaris/core/data.service';
import { UserPreferences, Language, CoordinatesFormat, DistanceUnit, LightMSALevel } from '@yaris/core/domain';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MsaService } from '@yaris/msa/msa.service';
import { PermissionsService } from '@yaris/core/permissions.service';

@Component({
  selector: 'app-preferences-dialog',
  templateUrl: './preferences-dialog.component.html',
  styleUrls: ['./preferences-dialog.component.sass'],
})
export class PreferencesDialogComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  formError = '';
  preferences: UserPreferences;
  preferencesModel: {
    AutoRefreshSession: boolean;
    EmailNotifications: boolean;
    ShowErrorDialog: boolean;
    Language: { label: string; value: Language };
    CoordinatesFormat: { label: string; value: CoordinatesFormat };
    DistanceUnit: { label: string; value: DistanceUnit };
    LightMSA: { label: string; value: LightMSALevel };
    AlertPreferences?: { SoundAlert?: boolean; NotifyAllSituations?: boolean };
  } = {
    AutoRefreshSession: undefined,
    ShowErrorDialog: undefined,
    EmailNotifications: undefined,
    Language: undefined,
    CoordinatesFormat: undefined,
    DistanceUnit: undefined,
    LightMSA: undefined,
    AlertPreferences: { SoundAlert: undefined, NotifyAllSituations: undefined },
  };

  languageOptions: { label: string; value: Language }[] = [];
  lightMSAOptions: { label: string; value: LightMSALevel }[] = [];
  coordinatesFormatOptions: { label: string; value: CoordinatesFormat }[] = [];
  distanceUnitOptions: { label: string; value: DistanceUnit }[] = [];
  private ngUnsubscribe = new Subject<void>();
  isLightMSA: boolean = false;
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private msaService: MsaService,
    private permissionService: PermissionsService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.preferences = this.injector.get('user').Preferences;

    this.languageOptions = Object.keys(Language).map((k) => ({
      label: this.translate.instant(`LANGUAGE.${Language[k]}`.toUpperCase()),
      value: Language[k],
    }));
    this.lightMSAOptions = Object.keys(LightMSALevel).map((k) => ({
      label: k,
      value: LightMSALevel[k],
    }));
    this.coordinatesFormatOptions = Object.keys(CoordinatesFormat).map((k) => ({
      label: this.translate.instant('PREFERENCES.COORDINATES.' + k.toUpperCase()),
      value: CoordinatesFormat[k],
    }));
    this.distanceUnitOptions = Object.keys(DistanceUnit).map((k) => ({
      label: DistanceUnit[k],
      value: DistanceUnit[k],
    }));

    this.preferencesModel.AutoRefreshSession = this.preferences?.AutoRefreshSession || false;
    this.preferencesModel.ShowErrorDialog = this.preferences?.ShowErrorDialog || false;
    this.preferencesModel.EmailNotifications = this.preferences?.EmailNotifications || false;
    this.preferencesModel.AlertPreferences.NotifyAllSituations =
      this.preferences?.AlertPreferences?.NotifyAllSituations || false;
    this.preferencesModel.AlertPreferences.SoundAlert = this.preferences?.AlertPreferences?.SoundAlert || false;

    // tslint:disable-next-line: max-line-length
    this.preferencesModel.Language = this.preferences?.Language
      ? this.languageOptions.find((o) => o.value === this.preferences?.Language)
      : this.languageOptions[0];
    // tslint:disable-next-line: max-line-length
    this.preferencesModel.Language = this.preferences?.Language
      ? this.languageOptions.find((o) => o.value === this.preferences?.Language)
      : this.languageOptions[0];
    // tslint:disable-next-line: max-line-length
    this.preferencesModel.CoordinatesFormat = this.preferences?.CoordinatesFormat
      ? this.coordinatesFormatOptions.find((o) => o.value === this.preferences?.CoordinatesFormat)
      : this.coordinatesFormatOptions[0];
    // tslint:disable-next-line: max-line-length
    this.preferencesModel.DistanceUnit = this.preferences?.DistanceUnit
      ? this.distanceUnitOptions.find((o) => o.value === this.preferences?.DistanceUnit)
      : this.distanceUnitOptions[0];
    this.preferencesModel.LightMSA = this.preferences?.LightMSA
      ? this.lightMSAOptions.find((o) => o.value === this.preferences?.LightMSA)
      : this.lightMSAOptions[0];

    this.isLightMSA = this.permissionService.isLightDomain();
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit() {
    if (this.form.invalid) {
      // tslint:disable-next-line: forin
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
    if (this.preferences.CoordinatesFormat != this.preferencesModel.CoordinatesFormat?.value) {
      this.dataService.coordinatesFormat$.next(this.preferencesModel.CoordinatesFormat?.value);
    }
    this.dataService
      .updateUserPreferences({
        ...this.preferences,
        AutoRefreshSession: this.preferencesModel.AutoRefreshSession,
        ShowErrorDialog: this.preferencesModel.ShowErrorDialog,
        EmailNotifications: this.preferencesModel.EmailNotifications,
        Language: this.preferencesModel.Language?.value,
        CoordinatesFormat: this.preferencesModel.CoordinatesFormat?.value,
        DistanceUnit: this.preferencesModel.DistanceUnit?.value,
        LightMSA: this.preferencesModel.LightMSA?.value,
        AlertPreferences: this.preferencesModel.AlertPreferences,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (user) => {
          this.injector.get('outputSubject').next(user);
          if (this.preferences.LightMSA !== this.preferencesModel.LightMSA.value) {
            this.msaService.removeAllLayersFromMap();
            window.location.reload();
          } else {
            if (this.preferences?.Language != user.Preferences?.Language) this.translate.use(user.Preferences.Language);
          }
        },
        error: (err) => {
          this.formError = err.error.error.type;
        },
      });
  }
}
