<form
  class="msa-manage-tags yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
    <text-control
      name="Search"
      class="search"
      (ngModelChange)="onSearch($event)"
      style="width: 100%"
      placeholder="{{ 'MSA.PLACEHOLDERS.SEARCHLAYERS' | translate }}"
      [(ngModel)]="search"
    >
    </text-control>
    <close-button *ngIf="search.length !== 0" (click)="onEndSearch(undefined, true)"></close-button>
    <main-button type="create" (click)="createTags()">{{ 'BUTTON.ADD' | translate }}</main-button>
  </div>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <ul class="icon-list" fxLayout="column">
      <ng-container>
        <li
          fxLayout
          fxLayoutGap="1rem"
          fxLayoutAlign="space-between center"
          class="icon-item"
          *ngFor="let icon of iconOptions"
        >
          <div fxLayout fxLayoutGap=".5rem" fxLayoutAlign="start center" style="width: 4rem">
            <span
              [inlineSVG]="getTypeIcon(icon)"
              alt="YARIS"
              [ngClass]="classTypeState(icon)"
              title="{{ getType(icon) }}"
            ></span>
            <span
              *ngIf="isSystem(icon)"
              [inlineSVG]="getManageLayerIcon(icon)"
              [ngClass]="classTypeState(icon)"
              alt="YARIS"
              class="logo"
              title="Layer"
            ></span>
          </div>
          <div fxLayout fxLayoutGap=".5rem" fxLayoutAlign="start center" style="width: 75%">
            <h4 style="width: 10rem; text-overflow: ellipsis">{{ icon.label }}</h4>
            <span class="description-item" title="{{ icon.Description }}">{{
              icon.Description ? icon.Description : ' '
            }}</span>
          </div>
          <ul class="icon-tools" fxLayout fxLayoutGap="1rem">
            <span
              [ngClass]="classTypeDelete(icon)"
              inlineSVG="/assets/images/trash.svg"
              (click)="onDeleteUnusedClick(icon)"
            ></span>
            <span
              [ngClass]="classTypeDefault()"
              [inlineSVG]="visibilityIcon(icon)"
              (click)="onVisibilityClick(icon)"
            ></span>
            <span
              [ngClass]="classTypeTool()"
              inlineSVG="/assets/images/cog.svg"
              (click)="onOptionsClick(icon, $event)"
            ></span>
          </ul>
        </li>
        <loading-spinner *ngIf="isLoading" class="loader"></loading-spinner>
      </ng-container>
    </ul>
  </div>
  <span *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </span>
</form>
