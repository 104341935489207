import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CenterStatusDialogComponent } from '@yaris/core/components/center-status-dialog/center-status-dialog.component';
import { DataService } from '@yaris/core/data.service';
import {
  NotificationActionType,
  NotificationObjectType,
  SensitivityLevel,
  Situation,
  User,
  LayerType,
  Notification,
  RolePermission,
  EnvironmentsLongName,
  IDefaultParams,
} from '@yaris/core/domain';
import { ModalService } from '@yaris/core/modal.service';
import { SocketService } from '@yaris/core/socket.service';
import { Subject, timer, interval, from } from 'rxjs';
import { delay, filter, flatMap, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from 'jwt-decode';
import { PreferencesDialogComponent } from '@yaris/core/components/preferences-dialog/preferences-dialog.component';
import { PermissionsService } from '@yaris/core/permissions.service';
import { MsaService } from '@yaris/msa/msa.service';
import { ListReportsComponent } from '@yaris/situation/list-reports/list-reports.component';
import { AdminService } from '@yaris/core/services/admin.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  userMenu = false;
  langMenu = false;
  notifications = false;
  newNotifications = 0;
  secondsCounter;
  secondsLostConnection = 0;
  lostConnection = false;
  sessionExpired = false;
  myvar: string;
  appVersion: string = '';
  environment: string = '';
  private situation: Situation;
  private ngUnsubscribe = new Subject<void>();
  private readonly sessionTimeoutCookieName = 'timeout';
  isYaonde = false;
  adminLink: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dataService: DataService,
    private socketService: SocketService,
    private modalService: ModalService,
    private cookieService: CookieService,
    private permissionsService: PermissionsService,
    private msaService: MsaService,
    private adminApiService: AdminService,
  ) {}

  async ngOnInit() {
    from(this.adminApiService.tools.getAll()).subscribe((tools) => {
      this.adminLink = tools?.find((tool) => tool.name === 'Admin')?.url || '/legacy/admin';
    });
    this.isYaonde = this.permissionsService.isYaonde();

    this.router.events
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(filter((e) => e instanceof NavigationEnd))
      .pipe(delay(200))
      .pipe(filter((event: NavigationEnd) => !event.url.startsWith('/situation')))
      .pipe(map((event: NavigationEnd) => this.router.parseUrl(event?.url)?.root?.children[PRIMARY_OUTLET]?.segments))
      .pipe(filter((segments) => segments?.length > 1))
      .pipe(map((segments) => segments[1]?.path))
      .pipe(switchMap((id) => this.dataService.getOperationalSituation(id)))
      .subscribe((situation) => (this.situation = situation));

    this.setNotificationListeners();
    this.setTokenExpirationListener();
    this.dataService
      .getLogoURL()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        document.getElementById('logoURL').setAttribute('href', res ? res : '#');
      });
    const lang = localStorage.getItem('Language');
    if (lang) this.onChangeLanguageClick(lang);

    const defaultParams: IDefaultParams = await this.getDefaultParamsSync();
    this.appVersion = defaultParams.AppVersion;
    this.environment = defaultParams.Environment;
  }

  showUserMenu() {
    return this.user && !location.href.includes('/denied');
  }

  showReport() {
    if (this.isYaonde && this.isOperationManager) {
      return true;
    }
  }

  get isLight(): boolean {
    return this.permissionsService.isLightDomain();
  }

  get isOperationManager(): boolean {
    return this.permissionsService.hasRolePermissionEqualOrAbove(RolePermission.operations_manager);
  }

  showCenterStatus() {
    return !location.href.includes('/denied') && !location.href.includes('/admin');
  }

  showNotifications() {
    return !location.href.includes('/denied') && !location.href.includes('/admin');
  }

  showMessageControl() {
    return !location.href.includes('/denied') && !location.href.includes('/admin');
  }

  getState() {
    if (this.socketService.connectionState == 1 && !this.lostConnection) this.setTimer();
    if (this.socketService.connectionState == 2 && this.lostConnection) this.clearTimer();
    return this.socketService.connectionState;
  }

  clearTimer() {
    clearInterval(this.secondsCounter);
    this.secondsLostConnection = 0;
    this.lostConnection = false;
  }
  setTimer() {
    this.lostConnection = true;
    const self = this;
    this.secondsCounter = setInterval(function () {
      self.secondsLostConnection += 1;
    }, 1000);
  }
  getTimeLost() {
    return this.secondsLostConnection;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get app(): string {
    if (this.router.url.startsWith('/msa')) {
      return 'MAP';
    }
    if (this.router.url.startsWith('/log')) {
      return 'LOG';
    }
    if (this.router.url.startsWith('/chat')) {
      return 'CHAT';
    }
    return '';
  }

  get title(): string {
    if (!this.situation) {
      return '';
    }
    if (this.router.url.startsWith('/situation')) {
      return '';
    }
    return this.situation.Name;
  }

  get subtitle(): string {
    if (!this.situation) {
      return '';
    }
    if (this.router.url.startsWith('/situation')) {
      return '';
    }
    return ' by '.concat(this.situation.Owner, ' @ ', this.situation.CreatedAt.split('T')[0]);
  }
  get titleColor(): string {
    if (!this.situation) {
      return '';
    }
    if (this.router.url.startsWith('/situation')) {
      return '';
    }
    if (this.situation.IsDefault) {
      return '';
    }
    switch (this.situation.SensitivityLevel) {
      case SensitivityLevel.Public:
        return 'green';
      case SensitivityLevel.Private:
        return 'yellow';
      case SensitivityLevel.Secret:
        return 'red';
      default:
        return 'green';
    }
  }

  get titleIcon(): string {
    if (!this.situation) {
      return '';
    }
    if (this.router.url.startsWith('/situation')) {
      return '';
    }
    if (this.situation.IsDefault) {
      return '';
    }
    return '/assets/images/' + this.situation.Security.toLowerCase() + '.svg';
  }

  get language(): string {
    return this.translate.currentLang || 'en';
  }

  get languageOptions(): string[] {
    return this.translate.getLangs();
  }

  get user(): User {
    return this.permissionsService.getUser();
  }

  get canManageAdminBoard(): boolean {
    return this.permissionsService.getUserPermissions().MSA.Manage_Admin_Board;
  }

  logout(): void {
    localStorage.removeItem('Language');
    localStorage.removeItem('Exercice');
    this.socketService.sendMsg('logout', this.user);
    if (
      location.pathname.indexOf('/situation') > -1 ||
      location.pathname.indexOf('/bandwidth') > -1 ||
      location.pathname.indexOf('/denied') > -1 ||
      location.pathname.indexOf('/admin') > -1
    ) {
      window.location.href = `/light/api/logout?appType=${this.permissionsService.isLightDomain() ? 'light' : 'msa'}`;
    } else window.close();
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  async refreshSession() {
    await this.dataService.refreshSession(this.isLight ? 'light' : 'msa');
  }

  onPreferencesClick(): void {
    this.modalService
      .open({
        contentComponent: PreferencesDialogComponent,
        exclusive: true,
        closable: true,
        draggable: true,
        title: this.translate.instant('HEADER.PREFERENCES'),
        inputs: { user: this.user },
      })
      .output.pipe(takeUntil(this.ngUnsubscribe))
      .pipe(
        tap((user) => {
          if (user.Preferences?.Language !== this.user.Preferences?.Language) {
            this.translate.use(user.Preferences.Language);
          }
        }),
      )
      .pipe(flatMap((out) => from(this.permissionsService.load())))
      .subscribe((user) => {});
  }

  onAdminBoardClick(): void {
    window.open(this.adminLink);
  }

  onNotificationClick(): void {
    this.notifications = true;
    this.newNotifications = 0;
  }

  onNotificationClose(): void {
    this.notifications = false;
  }

  onCenterStatusClick(): void {
    this.modalService.open({
      contentComponent: CenterStatusDialogComponent,
      exclusive: true,
      closable: true,
      title: this.translate.instant('HEADER.CENTERSTATUS'),
      inputs: {},
    });
  }

  onListAttachmentsPDF(): void {
    this.modalService.open({
      contentComponent: ListReportsComponent,
      exclusive: true,
      closable: true,
      title: this.translate.instant('HEADER.OFFICIALREPORTSLIST'),
      inputs: {},
    });
  }

  onChangeLanguageClick(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('Language', lang);
  }

  private timerSessionExpiration(): void {
    const timeoutToken = jwt_decode(this.cookieService.get(this.sessionTimeoutCookieName));
    const timeout = new Date(parseInt(timeoutToken.expires)).getTime() - new Date().getTime();
    timer(timeout - 5000).subscribe((_) => {
      // should refresh 5s before cookie expire
      const autoRefreshSession = this.user.Preferences?.AutoRefreshSession;
      if (autoRefreshSession === true && this.router.url.startsWith('/situation')) {
        location.reload();
      }
      this.sessionExpired = true;
    });
  }

  private setTokenExpirationListener() {
    this.timerSessionExpiration();
    interval(1000).subscribe((_) => {
      if (this.cookieService.get(this.sessionTimeoutCookieName) && this.sessionExpired === true) {
        const timeoutToken = jwt_decode(this.cookieService.get(this.sessionTimeoutCookieName));
        const timeout = new Date(parseInt(timeoutToken.expires)).getTime() - new Date().getTime();
        if (timeout > 0) {
          if (timeoutToken.user !== this.user?.Email) {
            location.reload();
          }
          this.sessionExpired = false;
          this.timerSessionExpiration();
        }
      }
    });
  }

  private setNotificationListeners(): void {
    const notifier = this.socketService.getNotifications().pipe(takeUntil(this.ngUnsubscribe));

    notifier
      .pipe(filter((_) => !this.notifications))
      .pipe(filter((n) => n.ObjectType === NotificationObjectType.Layer))
      .pipe(filter((n) => n.ActionType === NotificationActionType.Updated))
      .pipe(filter((n) => n.ObjectInfo?.LayerType === LayerType.Smart))
      .subscribe((notification) => this.newNotifications++);

    notifier
      .pipe(filter((_) => !this.notifications))
      .pipe(filter((n) => n.ObjectType === NotificationObjectType.Layer))
      .pipe(filter((n) => n.ActionType === NotificationActionType.Updated))
      .pipe(filter((n) => n.IsSharing))
      .subscribe((notification) => this.newNotifications++);

    notifier
      .pipe(filter((_) => !this.notifications))
      .pipe(filter((n) => n.ObjectType === NotificationObjectType.Situation))
      .pipe(filter((n) => n.ActionType === NotificationActionType.Updated))
      .pipe(filter((n) => n.IsSharing))
      .pipe(
        filter((n) => !!this.permissionsService.getGroupFromSharingGroupList({ SharingGroups: n.SharingGroups || [] })),
      )
      .subscribe((notification) => this.newNotifications++);

    notifier
      .pipe(filter((_) => !this.notifications))
      .pipe(filter((n) => n.ObjectType === NotificationObjectType.Situation))
      .pipe(filter((n) => n.ActionType === NotificationActionType.Created))
      .subscribe((notification) => this.newNotifications++);

    notifier
      .pipe(filter((_) => !this.notifications))
      .pipe(filter((n) => n.ObjectType === NotificationObjectType.MSAObject))
      .pipe(filter((n) => n.ActionType === NotificationActionType.Trespass))
      .pipe(filter((n) => this.msaService.hasLayer(n.ObjectInfo?._id)))
      .subscribe((notification) => this.newNotifications++);

    notifier
      .pipe(filter((_) => !this.notifications))
      .pipe(filter((n) => n.ObjectType === NotificationObjectType.Layer))
      .pipe(filter((n) => n.ActionType === NotificationActionType.Created))
      .pipe(filter((n) => n.ObjectInfo?.LayerType !== LayerType.ThirdPartyLayer))
      .subscribe((notification) => this.newNotifications++);

    notifier
      .pipe(filter((_) => !this.notifications))
      .pipe(filter((n) => n.ObjectType === NotificationObjectType.CenterState))
      .pipe(filter((n) => n.ActionType === NotificationActionType.Updated))
      .pipe(filter((n) => n.SharedWith !== this.permissionsService.getUser().GroupName))
      .subscribe((notification) => this.newNotifications++);

    notifier
      .pipe(
        filter(
          (n: Notification) =>
            n.ObjectType === NotificationObjectType.Situation && n.ActionType === NotificationActionType.Updated,
        ),
      )
      .subscribe((n: Notification) => {
        if (this.situation?._id == n.ObjectInfo?._id && n.ObjectInfo?._id && this.situation?._id)
          this.situation = n.ObjectInfo;
      });
  }

  getEnvironmentLongName(environment: string) {
    return EnvironmentsLongName[environment]?.toUpperCase();
  }

  goHome() {
    this.router.navigate(['/situation'], { replaceUrl: true });
  }

  private getDefaultParamsSync(): Promise<IDefaultParams> {
    return new Promise((resolve, reject) => {
      this.dataService.getDefaultParams().subscribe((d) => resolve(d));
    });
  }
}
