import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';

import { ChatPart, ContentSource } from './domain';
import { SocketService } from './socket.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  selectedFile: File;
  constructor(private _http: HttpClient, private socketService: SocketService, private dataService: DataService) {}

  downloadFile(file: string, pathtostorage?: string) {
    return this.dataService.downloadAttachment(file, pathtostorage);
  }

  exportChat(room_id) {
    return this._http.get(`/light/api/chat/exportChat`, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/zip').append('room_id', room_id),
      //headers: new HttpHeaders().append('Content-Type', 'text/csv').append('room_id', room_id)
    });
  }

  private buildMessage(filename: string, text: string) {
    const textPart = text ? `\n\n ${text}` : '';
    return filename + textPart;
  }

  onFileChanged(event, obj) {
    this.selectedFile = event.target.files[0];

    this.dataService
      .uploadAttachment(this.selectedFile, {
        Sensitivity: obj.SensitivityLevel,
        Security: obj.Security,
        Source: ContentSource.Chat,
        ParentName: obj.ChatName,
        Message: obj.Text,
        ParentId: null,
      })
      .subscribe(
        (res) => {
          const chatmessage = <ChatPart>{
            Chat_id: obj.Chat_id,
            User_id: obj.User_id,
            FirstName: obj.FirstName,
            Text: this.buildMessage(res.Filename, obj.Text),
            fileURL: res.Filename,
            Content: res._id,
          };
          this.socketService.sendMsg('chatMessage', chatmessage);
        },
        (err) => console.error(err),
      );
  }
}
