import { Directive, Input } from '@angular/core';

import * as _ from 'lodash';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[fileExtension][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FileExtensionValidator, multi: true }],
})
export class FileExtensionValidator {
  @Input() fileExtension: string[];

  validate(control: AbstractControl): { [validator: string]: any } {
    if (_.isNil(control.value) || !this.fileExtension || this.fileExtension.length === 0) {
      return null;
    }
    const filename = control.value.name;
    if (!filename) {
      return null;
    }
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    if (this.fileExtension.indexOf(extension) === -1) {
      return {
        fileExtension: {
          value: this.fileExtension,
          message: 'The file extension must be one of ' + this.fileExtension.join(', '),
        },
      };
    }
    return null;
  }
}
