import { Directive } from '@angular/core';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

@Directive({
  selector: '[integer][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: IntegerValidator, multi: true }],
})
export class IntegerValidator {
  validate(control: AbstractControl): { [validator: string]: any } {
    const integer = /^(?:-){0,1}[0-9]+$/g;
    if (_.isNil(control.value)) {
      // the [required] validator will check presence, not us
      return null;
    }
    const value = control.value;
    if (integer.test(value)) {
      return null;
    }

    return { integer: { message: 'The value must be an integer number' } };
  }
}
