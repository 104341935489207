import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.sass'],
})
export class CloseButtonComponent implements OnInit {
  ngOnInit(): void {}
}
