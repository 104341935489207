import { HttpClient } from '@angular/common/http';
import { IJobLog } from '../interfaces/joblog.interface';
import { AdminBaseService } from './admin-base.service';

export class AdminKibana extends AdminBaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  public async saveTemplateState(): Promise<any> {
    return this.getAsync(`/light/api/background-tasks/kibana/saveTemplateState`);
  }

  public async sincronizeUsersAsync(): Promise<any> {
    return this.getAsync(`/light/api/background-tasks/kibana/syncronizeUsers`);
  }

  public async sincronizeGroupsAsync(): Promise<any> {
    return this.getAsync(`/light/api/background-tasks/kibana/syncronizeGroups`);
  }

  public async getSyncronizationUsers(): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/kibana/getSyncronizationUsers`);
  }

  public async getLastSyncronizationUsers(): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/kibana/getLastSyncronizationUsers`);
  }

  public async getLastSyncronizationGroups(): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/kibana/getLastSyncronizationGroups`);
  }

  public async getSyncronizationGroups(): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/kibana/getSyncronizationGroups`);
  }

  public async deleteUserSyncronizationJobs(): Promise<any> {
    return this.deleteAsync(`/light/api/background-tasks/kibana/deleteUserSyncronizationJobs`);
  }

  public async deleteGroupsSyncronizationJobs(): Promise<any> {
    return this.deleteAsync(`/light/api/background-tasks/kibana/deleteGroupsSyncronizationJobs`);
  }

  public async getJob(id: string): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/kibana/getJob?id=${id}`);
  }
}
