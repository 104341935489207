<form
  class="msa-create-object yaris-form-reduced"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <mat-tab-group mat-stretch-tabs animationDuration="0ms">
    <mat-tab label="{{ 'MSA.MSAOBJECT.GLOBAL' | translate }}">
      <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
        <text-select-control-icons
          required
          title="Layer"
          name="objectModel.Layer"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="layerOptions"
          [(ngModel)]="objectModel.Layer"
          [initial]="objectModel.Layer"
          (click)="onClickExpandDropDown(37)"
        ></text-select-control-icons>
        <text-control
          required
          name="objectModel.Name"
          title="{{ 'PROPERTY.NAME' | translate }}"
          placeholder="{{ 'PROPERTY.NAME' | translate }}"
          [(ngModel)]="objectModel.Name"
        ></text-control>
        <text-control
          required
          name="objectModel.PositionUtc"
          title="{{ 'PROPERTY.POSITIONUTC' | translate }}"
          placeholder="{{ 'PROPERTY.POSITIONUTC' | translate }}"
          [(ngModel)]="objectModel.PositionUtc"
          (click)="openDatePicker($event, 'PositionUtc')"
        ></text-control>

        <checkbox-control
          name="objectModel.Dynamic"
          title="{{ 'PROPERTY.DYNAMIC' | translate }}"
          [(ngModel)]="objectModel.Dynamic"
        ></checkbox-control>

        <hr />

        <div class="property-list" fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="2rem">
          <numeric-control
            name="objectModel.IMONumber"
            title="IMONumber"
            placeholder="IMONumber"
            [(ngModel)]="objectModel.IMONumber"
            digits="7"
          ></numeric-control>
          <numeric-control
            name="objectModel.MMSI"
            title="MMSI"
            placeholder="MMSI"
            [(ngModel)]="objectModel.MMSI"
            digits="9"
          ></numeric-control>
        </div>
        <text-control
          name="objectModel.CallSign"
          title="CallSign"
          placeholder="CallSign"
          [(ngModel)]="objectModel.CallSign"
        ></text-control>
        <text-select-control
          title="{{ 'MSA.PLACEHOLDERS.VESSELTYPE' | translate }}"
          name="objectModel.VesselType"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="vesselTypeOptions"
          [(ngModel)]="objectModel.VesselType"
          [initial]="objectModel.VesselType"
          (click)="onClickExpandDropDown(37)"
        ></text-select-control>
        <text-select-control
          title="{{ 'MSA.PLACEHOLDERS.FLAG' | translate }}"
          name="objectModel.Flag"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="flagOptions"
          [(ngModel)]="objectModel.Flag"
          [initial]="objectModel.Flag"
          (click)="onClickExpandDropDown(37)"
        ></text-select-control>
        <text-select-control
          title="{{ 'MSA.PLACEHOLDERS.PORTOFORIGIN' | translate }}"
          name="objectModel.PortOfOrigin"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="portOfOriginOptions"
          [(ngModel)]="objectModel.PortOfOrigin"
          [initial]="objectModel.PortOfOrigin"
          (click)="onClickExpandDropDown(37)"
        ></text-select-control>

        <div style="width: 100%; align-items: center; display: inherit">
          <text-control
            name="objectModel.PortOfOriginATD"
            title="{{ 'MSA.PLACEHOLDERS.PORTOFORIGINATD' | translate }}"
            placeholder="{{ 'MSA.PLACEHOLDERS.PORTOFORIGINATD' | translate }}"
            [(ngModel)]="objectModel.PortOfOriginATD"
            style="min-width: 92%"
            disabled
          ></text-control>
          <help-button
            inlineSVG="/assets/images/calendar.svg"
            type="button"
            (click)="openDatePicker($event, 'ATD')"
            class="toolCalendar"
          ></help-button>
        </div>
        <text-select-control
          title="{{ 'MSA.PLACEHOLDERS.PORTOFDESTINATION' | translate }}"
          name="objectModel.Destination"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="portOfDestinationnOptions"
          [(ngModel)]="objectModel.Destination"
          [initial]="objectModel.Destination"
          (click)="onClickExpandDropDown(37)"
        ></text-select-control>
        <div style="width: 100%; align-items: center; display: inherit">
          <text-control
            name="objectModel.DestinationETA"
            title="{{ 'MSA.PLACEHOLDERS.PORTOFDESTINATIONETA' | translate }}"
            placeholder="{{ 'MSA.PLACEHOLDERS.PORTOFDESTINATIONETA' | translate }}"
            [(ngModel)]="objectModel.DestinationETA"
            style="min-width: 92%"
            disabled
          ></text-control>
          <help-button
            inlineSVG="/assets/images/calendar.svg"
            (click)="openDatePicker($event, 'ETA')"
            class="toolCalendar"
          ></help-button>
        </div>
        <text-select-control
          title="{{ 'MSA.PLACEHOLDERS.PORTOFREGISTRY' | translate }}"
          name="objectModel.PortOfRegistry"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="portOfRegistryOptions"
          [(ngModel)]="objectModel.PortOfRegistry"
          [initial]="objectModel.PortOfRegistry"
          (click)="onClickExpandDropDown(37)"
        ></text-select-control>
        <multi-text-select-control
          title="{{ 'MSA.PLACEHOLDERS.CARGO' | translate }}"
          name="objectModel.Cargo"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="cargoOptions"
          [(ngModel)]="objectModel.Cargo"
          [initial]="objectModel.Cargo"
          enableSelectAll="true"
          (click)="onClickExpandDropDown(37)"
        ></multi-text-select-control>

        <text-control
          name="objectModel.Owner"
          title="{{ 'MSA.PLACEHOLDERS.OWNER' | translate }}"
          placeholder="{{ 'MSA.PLACEHOLDERS.OWNER' | translate }}"
          [(ngModel)]="objectModel.Owner"
        ></text-control>

        <color-picker-control
          required
          name="objectModel.Color"
          title="{{ 'PROPERTY.COLOR' | translate }}"
          placeholder="{{ 'PLACEHOLDERS.HEXADECIMALCOLOR' | translate }}"
          [(ngModel)]="objectModel.Color"
        ></color-picker-control>
        <text-select-control-icons-list
          required
          name="objectModel.Icon"
          title="{{ 'PROPERTY.ICON' | translate }}"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="iconOptions"
          [(ngModel)]="objectModel.Icon"
          [initial]="objectModel.Icon"
          (click)="onClickExpandDropDown(37)"
        ></text-select-control-icons-list>

        <radio-control-coordinates
          required
          class="radio-control"
          (onCoordinatesFormatChange)="onCoordinatesFormatChange($event)"
          textColor="#3B4167"
          name="objectModel.CoordinatesFormat"
          title="{{ 'PREFERENCES.COORDINATESFORMAT' | translate }}"
          [options]="coordinatesFormatOptions"
          [initial]="objectModel.CoordinatesFormat"
          [inverted]="false"
          fxLayoutGap=".5rem"
        ></radio-control-coordinates>
        <div [hidden]="objectModel?.CoordinatesFormat?.value != 'DMS'">
          <div fxLayout="column" fxLayoutGap="1rem">
            <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> DD°MM'SS" [N,S]</code></span>
            <div fxLayout fxLayoutGap=".5rem">
              <div>
                <numeric-control
                  [required]="isRequired('DMS')"
                  name="objectModel.DMS.Latitude.Deg"
                  placeholder="{{ 'PROPERTY.DEG' | translate }}"
                  [(ngModel)]="objectModel.DMS.Latitude.Deg"
                  unit="º"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Deg']?.errors" class="form-input-error">{{
                  DMSLatDegError
                }}</span>
              </div>
              <div>
                <numeric-control
                  [required]="isRequired('DMS')"
                  name="objectModel.DMS.Latitude.Min"
                  placeholder="{{ 'PROPERTY.MIN' | translate }}"
                  [(ngModel)]="objectModel.DMS.Latitude.Min"
                  unit="'"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Min']?.errors" class="form-input-error">{{
                  DMSLatMinError
                }}</span>
              </div>
              <div>
                <numeric-control
                  [required]="isRequired('DMS')"
                  name="objectModel.DMS.Latitude.Sec"
                  placeholder="{{ 'PROPERTY.SEC' | translate }}"
                  [(ngModel)]="objectModel.DMS.Latitude.Sec"
                  unit="''"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Sec']?.errors" class="form-input-error">{{
                  DMSLatSecError
                }}</span>
              </div>
              <text-select-control
                [required]="isRequired('DMS')"
                name="objectModel.DMS.Latitude.Hemisphere"
                placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
                [options]="latitudeHemisphereOptions"
                [(ngModel)]="objectModel.DMS.Latitude.Hemisphere"
                [initial]="objectModel.DMS.Latitude.Hemisphere"
                style="width: 10rem"
                (click)="onClickExpandDropDown(5)"
              ></text-select-control>
            </div>
            <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> DDD°MM'SS" [W,E]</code></span>
            <div fxLayout fxLayoutGap=".5rem">
              <div>
                <numeric-control
                  [required]="isRequired('DMS')"
                  name="objectModel.DMS.Longitude.Deg"
                  placeholder="{{ 'PROPERTY.DEG' | translate }}"
                  [(ngModel)]="objectModel.DMS.Longitude.Deg"
                  unit="º"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Deg']?.errors" class="form-input-error">{{
                  DMSLngDegError
                }}</span>
              </div>
              <div>
                <numeric-control
                  [required]="isRequired('DMS')"
                  name="objectModel.DMS.Longitude.Min"
                  placeholder="{{ 'PROPERTY.MIN' | translate }}"
                  [(ngModel)]="objectModel.DMS.Longitude.Min"
                  unit="'"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Min']?.errors" class="form-input-error">{{
                  DMSLngMinError
                }}</span>
              </div>
              <div>
                <numeric-control
                  [required]="isRequired('DMS')"
                  name="objectModel.DMS.Longitude.Sec"
                  placeholder="{{ 'PROPERTY.SEC' | translate }}"
                  [(ngModel)]="objectModel.DMS.Longitude.Sec"
                  unit="''"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Sec']?.errors" class="form-input-error">{{
                  DMSLngSecError
                }}</span>
              </div>
              <text-select-control
                [required]="isRequired('DMS')"
                name="objectModel.DMS.Longitude.Hemisphere"
                placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
                [options]="longitudeHemisphereOptions"
                [(ngModel)]="objectModel.DMS.Longitude.Hemisphere"
                [initial]="objectModel.DMS.Longitude.Hemisphere"
                style="width: 10rem"
                (click)="onClickExpandDropDown(5)"
              ></text-select-control>
            </div>
          </div>
        </div>
        <div [hidden]="objectModel?.CoordinatesFormat?.value != 'DM.m'">
          <div fxLayout="column" fxLayoutGap="1rem">
            <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> [-]DD°MM.MMMMMM'</code></span>
            <div fxLayout fxLayoutGap=".5rem">
              <div>
                <numeric-control
                  [required]="isRequired('DM.m')"
                  name="objectModel.DMm.Latitude.Deg"
                  placeholder="{{ 'PROPERTY.DEG' | translate }}"
                  [(ngModel)]="objectModel.DMm.Latitude.Deg"
                  unit="º"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMm.Latitude.Deg']?.errors" class="form-input-error">{{
                  DMmLatDegError
                }}</span>
              </div>
              <div>
                <numeric-control
                  [required]="isRequired('DM.m')"
                  name="objectModel.DMm.Latitude.Min"
                  placeholder="{{ 'PROPERTY.MIN' | translate }}"
                  [(ngModel)]="objectModel.DMm.Latitude.Min"
                  unit="'"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMm.Latitude.Min']?.errors" class="form-input-error">{{
                  DMmLatMinError
                }}</span>
              </div>
            </div>
            <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> [-]DDD°MM.MMMMMM'</code></span>
            <div fxLayout fxLayoutGap=".5rem">
              <div>
                <numeric-control
                  [required]="isRequired('DM.m')"
                  name="objectModel.DMm.Longitude.Deg"
                  placeholder="{{ 'PROPERTY.DEG' | translate }}"
                  [(ngModel)]="objectModel.DMm.Longitude.Deg"
                  unit="º"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMm.Longitude.Deg']?.errors" class="form-input-error">{{
                  DMmLngDegError
                }}</span>
              </div>
              <div>
                <numeric-control
                  [required]="isRequired('DM.m')"
                  name="objectModel.DMm.Longitude.Min"
                  placeholder="{{ 'PROPERTY.MIN' | translate }}"
                  [(ngModel)]="objectModel.DMm.Longitude.Min"
                  unit="'"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.DMm.Longitude.Min']?.errors" class="form-input-error">{{
                  DMmLngMinError
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div [hidden]="objectModel?.CoordinatesFormat?.value != 'D.dd'">
          <div fxLayout="column" fxLayoutGap="1rem">
            <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> DD.MMMMMM° [N,S]</code></span>
            <div fxLayout="row" style="justify-content: space-evenly" fxLayoutGap=".5rem">
              <div>
                <numeric-control
                  [required]="isRequired('D.dd')"
                  name="objectModel.Ddd.Latitude.Deg"
                  placeholder="{{ 'PROPERTY.DEG' | translate }}"
                  [(ngModel)]="objectModel.Ddd.Latitude.Deg"
                  unit="º"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.Ddd.Latitude.Deg']?.errors" class="form-input-error">{{
                  DddLatDegError
                }}</span>
              </div>
              <text-select-control
                [required]="isRequired('D.dd')"
                name="objectModel.Ddd.Latitude.Hemisphere"
                placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
                [options]="latitudeHemisphereOptions"
                [(ngModel)]="objectModel.Ddd.Latitude.Hemisphere"
                [initial]="objectModel.Ddd.Latitude.Hemisphere"
                style="width: 10rem"
                (click)="onClickExpandDropDown(5)"
              ></text-select-control>
            </div>
            <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> DDD.MMMMMM° [W,E]</code></span>
            <div fxLayout="row" style="justify-content: space-evenly" fxLayoutGap=".5rem">
              <div>
                <numeric-control
                  [required]="isRequired('D.dd')"
                  name="objectModel.Ddd.Longitude.Deg"
                  placeholder="{{ 'PROPERTY.DEG' | translate }}"
                  [(ngModel)]="objectModel.Ddd.Longitude.Deg"
                  unit="º"
                ></numeric-control>
                <span *ngIf="this.form?.controls['objectModel.Ddd.Longitude.Deg']?.errors" class="form-input-error">{{
                  DddLngDegError
                }}</span>
              </div>
              <text-select-control
                [required]="isRequired('D.dd')"
                name="objectModel.Ddd.Longitude.Hemisphere"
                placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
                [options]="longitudeHemisphereOptions"
                [(ngModel)]="objectModel.Ddd.Longitude.Hemisphere"
                [initial]="objectModel.Ddd.Longitude.Hemisphere"
                style="width: 10rem"
                (click)="onClickExpandDropDown(5)"
              ></text-select-control>
            </div>
          </div>
        </div>

        <div fxLayout fxLayoutGap="1rem">
          <div>
            <numeric-control
              min="0"
              name="objectModel.SOG"
              title="{{ 'PROPERTY.SOG' | translate }}"
              placeholder="{{ 'PROPERTY.SOG' | translate }}"
              [(ngModel)]="objectModel.SOG"
              unit="Knts"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.SOG']?.errors" class="form-input-error">{{ sogError }}</span>
          </div>
          <div>
            <numeric-control
              min="0"
              name="objectModel.COG"
              title="{{ 'PROPERTY.COG' | translate }}"
              placeholder="{{ 'PROPERTY.COG' | translate }}"
              [(ngModel)]="objectModel.COG"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.COG']?.errors" class="form-input-error">{{ cogError }}</span>
            <span *ngIf="checkForInputErrors()"></span>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="{{ 'MSA.MSAOBJECT.EXTENDEDDATA' | translate }}">
      <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
        <h3>{{ 'MSA.MSAOBJECT.GENERALDATA' | translate }}</h3>
        <text-select-control
          title="{{ 'MSA.MSAOBJECT.SHOWINMAP' | translate }}"
          name="extendedDataOptions.ShowInMap.GeneralSection"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="vesselDataOptions"
          [(selectedValue)]="extendedDataOptions.ShowInMap.GeneralSection"
          [initialValue]="extendedDataOptions.ShowInMap.GeneralSection"
          (click)="onClickExpandDropDown(37)"
        >
        </text-select-control>
        <text-control
          name="objectModel.Comments"
          title="{{ 'MSA.PLACEHOLDERS.COMMENTS' | translate }}"
          placeholder="{{ 'MSA.PLACEHOLDERS.COMMENTS' | translate }}"
          [(ngModel)]="
            getVesselExtendedDataByType(extendedDataOptions.ShowInMap.GeneralSection).GeneralSection.Comments
          "
        >
        </text-control>

        <div fxLayout="column" fxLayoutGap="1rem">
          <div fxLayout="column" fxLayoutGap="1rem">
            <div fxLayout fxLayoutAlign="space-between center">
              <span>{{ 'PROPERTY.HUMANPROPERTY' | translate }}</span>
              <button
                class="tool"
                type="button"
                title="{{ 'BUTTON.NEWHUMANPROPERTY' | translate }}"
                inlineSVG="/assets/images/new.svg"
                (click)="onNewHumanFieldClick()"
              ></button>
            </div>
            <div
              class="property-list"
              fxLayout
              fxLayoutGap=".5rem"
              *ngFor="let humanProperty of getSelectHumanModel(); let i = index"
            >
              <text-control
                [name]="'key' + i"
                title="{{ 'PROPERTY.PROPERTY' | translate }}"
                placeholder="{{ 'PROPERTY.PROPERTY' | translate }}"
                [(ngModel)]="getSelectHumanModel()[i].key"
              ></text-control>
              <text-control
                [name]="'value' + i"
                title="{{ 'PROPERTY.VALUE' | translate }}"
                placeholder="{{ 'PROPERTY.VALUE' | translate }}"
                [(ngModel)]="getSelectHumanModel()[i].value"
              ></text-control>
              <button
                class="tool red"
                type="button"
                (click)="onCloseHumanFieldClick(i)"
                inlineSVG="/assets/images/cross.svg"
              >
                {{ 'BUTTON.REMOVE' | translate }}
              </button>
            </div>
          </div>
        </div>

        <ng-container *ngIf="false">
          <h3>{{ 'MSA.MSAOBJECT.CLASSIFICATIONDATA' | translate }}</h3>
          <text-select-control
            title="{{ 'MSA.MSAOBJECT.SHOWINMAP' | translate }}"
            name="extendedDataOptions.ShowInMap.ClassificationSection"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="vesselDataOptions"
            [(selectedValue)]="extendedDataOptions.ShowInMap.ClassificationSection"
            [initialValue]="extendedDataOptions.ShowInMap.ClassificationSection"
            (click)="onClickExpandDropDown(37)"
          >
          </text-select-control>
          <text-select-control
            title="Classification"
            name="Classification"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="classificationOptions"
            [(selectedValue)]="
              getVesselExtendedDataByType(extendedDataOptions.ShowInMap.ClassificationSection).ClassificationSection
                .Classification
            "
            [initialValue]="
              getVesselExtendedDataByType(extendedDataOptions.ShowInMap.ClassificationSection).ClassificationSection
                .Classification
            "
            (click)="onClickExpandDropDown(37)"
          >
          </text-select-control>
          <text-select-control
            title="VOI"
            name="VOI"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="voiOptions"
            [(selectedValue)]="
              getVesselExtendedDataByType(extendedDataOptions.ShowInMap.ClassificationSection).ClassificationSection.VOI
            "
            [initialValue]="
              getVesselExtendedDataByType(extendedDataOptions.ShowInMap.ClassificationSection).ClassificationSection.VOI
            "
            (click)="onClickExpandDropDown(37)"
          >
          </text-select-control>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
  <span *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </span>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{ 'BUTTON.SUBMIT' | translate }}</main-button>
  </div>
</form>
