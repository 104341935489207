import { Directive } from '@angular/core';

import * as _ from 'lodash';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[minMaxRange][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinMaxRangeValidator, multi: true }],
})
export class MinMaxRangeValidator {
  validate(control: AbstractControl): { [validator: string]: any } {
    if (_.isNil(control.value) || _.isNil(control.value[0]) || _.isNil(control.value[1])) {
      // the [required] validator will check presence, not us
      return null;
    }
    if (isNaN(control.value[0]) || isNaN(parseFloat(control.value[0]))) {
      return null;
    }
    if (isNaN(control.value[1]) || isNaN(parseFloat(control.value[1]))) {
      return null;
    }
    const vmin = Number(control.value[0]);
    const vmax = Number(control.value[1]);
    if (!isFinite(vmin) || !isFinite(vmax)) {
      return null;
    }
    if (vmax < vmin) {
      return { minMaxRange: { message: 'The maximum value must be greater than the minimum' } };
    }
    return null;
  }
}
