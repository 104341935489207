import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ContentSource, SecurityType, SensitivityLevel, UserSummary } from '@yaris/core/domain';

export interface Report {
  _id: string;
  Filename: string;
  CreatedAt: Date;
  CreatedBy?: UserSummary;
  Sensitivity: SensitivityLevel;
  Security: SecurityType;
  Body: {
    Source: ContentSource;
    ParentName: string;
    Message?: string;
  };
}

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.sass'],
})
export class ReportDetailComponent {
  @Input() report: Report;
  @Output() download = new EventEmitter<Report>();

  securityIcon() {
    return '/assets/images/' + this.report.Security.toLowerCase() + '.svg';
  }

  onDownloadClick() {
    this.download.emit(this.report);
  }
}
