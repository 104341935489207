import { Directive, Input } from '@angular/core';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

@Directive({
  selector: '[decimals][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DecimalsValidator, multi: true }],
})
export class DecimalsValidator {
  @Input() decimals: number;
  validate(control: AbstractControl): { [validator: string]: any } {
    if (_.isNil(control.value) || _.isNil(this.decimals) || !control.value) {
      return null;
    }
    const integer = /^(?:-){0,1}[0-9]+$/g;
    const float = new RegExp(`^(?:-){0,1}[0-9]+[\\.\\,]{0,1}[0-9]{1,${this.decimals}}$`, 'g');
    const value = control.value;
    if (float.test(value) || integer.test(value)) {
      return null;
    }

    return {
      currency: { message: 'The value must be a number with a maximum of ' + this.decimals + ' decimal cases' },
    };
  }
}
