import { Component, Input, OnInit } from '@angular/core';
import { BackgroundProcessor, Content, ItemBackgroundProcessingStatusType } from '@yaris/core/domain';

@Component({
  selector: 'app-attachment-thumbnail',
  templateUrl: './attachment-thumbnail.component.html',
  styleUrls: ['./attachment-thumbnail.component.sass'],
})
export class AttachmentThumbnailComponent implements OnInit {
  @Input() content: Content;

  viewerOptions = {
    navbar: false,
    movable: true,
    rotatable: true,
    zoomable: true,
    toolbar: {
      zoomIn: true,
      zoomOut: true,
      oneToOne: true,
      reset: true,
      prev: false,
      play: {
        show: false,
      },
      next: false,
      rotateLeft: true,
      rotateRight: true,
      flipHorizontal: true,
      flipVertical: true,
    },
    url: (image) => {
      return this.originalUrl();
    },
    title: (image) => {
      return this.title();
    },
  };

  ngOnInit(): void {}

  originalUrl() {
    return '/api/content/download?filename=' + encodeURIComponent(this.content.Filename);
  }

  thumbnailUrl(): string {
    return '/api/content/thumbnail?filename=' + encodeURIComponent(this.content.Filename);
  }

  title() {
    return this.content.Filename;
  }

  getThumbnailStatus(): ItemBackgroundProcessingStatusType {
    if (!this.content) return null;

    return this.content.StatusOfBackgroundProcessings?.[BackgroundProcessor.ThumbnailPostProcessor]?.status;
  }

  isThumbnailLoading() {
    const status = this.getThumbnailStatus();
    return !!status && status !== ItemBackgroundProcessingStatusType.Done;
  }

  hasThumbnail(): boolean {
    return !!this.getThumbnailStatus();
  }
}
