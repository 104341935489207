import { Directive, Input } from '@angular/core';

import * as _ from 'lodash';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[min][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValidator, multi: true }],
})
export class MinValidator {
  @Input() min: number;

  validate(control: AbstractControl): { [validator: string]: any } {
    if (_.isNil(control.value) || _.isNil(this.min)) {
      // the [required] validator will check presence, not us
      return null;
    }
    const v = Number(control.value);
    if (v >= +this.min) {
      return null;
    }

    return { min: { value: +this.min, message: 'The value must be greater than ' + this.min } };
  }
}
