import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '@yaris/core/permissions.service';

@Component({
  selector: 'app-default-landing-page',
  templateUrl: './default-landing-page.component.html',
  styleUrls: ['./default-landing-page.component.sass'],
})
export class DefaultLandingPageComponent implements OnInit {
  constructor(private router: Router, private readonly permissionsService: PermissionsService) {}

  ngOnInit(): void {
    if (this.permissionsService.isLightDomain()) {
        this.router.navigate(['/situation'], { replaceUrl: true });
      } else {
        this.router.navigate(['/bandwidth'], { replaceUrl: true });
      }
  }
}
