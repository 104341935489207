<ul class="center-status-dialog" fxLayout="column" fxLayoutGap="1rem">
  <li class="placeholder" *ngIf="!groups">{{ 'LOADING' | translate }}</li>
  <li
    *ngFor="let group of groups; index as ix; first as f; last as l"
    fxLayout
    fxLayoutGap=".5rem"
    fxLayoutAlign="start center"
    [style.paddingLeft.rem]="group.Depth * 2"
    [style.paddingTop.rem]="f ? 0 : groups[ix - 1].Depth > group.Depth ? 0.5 : 0"
  >
    <i *ngIf="!group.NodeGroup" [class.active]="group.Status"></i>
    <h4 *ngIf="group.NodeGroup">
      {{ group.Description || group.Name }} {{ group.CountryCode ? '(' + group.CountryCode + ')' : '' }}
    </h4>
    <span *ngIf="!group.NodeGroup"
      >{{ group.Description || group.Name }} {{ group.CountryCode ? '(' + group.CountryCode + ')' : '' }}</span
    >
  </li>
</ul>
