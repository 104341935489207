import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { PermissionsService } from '@yaris/core/permissions.service';
import {
  CoordinatesFormat,
  Layer,
  SecurityType,
  SensitivityLevel,
} from '@yaris/core/domain';
import { CoordinateService } from '@yaris/msa/mapbox/services/coordinate.service';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '@yaris/core/modal.service';
import { MsaService } from '@yaris/msa/msa.service';
import { TranslateService } from '@ngx-translate/core';
import flatpickr from 'flatpickr';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import * as moment from 'moment';

@Component({
  selector: 'app-log-create-point',
  templateUrl: './log-create-point.component.html',
  styleUrls: ['./log-create-point.component.sass'],
})
export class LogCreatePointComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  layers: Layer[];
  event;
  initialCoordinates;
  situationId: string;
  formError = '';
  errorcontrolObj = {
    'objectModel.SOG': '',
    'objectModel.COG': '',
    'objectModel.DMS.Longitude.Deg': '',
    'objectModel.DMS.Longitude.Min': '',
    'objectModel.DMS.Longitude.Sec': '',
    'objectModel.DMS.Latitude.Deg': '',
    'objectModel.DMS.Latitude.Min': '',
    'objectModel.DMS.Latitude.Sec': '',
    'objectModel.DMm.Longitude.Deg': '',
    'objectModel.DMm.Longitude.Min': '',
    'objectModel.DMm.Latitude.Deg': '',
    'objectModel.DMm.Latitude.Min': '',
    'objectModel.Ddd.Longitude.Deg': '',
    'objectModel.Ddd.Latitude.Deg': '',
  };
  objectModel: {
    Layer: { label: string; value: string };
    Name: string;
    PositionUtc: string;
    Color: string;
    Icon: { label: string; value: string };
    DMS: {
      Longitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    DMm: {
      Longitude: {
        Deg: number;
        Min: number;
      };
      Latitude: {
        Deg: number;
        Min: number;
      };
    };
    Ddd: {
      Longitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    SOG: number;
    COG: number;
    Properties: {
      key: string;
      value: string;
    }[];
    IMONumber: string;
    MMSI: string;
    CallSign: string;
    Classification: { label: string; value: string };
    VesselType: { label: string; value: string };
    Flag: { label: string; value: string };
    PortOfOrigin: { label: string; value: string; PortLOCODE?: string };
    PortOfOriginATD: string;
    Destination: { label: string; value: string; PortLOCODE?: string };
    DestinationETA: string;
    PortOfRegistry: { label: string; value: string };
    Cargo: { label: string; value: string }[];
    VOI: { label: string; value: string };
    Owner: string;
    Comments: string;
    CoordinatesFormat: { label: string; value: CoordinatesFormat };
  } = {
    Layer: undefined,
    Name: undefined,
    Color: undefined,
    Icon: undefined,
    SOG: undefined,
    COG: undefined,
    PositionUtc: undefined,
    IMONumber: undefined,
    MMSI: undefined,
    CallSign: undefined,
    Classification: undefined,
    VesselType: undefined,
    Flag: undefined,
    PortOfOrigin: undefined,
    PortOfOriginATD: undefined,
    Destination: undefined,
    DestinationETA: undefined,
    PortOfRegistry: undefined,
    Cargo: undefined,
    VOI: undefined,
    Owner: undefined,
    Comments: undefined,
    DMS: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
    },
    DMm: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
      },
    },
    Ddd: {
      Longitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
    },
    Properties: [],
    CoordinatesFormat: { label: undefined, value: undefined },
  };
  longitudeHemisphereOptions: { label: string; value: 'E' | 'W' }[] = [];
  latitudeHemisphereOptions: { label: string; value: 'N' | 'S' }[] = [];
  subscribedToFormChanges = false;
  layerOptions: {
    label: string;
    value: string;
    security: SecurityType;
    sensitivity: SensitivityLevel;
    type: string;
    defaultFromSituation: string;
  }[] = [];
  iconOptions: { label: string; value: string }[] = [];
  classificationOptions: { label: string; value: string }[] = [];
  vesselTypeOptions: { label: string; value: string }[] = [];
  flagOptions: { label: string; value: string }[] = [];
  portOfOriginOptions: { label: string; value: string; PortLOCODE: string }[] = [];
  portOfDestinationnOptions: { label: string; value: string; PortLOCODE: string }[] = [];
  portOfRegistryOptions: { label: string; value: string }[] = [];
  cargoOptions: { label: string; value: string }[] = [];
  coordinatesFormatOptions: { label: string; value: CoordinatesFormat }[] = [];

  voiOptions: { label: string; value: string }[] = [];

  private cargoSplitter: string = ' ;;; ';

  private ngUnsubscribe = new Subject<void>();

  formGroup = new FormGroup({});
  constructor(
    private injector: Injector,
    private msaService: MsaService,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private coordinateService: CoordinateService,
    private permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.layers = this.injector.get('layers');
    this.event = this.injector.get('event');
    this.initialCoordinates = this.injector.get('initialCoordinates');
    this.situationId = this.injector.get('situationId');
    this.longitudeHemisphereOptions = [
      { label: 'E', value: 'E' },
      { label: 'W', value: 'W' },
    ];
    this.latitudeHemisphereOptions = [
      { label: 'N', value: 'N' },
      { label: 'S', value: 'S' },
    ];

    this.coordinatesFormatOptions = Object.keys(CoordinatesFormat).map((k) => ({
      label: this.translateService.instant('PREFERENCES.COORDINATES.' + k.toUpperCase()),
      value: CoordinatesFormat[k],
    }));

    this.objectModel.CoordinatesFormat = this.coordinatesFormatOptions.find(
      (o) => o.value === this.permissionsService.getUser().Preferences.CoordinatesFormat,
    );
    if (this.initialCoordinates) {
      this.setCoordinates(this.initialCoordinates);
    }

    this.classificationOptions = [
      { label: 'Friendly', value: 'Friendly' },
      { label: 'Suspect', value: 'Suspect' },
      { label: 'Neutral', value: 'Neutral' },
      { label: 'Under Evaluation', value: 'Under Evaluation' },
      { label: 'Hostile', value: 'Hostile' },
    ];
    this.voiOptions = [
      { label: '0', value: '0' },
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
    ];
    this.layerOptions = this.layers
      .sort((a, b) => -a.UpdatedAt.localeCompare(b.UpdatedAt))
      .map((l) => ({
        label: l.Name,
        value: l._id,
        security: l.Security,
        sensitivity: l.SensitivityLevel,
        type: l.LayerType,
        defaultFromSituation: l?.DefaultFromSituation,
      }));
    this.dataService
      .listMsaObjectsDefaultProperties('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((properties) => {
        this.vesselTypeOptions = properties
          .filter((prop) => prop.Type === 'VesselType')
          .map((prop) => ({ label: prop.Value, value: prop._id }));
        this.vesselTypeOptions.sort(this.compare);
        this.flagOptions = properties
          .filter((prop) => prop.Type === 'Flag')
          .map((prop) => ({ label: prop.Value, Value: prop._id }));
        this.flagOptions.sort(this.compare);
        this.portOfOriginOptions = properties
          .filter((prop) => prop.Type === 'Port')
          .map((prop) => ({ label: prop.Value, value: prop._id, PortLOCODE: prop.PortLOCODE }));
        this.portOfOriginOptions.sort(this.compare);
        this.portOfDestinationnOptions = properties
          .filter((prop) => prop.Type === 'Port')
          .map((prop) => ({ label: prop.Value, value: prop._id, PortLOCODE: prop.PortLOCODE }));
        this.portOfDestinationnOptions.sort(this.compare);
        this.portOfRegistryOptions = properties
          .filter((prop) => prop.Type === 'Port')
          .map((prop) => ({ label: prop.Value, value: prop._id }));
        this.portOfRegistryOptions.sort(this.compare);
        this.cargoOptions = properties
          .filter((prop) => prop.Type === 'Cargo')
          .map((prop) => ({ label: prop.Value, value: prop._id }));
        this.cargoOptions.sort(this.compare);
      });
    this.dataService
      .listMsaIcons('true')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((icons) => {
        this.iconOptions = this.iconOptions.concat(icons.map((i) => ({ label: i.Name, value: i.IconURL })));
        this.objectModel.Icon = this.iconOptions[0];
      });

    this.iconOptions.sort(this.compare);
    this.objectModel.Layer = this.layerOptions.find((layer) => layer?.defaultFromSituation === this.situationId);
    this.objectModel.Color = '#00FF00';
    this.objectModel.PositionUtc = new Date().toISOString();
  }

  private openCustomHistoryStartDatePicker(targetEl: HTMLElement): Observable<Date> {
    const date$ = new Subject<Date>();
    const offset = new Date().getTimezoneOffset();
    const pickerFp = flatpickr(targetEl, {
      enableTime: true,
      time_24hr: true,
      clickOpens: false,
      defaultDate: moment.utc(Date.now()).toDate(),
      plugins: [confirmDatePlugin({ showAlways: true, confirmIcon: '' })],

      dateFormat: 'Z',
      onClose: () => {
        if (pickerFp.selectedDates.length === 0) {
          return;
        }
        const date = moment(pickerFp.selectedDates[0]).subtract(offset, 'm').toDate();
        date$.next(date);
        date$.complete();
        setTimeout((_) => pickerFp.destroy());
      },
    });
    pickerFp.open();
    return date$.asObservable();
  }

  openDatePicker(event, type?: string) {
    const el = event.target.parentElement;

    this.openCustomHistoryStartDatePicker(el as HTMLElement)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((date) => {
        if (!date) {
          return;
        }
        if (type === 'ETA') this.objectModel.DestinationETA = date.toISOString();
        else this.objectModel.PortOfOriginATD = date.toISOString();
      });
  }

  compare(a, b) {
    if (a.label.toUpperCase() < b.label.toUpperCase()) {
      return -1;
    }
    if (a.label.toUpperCase() > b.label.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onNewCustomFieldClick() {
    this.objectModel.Properties.push({
      key: undefined,
      value: undefined,
    });
  }

  onCloseCustomFieldClick(ix: number) {
    this.objectModel.Properties.splice(ix, 1);
  }

  checkForInputErrors() {
    if (
      !this.subscribedToFormChanges &&
      this.form &&
      this.form.controls &&
      Object.keys(this.form.controls).length !== 0
    ) {
      this.listenToFormInputChanges();
      this.subscribedToFormChanges = true;
    }
  }

  listenToFormInputChanges() {
    const controlObject = {
      'objectModel.SOG': { Min: 0 },
      'objectModel.COG': { Min: 0, Max: 359 },
      'objectModel.DMS.Latitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
      },
      'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Longitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
      },
      'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
      'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
      'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
      'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
      'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
    };
    Object.keys(controlObject).forEach((key) => {
      this.form.controls[key]?.valueChanges.subscribe((val) => {
        if (isNaN(val)) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.NUMBERREQUIRED');
          this.form.controls[key].setErrors({ incorrect: true }); // <--- Set invalidNumber to true
        } else if (val < controlObject[key].Min) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMIN', {
            min: controlObject[key].Min,
          });

          this.form.controls[key].setErrors({ min: true });
        } else if (val > controlObject[key].Max) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMAX', {
            max: controlObject[key].Max,
          });
          this.form.controls[key].setErrors({ max: true });
        } else {
          this.form.controls[key].setErrors(null);
        }
      });
    });
  }

  get cogError(): string {
    return this.errorcontrolObj['objectModel.COG'];
  }
  get sogError(): string {
    return this.errorcontrolObj['objectModel.SOG'];
  }
  get DMSLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Deg'];
  }
  get DMSLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Min'];
  }
  get DMSLatSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Sec'];
  }
  get DMSLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Deg'];
  }
  get DMSLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Min'];
  }
  get DMSLngSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Sec'];
  }
  get DMmLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Deg'];
  }
  get DMmLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Min'];
  }
  get DMmLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Deg'];
  }
  get DMmLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Min'];
  }
  get DddLatDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Latitude.Deg'];
  }
  get DddLngDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Longitude.Deg'];
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    let lng = undefined;
    let lat = undefined;

    if (this.objectModel.CoordinatesFormat.value == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere.value,
      );
      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere.value,
      );
    } else if (this.objectModel.CoordinatesFormat.value == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere.value,
      );
      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere.value,
      );
    }

    const custom = {};
    this.objectModel.Properties?.forEach((prop) => {
      custom[prop.key] = prop.value;
    });

    const properties = {
      ...this.objectModel.Properties.reduce((properties, pair) => {
        properties[pair.key] = pair.value;
        return properties;
      }, {}),
      Provider: 'HUMAN',
      PositionUtc: this.objectModel.PositionUtc,
      SOG: this.objectModel.SOG || this.objectModel.SOG == 0 ? String(this.objectModel.SOG) : undefined,
      COG: this.objectModel.COG || this.objectModel.COG == 0 ? String(this.objectModel.COG) : undefined,
      Name: this.objectModel.Name,
      IMONumber: this.objectModel.IMONumber,
      MMSI: this.objectModel.MMSI,
      CallSign: this.objectModel.CallSign,
      Classification: this.objectModel.Classification?.value,
      'Vessel Type': this.objectModel.VesselType?.label,
      Flag: this.objectModel.Flag?.label,
      PortOfOrigin: this.objectModel.PortOfOrigin?.label,
      PortOfOriginATD: this.objectModel.PortOfOriginATD,
      Destination: this.objectModel.Destination?.label,
      DestinationETA: this.objectModel.DestinationETA,
      DestinationLOCODE: this.objectModel.Destination?.PortLOCODE,
      PortOfOriginLOCODE: this.objectModel.PortOfOrigin?.PortLOCODE,
      PortOfRegistry: this.objectModel.PortOfRegistry?.label,
      VOI: this.objectModel.VOI?.label === '0' ? undefined : this.objectModel.VOI?.label,
      Owner: this.objectModel.Owner,
      Comments: this.objectModel.Comments,
      Cargo: this.objectModel.Cargo?.map((s) => s.label).join(this.cargoSplitter),
    };

    const event = {
      Situation_id: this.situationId,
      EventType: this.event.EventType,
      Description: this.event.Description,
      Tags: this.event.Tags,
      fileURL: this.event.fileURL,
      Content: this.event.Content,
      MSAObject_id: undefined,
      MSAObject_info: undefined,
      EventCategory: this.event.EventCategory,
      IncidentType: this.event.IncidentType,
      CreatedBy: this.permissionsService.getUser()._id,
    };

    this.dataService
      .createMSAObject({
        Layer_id: this.objectModel.Layer.value,
        Geometry: {
          Type: 'Point',
          Coordinates: [lng, lat],
        },
        Color: this.objectModel.Color,
        Icon: this.objectModel.Icon.value,
        Properties: properties,
        Event: event,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (msaObject) => this.injector.get('outputSubject').next(msaObject),
        error: (err) => (this.formError = err.error.error.type),
      });
  }

  onCoordinatesFormatChange(changes) {
    this.objectModel.CoordinatesFormat = changes.newFormat;
    this.updateCoordinates();
  }

  updateCoordinates() {
    let lng = undefined;
    let lat = undefined;
    const currentFormat = this.objectModel.CoordinatesFormat.value;

    if (currentFormat == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere?.value,
      );
    } else if (currentFormat == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere?.value,
      );
    }

    if (!isNaN(lng) && !isNaN(lat)) {
      this.setCoordinates([lng, lat]);
    }
  }

  isRequired(coordinatesFormat) {
    return this.objectModel.CoordinatesFormat.value == coordinatesFormat;
  }

  setCoordinates(coordinates: number[]) {
    const [lngDMS, latDMS] = this.coordinateService.DDToDMS(coordinates);
    const [lngDMm, latDMm] = this.coordinateService.DDToDMm(coordinates);
    const [lngDdd, latDdd] = this.coordinateService.DDToDdd(coordinates);

    this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
    this.objectModel.DMS.Longitude.Min = lngDMS.min;
    this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
    this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDMS.hemisphere,
    );
    this.objectModel.DMS.Latitude.Deg = latDMS.deg;
    this.objectModel.DMS.Latitude.Min = latDMS.min;
    this.objectModel.DMS.Latitude.Sec = latDMS.sec;
    this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDMS.hemisphere,
    );
    this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
    this.objectModel.DMm.Longitude.Min = lngDMm.min;
    this.objectModel.DMm.Latitude.Deg = latDMm.deg;
    this.objectModel.DMm.Latitude.Min = latDMm.min;
    this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
    this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDdd.hemisphere,
    );
    this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
    this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDdd.hemisphere,
    );
  }
}
