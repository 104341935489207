import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '@yaris/core/permissions.service';
import { DataService } from '@yaris/core/data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDefaultParams, LightMSALevel } from '@yaris/core/domain';
import { Router } from '@angular/router';
import { ModalService } from '@yaris/core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { BandwidthSubtitleDialogComponent } from '@yaris/core/components/bandwith-profile-form/bandwidth-subtitle-dialog/bandwidth-subtitle-dialog.component';

@Component({
  selector: 'app-bandwith-profile-form',
  templateUrl: './bandwith-profile-form.component.html',
  styleUrls: ['./bandwith-profile-form.component.sass'],
})
export class BandwithProfileFormComponent implements OnInit {
  loading = false;
  formError = '';
  levels = LightMSALevel;
  private ngUnsubscribe = new Subject<void>();
  private highlighted = '';
  isLoading = false;
  bandwidthLatency;
  startDate: Date;

  constructor(
    private permissionsService: PermissionsService,
    private modal: ModalService,
    private dataService: DataService,
    private translateService: TranslateService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.testLatency();
  }

  //Will get default values from DB for the ping function
  async testLatency() {
    this.isLoading = true;
    const defaultParams = this.dataService.getDefaultParams().subscribe((defaultParams: IDefaultParams) => {
      this.bandwidthLatency = defaultParams.BandwidthLatency;
      this.startDate = new Date();
      this.dataService.getBandwidthTestFile().subscribe(
        (data) => {
          const receivedDate = new Date();
          this.calculateConnectionType(receivedDate.getTime() - this.startDate.getTime());
        },
        (error) => console.error(error),
      );
    });
  }

  //Will ping the the local url to establish the back-end latency
  calculateConnectionType(latency: number) {
    try {
      //latency = 1//await ping(window.location.href);
      latency = Math.round(latency);
      //In the case the default parameters aren't loaded from the BD 50, 100 and 200 will be used as the default latency values

      if (latency < (this.bandwidthLatency?.Best ? this.bandwidthLatency.Best : 50)) {
        this.highlighted = 'blue';
      } else if (latency < (this.bandwidthLatency?.Good ? this.bandwidthLatency.Good : 100)) {
        this.highlighted = 'green';
      } else if (latency < (this.bandwidthLatency?.Medium ? this.bandwidthLatency.Medium : 200)) {
        this.highlighted = 'yellow';
      } else {
        this.highlighted = 'red';
      }
    } catch (err) {
      latency = NaN;
    }
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onProfileClick(profile: LightMSALevel): void {
    const preferences = this.permissionsService.getUser().Preferences || {};
    this.loading = true;
    this.dataService
      .updateUserPreferences({
        ...preferences,
        LightMSA: profile,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (user) => {
          localStorage.setItem('bandwidth', 'done');
          this.permissionsService.getUser().Preferences = user.Preferences;
          this.router.navigate(['/situation'], { replaceUrl: true });
          this.loading = false;
        },
        error: (err) => {
          this.formError = err.error.error.type;
          this.loading = false;
        },
      });
  }
  onHelpClick(): void {
    this.modal.open({
      title: this.translateService.instant('LIGHTMSA.BANDWITHSYMBOLOGY'),
      contentComponent: BandwidthSubtitleDialogComponent,
      exclusive: true,
      closable: true,
      inputs: {},
    });
  }

  isHighlighted(button: string): boolean {
    return this.highlighted === button;
  }
}
