import { MSAObjectMap } from '@yaris/msa/msa.service';

export interface RequestResponse {
  [x: string]: any;
  type: string;
  error: any;
  data:
    | any
    | {
        docs: any[] | any;
      };
  total: number;
  limit: number;
  page: number;
  pages: number;
  offset: number;
}

export interface IPaginatedRequest {
  limit?: number;
  offset?: number;
}

export interface Chat {
  _id: string;
  Name: string;
  Users_ids: string[];
  Security: SecurityType;
  SensitivityLevel: SensitivityLevel;
  CreatedAt: string;
  UpdatedAt: string;
  Situation_id: string;
  CreatedBy: UserSummary;
  MSAObject_id: string;
  MSAObject_info?: string;
  Owner?: string;
  OwnerHidden?: string;
  IsDefault?: boolean;
  FavoritedByGroupNames?: string[];
  ShareWithOutsideYA: boolean;
  UnreadMessage?: boolean;
  OpenToAnyone: boolean;
  SpecificRole: string;
}

export interface ChatPart {
  _id: string;
  Text: string;
  Chat_id: string;
  User_id: string;
  FirstName: string;
  Receiver_id?: string;
  Receiver_ids?: string[];
  Receiver_names?: { _id: string; Username: string }[];
  ReceiverUsername?: string;
  SentAt: string;
  UpdatedAt: string;
  fileURL: string;
  Content: string | Content;
  MessageReply?: { _id: string; Sender: string; Text: string };
  IsExercice?: boolean;
}

export interface ChatPartDefaultMessage {
  _id: string;
  Name: string;
  Description: string;
  Visible: boolean;
  MessageReply?: { _id: string; Sender: string; Text: string };
}

export interface BackgroundProcessingStatusData {
  processorName: string;
  processingStatus: BackgroundProcessingStatus;
  data: any;
}

export interface BackgroundProcessingStatus {
  status: ItemBackgroundProcessingStatusType;
}

export interface Content {
  _id: string;
  Filename: string;
  CreatedAt: Date;
  CreatedBy?: UserSummary;
  Sensitivity: SensitivityLevel;
  Security: SecurityType;
  Source: ContentSource;
  ParentName: string;
  Message?: string;
  StatusOfBackgroundProcessings: { [key: string]: BackgroundProcessingStatus };
}

export interface Comment {
  _id: string;
  Text: string;
  Security: SecurityType;
  SensitivityLevel: SensitivityLevel;
  CreatedAt: string;
  UpdatedAt: string;
  CreatedBy: UserSummary;
  Event_id: string;
  Owner: string;
  ShareWithOutsideYA: boolean;
}

export interface Event {
  _id: string;
  Description: string;
  Security: SecurityType;
  SensitivityLevel: SensitivityLevel;
  CreatedAt: string;
  UpdatedAt: string;
  CreatedBy: UserSummary;
  Situation_id: string;
  EventType: EventType;
  IsDefault?: boolean;
  fileURL?: string;
  MSAObject_id?: string;
  MSAObject_info?: string;
  EventLocation_id?: string;
  EventLocation_info?: string;
  Owner?: string;
  Tags: string[];
  ActionType: SituationActionType;
  ShareWithOutsideYA: boolean;
  IsHistory?: boolean;
  newComment?: boolean;
  Content: string | Content;
}

export interface EventLocation {
  _id: string;
  Geometry: GeometryType;
  Properties: PropertiesType;
  CreatedAt?: string;
  UpdatedAt?: string;
  EventCategory: EventCategory;
  EventIds: string[];
  SituationId: string;
  Layer_id: string;
}

export enum EventLocationCategoryType {
  Point = 'Point',
  Incident = 'Incident',
}

export interface Layer {
  _id: string;
  Name: string;
  Icon?: string;
  Description?: string;
  Color?: string;
  URL?: string;
  Source?: string;
  SourceOWSLayer?: string;
  ProxyWMS?: boolean;
  SourceType?: LayerSourceType;
  Provider?: string[];
  ImageURL?: string;
  ImageBoundingBox?: number[][];
  CustomFields?: { [key: string]: string };
  LayerType: LayerType;
  Security: SecurityType;
  SensitivityLevel: SensitivityLevel;
  CreatedAt?: string;
  UpdatedAt: string;
  CreatedBy?: UserSummary;
  IsDefault?: boolean;
  LightMSADefault: string[];
  SmartRules?: { [key: string]: any | Criteria }[];
  SharingGroups: SharingGroup[];
  SharingGroupRules: SharingGroupRule[];
  Owner?: string;
  Tags?: string[];
  Layers?: string[];
  LogicalQuerySmartLayers?: string;
  DefaultLayers?: string[];
  DefaultFromSituation?: string;
  DefaultPresentation?: {
    Active: boolean;
    Icon: string;
    Color: string;
    AutoSelectOnMap?: boolean;
  };
  ShareWithOutsideYA: boolean;
  FavoritedByGroupNames?: string[];
  Folders?: FolderGroup[];
  _changes?: {
    MSAObjects_ids: {
      added: string[];
      removed: string[];
    };
  };
  ZoneLayer?: boolean;
  Content?: Content;
  GroupLayer?: boolean;
  LayerParents?: string[];
  GroupChildrenLayers?: Layer[];
  Order?: number;
  ZeeIdentifier?: string;
  InternalWMS?: boolean;
}

type HumanMSAObjectActionType = 'Created' | 'Updated' | 'Deleted' | 'AddToLayer' | 'RemoveFromLayer' | 'Undo';

type MSAObjectGeofencingType = 'AttachedObjects' | 'AreaOfInterest' | 'ReferenceLine';

export interface MSAObject {
  _id: string;
  Name: string;
  Color?: string;
  Icon?: string;
  CreatedAt: string;
  UpdatedAt: string;
  updatedAt: string;
  Layer_id: string;
  ReferenceGeometry: any;
  Geometry: any;
  Properties?: { [key: string]: string | any };
  CustomFields?: { [key: string]: string };
  Human?: { [key: string]: string };
  Prediction?: boolean;
  AttachedObjects?: string[];
  LineColor?: string;
  LineWidth?: number;
  FillImage?: string;
  FillImageOpacity?: number;
  Opacity?: number;
  LineOpacity?: number;
  MSAObject_id?: string;
  LineDashed?: boolean;
  Geofencing: MSAObjectGeofencingType;
  Incident?: IncidentWarningOld; //TODO: Remove after Migration
  HumanActionType?: HumanMSAObjectActionType;
  HumanActionContext?: any;
  NeedUpdateExtendedData?: boolean;
  Type?: string;
  ExtendedData: ExtendedData;
  ExtendedDataOptions: ExtendedDataOptions;
  geometryFileURL?: string;
  Provider?: MsaObjectProvider;
}

export enum MsaObjectProvider {
  AIS = 'AIS',
  SKYLIGHT = 'SKYLIGHT',
  HUMAN = 'HUMAN',
}

export enum VesselConfigType {
  Global = 'Global',
  Center = 'Center',
  Situation = 'Situation',
  User = 'User',
}

export interface ExtendedDataOptions {
  ShowInMap?: {
    GeneralSection?: VesselConfigType;
    ClassificationSection?: VesselConfigType;
    AttachedObjectsSection?: VesselConfigType;
  };
}

export class VesselExtendedData {
  GeneralSection: { [field: string]: any };
  ClassificationSection: { [field: string]: any };
  AttachedObjectsSection: { [field: string]: any };
}

export interface ExtendedData {
  Global?: VesselExtendedData;
  Center?: { Local: VesselExtendedData };
  Situation?: VesselExtendedData;
  User?: VesselExtendedData;
}

export interface SkylightObject {
  _id: string;
  Geometry: GeometryType;
  type: SkylightPropertiesType;
  Properties: SkylightPropertiesType;
  Icon: string;
  ExternalId: string;
  ExternalUpdatedAt: Date;
  Type: SkylightLayerType;
  Layer_id: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CpaObject {
  _id: string;
  TrackObjects: MSAObjectMap[];
  Owner: string;
  ActivedFor: {
    User: string;
    Situation: string;
  }[];
  RefreshTimeInSec: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
}

export type CpaObjectProcessedResult = {
  cpaObject: CpaObject;
  cpaPoints: number[][];
  durationInHours: number;
  time: Date;
  processedAt: Date;
  distance: number;
  actualTracksPositions: number[][];
  center: number[];
};

export interface SkylightPropertiesType {
  transshipment?: string;
  participants?: string[];
  start_point?: number[];
  end_point?: number[];
  start_time?: Date;
  end_time?: Date;
  dist2coast?: number;
  fishing_score?: number;
  fishing_model_name?: string;
}

export enum SkylightLayerType {
  DRV = 'DarkRendezVous',
  SRV = 'StandartRendezVous',
  FISHING = 'Fishing',
}

export interface Vessel {
  createdAt?: Date;
  updatedAt?: Date;
  Name: string;
  MMSI: number;
  Country: string;
  VesselType: string;
  IMO: number;
  Length: number;
  Width: number;
  SkylightExternalId?: string;
  RadarHubExternalId?: string;
}

export interface IncidentWarningOld {
  //TODO: Remove after Migration
  WarningMessage: string;
  IncidentType: string;
  Description: string;
  Status: string;
  Tags?: string[];
  CreatedAt?: string;
  UpdatedAt?: string;
  Situation_id: string;
}
export interface Incident {
  _id: string;
  layerId: string;
  situationId: string;
  geometry: GeometryType;
  properties: PropertiesType;
  createdAt: string;
  updatedAt: string;
}

export interface GeometryType {
  Type: string;
  Coordinates: string[];
  Center: number[];
  Radius: number;
}

export interface PropertiesType {
  Name: string;
  WarningMessage: string;
  IncidentType: string;
  Description: string;
  EventCategory: EventCategoryType;
  Status: IncidentStatus;
  Tags: string[];
  Custom: Record<string, string>;
}

export interface Icons {
  _id: string;
  Name: string;
  IconURL: string;
  Description?: string;
  Visible: boolean;
  CreatedAt: Date;
  CreatedBy: string;
  GroupName: string;
}

export interface Port {
  _id: string;
  Name: string;
  LOCODE: string;
  geometryFileURL: string;
  Active: boolean;
  Ready: boolean;
  Centers: string[];
  Properties: any;
  MapProperties?: Map<string, string>;
}

export enum ItemBackgroundProcessingStatusType {
  Pending = 'Pending',
  Processing = 'Processing',
  Done = 'Done',
  Error = 'Error',
}

export enum BackgroundProcessor {
  ThumbnailPostProcessor = 'ThumbnailPostProcessor',
  GeometryFileProcessor = 'GeometryFileProcessor',
}

export enum MSAObjectProperties {
  UUID = 'UUID',
  Name = 'Name',
  Provider = 'Provider',
  Owner = 'Owner',
  ReceptionUtc = 'ReceptionUtc',
  PointID = 'PointID',
  PositionUtc = 'PositionUtc',
  MMSI = 'MMSI',
  CallSign = 'CallSign',
  IMONumber = 'IMONumber',
  ShipType = 'ShipType',
  COG = 'COG',
  FusedCourse = 'Fused Course',
  Heading = 'Heading',
  SOG = 'SOG',
  FusedSpeed = 'Fused Speed',
  Speed = 'Speed',
  Length = 'Length',
  Width = 'Width',
  Destination = 'Destination',
  DestinationETA = 'DestinationETA',
  NavigationalStatus = 'NavigationalStatus',
  Flag = 'Flag',
  AisEntityType = 'AisEntityType',
  Layer = 'Layer',
}

export enum SituationProperties {
  Name = 'Name',
  Tags = 'Tags',
  Security = 'Level',
  SensitivityLevel = 'Sensitivity',
  Description = 'Description',
}

export enum LayerProperties {
  Name = 'Name',
  Tags = 'Tags',
  Security = 'Level',
  SensitivityLevel = 'Sensitivity',
  Description = 'Description',
}

export enum SharingGroupRuleType {
  Anyone = 'Anyone',
}

export interface Tags {
  _id: string;
  Name: string;
  Description?: string;
  Situation_id?: string;
  Visible: boolean;
  Type: string;
  Used: boolean;
}

export interface MSAObjectHistory {
  _id: string;
  UpdatedAt: string;
  Properties?: { [key: string]: string };
  Geometry?: any;
  MSAObject_id: string;
  Color?: string;
  Icon?: string;
  Timestamp?: Date;
}

export interface Situation {
  _id: string;
  Name: string;
  Description: string;
  Layers_ids: string[];
  Security: SecurityType;
  SensitivityLevel: SensitivityLevel;
  CreatedAt: string;
  UpdatedAt: string;
  IsActive: boolean;
  Tags: string[];
  IsDefault?: boolean;
  CreatedBy?: UserSummary;
  Owner?: string;
  SharingGroups: SharingGroup[];
  SharingGroupRules: SharingGroupRule[];
  ShareWithOutsideYA: boolean;
  Priority: SituationPriority;
  FavoritedByGroupNames?: string[];
  Folders?: FolderGroup[];
}

export interface User {
  _id: string;
  Email: string;
  Roles: string[];
  usergroup: Group;
  CountryCode: string;
  Attributes: { [key: string]: string[] };
  Preferences: UserPreferences;
  ExternalId: string;
  FirstName: string;
  GroupName: string;
  GroupId: string;
  LastName: string;
  SensitivityLevel?: SensitivityLevel;
  Username: string;
  CreatedAt: string;
  UpdatedAt: string;
  Group: Group;
  Maps: {
    url?: string;
    Layer_id?: string;
    Name?: string;
  };
  Role: number;
  MSAMapConfig?: string;
  IsDirex?: boolean;
}

export interface UserPreferences {
  AutoRefreshSession?: boolean;
  ShowErrorDialog?: boolean;
  EmailNotifications?: boolean;
  Language?: Language;
  CoordinatesFormat?: CoordinatesFormat;
  DistanceUnit?: DistanceUnit;
  LightMSA?: LightMSALevel;
  FrontendType?: string;
  AlertPreferences?: { SoundAlert?: boolean; NotifyAllSituations?: boolean };
}
export interface UserSummary {
  _id: string;
  FirstName: string;
  GroupName: string;
  Email: string;
  Username: string;
}

export interface Group {
  _id: string;
  Name: string;
  CountryCode: string;
  Description?: string;
  DirectoryPath?: string;
  NodeGroup?: boolean;
  Depth?: number;
  ExternalId: string;
  Path?: {
    RegionName?: string;
    ZoneName?: string;
    Interregional?: string;
  };
  BelongToYA?: boolean;
  Children: string[];
  DefaultLayer?: string;
  DefaultLightMSALayer?: string;
  IsDefaultLayer?: string;
  DefaultLayers?: {
    Off?: string;
    Low?: string;
    Medium?: string;
    High?: string;
  };
  ActivityStatus?: string;
}

export interface GroupStatus {
  status: boolean;
  center_name: string;
  timestamp: string;
}

export interface Notification {
  _id: string;
  ObjectType: NotificationObjectType;
  ObjectReference: string;
  ActionType: NotificationActionType;
  Security: SecurityType;
  SensitivityLevel: SensitivityLevel;
  Timestamp?: string;
  CreatedBy?: string | User;
  SharedWith: string;
  Situation_id?: string;
  Event_id?: string;
  ObjectInfo?: any;
  IsSharing: boolean;
  Trespasser?: any;
  SharingGroups?: SharingGroup[];
  SharingGroupRules?: SharingGroupRule[];
}

export interface CpaObjectNotification {
  ActionType: NotificationActionType;
  ObjectInfo: CpaObject;
}

export interface GroupTree {
  group: Group;
  children: GroupTree[];
}

export interface SharingGroup {
  GroupName?: string;
  GroupId: string;
  GroupDescription?: string;
  CountryCode?: string;
  CanWrite?: boolean;
  CanDelete?: boolean;
  CanShare?: boolean;
  IsActive?: boolean;
  AlwaysShared?: boolean;
}

export interface SharingGroupRule {
  GroupName?: string;
  CanWrite?: boolean;
  CanDelete?: boolean;
  CanShare?: boolean;
}

export enum NotificationFilter {
  All = 'All',
  Hidden = 'Hidden',
  NotHidden = 'NotHidden',
}

export enum ChatFilter {
  All = 'All',
  Hidden = 'Hidden',
  NotHidden = 'NotHidden',
}

export enum NotificationObjectType {
  Situation = 'Situation',
  Chat = 'Chat',
  Event = 'Event',
  Comment = 'Comment',
  Layer = 'Layer',
  MSAObject = 'MSAObject',
  CenterState = 'CenterState',
  User = 'User',
  Folder = 'Folder',
  ChatPart = 'ChatPart',
  CpaObject = 'CpaObject',
  BoardMessage = 'BoardMessage',
  AnalyticBackup = 'AnalyticBackup',
}

export enum NotificationActionType {
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted',
  Added = 'Add',
  Deactivate = 'Deactivate',
  Add = 'Status',
  Remove = 'Remove',
  LostAccess = 'LostAccess',
  Trespass = 'Trespass',
}

export enum EventType {
  Information = 'Information',
  Decision = 'Decision',
}

export enum EventCategory {
  Log = 'Log',
  Point = 'Point',
  Incident = 'Incident',
}

export enum LayerType {
  View = 'View',
  Information = 'Information',
  Smart = 'Smart',
  File = 'File',
  Image = 'Image',
  Points = 'Points',
  ThirdPartyLayer = 'ThirdPartyLayer',
  Remote = 'Remote',
  Event = 'Event',
  Dynamic = 'Dynamic',
}

export enum LayerSourceType {
  WMS = 'WMS',
  SKYLIGHT = 'Skylight',
  EVENT_LOCATION = 'EventLocation',
  VesselsOfInterest = 'VesselsOfInterest',
}

export enum PermissionType {
  Situation,
  Event,
  Comment,
  Layer,
  MSAObject,
}

export enum SecurityType {
  Local = 'Local',
  National = 'National',
  Zone = 'Zone',
  Region = 'Region',
  Interregional = 'Interregional',
  International = 'International',
}

export enum ContentSource {
  Chat = 'Chat',
  Event = 'Event',
  Layer = 'Layer',
  Report = 'Report',
  Port = 'Port',
}

export enum SituationActionType {
  Shared = 'Shared',
  Created = 'Created',
  Updated = 'Updated',
}

export enum SensitivityLevel {
  Public = 0,
  Private = 1,
  Secret = 2,
}

export enum SituationPriority {
  VeryHigh = 600,
  High = 500,
  Medium = 400,
  Low = 300,
  VeryLow = 200,
  None = 100,
}

export enum TrackSymbology {
  'Friendly',
  'Suspect',
  'Neutral',
  'Under Evaluation',
  'Hostile',
}

export enum Criteria {
  $ne = '$ne',
  $lt = '$lt',
  $gt = '$gt',
  $eq = '$eq',
  $gte = '$gte',
  $lte = '$lte',
}

export enum RolePermission {
  judicial_expert = 'judicial_expert',
  guest = 'guest',
  authority = 'authority',
  operator = 'operator',
  supervisor = 'supervisor',
  decidor = 'decidor',
  local_administrator = 'local_administrator',
  operations_manager = 'operations_manager',
  system_administrator = 'system_administrator',
  it_administrator = 'it_administrator',
}

export enum SearchCriteria {
  $eq = 'EQ',
  $ne = 'NE',
  $lt = 'LT',
  $lte = 'LTE',
  $gt = 'GT',
  $gte = 'GTE',
  $in = 'IN',
  $out = 'OUT',
  $insi = 'INSI',
}
export enum Language {
  en = 'en',
  es = 'es',
  fr = 'fr',
  pt = 'pt',
}

export enum LightMSALevel {
  OFF = 'OFF',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum CoordinatesFormat {
  DMS = 'DMS',
  DMm = 'DM.m',
  Ddd = 'D.dd',
}

export enum DistanceUnit {
  Km = 'Km',
  Nm = 'Nm',
}

export enum IncidentStatus {
  Created = 'Created',
  Validated = 'Validated',
  Updated = 'Updated',
  Terminated = 'Terminated',
}

export enum EventCategoryType {
  Log = 'Log',
  Point = 'Point',
  Incident = 'Incident',
}

export enum ReadableType {
  Event = 'Event',
  Comment = 'Comment',
  ChatPart = 'ChatPart',
}

export interface IDefaultParams {
  IncidentRadius: number;
  IncidentEmailSubject: string;
  IncidentEmailCC: string;
  IncidentEmailUsername: string;
  VesselPhotoByMMSIProviderLink?: string;
  VesselPhotoByIMONumberProviderLink?: string;
  CpaMaxDurationInHours: number;
  HistoryTTL: number;
  InactiveMSATime: number;
  RefreshTimeToken: number;
  LogoURL: string;
  BandwidthLatency?: {
    Best?: number;
    Good?: number;
    Medium?: number;
    Low?: number;
  };
  ExerciceTag: string;
  MSADefaults?: {
    OFF?: any;
    LOW?: any;
    MEDIUM?: any;
    HIGH?: any;
  };
  EventsOnlineTTL: number;
  MaxDynamicCircleRadiusNM: number;
  SKYLIGHT_QUERYINTERVAL: number;
  SKYLIGHT_TTL: number;
  SKYLIGHT_SYNCCRON: string;
  SKYLIGHT_URL: string;
  SKYLIGHT_USERNAME: string;
  SKYLIGHT_PASSWORD: string;
  SKYLIGHT_AOI_ID: string;
  SkylightEmailDestination: string;
  SkylightEmailDestinationFlag: boolean;
  SkylightShowEventLink: boolean;
  TurfTolerance: number;
  MapboxTolerance: number;
  ShowInMapAvailableOptions: VesselConfigType[];
  AllowedRoles: string[];
  AppVersion: string;
  Environment: string;
  AnalyticBackupMaximumAgeInDays: number;
  AnalyticBackupSizeInDays: number;
  Debug?: Debug;
  Streaming?: Streaming;
  TranslatorProvider?: 'deepl' | 'google';
  TranslationCacheEnabled?: boolean;
  TRANSLATION_DEEPL_AUTHKEY?: string;
  TRANSLATION_DEEPL_APITYPE?: string;
  TRANSLATION_GOOGLE_AUTHKEY?: string;
  ShowLayerLibraryInLayerOptions?: boolean;
  CanEditFoldersInLayerLibrary?: boolean;
  Audio?: IAudio;
  EnableTitleErrorWarn?: boolean;
  UserHeartbitStatusDataTTLInSeconds?: number;
  UserGroupStatusDataTTLInSeconds?: number;
  ForceLogoutOfInacticeHeartbitUsers?: boolean;
  EnableLayerPreferences?: boolean;
  EnableSituationDocumentsMenu?: boolean;
}

export interface Debug {
  isActive: boolean;
  isProtected: boolean;
  password: string;
}

export interface Streaming {
  isActive: boolean;
  maxStreams: number;
}

export interface IAudio {
  AudioRecordingEnabled: boolean;
  AudioRecordingTimeout: number;
  SpeechRecognitionEnabled: boolean;
  SpeechRecognitionContinuous: boolean;
  SpeechRecognitionInterimResults: boolean;
}

export interface MSAMapConfig {
  VesselNameDisplay: string[];
}

export interface UserPermissions {
  MSA: {
    Create_Situation?: boolean;
    Change_Situation?: boolean;
    Change_Favorite?: boolean;
    Share_Situation?: boolean;
    Deactivate_Situation?: boolean;
    Reactivate_Situation?: boolean;
    List_Inactive_Situation?: boolean;
    Export_Situation?: boolean;
    Export_Chat?: boolean;
    Export_Log?: boolean;
    Create_Events?: boolean;
    Create_Decision_Events?: boolean;
    Create_Comments?: boolean;
    Attach_Documents_Events?: boolean;
    Create_Chat_Room?: boolean;
    Invite_Users_To_Chat_Room?: boolean;
    Create_Tags?: boolean;
    Manage_Admin_Board?: boolean;
    Edit_Root_Folders?: boolean;
    Edit_Sub_Folders?: boolean;
    Manage_Incident_Warning?: boolean;
  };
  Layer: {
    Add_To_Situation_Layer?: boolean;
    Create_Layer?: boolean;
    Edit_Layer?: boolean;
    Duplicate_Layer?: boolean;
    Share_Layer?: boolean;
    Delete_Layer?: boolean;
    Remove_From_Situation_Layer?: boolean;
    Manage_Msaobjects_Layer?: boolean;
    Export_Msaobjects_Layer?: boolean;
  };
}

export interface Folder {
  _id: string;
  Name: string;
  Owner: string;
  Type: FolderType;
  Parent: string;
  Root?: boolean;
}

export enum FolderType {
  Situation = 'Situation',
  LayerView = 'LayerView',
  LayerInformation = 'LayerInformation',
  LayerSmart = 'LayerSmart',
  LayerThirdParty = 'LayerThirdParty',
  LayerLibrary = 'LayerLibrary',
}

export interface FolderGroup {
  Folder: string;
  GroupName: string;
  FolderType: FolderType;
}

export interface FolderSummary {
  Items: number;
  Folders: number;
  DefaultItems: number;
  cannotBeDeletedItems: number;
}

export enum EnvironmentsLongName {
  development = 'Development',
  integration = 'Integration',
  operations = 'OPS/TRN',
  training = 'EDUC/DEMO',
}

export interface UnreadCountSummary {
  UnreadCount: number;
  ReadableParentId: string;
}

export enum AttributionType {
  skylight = 'skylight',
  mapbox = 'mapbox',
  openstreetmap = 'openstreetmap',
}
export enum DynamicModes {
  Translation = 'Translation',
  FurtherOnCircle = 'FurtherOnCircle',
}

export enum MapConfigViewType {
  Default = 'Default',
}

export interface LayerConfig {
  LayerId: string;
  IsSelected: boolean;
  IsHidden: boolean;
  Opacity: number;
}

export interface MapLocation {
  Type: string;
  Coordinates: number[];
  Zoom: number;
}

export interface UserMapConfig {
  SituationId: string;
  UserId: string;
  Layers: LayerConfig[];
  ViewType: MapConfigViewType;
  MapLocation: MapLocation;
  CreatedAt?: string;
  UpdatedAt?: string;
}

export interface BoardMessage {
  _id: string;
  CreatedBy: UserSummary;
  SensitivityLevel: SensitivityLevel;
  Security: SecurityType;
  SharingGroups: SharingGroup[];
  SharingGroupRules: SharingGroupRule[];
  ShareWithOutsideYA: boolean;
  Owner: string;
  Message: string;
  CreatedAt?: string;
  UpdatedAt?: string;
  StartsAt: string;
  ExpiresAt: string;
}
export interface Property {
  Type: 'Human' | 'System';
  Value: string;
  Visible: boolean;
  _id: string;
}
export interface MsaObjectDefaultProperties {
  _id?: string;
  Value: string;
  Type?: string;
  Visible?: boolean;
  Description?: string;
  Locked?: boolean;
  PortLOCODE?: string;
  Port?: string;
  IncidentWarning?: boolean;
}
export interface MsaObjectDefaultPropertiesFlag extends MsaObjectDefaultProperties {
  Translations: {
    English: string;
    Portuguese: string;
    French: string;
    Spanish: string;
  };
}

export interface MsaDefaultMessage {
  _id?: string;
  Name: string;
  Visible: boolean;
  Description: string;
}

export interface CustomVesselPhotoLinks {
  Target: string;
  Link: string;
}

export interface MsaCustomVesselsPhotos {
  _id?: string;
  Source: string;
  Links: CustomVesselPhotoLinks[];
}

export enum BackupStatus {
  Dumping = 'Dumping',
  Created = 'Created',
  Restoring = 'Restoring',
  Restored = 'Restored',
  FreeingUpSpace = 'FreeingUpSpace',
  FreedUpSpace = 'FreedUpSpace',
  Error = 'Error',
}

export interface AnalyticBackup {
  _id?: string;
  CreatedAt: string;
  UpdatedAt: string;
  StatusHistory: { status: BackupStatus; when: string; error?: { id: string; message: string } }[];
  Index: string;
  Start: string;
  End: string;
  File?: string;
}
