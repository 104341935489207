<form
  class="add-icons yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <h2>{{ label }}</h2>
  </div>
  <div class="footer" fxFlexAlign="center">
    <main-button [disabled]="!form.valid" (click)="onClickExport('CSV')" style="padding-right: 3rem" type="submit"
      >CSV</main-button
    >
    <main-button [disabled]="!form.valid" (click)="onClickExport('KMZ')" type="submit">KMZ</main-button>
  </div>
</form>
