import { HttpClient } from '@angular/common/http';
import { IUser } from '../interfaces/user.interface';
import { AdminBaseService } from './admin-base.service';

export class AdminUsers extends AdminBaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  public async getUser(id: string): Promise<IUser> {
    return this.getAsync(`/light/api/admin/user/${id}`);
  }

  public async getUsers(
    isActive: boolean,
    query: string,
    sortBy: string,
    sortByType: string,
    page: number = 0,
    take: number = 10,
  ): Promise<IUser[]> {
    if (!page) page = 0;
    if (!take) take = 10;
    let q = '';
    q += `page=${page}&take=${take}&isActive=${isActive}`;
    if (query) {
      q += `&q=${query}`;
    }
    if (sortBy && sortByType) {
      q += `&sortBy=${sortBy}&sortByType=${sortByType}`;
    }
    return this.getAsync(`/light/api/admin/users/all?${q}`);
  }

  public async getUsersLog(
    query: string,
    sortBy: string,
    sortByType: string,
    page: number = 0,
    take: number = 10,
    event: 'LOG-IN' | 'LOG-OUT' = undefined,
    app: 'LEGACY' | 'MSA' | 'HMI' = undefined,
  ): Promise<{ items: any[], total: number }> {
    if (!page) page = 0;
    if (!take) take = 10;
    let q = '';

    q += `page=${page}&take=${take}`;
    
    if (event) {
      q += `&event=${event}`
    }

    if (app) {
      q += `&app=${app}`
    }
    
    if (query) {
      q += `&q=${query}`;
    }
    if (sortBy && sortByType) {
      q += `&sortBy=${sortBy}&sortByType=${sortByType}`;
    }
    return this.getAsync(`/light/api/admin/users/log?${q}`);
  }

  public async getUsersActive(): Promise<any[]> {
    return this.getAsync(`/light/api/admin/users/active`);
  }

  public async countUsers(isActive: boolean, query: string, sortBy: string, sortByType: string): Promise<number> {
    let q = `isActive=${isActive}`;
    if (query) {
      q += `&q=${query}`;
    }
    if (sortBy && sortByType) {
      q += `&sortBy=${sortBy}&sortByType=${sortByType}`;
    }
    return this.getAsync(`/light/api/admin/users/count?${q}`);
  }
}
