import { HttpClient } from '@angular/common/http';
import { IJobLog } from '../interfaces/joblog.interface';
import { AdminBaseService } from './admin-base.service';

export class AdminJobs extends AdminBaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  public async getJob(id: string): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/getJob?id=${id}`);
  }

  public async getJobs(): Promise<IJobLog[]> {
    return this.getAsync('/light/api/background-tasks/jobLogs');
  }

  public async deleteJobLogs(): Promise<any> {
    return this.deleteAsync('/light/api/background-tasks/jobLogs');
  }
}
