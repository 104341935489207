import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { Layer } from '@yaris/core/domain';
import { CoordinateService } from '@yaris/msa/mapbox/services/coordinate.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '@yaris/core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { MsaCreateIconsComponent } from '../msa-create-icons/msa-create-icons.component';
import { MsaEditIconsComponent } from '../msa-edit-icons/msa-edit-icons.component';

@Component({
  selector: 'app-msa-manage-icons',
  templateUrl: './msa-manage-icons.component.html',
  styleUrls: ['./msa-manage-icons.component.sass'],
})
export class MsaManageIconsComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  layers: Layer[];
  coordinates: number[];
  formError = '';
  /* objectModel: {
    Layer: {label: string, value: string};
    Name: string;
  } = {
    Layer: undefined,
    Name: undefined
  }*/
  public search: string = '';
  iconOptions: { _id: string; label: string; value: string; Visible: boolean; Description: string }[] = [];
  unfilteredIcons: { _id: string; label: string; value: string; Visible: boolean; Description: string }[] = [];
  isLoading = false;

  private ngUnsubscribe = new Subject<void>();
  //private msaObject: MSAObject;

  private searchTimeout = undefined;

  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private coordinateService: CoordinateService,
  ) {}

  ngOnInit(): void {
    this.dataService
      .listMsaIcons()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((icons) => {
        this.iconOptions = this.iconOptions.concat(
          icons.map((i) => ({
            _id: i._id,
            label: i.Name,
            value: i.IconURL,
            Visible: i.Visible,
            Description: i.Description,
          })),
        );
        this.unfilteredIcons = this.iconOptions;
        //this.objectModel.Icon = this.iconOptions[0];
        this.iconOptions.sort(this.compare);
      });
    //this.iconOptions = this.iconOptions.concat(Object.keys(MapIcon)
    //  .map(k => ({_id:"koi",label: k, value: `assets/images/map/${k.toLowerCase()}.png`, Visible:true,Description:"Teste description that is showing how things will look like you know? Hope it will work as expected. Seems too short to be used as expected here and there you know. I don't know what else to say to make this description bigger so i can properly test this out"})));
    /* this.dataService.listLayers(LayerType.Points)
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(map(
        layers => layers.map(l => ({label: l.Name, value: l._id}))
      )).subscribe(layers => {
        this.layerOptions = layers;
      });*/
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }
  get hasSearchBar(): boolean {
    return true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSearch(term: string) {
    if (this.searchTimeout !== undefined) {
      clearTimeout(this.searchTimeout);
    }
    if (term.length === 0) {
      this.iconOptions = this.unfilteredIcons;
      return;
    }
    this.searchTimeout = setTimeout(() => {
      this.iconOptions = this.unfilteredIcons.filter((icon) => icon.label.toLowerCase().includes(term.toLowerCase()));
    }, 500);
  }

  compare(a, b) {
    if (a.label.toUpperCase() < b.label.toUpperCase()) {
      return -1;
    }
    if (a.label.toUpperCase() > b.label.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  onEndSearch(search: string = '', fetch: boolean = true): void {
    this.iconOptions = this.unfilteredIcons;
    this.search = '';
  }
  iconPath(icon) {
    return icon.value;
  }
  iconPath2(icon) {
    return '/assets/images/ais.svg';
  }
  visibilityIcon(icon): string {
    return icon.Visible ? '/assets/images/visibility.svg' : '/assets/images/hidden.svg';
  }
  onVisibilityClick(icon): void {
    icon.Visible = !icon.Visible;
    this.dataService.updateIconVisibility(icon).subscribe((icon) => {});
  }
  onOptionsClick(icon, event: MouseEvent): void {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.EDITICONS'),
      inputs: {
        icon: icon,
        names: this.iconOptions
          .map((icon) => {
            return icon.label;
          })
          .filter((ico) => ico !== icon.label),
      },
      contentComponent: MsaEditIconsComponent,
    });
  }

  classTypeDefault() {
    return 'default';
  }
  classTypeTool() {
    return 'tool';
  }

  createIcons() {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.ADDICONS'),
      inputs: {
        names: this.iconOptions.map((icon) => {
          return icon.label;
        }),
      },
      contentComponent: MsaCreateIconsComponent,
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
    /* const duplicate = {
      Layer_id: this.objectModel.Layer.value,
      Properties: {
        ...this.msaObject.Properties,
        Name: this.objectModel.Name
      },
      Color: this.msaObject.Color,
      Icon: this.msaObject.Icon,
      Geometry: this.msaObject.Geometry
    }
    this.dataService.createMSAObject(duplicate,this.msaObject._id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: msaObject => this.injector.get('outputSubject').next(msaObject),
        error: err => this.formError = err.error.error.type
      })*/
  }
}
