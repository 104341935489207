<form
  class="msa-edit-flag yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <text-control
      required
      name="objectModel.Name"
      title="{{ 'PROPERTY.NAME' | translate }}"
      placeholder="{{ 'PROPERTY.NAME' | translate }}"
      [(ngModel)]="objectModel.Name"
    >
    </text-control>
    <text-control
      required
      name="objectModel.Translations.English"
      title="{{ 'LANGUAGE.EN' | translate }}"
      placeholder="{{ placeholders.English }}"
      [(ngModel)]="objectModel.Translations.English"
    >
    </text-control>
    <text-control
      required
      name="objectModel.Translations.Portuguese"
      title="{{ 'LANGUAGE.PT' | translate }}"
      placeholder="{{ placeholders.Portuguese }}"
      [(ngModel)]="objectModel.Translations.Portuguese"
    >
    </text-control>
    <text-control
      required
      name="objectModel.Translations.French"
      title="{{ 'LANGUAGE.FR' | translate }}"
      placeholder="{{ placeholders.French }}"
      [(ngModel)]="objectModel.Translations.French"
    >
    </text-control>
    <text-control
      required
      name="objectModel.Translations.Spanish"
      title="{{ 'LANGUAGE.ES' | translate }}"
      placeholder="{{ placeholders.Spanish }}"
      [(ngModel)]="objectModel.Translations.Spanish"
    >
    </text-control>
  </div>

  <div *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </div>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{ 'BUTTON.SUBMIT' | translate }}</main-button>
  </div>
</form>
