import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.sass'],
})
export class SearchButtonComponent implements OnInit {
  ngOnInit(): void {}
}
