import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'main-button',
  templateUrl: './main-button.component.html',
  styleUrls: ['./main-button.component.sass'],
})
export class MainButtonComponent implements OnInit {
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() small: boolean = false;
  @Input() type: string = 'button';

  ngOnInit(): void {}
}
