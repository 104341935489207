import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreModule } from '@yaris/core/core.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorManagerService } from './core/error-manager.service';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function getCookies() {
  const cookies = document.cookie.split(';');
  const cookieMap = new Map();
  cookies.forEach((cookie) => {
    if (cookie.length > 0) {
      const cookieParsed = cookie.split('=');
      cookieMap.set(cookieParsed[0].trim(), cookieParsed[1].trim());
    }
  });
  return cookieMap;
}

const handleBaseHref = () => {
  const cookieMap = getCookies();
  const appType = cookieMap.get('app');

  if (window.location.pathname.startsWith('/legacy/admin')) {
    return '/legacy';
  }

  if (!appType || appType === 'msa') {
    return '/legacy';
  } else {
    return '/light';
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreModule,
    BrowserAnimationsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: handleBaseHref,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    ErrorManagerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
