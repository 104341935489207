import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessDeniedComponent } from '@yaris/core/components/access-denied/access-denied.component';
import { BandwithProfileFormComponent } from '@yaris/core/components/bandwith-profile-form/bandwith-profile-form.component';
import { DefaultLandingPageComponent } from '@yaris/core/components/default-landing-page/default-landing-page.component';
import { AuthGuard } from '@yaris/core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';

const routes: Routes = [
  {
    path: 'situation',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('./operational-situation/operational-situation.module').then((m) => m.OperationalSituationModule),
  },
  {
    path: 'chat',
    canLoad: [AuthGuard],
    loadChildren: () => import('./chat/chat.module').then((m) => m.ChatModule),
  },
  {
    path: 'log',
    canLoad: [AuthGuard],
    loadChildren: () => import('./log/log.module').then((m) => m.LogModule),
  },
  {
    path: 'msa',
    canLoad: [AuthGuard],
    loadChildren: () => import('./msa/msa.module').then((m) => m.MsaModule),
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },

  {
    path: 'bandwidth',
    canActivate: [AuthGuard],
    component: BandwithProfileFormComponent,
  },
  {
    path: 'denied',
    component: AccessDeniedComponent,
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    component: DefaultLandingPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
