import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  ColumnChangesService,
  ColumnMode,
  DatatableComponent,
  DimensionsHelper,
  ScrollbarHelper,
} from '@swimlane/ngx-datatable';

enum DefaultColumnProp {
  actionsButton = 'actionsButton',
}

export interface ActionButton {
  icon: string;
  title?: string;
  isVisible(): boolean;
  action(row): void;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.sass'],
  providers: [ScrollbarHelper, DimensionsHelper, ColumnChangesService],
})
export class DataTableComponent implements OnInit {
  @Input() rows: any[];
  @Input() columns: any[];
  @Input() sorts: any[];
  @Input() limit: number;
  @Input() scrollbarH: boolean;
  @Input() scrollbarV: boolean;
  @Input() searchPlaceHolder: string;
  @Input() columnsToSearch: string[];

  @Output('search') searchEvent = new EventEmitter<string>();
  @Output('endSearch') endSearchEvent = new EventEmitter<string>();

  @Output() searchTermChange = new EventEmitter<string>();
  @Input() searchTerm: string;

  private _visibleColumns: string[];
  @Output() visibleColumnsChange = new EventEmitter<string[]>();
  @Input() set visibleColumns(value: string[]) {
    this._visibleColumns = value || this.getColumnsProps();
    this.visibleColumnsOptions?.length
      ? this.updateVisibleColumnsSelected(this._visibleColumns)
      : this.buildVisibleColumnsOptions(this._visibleColumns);
  }
  get visibleColumns() {
    return this.visibleColumnsSelected.map((selected) => selected.value);
  }

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ContentChild('actionsButtons') actionButtonTemplateRef: TemplateRef<any>;

  filteredRows: any[];
  filteredColumns: any[];
  columnMode = ColumnMode.force;
  visibleColumnsOptions: { label: string; value: string }[] = [];
  visibleColumnsSelected: { label: string; value: string }[] = [];

  readonly defaultColumns = [
    {
      prop: DefaultColumnProp.actionsButton,
      name: 'Actions',
      sortable: false,
      draggable: false,
    },
  ];

  ngOnInit(): void {}

  get rowsToShow(): any[] {
    return this.isInSearch() ? this.filteredRows : this.rows;
  }

  get columnsToShow(): any[] {
    return this.visibleColumns?.length ? this.filteredColumns : this.columns;
  }

  private getColumnsProps(): string[] {
    return this.columns.map((col) => col.prop);
  }

  private buildVisibleColumnsOptions(visibleColumns?: string[]) {
    this.visibleColumnsOptions = this.columns.map((col) => ({ label: col.name, value: col.prop }));
    this.updateVisibleColumnsSelected(visibleColumns);
  }

  private isInSearch() {
    return !!this.searchTerm?.length;
  }

  private updateVisibleColumnsSelected(visibleColumns: string[]) {
    this.visibleColumnsSelected = this.visibleColumnsOptions.filter((option) => visibleColumns.includes(option.value));
    this.updateFilteredColumns();
  }

  private updateFilteredColumns() {
    this.filteredColumns = this.columns.filter((col) => this.visibleColumns.includes(col.prop));
  }

  isActionButtonsCell(prop: string) {
    return prop === DefaultColumnProp.actionsButton;
  }

  onVisibleColumnsSelectedChange(visibleColumns) {
    this.updateFilteredColumns();
  }

  onSearch(searchTerm: string) {
    if (searchTerm?.length < 3) {
      if (searchTerm?.length === 0) {
        this.onEndSearch();
        return;
      }
      this.filteredRows = this.rows.slice();
      return;
    }

    const term = searchTerm.toLowerCase();
    const columnsToSearch = this.columnsToSearch || this.visibleColumns || [];
    this.filteredRows = this.rows.filter((row) =>
      columnsToSearch.some((col) => row[col] && row[col].toString().toLowerCase().includes(term)),
    );
    this.searchEvent.emit(term);
  }

  onEndSearch() {
    this.searchTerm = '';
    this.endSearchEvent.emit();
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }
}
