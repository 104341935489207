import { Directive, ElementRef, AfterViewInit, AfterViewChecked, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[detectResize]',
})
export class DetectResizeDirective implements AfterViewInit, AfterViewChecked {
  @Output() resize = new EventEmitter<void>();
  private currentSize = {
    width: 0,
    height: 0,
  };

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.currentSize.height = this.el.nativeElement.clientHeight;
    this.currentSize.width = this.el.nativeElement.clientWidth;
  }

  ngAfterViewChecked() {
    if (
      this.currentSize.height !== this.el.nativeElement.clientHeight ||
      this.currentSize.width !== this.el.nativeElement.clientWidth
    ) {
      this.currentSize.height = this.el.nativeElement.clientHeight;
      this.currentSize.width = this.el.nativeElement.clientWidth;
      this.resize.emit();
      return;
    }
  }
}
