<form
  class="msa-create-view-layer yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <text-control
      required
      name="layerModel.Name"
      title="{{ 'PROPERTY.NAME' | translate }}"
      placeholder="{{ 'PROPERTY.NAME' | translate }}"
      [(ngModel)]="layerModel.Name"
    ></text-control>
    <text-area-control
      name="layerModel.Description"
      title="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      placeholder="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      [(ngModel)]="layerModel.Description"
    ></text-area-control>
    <text-control
      name="layerModel.URL"
      title="{{ 'PROPERTY.URL' | translate }}"
      placeholder="{{ 'PROPERTY.URL' | translate }}"
      [(ngModel)]="layerModel.URL"
    ></text-control>
    <text-select-control
      required
      name="layerModel.Folder"
      title="{{ 'PROPERTY.FOLDER' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="foldersPathOptions"
      [(ngModel)]="layerModel.Folder"
      [initial]="layerModel.Folder"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control>

    <checkbox-control
      name="GroupLayer"
      *ngIf="!layerModel.ZoneLayer"
      title="View Group Layer"
      (ngModelChange)="groupLayerChanged($event)"
      [(ngModel)]="layerModel.GroupLayer"
    >
    </checkbox-control>

    <multi-text-select-control-icons
      *ngIf="!objectIds && !layerModel.ZoneLayer"
      name="layerModel.Layers"
      title="{{ 'PROPERTY.LAYERS' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSETHELAYERS' | translate }}"
      [options]="layerOptions"
      [(ngModel)]="layerModel.Layers"
      [initial]="layerModel.Layers"
      enableSelectAll="true"
      (click)="onClickExpandDropDown(57)"
    ></multi-text-select-control-icons>

    <checkbox-control
      *ngIf="IsSystemAdmin && !layerModel.GroupLayer"
      name="ZoneLayer"
      title="Zone Layer"
      (ngModelChange)="zoneLayerChanged($event)"
      [(ngModel)]="layerModel.ZoneLayer"
    >
    </checkbox-control>

    <file-control
      extension=".kmz, .kml,.geojson,application/vnd.google-earth.kml+xml"
      [fileExtension]="['kmz', 'kml', 'geojson']"
      [fileSize]="maxFileSize"
      *ngIf="layerModel.ZoneLayer && IsSystemAdmin && !layerModel.GroupLayer"
      name="layerModel.GeometryFile"
      title="{{ 'PROPERTY.GEOMETRYFILE' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.SELECTGEOMETRYFILE' | translate }}"
      [(ngModel)]="layerModel.GeometryFile"
      (click)="onClickExpandDropDown(57)"
    ></file-control>

    <text-select-control
      required
      name="layerModel.Security"
      title="{{ 'PROPERTY.LEVEL' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="securityTypeOptions"
      [(ngModel)]="layerModel.Security"
      [initial]="layerModel.Security"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control>
    <text-select-control
      required
      name="layerModel.SensitivityLevel"
      title="{{ 'PROPERTY.SENSITIVITY' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="sensitivityLevelOptions"
      [(ngModel)]="layerModel.SensitivityLevel"
      [initial]="layerModel.SensitivityLevel"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control>

    <multi-text-select-control
      name="layerModel.Tags"
      title="{{ 'PROPERTY.TAGS' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSETHETAGS' | translate }}"
      [options]="tagOptions"
      [(ngModel)]="layerModel.Tags"
      (click)="onClickExpandDropDown(57)"
    >
    </multi-text-select-control>

    <div fxLayout="column" fxLayoutGap="1rem">
      <div fxLayout fxLayoutGap="2rem">
        <checkbox-control
          name="initialActiveState"
          title="{{ 'PROPERTY.ACTIVE' | translate }}"
          [(ngModel)]="layerModel.DefaultPresentation.Active"
        >
        </checkbox-control>

        <checkbox-control
          name="shareWithOutsideYA"
          *ngIf="
            isYaonde &&
            (layerModel.Security?.value == 'Zone' ||
              layerModel.Security?.value == 'Region' ||
              layerModel.Security?.value == 'Interregional')
          "
          title="{{ 'PROPERTY.SHARE_OUTSIDE_YA' | translate }}"
          [(ngModel)]="layerModel.ShareWithOutsideYA"
        >
        </checkbox-control>
      </div>

      <div class="field-list activeDefaultPresentation" fxLayout fxLayoutGap=".5rem">
        <text-select-control
          required
          name="layerModel.DefaultPresentation.Icon"
          title="{{ 'PROPERTY.ICON' | translate }}"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="iconOptions"
          [(ngModel)]="layerModel.DefaultPresentation.Icon"
          [initial]="layerModel.DefaultPresentation.Icon"
          (click)="onClickExpandDropDown(57)"
          [disabled]="!layerModel.DefaultPresentation.Active"
          style="min-width: 50%"
        ></text-select-control>
        <color-picker-control
          required
          name="layerModel.DefaultPresentation.Color"
          title="{{ 'PROPERTY.COLOR' | translate }}"
          placeholder="{{ 'PLACEHOLDERS.HEXADECIMALCOLOR' | translate }}"
          [(ngModel)]="layerModel.DefaultPresentation.Color"
          [disabled]="!layerModel.DefaultPresentation.Active"
          style="min-width: 50%"
        ></color-picker-control>
      </div>
    </div>

    <div fxLayout="column" fxLayoutGap="1rem">
      <div fxLayout fxLayoutAlign="space-between center">
        <span>{{ 'PROPERTY.CUSTOMFIELDS' | translate }}</span>
        <button
          class="tool"
          type="button"
          title="{{ 'BUTTON.NEWCUSTOMFIELD' | translate }}"
          [inlineSVG]="'/assets/images/new.svg'"
          (click)="onAddCustomFieldClick()"
        ></button>
      </div>
      <div class="field-list" fxLayout fxLayoutGap=".5rem" *ngFor="let field of layerModel.CustomFields; let i = index">
        <text-control
          [name]="'key' + i"
          title="{{ 'PROPERTY.PROPERTY' | translate }}"
          placeholder="{{ 'PROPERTY.PROPERTY' | translate }}"
          [(ngModel)]="layerModel.CustomFields[i].key"
          style="min-width: 27rem"
        ></text-control>
        <text-control
          [name]="'value' + i"
          title="{{ 'PROPERTY.VALUE' | translate }}"
          placeholder="{{ 'PROPERTY.VALUE' | translate }}"
          [(ngModel)]="layerModel.CustomFields[i].value"
          style="min-width: 27rem"
        ></text-control>
        <button
          class="tool red"
          type="button"
          (click)="onCloseCustomFieldClick(i)"
          [inlineSVG]="'/assets/images/cross.svg'"
        >
          {{ 'BUTTON.REMOVE' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </div>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{ 'BUTTON.SUBMIT' | translate }}</main-button>
  </div>
</form>
