import { Component, OnDestroy, OnInit } from '@angular/core';
import { DataService } from '@yaris/core/data.service';
import { Group } from '@yaris/core/domain';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-center-status-dialog',
  templateUrl: './center-status-dialog.component.html',
  styleUrls: ['./center-status-dialog.component.sass'],
})
export class CenterStatusDialogComponent implements OnInit, OnDestroy {
  groups: Group[];
  private ngUnsubscribe = new Subject<void>();

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    this.dataService
      .listGroupStatus()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((groups) => {
        this.groups = groups;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
