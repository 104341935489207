import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { TranslateService } from '@ngx-translate/core';
import { MsaManageDefaultPropertiesComponent } from '../msa-manage-default-properties/msa-manage-default-properties.component';
import { ModalService } from '@yaris/core/modal.service';

@Component({
  selector: 'msa-edit-default-properties',
  templateUrl: './msa-edit-default-properties.component.html',
  styleUrls: ['./msa-edit-default-properties.component.sass'],
})
export class MsaEditDefaultPropertiesComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  tagModel: {
    Name: string;
    Description: string;
  } = { Name: undefined, Description: undefined };
  formError: any;
  tag;
  tagTypeOptions: { label: string; value: string }[];
  type: string;
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.tag = this.injector.get('tag');
    //this.tagTypeOptions = [{label:"System",value:"System"},{label:"Human",value:"Human"}]
    this.tagModel.Description = this.tag.Description;
    this.tagModel.Name = this.tag.Value;
    //this.tagModel.Type =this.tagTypeOptions.find(t=>t.label===this.tag.Type)
  }
  manageTags() {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGEPROPERTIESVALUES'),
      inputs: { type: this.tag.Type },
      contentComponent: MsaManageDefaultPropertiesComponent,
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
    //this.tagModel.Type = this.type
    this.dataService
      .updateDefaultProperties({
        _id: this.tag._id,
        Value: this.tagModel.Name,
        Type: this.tag.Type.value,
        Visible: this.tag.Visible,
        Description: this.tagModel.Description,
      })
      .subscribe({
        next: (tag) => {
          this.injector.get('outputSubject').next(tag);
          this.manageTags();
        },
        error: (err) => {
          if (err.error.error.type.includes('duplicate key error collection'))
            this.formError = this.translateService.instant('DEFAULTPROPERTIES.DUPLICATEKEYNAME');
          else this.formError = err.error.error.type;
        },
      });
  }
  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }
}
