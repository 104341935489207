<form
  class="msa-create-default-properties yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <text-select-control
      name="tagTypeOptions.Type"
      title="{{ 'PROPERTY.PROPERTYTYPE' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSETHETAGS' | translate }}"
      [options]="tagTypeOptions"
      [(ngModel)]="tagModel.Type"
      [initial]="tagModel.Type"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control>
    <text-control
      required
      name="tagModel.Name"
      title="{{ 'PROPERTY.NAME' | translate }}"
      placeholder="{{ 'PROPERTY.NAME' | translate }}"
      [(ngModel)]="tagModel.Name"
    >
    </text-control>
    <text-area-control
      name="tagModel.Description"
      title="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      placeholder="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      [(ngModel)]="tagModel.Description"
    >
    </text-area-control>
  </div>
  <div *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </div>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{ 'BUTTON.SUBMIT' | translate }}</main-button>
  </div>
</form>
