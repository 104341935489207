import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'favorite-checkbox',
  templateUrl: './favorite-checkbox.component.html',
  styleUrls: ['./favorite-checkbox.component.sass'],
})
export class FavoriteCheckboxComponent implements OnInit {
  private readonly icons = {
    checked: '/assets/images/favorite_checked.svg',
    unchecked: '/assets/images/favorite_unchecked.svg',
  };

  @Input() disabled: boolean = false;
  @Input() checked: boolean = false;
  @Output() checkedChange = new EventEmitter();

  ngOnInit(): void {}

  onChanged() {
    this.checkedChange.emit(this.checked);
  }

  getIcon(): string {
    return this.checked ? this.icons.checked : this.icons.unchecked;
  }
}
