<button
  [disabled]="disabled"
  [type]="type"
  fxLayout
  fxLayoutAlign="center center"
  fxLayoutGap="1rem"
  class="main-remove-button"
  [class.small]="small"
  [inlineSVG]="icon"
  [class.remove]="type == 'remove'"
  [replaceContents]="false"
  [title]="title"
>
  <span><ng-content></ng-content></span>
</button>
