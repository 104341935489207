import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { Subject } from 'rxjs';
import { ModalService } from '@yaris/core/modal.service';
import { MsaService } from '@yaris/msa/msa.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.sass'],
})
export class ErrorModalComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  private closeSubject: Subject<void>;

  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modal: ModalService,
    private msaService: MsaService,
  ) {}
  type: any;
  label: string;
  private ngUnsubscribe = new Subject<void>();
  ngOnInit(): void {
    this.label = this.injector.get('label');
    this.type = this.injector.get('type');
    this.closeSubject = this.injector.get('closeSubject');
  }

  onSubmit(): void {
    this.closeSubject.next();
  }
}
