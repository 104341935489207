import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { Layer } from '@yaris/core/domain';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '@yaris/core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { MsaEditDefaultPropertiesComponent } from '../msa-edit-default-properties/msa-edit-default-properties.component';
import { MsaCreateDefaultPropertiesComponent } from '../msa-create-default-properties/msa-create-default-properties.component';
import { MsaEditFlagComponent } from '../msa-edit-flag/msa-edit-flag.component';
import { MsaCreateFlagComponent } from '../msa-create-flag/msa-create-flag.component';

@Component({
  selector: 'app-msa-manage-default-properties',
  templateUrl: './msa-manage-default-properties.component.html',
  styleUrls: ['./msa-manage-default-properties.component.sass'],
})
export class MsaManageDefaultPropertiesComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  layers: Layer[];
  coordinates: number[];
  formError = '';
  objectModel: {
    Property: { label: string; value: string };
  } = {
    Property: undefined,
  };
  public search: string = '';
  showSearch: boolean = false;
  propertyOptions: { label: string; value: string }[];
  iconOptions: { _id: string; Value: string; Type: string; Visible: boolean; Description: string; Locked: boolean }[] =
    [];
  unfilteredIcons: {
    _id: string;
    Value: string;
    Type: string;
    Visible: boolean;
    Description: string;
    Locked: boolean;
  }[] = [];
  isLoading = false;

  private ngUnsubscribe = new Subject<void>();
  private searchTimeout = undefined;

  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const type = this.injector.get('type');
    this.propertyOptions = [
      { label: this.translateService.instant('MSA.PLACEHOLDERS.CARGO'), value: 'Cargo' },
      { label: this.translateService.instant('MSA.PLACEHOLDERS.FLAG'), value: 'Flag' },
      { label: this.translateService.instant('MSA.PLACEHOLDERS.PORTS'), value: 'Port' },
      { label: this.translateService.instant('MSA.PLACEHOLDERS.VESSELTYPE'), value: 'VesselType' },
      { label: this.translateService.instant('MSA.PLACEHOLDERS.INCIDENTTYPE'), value: 'IncidentType' },
    ];
    if (type) {
      this.objectModel.Property = this.propertyOptions.find((o) => o.value === type);
      this.changeProperty({ value: type });
    }
  }

  getTitle() {
    if (this.objectModel.Property)
      return this.translateService.instant('MSA.PLACEHOLDERS.SEARCHPROPERTY', {
        property: this.objectModel.Property.label,
      });
    else return '';
  }

  changeProperty(property) {
    this.iconOptions = [];
    this.isLoading = true;
    this.dataService
      .listMsaObjectsDefaultProperties(property.value)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((icons) => {
        this.iconOptions = this.iconOptions.concat(
          property.value === 'Flag'
            ? icons.map((i) => ({
                _id: i._id,
                Value: i.Value,
                Type: i.Type,
                Visible: i.Visible,
                Description: i.Description,
                Locked: i.Locked,
                Translations: {
                  English: i?.Translations?.English,
                  Portuguese: i?.Translations?.Portuguese,
                  French: i?.Translations?.French,
                  Spanish: i?.Translations?.Spanish,
                },
              }))
            : icons.map((i) => ({
                _id: i._id,
                Value: i.Value,
                Type: i.Type,
                Visible: i.Visible,
                Description: i.Description,
                Locked: i.Locked,
              })),
        );
        this.iconOptions.sort(this.compare);
        this.unfilteredIcons = this.iconOptions;
        this.isLoading = false;
      });
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  get hasSearchBar(): boolean {
    return true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSearch(term: string) {
    console.log('Search:1');
    if (this.searchTimeout !== undefined) {
      console.log('1.clearTimeOut');
      clearTimeout(this.searchTimeout);
    }

    if (term.length === 0) {
      console.log('1.Term=0');
      this.iconOptions = this.unfilteredIcons;
      return;
    }

    this.searchTimeout = setTimeout(() => {
      this.iconOptions = this.unfilteredIcons.filter((icon) => icon.Value.toLowerCase().includes(term.toLowerCase()));
    }, 500);
  }

  compare(a, b) {
    if (a.Value.toUpperCase() < b.Value.toUpperCase()) {
      return -1;
    }

    if (a.Value.toUpperCase() > b.Value.toUpperCase()) {
      return 1;
    }

    return 0;
  }

  onEndSearch(search: string = '', fetch: boolean = true): void {
    console.log('EndSearch');
    this.iconOptions = this.unfilteredIcons;
    this.search = '';
  }

  iconPath(icon) {
    return icon.value;
  }

  iconPath2(icon) {
    return '/assets/images/ais.svg';
  }

  visibilityIcon(icon): string {
    return icon.Visible ? '/assets/images/visibility.svg' : '/assets/images/hidden.svg';
  }

  onVisibilityClick(icon): void {
    icon.Visible = !icon.Visible;
    this.dataService.updateDefaultPropertyVisibility(icon).subscribe((icon) => {});
  }

  onOptionsClick(icon, event: MouseEvent): void {
    if (!icon.Locked) {
      if (icon.Type === 'Flag') {
        this.modalService.open({
          closable: true,
          title: this.translateService.instant('DEFAULTPROPERTIES.EDITDEFAULTPROPERTY'),
          inputs: { flag: icon },
          contentComponent: MsaEditFlagComponent,
        });
      } else {
        this.modalService.open({
          closable: true,
          title: this.translateService.instant('DEFAULTPROPERTIES.EDITDEFAULTPROPERTY'),
          inputs: { tag: icon },
          contentComponent: MsaEditDefaultPropertiesComponent,
        });
      }
    }
  }

  classTypeDefault() {
    return 'default';
  }

  classTypeState(icon) {
    return icon.Locked ? 'disabled' : 'default';
  }

  classTypeTool() {
    return 'tool';
  }

  onAdd(property) {
    if (property.value === 'Flag') {
      this.modalService
        .open({
          contentComponent: MsaCreateFlagComponent,
          title: this.translateService.instant('DEFAULTPROPERTIES.NEWDEFAULTPROPERTY'),
          closable: true,
          inputs: { type: this.objectModel.Property.value },
        })
        .output.pipe(takeUntil(this.ngUnsubscribe));
    } else {
      this.modalService
        .open({
          contentComponent: MsaCreateDefaultPropertiesComponent,
          title: this.translateService.instant('DEFAULTPROPERTIES.NEWDEFAULTPROPERTY'),
          closable: true,
          inputs: { type: this.objectModel.Property.value },
        })
        .output.pipe(takeUntil(this.ngUnsubscribe));
    }
  }

  getTypeIcon(icon) {
    return icon.Type === 'System' ? '/assets/images/system.svg' : '/assets/images/user-avatar.svg';
  }

  isSystem(icon) {
    return icon.Type === 'System';
  }

  getType(icon) {
    return icon.Type === 'System' ? 'Situation' : icon.Type;
  }

  getManageLayerIcon(icon) {
    return '/assets/images/manage-layers.svg';
  }

  classTypeDelete(icon): string {
    return icon?.Locked ? 'disabledTrash' : 'defaultTrash';
  }

  onDeleteUnusedClick(icon): void {
    console.log('Tao : ', icon?.Locked !== true);
    if (icon?.Locked !== true) {
      this.dataService.deleteDefaultPropertie(icon).subscribe((Dicon) => {
        if (icon._id === Dicon?._id) {
          this.iconOptions = this.iconOptions.filter((deletedTag) => deletedTag._id !== icon._id);
        } else {
          console.error('Property value could not be found.');
        }
      });
    }
  }

  onSubmit() {
    console.log('onSubmit');
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
  }
}
