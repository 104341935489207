<div
  class="error-notification"
  [ngClass]="{
    'error-notification--visible': visible
  }"
>
  <div class="error-notification__item" *ngIf="error">
    <button class="error-notification__close-button" (click)="onClose()" inlineSVG="/assets/images/cross.svg"></button>
    <h3 class="error-notification__title">{{ error.message || 'Something went wrong' }}</h3>
    <p class="error-notification__status" *ngIf="error.status"><strong>Status:</strong> {{ error.status }}</p>
    <button
      *ngIf="error.stacktrace"
      class="error-notification__copy-to-clipboard"
      type="button"
      (click)="copyStacktrace()"
    >
      <span>Copy Stacktrace</span>
    </button>
  </div>
</div>
