import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { Subject } from 'rxjs';
import { ModalService } from '@yaris/core/modal.service';
import { MsaService } from '@yaris/msa/msa.service';
import { TranslateService } from '@ngx-translate/core';
import { MSAObject } from '@yaris/core/domain';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-export-type-modal',
  templateUrl: './export-type-modal.component.html',
  styleUrls: ['./export-type-modal.component.sass'],
})
export class ExportTypeModalComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modal: ModalService,
    private translateService: TranslateService,
    private msaService: MsaService,
  ) {}
  data: MSAObject;
  type: any;
  label: string;

  private ngUnsubscribe = new Subject<void>();
  ngOnInit(): void {
    this.label = this.injector.get('label');
    this.data = this.injector.get('data');
  }

  onClickExport(event) {
    this.type = event;
  }

  onSubmit(): void {
    if (this.type === 'KMZ') {
      this.dataService.ExportKMZ(this.data._id).subscribe(
        (data) => {
          saveAs(data, `${this.data.Properties.Name || this.data.Properties.UUID}.kmz`);
          this.injector.get('outputSubject').next();
        },
        (error) => console.error(error),
      );
    } else if (this.type === 'CSV') {
      const typeToDownload =
        this.data.Geometry.Type !== 'Point' && this.data.Geometry.Radius === undefined ? '.zip' : '.csv';
      this.dataService.ExportCSV(this.data._id).subscribe(
        (data) => {
          console.log('Data? ', data);
          saveAs(data, `${this.data.Properties.Name || this.data.Properties.UUID}` + `${typeToDownload}`);
          this.injector.get('outputSubject').next();
        },
        (error) => console.error(error),
      );
    }
  }
}
