import {
  Component,
  Optional,
  Inject,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
} from '@angular/core';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { ElementBase } from '../element-base';

import flatpickr from 'flatpickr';
import * as moment from 'moment';

@Component({
  selector: 'date-select-control',
  templateUrl: './date-select-control.component.html',
  styleUrls: ['./date-select-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateSelectControlComponent,
      multi: true,
    },
  ],
})
export class DateSelectControlComponent extends ElementBase<Date> implements OnInit, AfterViewInit, OnChanges {
  @Input() placeholder: string = '';
  @Input() title: string;
  @Input() value: Date = null;
  @Input() initial: Date;
  @Input() maxNow = false;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @ViewChild(NgModel) model: NgModel;
  @ViewChild('date') dateRef: ElementRef;
  private dateFp;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    if (this.initial) {
      this.value = this.initial;
    }
  }

  ngAfterViewInit() {
    const timezoneOffset = this.initial ? this.initial.getTimezoneOffset() : new Date().getTimezoneOffset();
    this.dateFp = flatpickr(this.dateRef.nativeElement, {
      enableTime: true,
      time_24hr: true,
      defaultDate: this.initial ? moment(this.initial).add(timezoneOffset, 'm').valueOf() : null,
      dateFormat: 'Y-m-d H:i',
      onClose: (selected) => {
        this.value = moment(selected[0]).subtract(timezoneOffset, 'm').toDate() || null;
      },
      onOpen: () => {
        if (!this.maxNow || this.maxDate) {
          return;
        }
        this.dateFp.set('maxDate', new Date());
      },
    });
    if (this.maxDate) {
      this.dateFp.set('maxDate', this.maxDate);
    }
    if (this.minDate) {
      this.dateFp.set('minDate', this.minDate);
    }
  }

  ngOnChanges(changes) {
    if (!changes) {
      return;
    }
    if (changes.maxDate && this.dateFp) {
      this.dateFp.set('maxDate', changes.maxDate.currentValue);
    }
    if (changes.minDate && this.dateFp) {
      this.dateFp.set('minDate', changes.minDate.currentValue);
    }
  }
}
