import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Dialog, ModalService } from '@yaris/core/modal.service';
import { MsaManageIconsComponent } from '@yaris/msa/dialogs/msa-manage-icons/msa-manage-icons.component';
import { MsaManageTagsComponent } from '@yaris/msa/dialogs/msa-manage-tags/msa-manage-tags.component';
import { MsaManagePropertiesComponent } from '@yaris/msa/dialogs/msa-manage-properties/msa-manage-properties.component';
import { MsaManageDefaultPropertiesComponent } from '@yaris/msa/dialogs/msa-manage-default-properties/msa-manage-default-properties.component';

import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/observable/fromEvent';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass'],
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewChecked {
  dialogs: Dialog[];

  private currentComponent = null;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private cdref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.modalService.dialogSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((dialogs) => {
      this.dialogs = dialogs;
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewChecked(): void {
    this.cdref.detectChanges();
  }

  onClose(dialog: Dialog) {
    const helper: string = dialog.contentComponent.name;
    const helper2 = dialog.component?.location?.nativeElement?.nodeName;
    const type = dialog.inputs?.type || dialog.inputs.tag?.Type || dialog.inputs?.names;
    if (!dialog.closeSub.isStopped) {
      dialog.closeSub.next();
      dialog.closeSub.complete();
      dialog.errorSub.complete();
      dialog.outputSub.complete();
    }
    if (this.currentComponent) {
      this.currentComponent.destroy();
    }

    this.modalService.closeDialog(dialog);

    if (helper2?.includes('-MANAGE-')) return;
    if (helper === 'AddTagsComponent' || helper === 'MsaEditTagsComponent' || type === 'Situation') {
      this.manageTags();
    } else if (helper === 'MsaCreateIconsComponent' || helper === 'MsaEditIconsComponent' || Array.isArray(type)) {
      this.manageIcons();
    } else if (
      helper === 'MsaCreatePropertiesComponent' ||
      helper === 'MsaEditPropertiesComponent' ||
      type === 'System'
    ) {
      this.manageProperties();
    } else if (
      helper === 'MsaCreateDefaultPropertiesComponent' ||
      helper === 'MsaEditDefaultPropertiesComponent' ||
      ['Flag', 'Cargo', 'VesselType', 'Ports'].includes(type)
    ) {
      this.manageDefaultProperties(type);
    }
  }

  onOutput(dialog: Dialog, data: any) {
    if (!dialog.outputSub.isStopped) {
      dialog.outputSub.next(data);
    }
    this.onClose(dialog);
  }

  onError(dialog: Dialog, data) {
    if (!dialog.errorSub.isStopped) {
      dialog.errorSub.next(data);
    }
    this.onClose(dialog);
  }

  focus(dialog: Dialog) {
    this.modalService.focus(dialog);
  }
  manageIcons() {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGEICONS'),
      inputs: {},
      contentComponent: MsaManageIconsComponent,
      exclusive: true,
    });
  }
  manageTags() {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGETAGS'),
      inputs: {},
      contentComponent: MsaManageTagsComponent,
      exclusive: true,
    });
  }
  manageProperties() {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGEPROPERTIES'),
      inputs: {},
      contentComponent: MsaManagePropertiesComponent,
      exclusive: true,
    });
  }
  manageDefaultProperties(type) {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGEPROPERTIESVALUES'),
      inputs: { type: type },
      contentComponent: MsaManageDefaultPropertiesComponent,
      exclusive: true,
    });
  }
}
