import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading-spinner',
  templateUrl: 'loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.sass'],
})
export class LoadingSpinnerComponent {
  @Input() width = '40';
  @Input() color = '';
}
