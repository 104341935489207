import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  SecurityType,
  SensitivityLevel,
  LayerType,
  FolderType,
  LayerSourceType,
} from '@yaris/core/domain';
import { DataService } from '@yaris/core/data.service';
import { Subject } from 'rxjs';
import { takeUntil, flatMap } from 'rxjs/operators';
import { PermissionsService } from '@yaris/core/permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { getFoldersPaths } from '@yaris/core/utils/ListTree';
import { ErrorManagerService } from '@yaris/core/error-manager.service';
import { MsaService } from '@yaris/msa/msa.service';

@Component({
  selector: 'app-msa-create-view-layer',
  templateUrl: './msa-create-view-layer.component.html',
  styleUrls: ['./msa-create-view-layer.component.sass'],
})
export class MsaCreateViewLayerComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  private readonly rootFolderId = 'rootFolderId';
  formError = '';
  maxFileSize = 40 * 1024 * 1024; // 10MB
  layerModel: {
    Name: string;
    Description: string;
    Security: { label: string; value: SecurityType };
    SensitivityLevel: { label: string; value: SensitivityLevel };
    URL: string;
    Folder: { label: string; value: string };
    CustomFields: {
      key: string;
      value: string;
    }[];
    Layers: { label: string; value: string }[];
    Tags: { label: string; value: string }[];
    DefaultPresentation: {
      Active: boolean;
      Icon: { label: string; value: string };
      Color: string;
    };
    ShareWithOutsideYA: boolean;
    ZoneLayer: boolean;
    GroupLayer: boolean;
    GeometryFile: File;
  } = {
    Name: undefined,
    Description: undefined,
    Security: undefined,
    SensitivityLevel: undefined,
    URL: undefined,
    Folder: undefined,
    CustomFields: [],
    Layers: undefined,
    Tags: undefined,
    DefaultPresentation: {
      Active: undefined,
      Icon: undefined,
      Color: undefined,
    },
    ShareWithOutsideYA: undefined,
    ZoneLayer: false,
    GroupLayer: false,
    GeometryFile: undefined,
  };
  sensitivityLevelOptions: { label: string; value: SensitivityLevel }[] = [];
  securityTypeOptions: { label: string; value: SecurityType }[] = [];
  tagOptions: { label: string; value: string }[] = [];
  foldersPathOptions: { label: string; value: string }[] = [];
  layerOptions: {
    _id: string;
    label: string;
    value: string[];
    security: SecurityType;
    sensitivity: SensitivityLevel;
    type: string;
  }[] = [];
  objectIds: string[];
  iconOptions: { label: string; value: string }[] = [];
  docsTotal = null;
  private docsOffset = 0;
  pageLimit = 100;
  private ngUnsubscribe = new Subject<void>();
  isYaonde = false;

  constructor(
    private injector: Injector,
    private dataService: DataService,
    private permissionsService: PermissionsService,
    private errorManager: ErrorManagerService,
    private msaService: MsaService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.errorManager
      .subscribeToError({
        priority: 10,
        componentName: this.constructor.name,
        whenMethod: 'priority',
        errorIdentifiers: [{ group: 'layer', errorCode: 8 }],
      })
      .subscribe((error) => {
        this.formError = error.message;
      });

    this.isYaonde = this.permissionsService.isYaonde();
    const allowedSecurity = this.permissionsService.allowedSecurity();
    this.securityTypeOptions = Object.keys(SecurityType)
      .filter((k) => allowedSecurity.includes(SecurityType[k]))
      .map((k) => ({
        label: this.translateService.instant(`ENUM.SECURITY.${SecurityType[k]}`.toUpperCase()),
        value: SecurityType[k],
      }));
    this.securityTypeOptions = this.permissionsService.isAfricanStakeholder()
      ? this.securityTypeOptions.filter((o) => o.label !== SecurityType.International)
      : this.securityTypeOptions;

    this.dataService
      .listMsaIcons('true')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((icons) => {
        this.iconOptions = this.iconOptions.concat(icons.map((i) => ({ label: i.Name, value: i.IconURL })));
        //this.layerModel.DefaultPresentation.Icon = this.iconOptions.find(o=>o.value===this.layer.DefaultPresentation?.Icon);
        this.iconOptions.sort(this.compare);
      });

    const allowedSensitivity = this.permissionsService.allowedSensitivity();
    this.sensitivityLevelOptions = Object.keys(SensitivityLevel)
      .filter((k) => isNaN(Number(k)))
      .filter((k) => allowedSensitivity.includes(SensitivityLevel[k]))
      .map((k) => ({
        label: this.translateService.instant(`ENUM.SENSITIVITY.${k}`.toUpperCase()),
        value: SensitivityLevel[k],
      }));

    this.dataService
      .listFolders(FolderType.LayerView)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((folders) => {
        const foldersPath = getFoldersPaths(folders);
        const rootFolderOption = { label: '/', value: this.rootFolderId };

        this.foldersPathOptions = folders
          .map((folder) => ({ label: foldersPath[folder._id], value: folder._id }))
          .concat(rootFolderOption)
          .sort((a, b) => a.label.localeCompare(b.label, this.translateService.currentLang));

        this.layerModel.Folder = rootFolderOption;
      });

    this.dataService
      .listTags('Layer')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((tags) => {
        this.tagOptions = tags.map((t) => ({ label: t.Name, value: t._id }));
        this.layerModel.Tags = [];
      });

    this.layerModel.Security = this.securityTypeOptions[0];
    this.layerModel.SensitivityLevel = this.sensitivityLevelOptions[0];

    const ids = this.injector.get('msaObjects', []);
    if (ids && ids.length > 0) {
      this.objectIds = ids;
      return;
    }

    this.layerModel.Layers = [];

    this.layerOptions = this.msaService
      .listMapLayers()
      .filter(
        (layer) => layer.SourceType != LayerSourceType.EVENT_LOCATION && layer.SourceType != LayerSourceType.SKYLIGHT,
      )
      .map((l) => ({
        _id: l._id,
        label: l.Name,
        value: [l._id],
        security: l.Security,
        sensitivity: l.SensitivityLevel,
        type: l.LayerType,
      }));
  }

  compare(a, b) {
    if (a.label.toUpperCase() < b.label.toUpperCase()) {
      return -1;
    }
    if (a.label.toUpperCase() > b.label.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  get IsSystemAdmin(): boolean {
    return this.permissionsService.isSystemAdmin();
  }

  ngOnDestroy() {
    this.errorManager.unsubscribeToError(this.constructor.name);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  onCloseCustomFieldClick(ix: number) {
    this.layerModel.CustomFields.splice(ix, 1);
  }

  onAddCustomFieldClick() {
    this.layerModel.CustomFields.push({
      key: undefined,
      value: undefined,
    });
  }

  groupLayerChanged(value: boolean) {
    if (value) {
      this.layerModel.ZoneLayer = false;
    }
  }

  zoneLayerChanged(value: boolean) {
    if (value) {
      this.layerModel.GroupLayer = false;
    }
  }

  private getNewFolder() {
    return this.layerModel.Folder?.value === this.rootFolderId ? '' : this.layerModel.Folder.value;
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
    const layersToGetMsaObjects = this.layerModel.Layers?.map((layer) =>
      Array.isArray(layer.value) && layer.value.length ? layer.value[0] : layer.value,
    );

    const layerDTO = {
      Name: this.layerModel.Name,
      LayerType: LayerType.View,
      Security: this.layerModel.Security.value,
      SensitivityLevel: this.layerModel.SensitivityLevel.value,
      Description: this.layerModel.Description,
      URL: this.layerModel.URL,
      Folder: this.getNewFolder(),
      Layers: layersToGetMsaObjects,
      MSAObjects_ids: this.objectIds,
      CustomFields:
        this.layerModel.CustomFields.length > 0
          ? this.layerModel.CustomFields.reduce((fields, pair) => {
              fields[pair.key] = pair.value;
              return fields;
            }, {})
          : undefined,
      Tags: this.layerModel.Tags.map((l) => l.label),
      DefaultPresentation: {
        Active: this.layerModel.DefaultPresentation.Active,
        Icon: this.layerModel.DefaultPresentation.Icon?.value,
        Color: this.layerModel.DefaultPresentation.Color,
      },
      ShareWithOutsideYA: this.layerModel.ShareWithOutsideYA,
      ZoneLayer: this.layerModel.ZoneLayer,
      GroupLayer: this.layerModel.GroupLayer,
    };

    if (this.layerModel.ZoneLayer) {
      this.dataService
        .uploadFile(this.layerModel.GeometryFile, 'converter')
        .pipe(
          flatMap((objectIds) =>
            this.dataService.createLayer({ ...layerDTO, MSAObjects_ids: objectIds, LayerType: LayerType.View }),
          ),
        )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (layer) => this.injector.get('outputSubject').next(layer),
        });
    } else {
      this.dataService
        .createLayer(layerDTO)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (layer) => this.injector.get('outputSubject').next(layer),
        });
    }
  }
}
