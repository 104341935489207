import { Directive } from '@angular/core';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

@Directive({
  selector: '[numeric][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NumericValidator, multi: true }],
})
export class NumericValidator {
  validate(control: AbstractControl): { [validator: string]: any } {
    if (_.isNil(control.value)) {
      // the [required] validator will check presence, not us
      return null;
    }
    if (isNaN(control.value) || isNaN(parseFloat(control.value))) {
      return null;
    }
    return { numeric: { message: 'The value must be a number' } };
  }
}
