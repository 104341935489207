<div class="modal" fxLayout fxLayoutAlign="center center">
  <ng-container *ngFor="let dialog of dialogs">
    <app-modal-dialog
      [dialog]="dialog"
      (onClose)="onClose(dialog)"
      (onOutput)="onOutput(dialog, $event)"
      (onError)="onError(dialog, $event)"
      [style.left.px]="dialog.posX"
      [style.top.px]="dialog.posY"
      [style.z-index]="dialog.zindex"
      (mousedown)="focus(dialog)"
    ></app-modal-dialog>
  </ng-container>
</div>
