import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { ModalService } from '@yaris/core/modal.service';
import { PermissionsService } from '@yaris/core/permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { flatMap } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { MsaManageIconsComponent } from '../msa-manage-icons/msa-manage-icons.component';

@Component({
  selector: 'app-msa-edit-icons',
  templateUrl: './msa-edit-icons.component.html',
  styleUrls: ['./msa-edit-icons.component.sass'],
})
export class MsaEditIconsComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  maxFileSize = 10 * 1024 * 1024; //5MB
  formError = '';
  names: string[];
  validIcon = true;
  icon;
  iconModel: {
    Name: string;
    Description?: string;
    IconURL: File;
  } = {
    Name: undefined,
    Description: undefined,
    IconURL: undefined,
  };
  private ngUnsubscribe = new Subject<void>();
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private permissionService: PermissionsService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.icon = this.injector.get('icon');
    this.names = this.injector.get('names');
    this.iconModel.Name = this.icon.label;
    this.iconModel.Description = this.icon.Description;
    //this.iconModel.Visible=this.icon.Visible
    //this.iconModel.IconURL= this.icon.value
  }

  validateIcon(file) {
    const reader = new FileReader();
    const self = this;
    reader.readAsDataURL(this.iconModel.IconURL);
    reader.onload = function (e) {
      const image = new Image();
      image.src = e.target.result as string;
      image.onload = function (e) {
        const height = image.width;
        const width = image.height;
        if (height > 72 || width > 72) {
          self.validIcon = false;
          self.formError = self.translateService.instant('ICON.WRONGIMAGESIZE');
          return;
        }
        self.validIcon = true;
        self.formError = '';
        return true;
      };
    };
  }

  manageIcons() {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGEICONS'),
      inputs: {},
      contentComponent: MsaManageIconsComponent,
    });
  }

  onSubmit() {
    if (this.names.includes(this.iconModel.Name)) {
      this.formError = this.translateService.instant('ICON.DUPLICATEKEYNAME');
      return;
    } else this.formError = '';
    if (this.form.invalid || !this.validIcon) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    if (this.iconModel.IconURL === undefined) {
      //this.iconModel.IconURL=this.icon.value
      this.dataService
        .updateIcons({
          _id: this.icon._id,
          Name: this.iconModel.Name,
          IconURL: this.icon.value,
          Description: this.iconModel.Description,
          GroupName: 'System',
          Visible: this.icon.Visible,
        })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (icon) => {
            this.injector.get('outputSubject').next();
            this.manageIcons();
          },
          error: (err) => {
            if (err.error.error.type.includes('duplicate key error collection'))
              this.formError = this.translateService.instant(
                err.error.error.type.includes('IconURL') ? 'ICON.DUPLICATEKEYFILENAME' : 'ICON.DUPLICATEKEYNAME',
              );
            else this.formError = err.error.error.type;
            // this.formError = err.error.error.type;
          },
        });
    } else {
      this.dataService
        .uploadFile(this.iconModel.IconURL, 'icon')
        .pipe(
          flatMap((imageURL) =>
            this.dataService.updateIcons({
              _id: this.icon._id,
              Name: this.iconModel.Name,
              IconURL: imageURL.fileURL,
              Description: this.iconModel.Description,
              GroupName: 'System',
              Visible: this.icon.Visible,
            }),
          ),
        )
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (icon) => {
            this.injector.get('outputSubject').next();
          },
          error: (err) => {
            if (err.error.error.type.includes('duplicate key error collection'))
              this.formError = this.translateService.instant(
                err.error.error.type.includes('IconURL') ? 'ICON.DUPLICATEKEYFILENAME' : 'ICON.DUPLICATEKEYNAME',
              );
            else this.formError = err.error.error.type;
            // this.formError = err.error.error.type;
          },
        });
    }
  }
}
