<div
  class="radio-control"
  [class.checked]="checked(option)"
  *ngFor="let option of options"
  [class.inverted]="true"
  disableDoubleClickSelect
>
  <label class="radio-label" fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center" (click)="onClick(option)">
    <input type="radio" [(ngModel)]="value" [value]="option.value" />
    <span class="icon" fxFlex="0 0 auto"> </span>
    <span>{{ option.label }}</span>
  </label>
</div>
