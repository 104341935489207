import { ChangeDetectorRef, Component, ElementRef, Inject, Input, Optional, ViewChild } from '@angular/core';

import { NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

import { ElementBase } from '../element-base';

@Component({
  selector: 'file-control',
  templateUrl: './file-control.component.html',
  styleUrls: ['./file-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileControlComponent,
      multi: true,
    },
  ],
})
export class FileControlComponent extends ElementBase<File> {
  @Input() title: string;
  @Input() extension: string;
  @Input() placeholder: string = '';
  @Input() value: File;

  @ViewChild(NgModel) model: NgModel;
  @ViewChild('realvalue') realvalueRef: ElementRef;

  constructor(
    private cdRef: ChangeDetectorRef,
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (!fileList.length) {
      return;
    }
    this.value = fileList[0];
    this.markDirty();
  }

  markTouched() {
    this.model.control.markAsTouched();
  }

  markDirty() {
    this.model.control.markAsDirty();
  }

  get modelTouched() {
    if (!this.model) {
      return false;
    }
    return this.model.touched;
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }
}
