<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 100 100"
  preserveAspectRatio="xMidYMid"
  class="loading-spinner"
  [style.width.px]="width"
>
  <circle cx="50" cy="50" r="40" class="outer-circle" />
  <circle cx="50" cy="50" r="40" class="spinning-circle" [style.stroke]="color">
    <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="0" to="502" />
    <animate
      attributeName="stroke-dasharray"
      dur="2s"
      repeatCount="indefinite"
      values="150.6 100.4;1 250;150.6 100.4"
    />
  </circle>
</svg>
