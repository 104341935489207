import { Component } from '@angular/core';
import { DataService } from '@yaris/core/data.service';
import { RolePermission } from '@yaris/core/domain';
import { PermissionsService } from '@yaris/core/permissions.service';
@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.sass'],
})
export class AccessDeniedComponent {
  updatedLink: string = window.location.href;
  constructor(private dataService: DataService, private permissionsService: PermissionsService) {
    this.isNotAuthorizedForCurrentDomain()
  }

  isNotAuthorizedForCurrentDomain(): boolean{
    if(this.isJudicialExpert && this.isLight){
        this.updatedLink = window.location.href.replace(/light\/.*/, '');
        return true
    }
    return false
  }

  async goBack() {
    await this.dataService.forceLogout(this.isLight ? 'light' : 'msa').toPromise();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  async changeDomainAccess(){
    await this.dataService.forceLogout(this.isLight ? 'light' : 'msa').toPromise();
    setTimeout(() => {
        window.location.href = this.updatedLink;
    }, 500);
  }

  
  get isJudicialExpert(): boolean {
    return this.permissionsService.hasRolePermissionEqual(RolePermission.judicial_expert);
  }

  get isLight(): boolean {
    return this.permissionsService.isLightDomain();
  }
}
