import { Component, OnInit } from '@angular/core';
import { ApiError, ErrorManagerService } from '@yaris/core/error-manager.service';

@Component({
  selector: 'error-notification',
  templateUrl: './error-notification.component.html',
  styleUrls: ['./error-notification.component.sass'],
})
export class ErrorNotificationComponent implements OnInit {
  private interval: any;
  private readonly notificationTime = 5000;

  visible = false;
  error: ApiError;

  constructor(private errorManager: ErrorManagerService) {}

  ngOnInit() {
    this.errorManager.$errorGeneralNotification.subscribe((error) => this.showError(error));
  }

  /**
   * Show error.
   * @param error The error object.
   */
  showError(error: ApiError) {
    clearInterval(this.interval);

    this.error = error;
    this.visible = true;

    this.interval = setTimeout(() => {
      this.visible = false;
      this.error = undefined;
    }, this.notificationTime);
  }

  /**
   * Close the error.
   */
  onClose(): void {
    this.visible = false;
    this.error = undefined;
    clearInterval(this.interval);
  }

  /**
   * Copy error stacktrace to clipboard.
   */
  copyStacktrace(): void {
    this.copyToClipboard(JSON.stringify(this.error.stacktrace));
  }

  /**
   * Copy text to clipboard
   * @param text The string to be copied to clipboard.
   */
  copyToClipboard(text: string): any {
    if ((window as any).clipboardData && (window as any).clipboardData.setData) {
      return (window as any).clipboardData.setData('Text', text);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.select();

      try {
        return document.execCommand('copy');
      } catch (ex) {
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
}
