import { Directive } from '@angular/core';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';


@Directive({
  selector: '[emailOptional][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailOptionalValidator, multi: true }],
})
export class EmailOptionalValidator {
  validate(control: AbstractControl): { [validator: string]: any } {
    const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*$/g;
    if (!control.value) {
      // the [required] validator will check presence, not us
      return null;
    }
    const value = control.value;
    if (pattern.test(value)) {
      return null;
    }

    return { emailOptional: { message: 'The value must be a valid e-mail address' } };
  }
}
