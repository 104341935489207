<div class="bandwidth-subtitle-dialog" fxLayout="column" scrollable style="width: 60rem">
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <h4>{{ 'LIGHTMSA.CONNECTION.BEST' | translate }}</h4>
    <div class="description-container" fxLayout fxLayoutGap="2rem" fxLayoutAlign="space-between start">
      <p><span style="color: black">&#8226;</span> {{ 'LIGHTMSA.CONNECTION.BESTDESCRIPTION' | translate }}</p>
      <button disabled class="blue">{{ 'LIGHTMSA.CONNECTION.BEST' | translate }}</button>
    </div>
  </div>
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <h4>{{ 'LIGHTMSA.CONNECTION.GOOD' | translate }}</h4>
    <div class="description-container" fxLayout fxLayoutGap="2rem" fxLayoutAlign="space-between start">
      <p><span style="color: black">&#8226;</span> {{ 'LIGHTMSA.CONNECTION.GOODDESCRIPTION' | translate }}</p>
      <button disabled class="green">{{ 'LIGHTMSA.CONNECTION.GOOD' | translate }}</button>
    </div>
  </div>
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <h4>{{ 'LIGHTMSA.CONNECTION.MEDIUM' | translate }}</h4>
    <div class="description-container" fxLayout fxLayoutGap="2rem" fxLayoutAlign="space-between start">
      <p><span style="color: black">&#8226;</span> {{ 'LIGHTMSA.CONNECTION.MEDIUMDESCRIPTION' | translate }}</p>
      <button disabled class="yellow">{{ 'LIGHTMSA.CONNECTION.MEDIUM' | translate }}</button>
    </div>
  </div>
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <h4>{{ 'LIGHTMSA.CONNECTION.LOW' | translate }}</h4>
    <div class="description-container" fxLayout fxLayoutGap="2rem" fxLayoutAlign="space-between start">
      <p><span style="color: black">&#8226;</span> {{ 'LIGHTMSA.CONNECTION.LOWDESCRIPTION' | translate }}</p>
      <button disabled class="red">{{ 'LIGHTMSA.CONNECTION.LOW' | translate }}</button>
    </div>
  </div>
</div>
