import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminGroups } from './admin-groups';
import { AdminJobs } from './admin-jobs';
import { AdminKeycloak } from './admin-keycloak';
import { AdminKibana } from './admin-kibana';
import { AdminPorts } from './admin-ports';
import { AdminReports } from './admin-reports';
import { AdminTools } from './admin-tools';
import { AdminUsers } from './admin-users';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  keycloak: AdminKeycloak;
  kibana: AdminKibana;
  jobs: AdminJobs;
  users: AdminUsers;
  groups: AdminGroups;
  tools: AdminTools;
  reports: AdminReports;
  ports: AdminPorts;

  constructor(private http: HttpClient) {
    this.keycloak = new AdminKeycloak(this.http);
    this.kibana = new AdminKibana(this.http);
    this.jobs = new AdminJobs(this.http);
    this.users = new AdminUsers(this.http);
    this.groups = new AdminGroups(this.http);
    this.tools = new AdminTools(this.http);
    this.reports = new AdminReports(this.http);
    this.ports = new AdminPorts(this.http);
  }
}
