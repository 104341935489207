<div class="notification-box" fxLayout="column">
  <div class="overlay" (click)="onClose()"></div>
  <div class="header" fxLayout fxLayoutAlign="space-between center" fxFlex="0 0 auto" fxLayoutGap="1rem">
    <h2 class="title">{{ title | translate }}</h2>
    <ul class="tools" fxLayout fxLayoutGap="1rem">
      <li
        class="tool"
        title="{{ 'BUTTON.TOGGLEVISIBILITY' | translate }}"
        [inlineSVG]="visibilityIcon"
        (click)="onToggleVisibility()"
      ></li>
      <li
        class="tool"
        title="{{ 'BUTTON.CLOSE' | translate }}"
        inlineSVG="/assets/images/cross.svg"
        (click)="onClose()"
      ></li>
    </ul>
  </div>
  <div class="container" fxLayout="column" fxLayoutAlign="space-between" scrollable>
    <p class="placeholder" *ngIf="!notifications || notifications.length === 0">
      {{ 'NOTIFICATION.PLACEHOLDER.NONOTIFICATIONS' | translate }}
    </p>
    <ul fxLayout="column" fxLayoutGap="1rem">
      <li
        *ngFor="let notification of notifications"
        class="notification"
        [class.hidden]="hidden"
        fxLayout="column"
        fxLayoutGap="1rem"
      >
        <div class="notification-header" fxLayout fxLayoutAlign="space-between start">
          <h4>{{ getTitle(notification) }}</h4>
          <p>{{ notification.Timestamp }}</p>
        </div>
        <div class="notification-body" fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
          <div>
            <p *ngIf="notification.ObjectType != 'CenterState'" class="notification-description" style="width: 100%">
              {{ notification.ObjectInfo?.Name || '' }}
              {{ notification.CreatedBy?.FirstName ? 'by' + notification.CreatedBy?.FirstName : '' }}
            </p>
            <p
              *ngIf="
                notification.ObjectType == 'Layer' &&
                notification.ObjectInfo?.LayerType == 'Smart' &&
                notification.ObjectInfo?._changes?.MSAObjects_ids?.added
              "
              class="notification-description"
              style="max-width: 100%; white-space: nowrap"
              title="{{ notification.ObjectInfo?.Added }}"
            >
              Added: {{ notification.ObjectInfo?._changes?.MSAObjects_ids?.added?.length }}
            </p>
            <p
              *ngIf="
                notification.ObjectType == 'Layer' &&
                notification.ObjectInfo?.LayerType == 'Smart' &&
                notification.ObjectInfo?._changes?.MSAObjects_ids?.removed
              "
              class="notification-description"
              style="max-width: 100%; white-space: nowrap"
              title="{{ notification.ObjectInfo?.Removed }}"
            >
              Removed: {{ notification.ObjectInfo?._changes?.MSAObjects_ids?.removed?.length }}
            </p>
            <p
              *ngIf="notification.Trespasser"
              class="notification-description"
              style="max-width: 100%; white-space: nowrap"
              title="{{ getTrespassingNames(notification) }}"
            >
              {{ getTrespassingMessage(notification) }}
            </p>
          </div>
          <p *ngIf="notification.ObjectType == 'CenterState'" class="notification-description">
            {{ groupsDescription[notification.ObjectInfo?.center_name] }}
            {{ (notification.ObjectInfo?.status ? 'ENUM.BOOLEAN.ACTIVE' : 'ENUM.BOOLEAN.INACTIVE') | translate }}
          </p>

          <ul class="tools" fxLayout="column">
            <li
              class="tool"
              *ngIf="!hidden"
              title="{{ 'BUTTON.HIDE' | translate }}"
              inlineSVG="/assets/images/hidden.svg"
              (click)="onHideClick(notification)"
            ></li>
            <li
              class="tool"
              *ngIf="hidden"
              title="{{ 'BUTTON.SHOW' | translate }}"
              inlineSVG="/assets/images/visibility.svg"
              (click)="onShowClick(notification)"
            ></li>
            <li
              class="tool"
              title="{{ 'BUTTON.REMOVE' | translate }}"
              inlineSVG="/assets/images/cross.svg"
              (click)="onRemoveClick(notification)"
            ></li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="footer" fxLayout fxLayoutAlign="end">
      <main-button (click)="onViewMore()" *ngIf="hasMore" title="{{ 'BUTTON.VIEWMORE' | translate }}">{{
        'BUTTON.VIEWMORE' | translate
      }}</main-button>
    </div>
  </div>
  <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading">{{ 'NOTIFICATION.PLACEHOLDER.LOADING' | translate }}</p>
  </ngx-spinner>
</div>
