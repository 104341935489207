import { Component, OnInit, ViewChild, Injector, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { TranslateService } from '@ngx-translate/core';
import { MsaManageTagsComponent } from '@yaris/msa/dialogs/msa-manage-tags/msa-manage-tags.component';
import { ModalService } from '@yaris/core/modal.service';
import { ErrorManagerService } from '@yaris/core/error-manager.service';

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.sass'],
})
export class AddTagsComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  tagModel: {
    Name: string;
    Description?: string;
    Type: { label: string; value: string }[];
  } = { Name: undefined, Description: undefined, Type: undefined };
  formError: any;
  tagTypeOptions: { label: string; value: string }[];
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private errorManager: ErrorManagerService,
  ) {}

  ngOnInit(): void {
    this.tagTypeOptions = [
      { label: 'Layer', value: 'Layer' },
      { label: 'Situation', value: 'Situation' },
    ];

    this.errorManager
      .subscribeToError({
        priority: 10,
        componentName: this.constructor.name,
        whenMethod: 'priority',
        errorIdentifiers: [{ group: 'tag', errorCode: 2 }],
      })
      .subscribe((error) => {
        this.formError = error.message;
      });
  }

  ngOnDestroy() {
    this.errorManager.unsubscribeToError(this.constructor.name);
  }

  manageTags() {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGETAGS'),
      inputs: {},
      contentComponent: MsaManageTagsComponent,
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
    this.dataService
      .createTags({
        Name: this.tagModel.Name,
        Description: this.tagModel.Description,
        Type: this.tagModel.Type.length > 1 ? 'System' : this.tagModel.Type[0].value,
      })
      .subscribe({
        next: (tag) => {
          this.injector.get('outputSubject').next(tag);
          this.manageTags();
        },
      });
  }
  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }
}
