import { ComponentRef, Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

export interface IModalOutput {
  output: Observable<any>;
  error: Observable<any>;
  close: Observable<void>;
}
export class Modal {
  title: string;
  contentComponent: any;
  inputs: any;
  closable: boolean;
  draggable?: boolean;
  minimizable?: boolean;
  isMinimized?: boolean;
  exclusive?: boolean;
}

export class Dialog {
  title: string;
  contentComponent: any;
  inputs: any;
  closable: boolean;
  draggable?: boolean;
  minimizable: boolean;
  isMinimized: boolean;
  exclusive: boolean;
  component: ComponentRef<unknown>;

  outputSub: Subject<any>;
  errorSub: Subject<any>;
  closeSub: Subject<void>;
  posX: number;
  posY: number;
  zindex: number;

  constructor(
    title,
    inputs,
    contentComponent,
    closable,
    draggable = true,
    minimizable = true,
    exclusive: boolean = false,
  ) {
    this.title = title;
    this.inputs = inputs;
    this.contentComponent = contentComponent;
    this.closable = closable;
    this.draggable = draggable;
    this.minimizable = minimizable;
    this.isMinimized = false;
    this.exclusive = exclusive;
    this.outputSub = new Subject<any>();
    this.errorSub = new Subject<any>();
    this.closeSub = new Subject<void>();

    this.inputs.closeSubject = new Subject<void>();
    this.inputs.errorSubject = new Subject<any>();
    this.inputs.outputSubject = new Subject<any>();

    this.posX = 0;
    this.posY = 0;
    this.zindex = 0;
  }

  public toggleMinimize() {
    this.isMinimized = !this.isMinimized;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  dialogs: Dialog[];

  dialogSubject: Subject<Dialog[]>;

  currentZIndex: number;

  constructor() {
    this.dialogs = [];
    this.dialogSubject = new Subject<Dialog[]>();
    this.currentZIndex = 100;
  }

  open(modal: Modal): IModalOutput {
    const dialog = new Dialog(
      modal.title,
      modal.inputs,
      modal.contentComponent,
      modal.closable,
      modal.draggable,
      modal.minimizable,
      modal.exclusive,
    );

    dialog.posX = -20 * this.dialogs.length;
    dialog.posY = 20 * this.dialogs.length;
    dialog.zindex = this.currentZIndex++;

    if (modal.exclusive) this.closeByName(modal.title);
    this.dialogs.push(dialog);
    this.dialogSubject.next(this.dialogs);

    return {
      output: dialog.outputSub.asObservable(),
      error: dialog.errorSub.asObservable(),
      close: dialog.closeSub.asObservable(),
    };
  }

  closeDialog(dialog: Dialog) {
    this.dialogs.splice(this.dialogs.indexOf(dialog), 1);
    this.dialogSubject.next(this.dialogs);
  }

  closeByName(title: string) {
    const dialogs = this.dialogs.filter((dialog) => dialog.title == title);
    dialogs.forEach((dialog) => {
      this.dialogs.splice(this.dialogs.indexOf(dialog), 1);
    });
    this.dialogSubject.next(this.dialogs);
  }

  close() {
    const dialog = this.dialogs[this.dialogs.length - 1];
    console.warn('modal.service.ts', 'called deprecated closed while closing', dialog);
    dialog.closeSub.next();
  }

  focus(dialog: Dialog) {
    dialog.zindex = ++this.currentZIndex;
  }
}
