import { Component, Optional, Inject, Input, ViewChild } from '@angular/core';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { ElementBase } from '../element-base';

@Component({
  selector: 'radio-control',
  templateUrl: './radio-control.component.html',
  styleUrls: ['./radio-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioControlComponent,
      multi: true,
    },
  ],
})
export class RadioControlComponent extends ElementBase<any> {
  public value;
  @Input() inverted: boolean = false;
  @Input() options: {
    label: string;
    value: any;
  }[];

  @ViewChild(NgModel) model: NgModel;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  onClick(option) {
    if (this.checked(option)) {
      return;
    }
    this.value = option.value;
  }

  checked(option): boolean {
    return this.value === option.value;
  }
}
