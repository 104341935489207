<div class="multi-text-select-control" [class.disabled]="disabled" [style.padding]="style?.padding">
  <div class="label" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap=".5rem">
    <span class="title" *ngIf="title" fxFlex="0 1 auto">
      {{ title }}
    </span>
    <div class="input-container" fxFlex="1 1 auto">
      <div class="input">
        <input type="text" class="hidden" readonly #realValue [(ngModel)]="value" (click)="$event.stopPropagation()" />
        <input
          #displayInput
          type="text"
          class="display"
          readonly
          [placeholder]="placeholder"
          [(ngModel)]="displayVal"
          [ngClass]="{ invalid: (invalid | async) }"
          (click)="onInputClick()"
          [disabled]="disabled"
        />
      </div>
      <ul
        class="dropdown"
        *ngIf="options && dropdown && !disabled"
        scrollable
        closeOnOutsideClick
        (close)="onCloseDropdown()"
        [ngStyle]="{ 'min-width': style?.dropdownMinWidth }"
        [style.top.px]="dropdownPos.top"
        [style.left.px]="dropdownPos.left"
        [style.min-height.rem]="options.length > 10 ? 28 : options.length * 2.8"
      >
        <li class="selectAll" *ngIf="enableSelectAll" (click)="onSelectAll()">{{ selectAllTitle() }}</li>
        <li
          class="item"
          *ngFor="let option of options"
          (click)="onOptionClick(option)"
          [class.selected]="isActive(option)"
        >
          <svg *ngIf="isActive(option)" viewBox="0 0 12.18 8.656">
            <path
              d="M11.393,2.208 L5.736,7.865 C5.346,8.256 4.713,8.256 4.322,7.865 C4.190,7.734 4.120,7.571 4.077,7.403 C3.909,7.360 3.747,7.290 3.615,7.158 L0.787,4.330 C0.396,3.939 0.396,3.306 0.787,2.915 C1.177,2.525 1.810,2.525 2.201,2.915 L5.029,5.744 L9.979,0.794 C10.369,0.404 11.003,0.404 11.393,0.794 C11.784,1.185 11.784,1.818 11.393,2.208 Z"
            />
          </svg>
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
  <validation [messages]="failures | async" *ngIf="invalid | async"></validation>
</div>
