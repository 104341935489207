import { HttpClient } from '@angular/common/http';
import { ITools } from '../interfaces/tools.interface';
import { AdminBaseService } from './admin-base.service';

export class AdminTools extends AdminBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  public async getAll(search?: string, page: number = 0, take: number = 10): Promise<ITools[]> {
    let q = `page=${page}&take=${take}`;
    if (search) {
      q += `&search=${search}`;
    }

    return this.getAsync(`/light/api/admin/tools/all?${q}`);
  }

  public async get(id: string): Promise<ITools> {
    return this.getAsync(`/light/api/admin/tools/tool?id=${id}`);
  }

  public async count(search: string): Promise<number> {
    let q = '';
    if (search) {
      q += `&search=${search}`;
    }
    return this.getAsync(`/light/api/admin/tools/count?${q}`);
  }

  public async create(tool: ITools): Promise<ITools> {
    return this.putAsync('/light/api/admin/tools/create', tool);
  }

  public async update(tool: ITools): Promise<ITools> {
    return this.postAsync('/light/api/admin/tools/update', tool);
  }

  public async delete(id: string): Promise<ITools> {
    return this.deleteAsync(`/light/api/admin/tools/delete?id=${id}`);
  }
}
