<div class="text-select-control" [class.disabled]="disabled">
  <div class="label" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap=".5rem">
    <span class="title" *ngIf="title" fxFlex="0 1 auto">
      {{ title }}
    </span>
    <div class="input-container" fxFlex="1 1 auto">
      <div class="input">
        <input type="text" class="hidden" readonly #realValue [(ngModel)]="value" />
        <input
          type="text"
          class="search"
          #searchInput
          [placeholder]="placeholder"
          [(ngModel)]="searchVal"
          (keyup)="onSearchKeyup($event)"
          (ngModelChange)="onSearchValChange($event)"
          [ngClass]="{ invalid: (invalid | async) }"
          (click)="onInputClick()"
          [disabled]="disabled"
          autocomplete="off"
        />
      </div>
      <ul
        class="dropdown"
        *ngIf="viewOptions && dropdown && !disabled"
        closeOnOutsideClick
        (close)="dropdown = false"
        scrollable
        [style.top.px]="dropdownPos.top"
        [style.left.px]="dropdownPos.left"
        [style.min-height.rem]="maxItems < options.length ? maxItems * 2.8 : options.length * 2.8"
      >
        <li
          class="item"
          *ngFor="let option of viewOptions"
          (click)="onOptionClick(option)"
          [class.selected]="isActive(option)"
          inlineSVG="/assets/images/check.svg"
          [prepend]="true"
          [replaceContents]="false"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
  <validation [messages]="failures | async" *ngIf="modelTouched && invalid | async"></validation>
</div>
