import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { Layer } from '@yaris/core/domain';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '@yaris/core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { MsaEditPropertiesComponent } from '../msa-edit-properties/msa-edit-properties.component';

@Component({
  selector: 'app-msa-manage-properties',
  templateUrl: './msa-manage-properties.component.html',
  styleUrls: ['./msa-manage-properties.component.sass'],
})
export class MsaManagePropertiesComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  layers: Layer[];
  coordinates: number[];
  formError = '';

  public search: string = '';
  iconOptions: { _id: string; label: string; Type: string; Visible: boolean; Description: string }[] = [];
  unfilteredIcons: { _id: string; label: string; Type: string; Visible: boolean; Description: string }[] = [];
  isLoading = false;

  private ngUnsubscribe = new Subject<void>();
  private searchTimeout = undefined;

  constructor(
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.dataService
      .listProperties()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((icons) => {
        this.iconOptions = this.iconOptions.concat(
          icons.map((i) => ({
            _id: i._id,
            label: i.Value,
            Type: i.Type,
            Visible: i.Visible,
            Description: i.Description,
          })),
        );
        const hum = this.iconOptions.filter((obj) => obj.Type === 'Human');
        const sys = this.iconOptions.filter((obj) => obj.Type === 'System');
        hum.sort(this.compare);
        sys.sort(this.compare);
        this.iconOptions = sys.concat(hum);
        this.unfilteredIcons = this.iconOptions;
      });
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }
  get hasSearchBar(): boolean {
    return true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSearch(term: string) {
    if (this.searchTimeout !== undefined) {
      clearTimeout(this.searchTimeout);
    }

    if (term.length === 0) {
      this.iconOptions = this.unfilteredIcons;
      return;
    }

    this.searchTimeout = setTimeout(() => {
      this.iconOptions = this.unfilteredIcons.filter((icon) => icon.label.toLowerCase().includes(term.toLowerCase()));
    }, 500);
  }

  compare(a, b) {
    if (a.label.toUpperCase() < b.label.toUpperCase()) {
      return -1;
    }

    if (a.label.toUpperCase() > b.label.toUpperCase()) {
      return 1;
    }

    return 0;
  }

  onEndSearch(search: string = '', fetch: boolean = true): void {
    this.iconOptions = this.unfilteredIcons;
    this.search = '';
  }

  iconPath(icon) {
    return icon.value;
  }

  iconPath2(icon) {
    return '/assets/images/ais.svg';
  }

  visibilityIcon(icon): string {
    return icon.Visible ? '/assets/images/visibility.svg' : '/assets/images/hidden.svg';
  }

  onVisibilityClick(icon): void {
    icon.Visible = !icon.Visible;
    this.dataService.updatePropertyVisibility(icon).subscribe((icon) => {});
  }

  onOptionsClick(icon, event: MouseEvent): void {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('SITUATION.EDITPROPERTY'),
      inputs: { tag: icon },
      contentComponent: MsaEditPropertiesComponent,
    });
  }

  classTypeDefault() {
    return 'default';
  }

  classTypeState(icon) {
    return icon.Visible ? 'default' : 'disabled';
  }

  classTypeTool() {
    return 'tool';
  }

  getTypeIcon(icon) {
    return icon.Type === 'System' ? '/assets/images/system.svg' : '/assets/images/user-avatar.svg';
  }

  isSystem(icon) {
    return icon.Type === 'System';
  }

  getType(icon) {
    return icon.Type === 'System' ? 'Situation' : icon.Type;
  }

  getManageLayerIcon(icon) {
    return '/assets/images/manage-layers.svg';
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
  }
}
