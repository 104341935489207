import { Component, Optional, Inject, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { ElementBase } from '../element-base';

@Component({
  selector: 'numeric-control',
  templateUrl: './numeric-control.component.html',
  styleUrls: ['./numeric-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumericControlComponent,
      multi: true,
    },
  ],
})
export class NumericControlComponent extends ElementBase<number> implements AfterViewInit {
  @Input() title: string;
  @Input() placeholder: string = '';
  @Input() unit: string;
  @Input() focus = false;
  @Input() disabled = false;
  @Input() digits = '';

  @ViewChild(NgModel) model: NgModel;
  @ViewChild('input') input: ElementRef;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  ngAfterViewInit() {
    if (this.focus) {
      setTimeout((_) => this.input.nativeElement.focus());
    }
  }
}
