import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CoordinateService {
  DMSToDD([deg, min, sec]: number[], hemisphere: 'N' | 'S' | 'E' | 'W'): number {
    const dd: number = deg + min / 60.0 + sec / (60.0 * 60.0);
    if (hemisphere === 'S' || hemisphere === 'W') {
      return -dd;
    } else {
      return dd;
    }
  }

  DMmToDD([deg, min]: number[]): number {
    const dd: number = deg < 0 ? deg - min / 60.0 : deg + min / 60.0;
    return dd;
  }

  DddToDD(deg: number, hemisphere: 'N' | 'S' | 'E' | 'W'): number {
    if (hemisphere === 'S' || hemisphere === 'W') {
      return -deg;
    } else {
      return deg;
    }
  }

  DDToDMS(decimals: number[]): {
    deg: number;
    min: number;
    sec: number;
    hemisphere: 'N' | 'S' | 'E' | 'W';
  }[] {
    return decimals.map((decimal, ix) => {
      const absolute = Math.abs(decimal);
      const degrees = Math.floor(absolute);
      const minutesNotTruncated = (absolute - degrees) * 60;
      const minutes = Math.floor(minutesNotTruncated);
      const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

      return {
        hemisphere: decimal < 0.0 ? (ix === 0 ? 'W' : 'S') : ix === 0 ? 'E' : 'N',
        deg: degrees,
        min: minutes,
        sec: seconds,
      };
    });
  }

  DDToDMm(decimals: number[]): {
    deg: number;
    min: number;
  }[] {
    return decimals.map((decimal) => {
      const degrees = Math.trunc(decimal);
      const minutes = Math.abs(decimal - degrees) * 60;

      return {
        deg: degrees,
        min: minutes,
      };
    });
  }

  DDToDdd(decimals: number[]): {
    deg: number;
    hemisphere: 'N' | 'S' | 'E' | 'W';
  }[] {
    return decimals.map((decimal, ix) => {
      const absolute = Math.abs(decimal);

      return {
        hemisphere: decimal < 0.0 ? (ix === 0 ? 'W' : 'S') : ix === 0 ? 'E' : 'N',
        deg: absolute,
      };
    });
  }

  DDToStringDMS(decimals: number[]): string[] {
    return this.DDToDMS(decimals).map(
      ({ deg, min, sec, hemisphere }) =>
        deg + '\u00b0 ' + this.padNumber(min, 2) + '\u2032 ' + sec + '\u2033 ' + hemisphere,
    );
  }

  DDToStringDMm(decimals: number[]): string[] {
    return this.DDToDMm(decimals).map(({ deg, min }) => deg + '\u00b0 ' + this.padNumber(min, 0, 6) + '\u2032');
  }

  DDToStringDDd(decimals: number[]): string[] {
    return this.DDToDdd(decimals).map(({ deg, hemisphere }) => this.padNumber(deg, 0, 6) + '\u00b0 ' + hemisphere);
  }

  DDToString(decimals: number[]): string[] {
    return decimals.map(
      (coordinate, ix) =>
        Math.abs(coordinate).toFixed(6) + ' ' + (coordinate < 0.0 ? (ix === 0 ? 'W' : 'S') : ix === 0 ? 'E' : 'N'),
    );
  }

  private modulo(a, b) {
    const r = a % b;
    return r * b < 0 ? r + b : r;
  }

  private padNumber(number, width, opt_precision?) {
    const numberString = opt_precision !== undefined ? number.toFixed(opt_precision) : '' + number;
    let decimal = numberString.indexOf('.');
    decimal = decimal === -1 ? numberString.length : decimal;
    return decimal > width ? numberString : new Array(1 + width - decimal).join('0') + numberString;
  }
}
