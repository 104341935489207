import { Injectable } from '@angular/core';
import { DataService } from '@yaris/core/data.service';
import { LayerConfig, MapConfigViewType, MapLocation, Situation, UserMapConfig } from '@yaris/core/domain';
import { PermissionsService } from '@yaris/core/permissions.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, filter, mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserMapConfigService {
  private readonly UserMapConfigStorage = 'UserMapConfigStorage';
  private savingOnCloud = false;

  private configSubject = new BehaviorSubject<UserMapConfig>(null);

  config$ = this.configSubject.asObservable();

  private get userId() {
    return this.permissionsService.getUser()._id;
  }

  constructor(private dataService: DataService, private permissionsService: PermissionsService) {
    this.config$
      .pipe(filter((config) => !!config))
      .pipe(debounceTime(500))
      .subscribe((config) => {
        this.saveConfigInLocalStorage(config);
      });

    this.config$
      .pipe(filter((config) => !!config))
      .pipe(tap({ next: () => (this.savingOnCloud = true) }))
      .pipe(debounceTime(2000))
      .pipe(mergeMap((config) => this.dataService.updateUserMapConfig(config)))
      .pipe(tap({ next: () => (this.savingOnCloud = false) }))
      .subscribe();
  }

  isSavingOnCloud() {
    return this.savingOnCloud;
  }

  setSituation(situation: Situation) {
    const newConfigSubject = new Subject<UserMapConfig>();
    this.dataService.getUserMapConfig(situation._id, this.userId).subscribe((configs) => {
      const remoteConfig = configs?.length && configs[0] ? configs[0] : this.getDefaultUserMapConfig(situation);
      const localConfig = this.getConfigFromLocalStorage(situation._id) || this.getDefaultUserMapConfig(situation);
      const lastestConfig = this.getLatestConfig(remoteConfig, localConfig);

      if (!configs?.length) {
        this.dataService.createUserMapConfig(lastestConfig).subscribe((createdConfig) => {
          this.setConfig(createdConfig);
          newConfigSubject.next(createdConfig);
        });
      } else {
        this.setConfig(lastestConfig);
        newConfigSubject.next(lastestConfig);
      }
    });

    return newConfigSubject.asObservable();
  }

  setZoom(zoom: number) {
    const mapLocation: MapLocation = {
      ...this.getConfig().MapLocation,
      Zoom: zoom,
    };

    const config: UserMapConfig = {
      ...this.getConfig(),
      MapLocation: mapLocation,
    };

    this.setConfig(config);
  }

  setCoordinates(coordinates: number[]) {
    const mapLocation: MapLocation = {
      ...this.getConfig().MapLocation,
      Coordinates: [...coordinates],
    };

    const config: UserMapConfig = {
      ...this.getConfig(),
      MapLocation: mapLocation,
    };

    this.setConfig(config);
  }

  getConfig(): UserMapConfig {
    return this.configSubject.getValue();
  }

  setLayersConfigFromList(listConfigs: LayerConfig[]) {
    const selectedLayersConfig = [...listConfigs];
    const unselectedLayersConfig = this.getConfig()
      .Layers.filter(
        (savedConfig) => !selectedLayersConfig.find((selectedConfig) => selectedConfig.LayerId === savedConfig.LayerId),
      )
      .map((savedConfig) => ({ ...savedConfig, IsSelected: false }));

    const layersConfig = [].concat(selectedLayersConfig, unselectedLayersConfig);

    this.setConfig({ ...this.getConfig(), Layers: layersConfig });
  }

  getLayerConfig(layerId: string) {
    const config = this.getConfig();
    return config?.Layers?.find((l) => l.LayerId === layerId);
  }

  setLayerConfig(layerConfig: LayerConfig) {
    const config = { ...this.getConfig() };
    if (config) {
      const layersConfigs: LayerConfig[] = config.Layers?.slice() || [];
      const layerConfigIndex = layersConfigs.findIndex((l) => l.LayerId === layerConfig.LayerId);
      layersConfigs[layerConfigIndex] = { ...layerConfig };
      config.Layers = layersConfigs;
      this.setConfig({ ...config });
    }
  }

  getLatestConfig(remoteConfig: UserMapConfig, localConfig: UserMapConfig): UserMapConfig {
    const remoteDate = new Date(remoteConfig.UpdatedAt || null);
    const localDate = new Date(localConfig.UpdatedAt || null);
    return remoteConfig.UpdatedAt && remoteDate > localDate ? remoteConfig : localConfig;
  }

  private setConfig(config: UserMapConfig) {
    this.configSubject.next(config);
  }

  private saveConfigInLocalStorage(config: UserMapConfig) {
    if (!config?.SituationId) return;

    const userStorage = this.getUserConfigsStorage();
    userStorage[config.SituationId] = config;
    this.saveUserConfigsStorage(userStorage);
  }

  private getConfigFromLocalStorage(situationId: string) {
    const userStorage = this.getUserConfigsStorage();
    return userStorage[situationId];
  }

  private getUserConfigsStorage() {
    const storedConfigs = JSON.parse(localStorage.getItem(this.UserMapConfigStorage)) || {};
    return storedConfigs[this.userId] || {};
  }

  private saveUserConfigsStorage(userStorage: any) {
    const storedConfigs = JSON.parse(localStorage.getItem(this.UserMapConfigStorage)) || {};
    storedConfigs[this.userId] = userStorage;
    localStorage.setItem(this.UserMapConfigStorage, JSON.stringify(storedConfigs));
  }

  private getDefaultUserMapConfig(situation: Situation): UserMapConfig {
    const _Layers = situation.Layers_ids.map((id) => ({
      LayerId: id,
      IsSelected: true,
      IsHidden: false,
      Opacity: 100,
    }));

    return {
      SituationId: situation._id,
      UserId: this.userId,
      Layers: _Layers,
      ViewType: MapConfigViewType.Default,
      MapLocation: {
        Coordinates: [0.318471, 6.092802],
        Type: 'Point',
        Zoom: 5,
      },
      UpdatedAt: null,
      CreatedAt: null,
    };
  }
}
