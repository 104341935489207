import { Component, OnInit, ElementRef, Input, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { SocketService } from '@yaris/core/socket.service';
import { MsaService } from '@yaris/msa/msa.service';
import { PermissionsService } from '@yaris/core/permissions.service';
import { DataService } from '@yaris/core/data.service';
import {
  Notification,
  LayerType,
  NotificationActionType,
  NotificationObjectType,
  SecurityType,
  SensitivityLevel,
} from '@yaris/core/domain';
import { delay, filter, takeUntil, concatMap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

interface AlertNotification {
  Id?: number;
  NotificationType: string;
  Trespasser?: any;
  Name?: string;
  Added?: string;
  Removed?: string;
  Security?: SecurityType;
  Sensitivity?: SensitivityLevel;
  Type?: LayerType;
  TTL: number;
  SituationName?: string;
  ChatName?: string;
  Text?: string;
}

@Component({
  selector: 'message-control',
  templateUrl: './message-control.component.html',
  styleUrls: ['./message-control.component.sass'],
})
export class MessageControlComponent implements OnInit, OnDestroy {
  audio = new Audio('assets/sounds/layerUpdatedSound.mp3');
  //map: mapboxgl.Map
  visible = true;
  //soundAlert=true
  @Input() type = undefined;
  notifications: AlertNotification[];
  private notificationIdCursor = 0;
  private notifications$ = new Subject<AlertNotification>();
  private readonly TTLDefault = 15 * 1000;
  private readonly TTLDefaultForSmartLayerAlert = 15 * 1000;
  private ngUnsubscribe = new Subject<void>();
  constructor(
    private el: ElementRef,
    private msaService: MsaService,
    private socketService: SocketService,
    private dataService: DataService,
    private permissionService: PermissionsService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.notifications = [];
    const notifier = this.socketService.getAlerts().pipe(takeUntil(this.ngUnsubscribe));

    notifier
      .pipe(
        filter(
          (n) =>
            n.ObjectType === NotificationObjectType.Situation &&
            (n.ActionType === NotificationActionType.Added || n.ActionType === NotificationActionType.Created),
        ),
      )
      .pipe(filter((n) => this.socketService.differentUser(n.CreatedBy as string)))
      .subscribe((n) => {
        const actionText = this.translateService.instant('NOTIFICATION.ACTION.SITUATION.' + n.ActionType.toUpperCase());
        const notification = {
          ...n.ObjectInfo,
          NotificationType: n.ObjectType,
          ActionText: actionText,
          TTL: this.TTLDefault,
        };
        this.announceAlertToUser(notification);
      });

    this.socketService
      .getNotifications()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(
        filter(
          (n) =>
            n.ObjectType === NotificationObjectType.Layer &&
            n.ActionType === NotificationActionType.Updated &&
            n.ObjectInfo.LayerType === LayerType.Smart &&
            !n.ObjectInfo._changes,
        ),
      )
      .subscribe((n) => {
        const actionText = this.translateService.instant('NOTIFICATION.ACTION.LAYER.UPDATED');
        const notification = {
          ...n.ObjectInfo,
          NotificationType: n.ObjectType,
          ActionText: actionText,
          TTL: this.TTLDefault,
        };
        this.announceAlertToUser(notification);
      });

    notifier
      .pipe(
        filter(
          (n) =>
            (n.ObjectType === NotificationObjectType.ChatPart || n.ObjectType === NotificationObjectType.Event) &&
            n.ActionType === NotificationActionType.Added,
        ),
      )
      .pipe(filter((n) => this.socketService.differentUser(n.CreatedBy as string)))
      .subscribe((n) => {
        const notification = { ...n.ObjectInfo, NotificationType: n.ObjectType, TTL: this.TTLDefault };

        if (window.location.href.includes('/chat/') && n.ObjectType !== NotificationObjectType.ChatPart)
          this.announceAlertToUser(notification);
        else if (window.location.href.includes('/log/') && n.ObjectType !== NotificationObjectType.Event)
          this.announceAlertToUser(notification);
        else if (window.location.href.includes('/situation/') || window.location.href.includes('/msa/'))
          this.announceAlertToUser(notification);
      });
    if (window.location.href.includes('/msa/')) {
      this.msaService.notificationControl$
        .pipe(takeUntil(this.ngUnsubscribe))
        .pipe(filter((n) => n.NotificationType === 'Smartlayer'))
        .subscribe((notification) => {
          this.announceAlertToUser({ ...notification, TTL: this.TTLDefaultForSmartLayerAlert });
        });

      this.socketService
        .getNotifications()
        .pipe(takeUntil(this.ngUnsubscribe))
        .pipe(filter((n) => n.ObjectType === NotificationObjectType.MSAObject))
        .pipe(filter((n) => n.ActionType === NotificationActionType.Trespass))
        .subscribe((n) => {
          const notification = {
            NotificationType: 'Trespass',
            Name: n.ObjectInfo.Name,
            Security: n.ObjectInfo.Security,
            Sensitivity: n.ObjectInfo.SensitivityLevel,
            Type: n.ObjectInfo.LayerType,
            Trespasser: n.Trespasser,
            TTL: this.TTLDefaultForSmartLayerAlert,
          };

          if (this.msaService.hasLayer(n.ObjectInfo?._id)) {
            this.announceAlertToUser(notification);
          }
        });
    }

    this.notifications$
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(
        concatMap((notification) => {
          return of(notification).pipe(delay(notification.TTL || this.TTLDefault));
        }),
      )
      .subscribe((notification) => {
        this.notifications = this.notifications.filter((n) => n.Id !== notification.Id);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  announceAlertToUser(notification: any) {
    if (this.permissionService.getUser().Preferences.AlertPreferences?.SoundAlert || false) this.audio.play();
    notification.Id = this.notificationIdCursor++;
    this.notifications.push(notification);
    this.notifications$.next(notification);
  }

  get visibilityIcon(): string {
    return this.visible ? '/assets/images/hidden.svg' : '/assets/images/visibility.svg';
  }

  getTrespassingMessage(notification: Notification) {
    const areaName = notification.Trespasser?.area?.Name;
    const pointName = notification.Trespasser?.point?.Name;
    const state = notification.Trespasser?.state;
    if (state == 'in') {
      return `'${pointName}' ${this.translateService.instant('NOTIFICATION.DESCRIPTIONS.TRESPASSER_IN')} '${areaName}'`;
    } else if (state == 'out') {
      return `'${pointName}' ${this.translateService.instant(
        'NOTIFICATION.DESCRIPTIONS.TRESPASSER_OUT',
      )} '${areaName}'`;
    } else if (state == 'crossing') {
      return `'${pointName}' ${this.translateService.instant(
        'NOTIFICATION.DESCRIPTIONS.TRESPASSER_CROSSED',
      )} '${areaName}'`;
    }

    let names = Array.isArray(notification.Trespasser.point)
      ? notification.Trespasser.point.map((trye) => trye?.Name)
      : notification.Trespasser.point?.Name;
    if (Array.isArray(notification.Trespasser.point) && names.length > 2) {
      names = names.slice(0, 2).join(' , ') + '...';
    }
    return this.translateService.instant('NOTIFICATION.DESCRIPTIONS.TRESPASSER', {
      point: notification.Trespasser.point.length > 1 ? names : notification.Trespasser.point[0]?.Name,
      area: notification.Trespasser.area?.Name,
    });
  }

  getTrespassingNames(notification) {
    return Array.isArray(notification.Trespasser.point)
      ? notification.Trespasser.point.map((trye) => trye?.Name).join(' , ')
      : notification.Trespasser.point?.Name;
  }

  get audioIcon(): string {
    return this.permissionService.getUser().Preferences.AlertPreferences?.SoundAlert || false
      ? '/assets/images/soundOn.svg'
      : '/assets/images/soundMuted.svg';
  }

  onToggleVisibility(): void {
    this.visible = !this.visible;
  }

  onToggleAudio(): void {
    //this.soundAlert = !this.
    this.dataService
      .updateUserPreferences({
        ...this.permissionService.getUser().Preferences,
        AlertPreferences: {
          SoundAlert: !(this.permissionService.getUser().Preferences.AlertPreferences?.SoundAlert || false),
          NotifyAllSituations: this.permissionService.getUser().Preferences.AlertPreferences?.NotifyAllSituations,
        },
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (user) => {},
        error: (err) => {},
      });
  }

  onNotificationClick(index: number) {
    this.notifications.splice(index, 1);
  }
  layerIcon(layer: any): string {
    switch (layer.Security) {
      case SecurityType.Local:
        return '/assets/images/local.svg';
      case SecurityType.National:
        return '/assets/images/national.svg';
      case SecurityType.Zone:
        return '/assets/images/zone.svg';
      case SecurityType.Region:
        return '/assets/images/region.svg';
      case SecurityType.Interregional:
        return '/assets/images/interregional.svg';
      case SecurityType.International:
        return '/assets/images/international.svg';
      default:
        return '/assets/images/chat.svg';
    }
  }
  layerColor(layer: any): string {
    switch (layer.Sensitivity) {
      case SensitivityLevel.Public:
        return 'green';
      case SensitivityLevel.Private:
        return 'yellow';
      case SensitivityLevel.Secret:
        return 'red';
      default:
        return 'default';
    }
  }
  layerType(layer: any) {
    switch (layer.Type) {
      case LayerType.Remote:
        return '/assets/images/remote-layers.svg';
      case LayerType.Smart:
        return '/assets/images/smart-layers-2.svg';
      case LayerType.View:
        return '/assets/images/view-layers.svg';
      case LayerType.ThirdPartyLayer:
        return '/assets/images/third-party-layers.svg';
      default:
        return '/assets/images/information-layers.svg';
    }
  }
  layerTypeShow() {
    return 'layerType';
  }
}
