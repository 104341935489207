import { Directive, Input } from '@angular/core';

import * as _ from 'lodash';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[fileSize][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FileSizeValidator, multi: true }],
})
export class FileSizeValidator {
  @Input() fileSize: number;

  validate(control: AbstractControl): { [validator: string]: any } {
    if (_.isNil(control.value) || !this.fileSize) {
      return null;
    }
    const size = control.value.size;
    if (size > this.fileSize) {
      let display = this.fileSize;
      let unit = 'bytes';
      if (display >= 1024 * 1024) {
        display = this.fileSize / (1024 * 1024);
        unit = 'MB';
      } else if (display >= 1024) {
        display = this.fileSize / 1024;
        unit = 'KB';
      }
      return { fileSize: { value: this.fileSize, message: 'The file size must be less than ' + display + ' ' + unit } };
    }
    return null;
  }
}
