import { Component, Optional, Inject, Input, ViewChild, Output } from '@angular/core';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { EventEmitter } from '@angular/core';

import { ElementBase } from '../element-base';

@Component({
  selector: 'radio-control-coordinates',
  templateUrl: './radio-control-coordinates.component.html',
  styleUrls: ['./radio-control-coordinates.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioControlCoordinatesComponent,
      multi: true,
    },
  ],
})
export class RadioControlCoordinatesComponent extends ElementBase<any> {
  public selected: { label: string; value: any } = { label: undefined, value: undefined };
  @Output() onCoordinatesFormatChange: EventEmitter<any> = new EventEmitter();
  @Input() title: string;
  @Input() textColor: string = '';
  @Input() initial: { label: string; value: any };
  @Input() inverted: boolean = true;
  @Input() options: {
    label: string;
    value: any;
  }[];

  @ViewChild(NgModel) model: NgModel;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    if (this.initial) {
      this.selected.label = this.initial.label;
      this.selected.value = this.initial.value;
    }
  }

  onClick(option) {
    if (this.checked(option)) {
      return;
    }

    this.onCoordinatesFormatChange.emit({ oldFormat: this.selected, newFormat: option });
    this.selected.label = option.label;
    this.selected.value = option.value;
  }

  checked(option): boolean {
    return this.selected.value === option.value;
  }
}
