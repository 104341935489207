import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.sass'],
})
export class SortButtonComponent implements OnInit {
  private readonly icons = {
    off: '/assets/images/sort_off.svg',
    desc: '/assets/images/sort_desc.svg',
    asc: '/assets/images/sort_asc.svg',
  };

  @Input() type: string = 'off';

  ngOnInit(): void {}

  getIcon(): string {
    return this.icons[this.type] || this.icons.off;
  }
}
