import { NgModel } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ValueAccessorBase } from './value-accessor';

import { AsyncValidatorArray, ValidatorArray, ValidationResult, validate, message } from './validate';

export abstract class ElementBase<T> extends ValueAccessorBase<T> {
  protected abstract model: NgModel;

  constructor(private validators: ValidatorArray, private asyncValidators: AsyncValidatorArray) {
    super();
  }

  protected validate(): Observable<ValidationResult> {
    if (!this.model) {
      return of(null);
    }
    return validate(this.validators, this.asyncValidators)(this.model.control);
  }

  public get invalid(): Observable<boolean> {
    return this.validate().pipe(map((v) => Object.keys(v || {}).length > 0));
  }

  public get modelTouched() {
    if (!this.model) {
      return false;
    }
    return this.model.touched;
  }

  public get modelDirty() {
    if (!this.model) {
      return false;
    }
    return this.model.dirty;
  }

  public get failures(): Observable<string[]> {
    return this.validate().pipe(map((v) => Object.keys(v).map((k) => this.failureMessage(v, k) || message(v, k))));
  }

  protected failureMessage(validator: ValidationResult, key: string): string {
    return '';
  }
}
