import { Component, OnInit, Injector, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  SecurityType,
  SensitivityLevel,
  EventType,
  Situation,
  EventCategory,
  LayerType,
  IncidentStatus,
  RolePermission,
  ContentSource,
} from '@yaris/core/domain';
import { ModalService } from '@yaris/core/modal.service';
import { DataService } from '@yaris/core/data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermissionsService } from '@yaris/core/permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { LogCreateIncidentComponent } from '@yaris/shared/components/log-create-incident/log-create-incident.component';
import { LogCreatePointComponent } from '@yaris/shared/components/log-create-point/log-create-point.component';
import { centerOfMass, lineString, multiLineString, polygon } from '@turf/turf';
import { MsaService } from '@yaris/msa/msa.service';

@Component({
  selector: 'app-msa-create-event',
  templateUrl: './msa-create-event.component.html',
  styleUrls: ['./msa-create-event.component.sass'],
})
export class MsaCreateEventComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;

  maxFileSize = 10 * 1024 * 1024; //5MB
  eventModel: {
    Security: { label: string; value: SecurityType };
    SensitivityLevel: { label: string; value: SensitivityLevel };
    EventType: { label: string; value: EventType };
    EventCategory: { label: string; value: EventCategory };
    IncidentType: { label: string; value: EventCategory };
    Tags: { label: string; value: string }[];
    Description: string;
    fileURL: File;
    ShareWithOutsideYA: boolean;
    WarningMessage: string;
    Status: { label: string; value: string };
  } = {
    Security: undefined,
    SensitivityLevel: undefined,
    EventType: undefined,
    EventCategory: undefined,
    IncidentType: undefined,
    Description: undefined,
    Tags: undefined,
    fileURL: undefined,
    ShareWithOutsideYA: undefined,
    WarningMessage: undefined,
    Status: { label: undefined, value: undefined },
  };
  createIncidentWarning: boolean = false;
  sensitivityLevelOptions: { label: string; value: SensitivityLevel }[] = [];
  securityTypeOptions: { label: string; value: SecurityType }[] = [];
  eventTypeOptions: { label: string; value: EventType }[] = [];
  eventCategoryOptions: { label: string; value: EventCategory }[] = [];
  incidentTypeOptions: { label: string; value: string }[] = [];
  tagOptions: { label: string; value: string }[] = [];
  statusOptions: { label: string; value: string }[] = [];
  MSAObject: any;
  private situation: Situation;
  private ngUnsubscribe = new Subject<void>();
  canShareOutside = false;
  IsYaonde = false;

  private closeSubject: Subject<void>;
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modal: ModalService,
    private msaService: MsaService,
    private permissionsService: PermissionsService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.situation = this.injector.get('situation');
    this.closeSubject = this.injector.get('closeSubject');
    this.MSAObject = this.injector.get('msaObject');

    this.IsYaonde = this.permissionsService.isYaonde();
    this.canShareOutside =
      this.situation.SharingGroups.find((g) => g.GroupName == this.permissionsService.getUser().GroupName)?.CanShare ||
      false;

    const allowedSecurity = this.permissionsService.allowedSecurity();
    this.securityTypeOptions = Object.keys(SecurityType)
      .filter((k) => allowedSecurity.includes(SecurityType[k]))
      .map((k) => ({
        label: this.translateService.instant(`ENUM.SECURITY.${SecurityType[k]}`.toUpperCase()),
        value: SecurityType[k],
      }));

    this.securityTypeOptions = this.permissionsService.isAfricanStakeholder()
      ? this.securityTypeOptions.filter((o) => o.label !== SecurityType.International)
      : this.securityTypeOptions;
    const allowedSensitivity = this.permissionsService.allowedSensitivity();
    this.sensitivityLevelOptions = Object.keys(SensitivityLevel)
      .filter((k) => isNaN(Number(k)))
      .filter((k) => allowedSensitivity.includes(SensitivityLevel[k]))
      .map((k) => ({
        label: this.translateService.instant(`ENUM.SENSITIVITY.${k}`.toUpperCase()),
        value: SensitivityLevel[k],
      }));

    this.eventTypeOptions = Object.keys(EventType)
      .filter(
        (k) =>
          EventType[k] !== EventType.Decision ||
          this.permissionsService.getUserPermissions().MSA.Create_Decision_Events,
      )
      .map((k) => ({
        label: this.translateService.instant(`ENUM.EVENTTYPE.${EventType[k]}`.toUpperCase()),
        value: EventType[k],
      }));

    this.eventCategoryOptions = Object.keys(EventCategory)
      .filter((k) => k != EventCategory.Log)
      .map((k) => ({
        label: this.translateService.instant(`ENUM.EVENTCATEGORY.${EventCategory[k]}`.toUpperCase()),
        value: EventCategory[k],
      }));

    this.dataService.listMsaObjectsDefaultProperties('IncidentType').subscribe((incidentTypes) => {
      this.incidentTypeOptions = incidentTypes.map((incidentType) => ({
        label: incidentType.Value,
        value: incidentType.Value,
      }));
    });

    this.dataService
      .listTagsBySituation(this.situation._id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((tags) => {
        this.tagOptions = tags.map((t: any) => ({ label: t, value: t }));
        this.eventModel.Tags = [];
      });

    this.statusOptions = Object.keys(IncidentStatus).map((k) => ({
      label: this.translateService.instant('ENUM.INCIDENTSTATUS.' + k.toUpperCase()),
      value: IncidentStatus[k],
    }));

    this.eventModel.Security = this.securityTypeOptions.find((s) => s.value === this.situation.Security);
    this.eventModel.EventType = this.eventTypeOptions[0];
    this.eventModel.EventCategory = this.eventCategoryOptions[0];
    this.eventModel.SensitivityLevel = this.sensitivityLevelOptions.find(
      (s) => s.value === this.situation.SensitivityLevel,
    );
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  hasPermissonAttach() {
    return this.permissionsService.getUserPermissions().MSA.Attach_Documents_Events;
  }

  showIncidentTypeField(): boolean {
    return this.eventModel.EventCategory.value == EventCategory.Incident;
  }

  showCreateIncidentWarningCheckbox(): boolean {
    return (
      this.isOperator() &&
      this.permissionsService.isYaonde() &&
      this.eventModel.EventCategory.value == EventCategory.Incident
    );
  }

  showIncidentWarningField(): boolean {
    return (
      this.isOperator() && this.eventModel.EventCategory.value == EventCategory.Incident && this.createIncidentWarning
    );
  }

  isOperator(): boolean {
    return this.permissionsService.hasRolePermissionEqualOrAbove(RolePermission.operations_manager);
  }

  buttonText() {
    return this.eventModel.EventCategory.value == EventCategory.Point ||
      this.eventModel.EventCategory.value == EventCategory.Incident
      ? 'BUTTON.NEXT'
      : 'BUTTON.SUBMIT';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit() {
    let fileurl;
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    const center: number[] = this.calculateCenter(this.MSAObject.Geometry);

    const event = {
      Description: this.eventModel.Description,
      Security: this.eventModel.Security.value,
      SensitivityLevel: this.eventModel.SensitivityLevel.value,
      EventType: this.eventModel.EventType.value,
      Situation_id: this.situation._id,
      Tags: this.eventModel.Tags ? this.eventModel.Tags.map((l) => l.value) : [],
      ShareWithOutsideYA: this.eventModel.ShareWithOutsideYA,
    };
    if (this.eventModel.EventCategory.value == EventCategory.Point) {
      if (this.eventModel.fileURL) {
        const result = this.dataService
          .uploadAttachment(this.eventModel.fileURL, {
            Sensitivity: this.eventModel.SensitivityLevel.value,
            Security: this.eventModel.Security.value,
            Source: ContentSource.Event,
            Message: this.eventModel.Description,
            ParentName: '',
            ParentId: null,
          })
          .subscribe((uploadRes) => {
            const layers = this.msaService.getMapLayers().filter((layer) => layer.LayerType === LayerType.Points);

            this.modal.open({
              closable: true,
              title: this.translateService.instant('LOG.NEWPOINT'),
              inputs: {
                layers: layers,
                event: {
                  ...event,
                  EventCategory: EventCategory.Point,
                  fileURL: uploadRes.Filename ? uploadRes.Filename : null,
                  Content: uploadRes._id,
                },
                situationId: event.Situation_id,
                initialCoordinates: center,
              },
              contentComponent: LogCreatePointComponent,
            });
            this.closeSubject.next();
          });
      } else {
        const layers = this.msaService.getMapLayers().filter((layer) => layer.LayerType === LayerType.Points);

        this.modal.open({
          closable: true,
          title: this.translateService.instant('LOG.NEWPOINT'),
          inputs: {
            layers: layers,
            event: {
              ...event,
              EventCategory: EventCategory.Point,
              fileURL: fileurl ? fileurl : null,
            },
            situationId: event.Situation_id,
            initialCoordinates: center,
          },
          contentComponent: LogCreatePointComponent,
        });
        this.closeSubject.next();
      }
    } else if (this.eventModel.EventCategory.value == EventCategory.Incident) {
      if (this.eventModel.fileURL) {
        const result = this.dataService
          .uploadAttachment(this.eventModel.fileURL, {
            Sensitivity: this.eventModel.SensitivityLevel.value,
            Security: this.eventModel.Security.value,
            Source: ContentSource.Event,
            Message: this.eventModel.Description,
            ParentName: '',
            ParentId: null,
          })
          .subscribe((uploadRes) => {
            const layers = this.msaService.getMapLayers().filter((layer) => layer.LayerType === LayerType.Event);

            this.modal.open({
              closable: true,
              title: this.createIncidentWarning
                ? this.translateService.instant('LOG.NEWINCIDENTWARNING')
                : this.translateService.instant('LOG.NEWINCIDENT'),
              inputs: {
                layers: layers,
                isIncidentWarning: this.createIncidentWarning,
                event: {
                  ...event,
                  EventCategory: EventCategory.Incident,
                  IncidentType: this.eventModel.IncidentType.value,
                  WarningMessage: this.eventModel.WarningMessage,
                  IncidentStatus: this.eventModel.Status.value,
                  fileURL: uploadRes.Filename ? uploadRes.Filename : null,
                  Content: uploadRes._id,
                },
                situationId: event.Situation_id,
                initialCoordinates: center,
                msaobject: this.MSAObject,
              },
              contentComponent: LogCreateIncidentComponent,
            });
            this.closeSubject.next();
          });
      } else {
        const layers = this.msaService.getMapLayers().filter((layer) => layer.LayerType === LayerType.Event);

        this.modal.open({
          closable: true,
          title: this.createIncidentWarning
            ? this.translateService.instant('LOG.NEWINCIDENTWARNING')
            : this.translateService.instant('LOG.NEWINCIDENT'),
          inputs: {
            layers: layers,
            isIncidentWarning: this.createIncidentWarning,
            event: {
              ...event,
              EventCategory: EventCategory.Incident,
              IncidentType: this.eventModel.IncidentType.value,
              WarningMessage: this.eventModel.WarningMessage,
              IncidentStatus: this.eventModel.Status.value,
              fileURL: fileurl ? fileurl : null,
            },
            situationId: event.Situation_id,
            initialCoordinates: center,
            msaobject: this.MSAObject,
          },
          contentComponent: LogCreateIncidentComponent,
        });
        this.closeSubject.next();
      }
    }
  }

  calculateCenter(geometry): number[] {
    if (this.isPoint(geometry)) {
      return geometry.Coordinates;
    } else if (this.isCircle(geometry)) {
      return geometry.Center;
    } else if (this.isPolygon(geometry)) {
      const center = centerOfMass(polygon(geometry.Coordinates));
      return center.geometry.coordinates;
    } else if (this.isLine(geometry)) {
      const center = centerOfMass(lineString(geometry.Coordinates));
      return center.geometry.coordinates;
    } else if (this.isMultiLine(geometry)) {
      const center = centerOfMass(multiLineString(geometry.Coordinates));
      return center.geometry.coordinates;
    }
  }

  isPoint(geometry): boolean {
    return geometry.Type === 'Point';
  }

  isCircle(geometry): boolean {
    return geometry.Type === 'Polygon' && geometry?.Radius && geometry?.Center.length;
  }

  isPolygon(geometry): boolean {
    return geometry.Type === 'Polygon' && !geometry?.Radius && !geometry?.Center.length;
  }

  isLine(geometry): boolean {
    return geometry.Type === 'LineString';
  }

  isMultiLine(geometry): boolean {
    return geometry.Type === 'MultiLineString';
  }
}
