import {
  Component,
  Optional,
  Inject,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { SecurityType, SensitivityLevel, LayerType } from '@yaris/core/domain';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';

import { ElementBase } from '../element-base';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'multi-text-select-control-icons',
  templateUrl: './multi-text-select-control-icons.component.html',
  styleUrls: ['./multi-text-select-control-icons.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiTextSelectControlIconsComponent,
      multi: true,
    },
  ],
})
export class MultiTextSelectControlIconsComponent extends ElementBase<any> implements OnInit, OnDestroy, AfterViewInit {
  @Output() closeSelect = new EventEmitter<{ label: string; value: any }[]>();
  @Input() title: string;
  @Input() placeholder: string = '';
  @Input() value: { label: string; value: any }[];
  @Input() options: {
    label: string;
    value: any;
    security: SecurityType;
    sensitivity: SensitivityLevel;
    type: string;
  }[];
  @Input() initial: { label: string; value: any }[];
  @Input() focus = false;
  @Input() disabled = false;
  @Input() style: { padding: string; titleMinWidth: string; dropdownMinWidth: string };
  @Input() enableSelectAll: false;
  @ViewChild('realValue', { read: NgModel }) model: NgModel;
  @ViewChild('displayInput', { read: ElementRef }) displayInputRef: ElementRef;
  dropdown = false;
  dropdownPos = { top: 0, left: 0 };
  displayVal: string;
  private readonly inputHeight = 26;
  private ngUnsubscribe = new Subject<void>();
  private selectAll: boolean = true;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private translateService: TranslateService,
  ) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    if (this.focus) {
      this.dropdown = true;
    }
    //console.log('initial value?', this.initial && this.initial.length);
    if (this.initial && this.initial.length) {
      this.value = this.initial;
    }
  }

  ngAfterViewInit() {
    this.model.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(debounceTime(50))
      .subscribe((value) => {
        if (this.value === null || this.value === undefined) {
          this.displayVal = null;
          return;
        }
        this.displayVal = this.value?.map((o) => o.label).join(', ');
      });

    this.dropdownPos = {
      top: this.displayInputRef.nativeElement.getBoundingClientRect().top + this.inputHeight,
      left: this.displayInputRef.nativeElement.getBoundingClientRect().left,
    };
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTitleFlex() {
    if (!this.style) {
      return '0 1 auto';
    }
    return this.style.titleMinWidth !== undefined && this.style.titleMinWidth !== null
      ? this.style.titleMinWidth
      : '0 1 auto';
  }

  onCloseDropdown() {
    this.dropdown = false;
    this.closeSelect.next(this.value);
  }

  onFocusIn() {
    this.dropdownPos = {
      top: this.displayInputRef.nativeElement.getBoundingClientRect().top + this.inputHeight,
      left: this.displayInputRef.nativeElement.getBoundingClientRect().left,
    };
  }

  onInputClick() {
    this.dropdownPos = {
      top: this.displayInputRef.nativeElement.getBoundingClientRect().top + this.inputHeight,
      left: this.displayInputRef.nativeElement.getBoundingClientRect().left,
    };
    this.dropdown = !this.dropdown;
  }

  onOptionClick(option) {
    if (!this.value) {
      this.value = [];
    }
    if (this.isActive(option)) {
      this.value = this.value.filter((v) => v.value !== option.value);
    } else {
      this.value = this.value.concat(option);
    }
  }

  isActive(option): boolean {
    return !!this.value && !!this.value.find((o) => o.value === option.value);
  }

  clearValues() {
    this.value = null;
  }
  layerIcon(layer: any): string {
    switch (layer.security) {
      case SecurityType.Local:
        return '/assets/images/local.svg';
      case SecurityType.National:
        return '/assets/images/national.svg';
      case SecurityType.Zone:
        return '/assets/images/zone.svg';
      case SecurityType.Region:
        return '/assets/images/region.svg';
      case SecurityType.Interregional:
        return '/assets/images/interregional.svg';
      case SecurityType.International:
        return '/assets/images/international.svg';
      default:
        return '/assets/images/chat.svg';
    }
  }
  layerColor(layer: any): string {
    switch (layer.sensitivity) {
      case SensitivityLevel.Public:
        return 'green';
      case SensitivityLevel.Private:
        return 'yellow';
      case SensitivityLevel.Secret:
        return 'red';
      default:
        return 'default';
    }
  }
  layerType(layer: any) {
    switch (layer.type) {
      case LayerType.Remote:
        return '/assets/images/remote-layers.svg';
      case LayerType.Smart:
        return '/assets/images/smart-layers-2.svg';
      case LayerType.View:
        return '/assets/images/view-layers.svg';
      case LayerType.ThirdPartyLayer:
        return '/assets/images/third-party-layers.svg';
      default:
        return '/assets/images/information-layers.svg';
    }
  }
  layerTypeShow() {
    return 'layerType';
  }

  onSelectAll() {
    this.selectAll ? (this.value = this.options) : (this.value = []);
    this.selectAll = !this.selectAll;
  }

  selectAllTitle() {
    this.value.length == this.options.length ? (this.selectAll = false) : (this.selectAll = true);
    return this.selectAll ? this.translateService.instant('SELECTALL') : this.translateService.instant('SELECTNONE');
  }
}
