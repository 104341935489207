import { Directive, Input } from '@angular/core';

import * as _ from 'lodash';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[max][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidator, multi: true }],
})
export class MaxValidator {
  @Input() max: number;

  validate(control: AbstractControl): { [validator: string]: any } {
    if (_.isNil(control.value) || _.isNil(this.max)) {
      // the [required] validator will check presence, not us
      return null;
    }
    const v = Number(control.value);
    if (v <= +this.max) {
      return null;
    }

    return { max: { value: +this.max, message: 'The value must be less than ' + this.max } };
  }
}
