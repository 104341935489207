import { Component, Optional, Inject, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { ElementBase } from '../element-base';
import { ColorPickerService } from 'ngx-color-picker';

@Component({
  selector: 'opacity-picker-control',
  templateUrl: './opacity-picker-control.component.html',
  styleUrls: ['./opacity-picker-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: OpacityPickerControlComponent,
      multi: true,
    },
  ],
})
export class OpacityPickerControlComponent extends ElementBase<string> implements AfterViewInit {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() value: string = '';
  @Input() focus = false;
  @Input() disabled = false;
  @Input() initial = undefined;

  @ViewChild(NgModel) model: NgModel;
  @ViewChild('input') input: ElementRef;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private colorPickerService: ColorPickerService,
  ) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    if (this.initial) this.value = this.initial;
  }
  ngAfterViewInit() {
    if (this.focus) {
      setTimeout((_) => this.input.nativeElement.focus());
    }
  }
}
