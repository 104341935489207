import { Directive } from '@angular/core';

import * as _ from 'lodash';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[positiveRange][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PositiveRangeValidator, multi: true }],
})
export class PositiveRangeValidator {
  validate(control: AbstractControl): { [validator: string]: any } {
    if (_.isNil(control.value)) {
      // the [required] validator will check presence, not us
      return null;
    }
    const v = control.value;
    if (v[0] !== undefined && v[0] < 0) {
      return { positiveRange: { value: 'min', message: 'The range values must be positive' } };
    }
    if (v[1] !== undefined && v[1] < 0) {
      return { positiveRange: { value: 'max', message: 'The range values must be positive' } };
    }
    return null;
  }
}
