import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'help-button-bandwidth',
  templateUrl: './help-button-bandwidth.component.html',
  styleUrls: ['./help-button-bandwidth.component.sass'],
})
export class HelpButtonBandwidthComponent implements OnInit {
  ngOnInit(): void {}
}
