import { HttpClient } from '@angular/common/http';
import { IGroup } from '../interfaces/group.interface';
import { AdminBaseService } from './admin-base.service';

export class AdminGroups extends AdminBaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  public async getGroup(id: string): Promise<IGroup> {
    return this.getAsync(`/light/api/admin/group/${id}`);
  }

  public async getGroups(
    isActive: boolean,
    query: string,
    sortBy: string,
    sortByType: string,
    page: number = 0,
    take: number = 10,
    isDeleted: boolean = false,
  ): Promise<IGroup[]> {
    if (!page) page = 0;
    if (!take) take = 10;
    let q = '';
    q += `page=${page}&take=${take}&isActive=${isActive}&isDeleted=${isDeleted}`;
    if (query) {
      q += `&q=${query}`;
    }
    if (sortBy && sortByType) {
      q += `&sortBy=${sortBy}&sortByType=${sortByType}`;
    }
    return this.getAsync(`/light/api/admin/groups/all?${q}`);
  }

  public async countGroups(isActive: boolean, query: string, sortBy: string, sortByType: string): Promise<number> {
    let q = `isActive=${isActive}`;
    if (query) {
      q += `&q=${query}`;
    }
    if (sortBy && sortByType) {
      q += `&sortBy=${sortBy}&sortByType=${sortByType}`;
    }
    return this.getAsync(`/light/api/admin/groups/count?${q}`);
  }
}
