import { TreeNode } from '@circlon/angular-tree-component';
import { Folder, Group, RolePermission, User } from '../domain';

export interface IParentBehavior {
  fetchChildren(node: TreeNode): Promise<IListNode[]>;
}

export interface IListNode {
  item: any;
  hasChildren: boolean;
  children: IListNode[];
  fetchChildren(node: TreeNode): Promise<IListNode[]>;
  height();
}

export class ListNode<Type extends { _id: string }> implements IListNode {
  public id: string;
  public item: Type;
  public hasChildren: boolean;
  public children: IListNode[];
  public isExpanded: boolean;
  public parentBehavior: IParentBehavior;

  constructor(item: Type, parentBehavior?: IParentBehavior) {
    this.item = item;
    this.parentBehavior = parentBehavior;
    this.id = item._id;
  }

  fetchChildren(node: TreeNode) {
    return this.parentBehavior?.fetchChildren(node) || new Promise<IListNode[]>((resolve) => resolve([]));
  }

  height() {
    return undefined;
  }
}

export function listUsersToTree(
  users: User[],
  expandAll: boolean,
  usersRoles: { [key: string]: RolePermission },
): { tree: IListNode[]; userHashTable: { [key: string]: IListNode } } {
  const groupsHashTable: { [key: string]: IListNode } = {};
  const userHashTable: { [key: string]: IListNode } = {};
  const rolesHashTable: { [key: string]: IListNode } = {};
  for (const user of users) {
    const userNode = new ListNode<User>(user);
    userNode.children = [];
    userNode.isExpanded = !!expandAll;
    userHashTable[user._id] = userNode;

    const userRole = usersRoles[user._id];
    if (!userRole || !user.usergroup) continue;

    const groupId = user.usergroup._id;
    const roleId = `${groupId}:${userRole}`;
    if (!rolesHashTable[roleId]) {
      const roleNode = new ListNode<{ _id: string; value: RolePermission; group: Group }>({
        _id: roleId,
        value: userRole,
        group: user.usergroup,
      });
      roleNode.children = [];
      roleNode.isExpanded = !!expandAll;

      rolesHashTable[roleId] = roleNode;
    }

    rolesHashTable[roleId].children.push(userNode);

    if (!groupsHashTable[groupId]) {
      const groupNode = new ListNode<Group>(user.usergroup);
      groupNode.children = [];
      groupNode.isExpanded = !!expandAll;

      groupsHashTable[groupId] = groupNode;
    }

    if (!groupsHashTable[groupId].children.some((roleNode) => roleNode.item._id === roleId))
      groupsHashTable[groupId].children.push(rolesHashTable[roleId]);
  }

  const tree: IListNode[] = Object.keys(groupsHashTable).map((groupId) => groupsHashTable[groupId]);

  return { tree, userHashTable };
}

export function listFolderToTree(
  folders: Folder[],
  expandAll?: boolean,
): { tree: IListNode[]; hashTable: { [key: string]: IListNode } } {
  const hashTable: { [key: string]: IListNode } = {};

  for (const folder of folders) {
    const node = new ListNode<Folder>(folder);
    node.children = [];
    node.isExpanded = !!expandAll;

    hashTable[folder._id] = node;
  }

  const tree = [];
  for (const folder of folders) {
    if (folder.Parent) hashTable[folder.Parent].children.push(hashTable[folder._id]);
    else tree.push(hashTable[folder._id]);
  }

  return { tree, hashTable };
}

function getFolderPath(folder: Folder, hashTable: { [key: string]: Folder }) {
  if (!folder) return '';

  return getFolderPath(hashTable[folder.Parent], hashTable) + '/' + folder.Name;
}

export function getFoldersPaths(folders: Folder[]) {
  const hashTable: { [key: string]: Folder } = {};
  const paths: { [key: string]: string } = {};

  for (const folder of folders) hashTable[folder._id] = folder;

  for (const folder of folders) paths[folder._id] = getFolderPath(folder, hashTable);

  return paths;
}

export function sortTree<Type extends { children: Type[] }>(node: Type, comparator: (a: Type, b: Type) => number) {
  if (node.children?.length) {
    node.children.forEach((child) => sortTree(child, comparator));

    node.children = node.children.sort(comparator);
  }
}

export function doForAllNodes<Type extends { children: Type[] }>(node: Type | Type[], callback: (node: Type) => void) {
  if (node instanceof Array) {
    for (const child of node) {
      doForAllNodes(child, callback);
      callback(child);
    }
  } else if (node.children?.length) {
    for (const child of node.children) doForAllNodes(child, callback);

    callback(node);
  }
}
