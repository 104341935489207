import { Directive, Input } from '@angular/core';

import * as _ from 'lodash';

import { NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[digits][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DigitsValidator, multi: true }],
})
export class DigitsValidator {
  @Input() digits: number;

  validate(control: AbstractControl): { [validator: string]: any } {
    if (_.isNil(control.value) || _.isNil(this.digits) || !control.value) {
      return null;
    }
    const value = '' + control.value;
    const regex = new RegExp(`\\b\\d{${this.digits}}\\b`, 'g');
    if (value.match(regex)) {
      return null;
    }

    return { digits: { required: this.digits, message: `The value must have exactly ${this.digits} digits` } };
  }
}
