<div class="label" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap=".5rem">
  <div class="title" *ngIf="title" fxFlex="0 1 auto">
    {{ title }}
  </div>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div
      class="radio-control"
      [class.checked]="checked(option)"
      *ngFor="let option of options"
      [class.inverted]="inverted"
      disableDoubleClickSelect
    >
      <label class="radio-label" fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center" (click)="onClick(option)">
        <input type="radio" (click)="onClick(option)" />
        <span class="icon" fxFlex="0 0 auto"> </span>
        <div>
          <span [ngStyle]="{ color: textColor }">{{ option.label }}</span>
        </div>
      </label>
    </div>
  </div>
</div>
