import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { Layer } from '@yaris/core/domain';
import { CoordinateService } from '@yaris/msa/mapbox/services/coordinate.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '@yaris/core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { MsaEditTagsComponent } from '../msa-edit-tags/msa-edit-tags.component';
import { AddTagsComponent } from '@yaris/situation/operational-situation-sidebar/add-tags/add-tags/add-tags.component';

@Component({
  selector: 'app-msa-manage-tags',
  templateUrl: './msa-manage-tags.component.html',
  styleUrls: ['./msa-manage-tags.component.sass'],
})
export class MsaManageTagsComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  layers: Layer[];
  coordinates: number[];
  formError = '';
  public search: string = '';
  iconOptions: { _id: string; label: string; Type: string; Visible: boolean; Description: string; Used: boolean }[] =
    [];
  unfilteredIcons: {
    _id: string;
    label: string;
    Type: string;
    Visible: boolean;
    Description: string;
    Used: boolean;
  }[] = [];
  isLoading = false;
  private ngUnsubscribe = new Subject<void>();
  private searchTimeout = undefined;

  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private coordinateService: CoordinateService,
  ) {}

  ngOnInit(): void {
    this.dataService
      .listTags('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((icons) => {
        this.iconOptions = this.iconOptions.concat(
          icons.map((i) => ({
            _id: i._id,
            label: i.Name,
            Type: i.Type,
            Visible: i.Visible,
            Description: i.Description,
            Used: i.Used,
          })),
        );
        this.unfilteredIcons = this.iconOptions;
        this.iconOptions.sort(this.compare);
      });
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  get hasSearchBar(): boolean {
    return true;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSearch(term: string) {
    if (this.searchTimeout !== undefined) {
      clearTimeout(this.searchTimeout);
    }

    if (term.length === 0) {
      this.iconOptions = this.unfilteredIcons;
      return;
    }

    this.searchTimeout = setTimeout(() => {
      this.iconOptions = this.unfilteredIcons.filter((icon) => icon.label.toLowerCase().includes(term.toLowerCase()));
    }, 500);
  }

  compare(a, b) {
    if (a.label.toUpperCase() < b.label.toUpperCase()) {
      return -1;
    }
    if (a.label.toUpperCase() > b.label.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  onEndSearch(search: string = '', fetch: boolean = true): void {
    this.iconOptions = this.unfilteredIcons;
    this.search = '';
  }

  iconPath(icon) {
    return icon.value;
  }

  iconPath2(icon) {
    return '/assets/images/ais.svg';
  }

  classTypeDelete(icon): string {
    return icon.Used ? 'disabledTrash' : 'defaultTrash';
  }

  visibilityIcon(icon): string {
    return icon.Visible ? '/assets/images/visibility.svg' : '/assets/images/hidden.svg';
  }

  onVisibilityClick(icon): void {
    icon.Visible = !icon.Visible;
    this.dataService.updateTagVisibility(icon).subscribe((icon) => {});
  }

  onDeleteUnusedClick(icon): void {
    if (!icon.Used) {
      this.dataService.deleteUnusedTag(icon).subscribe((Dicon) => {
        if (icon._id === Dicon?._id) {
          this.iconOptions = this.iconOptions.filter((deletedTag) => deletedTag._id !== icon._id);
        } else {
          console.error('Tag could not be found.');
        }
      });
    }
  }

  onOptionsClick(icon, event: MouseEvent): void {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('SITUATION.EDITTAG'),
      inputs: { tag: icon },
      contentComponent: MsaEditTagsComponent,
    });
  }

  classTypeDefault() {
    return 'default';
  }

  classTypeState(icon) {
    return icon.Visible ? 'default' : 'disabled';
  }

  classTypeTool() {
    return 'tool';
  }

  createTags() {
    this.modalService
      .open({
        contentComponent: AddTagsComponent,
        title: this.translateService.instant('SITUATION.NEWSITUATIONTAG'),
        closable: true,
        inputs: { type: 'Situation' },
      })
      .output.pipe(takeUntil(this.ngUnsubscribe));
  }

  getTypeIcon(icon) {
    return icon.Type === 'Situation' || icon.Type === 'System'
      ? '/assets/images/operational-situation.svg'
      : '/assets/images/manage-layers.svg';
  }

  isSystem(icon) {
    return icon.Type === 'System';
  }

  getType(icon) {
    return icon.Type === 'System' ? 'Situation' : icon.Type;
  }

  getManageLayerIcon(icon) {
    return '/assets/images/manage-layers.svg';
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
  }
}
