import {
  Component,
  Optional,
  Inject,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  OnChanges,
  AfterContentInit,
  ChangeDetectorRef,
} from '@angular/core';

import { SecurityType, SensitivityLevel, LayerType } from '@yaris/core/domain';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { ElementBase } from '../element-base';

@Component({
  selector: 'text-select-control-icons',
  templateUrl: './text-select-control-icons.component.html',
  styleUrls: ['./text-select-control-icons.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextSelectControlIconsComponent,
      multi: true,
    },
  ],
})
export class TextSelectControlIconsComponent extends ElementBase<any> implements OnInit, OnChanges, AfterContentInit {
  @Input() title: string;
  @Input() placeholder: string = '';
  @Input() value: { label: string; value: any };
  @Input() options: { label: string; value: any }[];
  @Input() initial: { label: string; value: any };
  @Input() focus = false;
  @Input() disabled = false;
  dropdown = false;
  dropdownPos = { top: 0, left: 0 };
  searchVal: string;
  viewOptions: { label: string; value: any }[];
  private readonly inputHeight = 26;

  @ViewChild('searchInput') searchInput: NgModel;
  @ViewChild('searchInput', { read: ElementRef }) searchInputRef: ElementRef;
  @ViewChild('realValue', { read: NgModel }) model: NgModel;

  constructor(
    private cdRef: ChangeDetectorRef,
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    if (this.focus) {
      this.dropdown = true;
    }
    if (this.initial) {
      this.value = this.initial;
      this.searchVal = this.initial.label;
    }
  }

  ngOnChanges(changes) {
    if (!changes) {
      return;
    }
    if (changes?.initial?.currentValue === null) {
      this.clearValues();
    }
    if (changes.options) {
      this.viewOptions = changes.options.currentValue;
    }
    if (changes?.initial?.currentValue?.value !== changes?.initial?.previousValue?.value) {
      this.searchVal = changes.initial.currentValue.label;
    }
  }

  ngAfterContentInit() {
    if (this.focus) {
      setTimeout((_) => this.searchInputRef.nativeElement.focus());
    }
    setTimeout((_) => this.updateDropdwonPos());
    this.cdRef.detectChanges();
  }

  onInputClick() {
    this.dropdown = !this.dropdown;
    if (this.dropdown) {
      this.updateDropdwonPos();
    }
  }

  onOptionClick(option) {
    this.value = option;
    this.searchVal = option.label;
    this.viewOptions = this.options;
    this.dropdown = false;
  }

  onSearchValChange(val: string) {
    this.dropdown = true;
    this.updateDropdwonPos();
    if (val === undefined || val === null) {
      this.viewOptions = this.options;
    }
    if (this.value !== null || this.value !== undefined) {
      this.value = null;
    }
    const lowVal = val.toLowerCase();
    this.viewOptions = this.options.filter((opt) => opt.label.toString().toLowerCase().indexOf(lowVal) !== -1);
  }

  isActive(option): boolean {
    return option === this.value;
  }

  clearValues() {
    this.value = null;
    this.searchVal = null;
    this.viewOptions = this.options;
  }

  onSearchKeyup(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.clearValues();
    }
  }

  get modelTouched() {
    if (!this.searchInput) {
      return false;
    }
    return this.searchInput.touched;
  }

  private updateDropdwonPos() {
    this.dropdownPos = {
      top: this.searchInputRef.nativeElement.getBoundingClientRect().top + this.inputHeight,
      left: this.searchInputRef.nativeElement.getBoundingClientRect().left,
    };
  }
  layerIcon(layer: any): string {
    switch (layer.security) {
      case SecurityType.Local:
        return '/assets/images/local.svg';
      case SecurityType.National:
        return '/assets/images/national.svg';
      case SecurityType.Zone:
        return '/assets/images/zone.svg';
      case SecurityType.Region:
        return '/assets/images/region.svg';
      case SecurityType.Interregional:
        return '/assets/images/interregional.svg';
      case SecurityType.International:
        return '/assets/images/international.svg';
      default:
        return '/assets/images/chat.svg';
    }
  }
  layerColor(layer: any): string {
    switch (layer.sensitivity) {
      case SensitivityLevel.Public:
        return 'green';
      case SensitivityLevel.Private:
        return 'yellow';
      case SensitivityLevel.Secret:
        return 'red';
      default:
        return 'default';
    }
  }
  layerType(layer: any) {
    switch (layer.type) {
      case LayerType.Remote:
        return '/assets/images/remote-layers.svg';
      case LayerType.Smart:
        return '/assets/images/smart-layers-2.svg';
      case LayerType.View:
        return '/assets/images/view-layers.svg';
      case LayerType.ThirdPartyLayer:
        return '/assets/images/third-party-layers.svg';
      default:
        return '/assets/images/information-layers.svg';
    }
  }
  layerTypeShow() {
    return 'layerType';
  }
}
