import { Component, Optional, Inject, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { ElementBase } from '../element-base';
import { ColorPickerService } from 'ngx-color-picker';

@Component({
  selector: 'color-picker-control',
  templateUrl: './color-picker-control.component.html',
  styleUrls: ['./color-picker-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ColorPickerControlComponent,
      multi: true,
    },
  ],
})
export class ColorPickerControlComponent extends ElementBase<string> implements AfterViewInit {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() value: string = '';
  @Input() focus = false;
  @Input() disabled = false;
  oldColor: string = '';

  @ViewChild(NgModel) model: NgModel;
  @ViewChild('input') input: ElementRef;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private colorPickerService: ColorPickerService,
  ) {
    super(validators, asyncValidators);
  }

  ngAfterViewInit() {
    if (this.focus) {
      setTimeout((_) => this.input.nativeElement.focus());
    }
  }

  colorPickerOpen(color: string) {
    this.oldColor = color;
  }

  colorPickerCancel() {
    this.value = this.oldColor;
  }
}
