import { Injectable } from '@angular/core';
import {
  ChatPart,
  CpaObjectNotification,
  MSAObject,
  Notification,
  User,
} from '@yaris/core/domain';
import { Observable, interval, of, Subject, forkJoin } from 'rxjs';
import { DataService } from '@yaris/core/data.service';
import { MSAObjectMap } from '@yaris/msa/msa.service';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

export interface Socket {
  on(event: string, callback: (data: any) => void);
  emit(event: string, data: any);
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  //Variable to Configure the number if minutes to wait before page reload when connection is lost. Default 5minutes
  minutesToRefresh = 5;

  openSockets = 0;
  user: User;
  activeSituation: string;
  activeEvent: string;
  activeChat: string;
  connectionState = 0;
  refreshloader: any;
  lastReceivedNotification: Date;
  public activeLayers: string[] = [];
  public rooms: any = {};

  private pingSocketStatusInterval$ = interval(1000 * 60); // 1 min

  worker: Worker;

  public $sktEmit: Subject<{ event: string; data: any }> = new Subject<{ event: string; data: any }>();
  public sktEmitObserver = this.$sktEmit.asObservable();
  public $sktOn: Subject<{ event: string; data: any }> = new Subject<{ event: string; data: any }>();
  public sktOnObserver = this.$sktOn.asObservable();

  constructor(private dataService: DataService) {}

  socketOn(event: string, callback: (data: any) => void) {
    this.sktOnObserver.pipe(filter((s: { event: string; data: any }) => s.event == event)).subscribe((s) => {
      callback(s.data);
    });
  }

  connect() {
    this.minutesToRefresh = this.minutesToRefresh * 60000;

    this.setGroupStatusListener();
    const self = this;

    this.socketOn('connect', function () {
      let page = location.pathname.split('/')[1];
      if (page == 'bandwidth') page = 'situation';

      self.$sktEmit.next({ event: 'ping-socket-status-open-tab', data: { frontendType: 'msa', page } });

      self.connectionState = self.connectionState >= 1 ? 2 : 0;
      if (self.refreshloader) {
        clearTimeout(self.refreshloader);
      }
      console.log('connected to server - ', self.lastReceivedNotification);

      if (self.lastReceivedNotification && this.user) {
        let roomsToAsk = [this.user?.GroupName + this.user.SensitivityLevel];
        Object.keys(this.rooms).forEach((key) => {
          if (this.rooms[key] !== undefined && Array.isArray(this.rooms[key]))
            roomsToAsk = roomsToAsk.concat(this.rooms[key]);
        });
        self.sendMsg('successfully_reconnected', { rooms: roomsToAsk, time: self.lastReceivedNotification });
        self.connectionState = 0;
      }
    });

    // on reconnect, use polling first and then websocket
    this.socketOn('reconnect_attempt', () => {
      // setTimeout(() => {
      //   if (io.opts) {
      //     io.opts.transports = ['websocket', 'polling'];
      //   }
      // }, 2000);
    });

    this.socketOn('disconnect', function () {
      console.log('disconnected from server');
      sleep(100).then(() => {
        self.connectionState = 1;
        self.refreshloader = setTimeout(function () {
          if (self) location.reload();
        }, self.minutesToRefresh);
      });
    });
    this.socketOn('leave', function () {
      console.log('disconnecting from server');
    });

    this.socketOn('eventMessage', (event) => {});

    this.socketOn('logout', (message) => {
      console.log('disconnected', message);
    });

    this.socketOn('commentMessage', (event) => {});

    this.socketOn('reader', (event) => {});

    this.socketOn('Welcome', (event) => {
      console.log('Welcome');
      self.joinToRoom({
        Situations: [self.activeSituation],
        Events: [self.activeEvent],
        Chats: [self.activeChat],
        Layers: self.activeLayers ? self.activeLayers : [],
        Notifications: true,
      });
    });

    this.socketOn('reconnect', function () {
      console.log('you have been reconnected');
    });

    this.socketOn('MSAObjectUpdate', function (msg) {
      self.lastReceivedNotification = new Date();
    });
    this.socketOn('chatMessage', function (msg) {
      self.lastReceivedNotification = new Date();
    });
    this.socketOn('Notification', function (msg: Notification) {
      self.lastReceivedNotification = new Date();
    });
    this.socketOn('Counter', function (msg: number) {
      self.openSockets = msg;
      console.log('Amount of open tabs: ', msg);
    });

    this.joinToRoom({ Notifications: true });

    this.sktOnObserver.pipe(filter((s) => s.event == 'Welcome')).pipe(
      tap((o: any) => {
        console.log(new Date().toISOString(), o);
      }),
    );
  }

  private setGroupStatusListener() {
    this.pingSocketStatusInterval$.subscribe((_) => {
      this.$sktEmit.next({
        event: 'ping-socket-status',
        data: { frontendType: 'msa', activeSituations: this.activeSituation },
      });
    });
  }

  public changedLayer(activeLayersId: string[]) {
    this.$sktEmit.next({ event: 'layerChange', data: activeLayersId });
  }

  public changedLayerUnsubscribe(activeLayersId: string) {
    this.$sktEmit.next({ event: 'layerChange-unsubscribe', data: activeLayersId });
  }

  public emitRemoveTab() {
    let page = location.pathname.split('/')[2];

    this.$sktEmit.next({
      event: 'ping-socket-status-remove_tab', data: {
        frontendType: 'msa',
        page,
        cluster: ['LIV']
      }
    });
  }

  disconnect() {
    this.$sktEmit.next({ event: 'end', data: 'close connection' });
  }

  setUser(user: User) {
    this.user = user;
    if (user !== undefined) {
      this.connect();
    }
  }

  setActiveEvent(eventID: string) {
    this.activeEvent = eventID;
  }

  setActiveChat(chatID: string) {
    this.activeChat = chatID;
  }

  setActiveSituation(situationID: string) {
    this.activeSituation = situationID;
  }

  sendMsg(event: any, message?: any) {
    this.$sktEmit.next({ event: event, data: message });
  }

  differentUser(id: string): boolean {
    return this.user._id !== id;
  }

  joinToRoom(data: {
    Layers?: string[];
    Chats?: string[];
    Situations?: string[];
    Events?: string[];
    Notifications?: boolean;
  }) {
    this.rooms.Layers = data.Layers ? data.Layers : undefined;
    this.rooms.Chats = data.Chats ? data.Chats : undefined;
    this.rooms.Situations = data.Situations ? data.Situations : undefined;
    this.rooms.Events = data.Events ? data.Events : {};
    this.rooms.Notifications = data.Notifications ? data.Notifications : undefined;
    this.$sktEmit.next({ event: 'subscribe', data: this.rooms });
  }

  leaveRoom(rooms: { Layers?: string[]; Chats?: string[]; Situations?: string[] }) {
    this.$sktEmit.next({ event: 'unsubscribe', data: rooms });
  }

  getMessages(): Observable<ChatPart> {
    return this.sktOnObserver.pipe(
      filter((s: { event: string; data: any }) => s.event == 'chatMessage'),
      map((s) => s.data as ChatPart),
    ); //fromEvent(<any>this.socket, );
  }

  getMSAObjects(situationId?: string | (() => string)): Observable<MSAObjectMap[]> {
    return this.sktOnObserver.pipe(
      filter((s) => s.event == 'MSAObjectUpdate'),
      map((o) => o.data),
      mergeMap((objects: MSAObject[]) => {
        const _situationId = typeof situationId === 'function' ? situationId() : situationId;

        const objectsWithExtendedData = objects?.map((o) => {
          if (_situationId && o.NeedUpdateExtendedData) {
            return this.dataService.getMSAObject(o._id, o.Layer_id, _situationId).pipe(
              map((objectDetails) => {
                return {
                  ...objectDetails,
                  HumanActionType: o.HumanActionType,
                  HumanActionContext: o.HumanActionContext,
                  NeedUpdateExtendedData: true,
                };
              }),
            );
          }
          return of(o);
        });

        return forkJoin(objectsWithExtendedData);
      }),
      map((objects: MSAObject[]) => {
        return objects?.map((o) => {
          return { ...o, layerIds: [o.Layer_id] };
        });
      }),
      //share() //TODO: the extended data is requested for each subscriber, it should be requested only once and multicast for all subscribers
    );
  }

  getCpaObjects(): Observable<CpaObjectNotification> {
    return this.sktOnObserver.pipe(
      filter((s: { event: string; data: any }) => s.event == 'CpaObject'),
      map((s) => s.data as CpaObjectNotification),
    ); //fromEvent(<any>this.socket, 'CpaObject');
  }

  getNotifications(): Observable<Notification> {
    return this.sktOnObserver.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Notification'),
      map((s) => s.data as Notification),
    ); //fromEvent(<any>this.socket, 'Notification');
  }

  getAlerts(): Observable<Notification> {
    return this.sktOnObserver.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Alert'),
      map((s) => s.data as Notification),
    ); // fromEvent(<any>this.socket, 'Alert');
  }

  getConfig(): Observable<Notification> {
    return this.sktOnObserver.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Config'),
      map((s) => s.data as Notification),
    ); //fromEvent(<any>this.socket, 'Config');
  }

  getCounter(): Observable<number> {
    return this.sktOnObserver.pipe(
      filter((s: { event: string; data: any }) => s.event == 'Counter'),
      map((s) => s.data as number),
    ); //fromEvent(<any>this.socket, 'Counter');
  }

  logout(): Observable<any> {
    return this.sktOnObserver.pipe(
      filter((s: { event: string; data: any }) => s.event == 'logout'),
      map((s) => s.data),
    ); //fromEvent(<any>this.socket, 'logout');
  }

  getUnreadCounter(): Observable<any> {
    return this.sktOnObserver.pipe(
      filter((s: { event: string; data: any }) => s.event == 'unread-counter'),
      map((s) => s.data),
    ); //fromEvent(<any>this.socket, 'unread-counter');
  }
}

function sleep(time): any {
  return new Promise((resolve) => setTimeout(resolve, time));
}
