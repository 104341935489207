import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@yaris/core/data.service';
import { SocketService } from '@yaris/core/socket.service';
import {
  SecurityType,
  SensitivityLevel,
  User,
  UserSummary,
  SharingGroup,
  NotificationActionType,
  LightMSALevel,
  Situation,
  Layer,
  SharingGroupRuleType,
  Group,
  MSAObject,
  LayerType,
  SharingGroupRule,
  BoardMessage,
  RolePermission,
  UserPermissions,
} from '@yaris/core/domain';
import { isEmpty, isNil } from 'lodash';
import { filter, pluck } from 'rxjs/operators';
import { MSAObjectMap } from '@yaris/msa/msa.service';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private user: User;
  private userPermissions: UserPermissions;
  private allPossiblesRolesNumbers: { [propName: string]: number };

  constructor(private router: Router, private dataService: DataService, private socketService: SocketService) {}

  private heartbit() {
    interval(60000).subscribe((_) => {
        this.dataService.heartbit(this.isLightDomain() ? 'LIGHT' : 'MSA').subscribe();
    })
    this.dataService.heartbit(this.isLightDomain() ? 'LIGHT' : 'MSA').subscribe();
  }

  load = () =>
    new Promise((resolve, reject) => {
      this.dataService.getCurrentUser().subscribe(
        (user) => {
          this.socketService.setUser(user);
          this.user = user;

          if (user?.Group?.ActivityStatus === 'INACTIVE') {
            this.router.navigate(['denied'], { replaceUrl: true });
            return resolve(user);
          }

          this.heartbit();
          this.setNotificationListeners();

          this.dataService.getUserPermissions().subscribe((data) => {
            this.userPermissions = data.UserPermissions;
            this.allPossiblesRolesNumbers = data.AllPossiblesRolesNumbers;
            resolve(user);
          });
        },
        (error) => {
          window.location.href = `/light/api/login?appType=${this.isLightDomain() ? 'light' : 'msa'}`;
          //this.router.navigate(['login'], { replaceUrl: true });
          reject(error);
        },
      );
    });

  getUser(): User {
    return this.user;
  }

  getUserPermissions() {
    return { ...this.userPermissions };
  }

  allowedSensitivity(): SensitivityLevel[] {
    if (!this.user || isNil(this.user.SensitivityLevel)) {
      return [];
    }
    const sensitivities = Object.keys(SensitivityLevel)
      .filter((k) => isNaN(Number(k)))
      .map((k) => SensitivityLevel[k]);
    return sensitivities.slice(0, sensitivities.indexOf(this.user.SensitivityLevel) + 1);
  }

  allowedSecurity(): SecurityType[] {
    if (!this.user) {
      return [];
    }
    const securities = Object.keys(SecurityType)
      .filter((k) => isNaN(Number(k)))
      .map((k) => SecurityType[k]);

    const group = this.user.Group;

    if (!group) {
      return securities.filter((s) => s === SecurityType.International);
    }
    /* if (!group.Path || isEmpty(group.Path)) {
      return securities
        .filter(s => s === SecurityType.International || s === SecurityType.Local);
    }*/
    return securities
      .filter((s) => s !== SecurityType.Interregional || !!group.Path.Interregional)
      .filter((s) => s !== SecurityType.Region || !!group.Path.RegionName)
      .filter((s) => s !== SecurityType.Zone || !!group.Path.ZoneName)
      .filter(
        (s) =>
          s !== SecurityType.National ||
          (isEmpty(group.Path) && !!group.CountryCode) ||
          (!!group.Path.ZoneName && !group.Children?.includes(group.Path.ZoneName)),
      );
  }

  hasRolePermissionEqual(rolePermissionRequired: RolePermission): boolean {
    if (!this.user) {
      return false;
    }

    return this.allPossiblesRolesNumbers[rolePermissionRequired] == this.user.Role;
  }

  hasRolePermissionEqualOrAbove(rolePermissionRequired: RolePermission): boolean {
    if (!this.user) {
      return false;
    }

    return this.allPossiblesRolesNumbers[rolePermissionRequired] >= this.user.Role;
  }

  hasRolePermissionForAuth(): boolean {
    if (!this.user) {
      return false;
    }

    let result: boolean;
    if (this.user?.Roles?.length)
      result = this.user.Roles?.some((role) => this.allPossiblesRolesNumbers[role] !== undefined);

    return result;
  }

  hasJudicialExportRolePermission(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.Roles?.includes(RolePermission.judicial_expert);
  }

  getRoleForUser(user: User): RolePermission {
    if (!this.allPossiblesRolesNumbers && !user?.Roles.length) {
      return undefined;
    }

    let greatestRole: RolePermission = undefined;
    user.Roles.forEach((role) => {
      if (
        greatestRole === undefined ||
        this.allPossiblesRolesNumbers[role] > this.allPossiblesRolesNumbers[greatestRole]
      )
        greatestRole = RolePermission[role];
    });

    return greatestRole;
  }

  isITAdmin(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.Roles && this.user.Roles?.includes('it_administrator');
  }

  isSystemAdmin(): boolean {
    if (!this.user) {
      return false;
    }
    return this.user.Roles && this.user.Roles?.includes('system_administrator');
  }

  isYaonde(path?): boolean {
    if (!this.user) {
      return false;
    }
    if (path) return path.Interregional === 'icc';
    return this.user.Group.Path?.Interregional === 'icc';
  }

  isAfricanStakeholder(): boolean {
    if (!this.user) {
      return false;
    }

    return this.user.Group?.DirectoryPath.includes('AS - African_Stakeholders');
  }

  isSameGroup(user: User) {
    return user.GroupId === this.getUser().GroupId;
  }

  isOwner(info: { CreatedBy?: UserSummary; Owner?: string }): boolean {
    if (!this.user) {
      return false;
    }
    if (this.user.GroupName === info.Owner || info.Owner?.includes(this.user.GroupName)) {
      return true;
    }
    return false;
  }

  isAlwaysSharedWith(info: { SharingGroups: SharingGroup[] }, group?: Group): boolean {
    if (!this.user) {
      return false;
    }

    if (info?.SharingGroups?.length) {
      const groupToGetSharing = group?._id ? { GroupId: group._id } : undefined;
      const sharingGroup = this.getGroupFromSharingGroupList(info, groupToGetSharing);
      return !!sharingGroup?.AlwaysShared;
    }

    return false;
  }

  isLightMSA(): boolean {
    if (!this.user) {
      return false;
    }
    if (!this.user.Preferences) {
      return false;
    }
    return this.user.Preferences?.LightMSA !== LightMSALevel.OFF;
  }

  isLightMSALow(): boolean {
    return this.isLightMSA() && this.user.Preferences?.LightMSA === LightMSALevel.LOW;
  }

  isLightMSAMedium(): boolean {
    return this.isLightMSA() && (this.user.Preferences?.LightMSA === LightMSALevel.MEDIUM || this.isLightMSALow());
  }

  isLightMSAHigh(): boolean {
    return this.isLightMSA() && (this.user.Preferences?.LightMSA === LightMSALevel.HIGH || this.isLightMSAMedium());
  }

  isLightDomain(): boolean {
    const origin = window.location.origin;
    const absoluteRoute = window.location.href;
    const base = absoluteRoute.replace(origin, '');
    return base.startsWith('/light');
  }

  isUserFromGroup(group: Group) {
    return this.user && this.user.GroupId === group._id;
  }

  /********************************************************************/
  /************************* PERMISSIONS ******************************/
  /********************************************************************/

  getGroupFromSharingGroupList(context: { SharingGroups: SharingGroup[] }, owner?: { GroupId: string }): SharingGroup {
    const _owner = owner || this.user;
    return context.SharingGroups?.find((g) => _owner.GroupId && g.GroupId === _owner.GroupId);
  }

  getGroupFromSharingGroupsAndRules(
    context: { SharingGroups: SharingGroup[]; SharingGroupRules: SharingGroupRule[] },
    groupId?: string,
  ): SharingGroup {
    const _groupId = groupId || this.user?.GroupId;
    const anyoneGroup = context?.SharingGroupRules?.find((g) => g.GroupName === SharingGroupRuleType.Anyone);
    const sharingGroup = context?.SharingGroups?.find((g) => g.GroupId === _groupId);

    if (!sharingGroup && !anyoneGroup) {
      return null;
    }

    return {
      GroupName: sharingGroup?.GroupName || this.user?.GroupName,
      GroupId: sharingGroup?.GroupId,
      CanDelete: !!(sharingGroup?.CanDelete || anyoneGroup?.CanDelete),
      CanWrite: !!(sharingGroup?.CanWrite || anyoneGroup?.CanWrite),
      CanShare: !!(sharingGroup?.CanShare || anyoneGroup?.CanShare),
    };
  }

  /**
   * Verify the access rights to the Log Events app.
   * Write permissions allow the user to create new events.
   */
  getSituationAccessRights(
    info: Situation,
    ignoreChangeSituationRolePermission = false,
  ): { write: boolean; share: boolean } {
    if (!this.user) {
      return null;
    }
    if (!info) {
      return { write: false, share: false };
    }

    const notLocal: boolean = info.Security !== SecurityType.Local;

    if (info.Owner === this.user.GroupName) {
      return {
        write: ignoreChangeSituationRolePermission || this.userPermissions.MSA.Change_Situation,
        share: info.IsActive && notLocal && this.userPermissions.MSA.Share_Situation,
      };
    } else {
      const group = this.getGroupFromSharingGroupsAndRules(info);
      if (!group) {
        return null;
      }

      return {
        write: group.CanWrite && (ignoreChangeSituationRolePermission || this.userPermissions.MSA.Change_Situation),
        share: group.CanShare && notLocal && this.userPermissions.MSA.Share_Situation,
      };
    }
  }

  /**
   * Verify the access rights to the Log Events app.
   * Write permissions allow the user to create new events.
   */
  getLogEventsAccessRights(info: Situation): { write: boolean } {
    if (!this.user) {
      return null;
    }
    if (!info) {
      return { write: false };
    }
    if (!info.IsActive) {
      return { write: false };
    }
    if (
      info.IsDefault &&
      (this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator))
    ) {
      return { write: true };
    }

    if (info.Owner === this.user.GroupName) {
      // Rules for all the users belonging to the situation owner center
      if (
        this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator)
      ) {
        return { write: true };
      }
    } else {
      // Rules for sharing
      const group = this.getGroupFromSharingGroupsAndRules(info);
      if (!group) {
        return null;
      }

      if (
        this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator)
      ) {
        return { write: group.CanWrite };
      }
    }

    return { write: false };
  }

  /**
   * Verify the access rights to the Comments app.
   * Write permissions allow the user to create new comments.
   */
  getLogEventsCommentsAccessRights(info: Situation): { write: boolean } {
    return this.getLogEventsAccessRights(info);
  }

  /**
   * Verify the access rights to the Chat app.
   * Write permissions allow the user to send new messages in the chat.
   */
  getChatAccessRights(info: Situation): { write: boolean } {
    if (!this.user) {
      return null;
    }
    if (!info) {
      return { write: false };
    }
    if (!info.IsActive) {
      return { write: false };
    }
    if (info.IsDefault) {
      return { write: true };
    }

    if (info.Owner === this.user.GroupName) {
      // Rules for all the users belonging to the situation owner center
      if (
        this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.guest)
      ) {
        return { write: true };
      }
    } else {
      // Rules for sharing
      const group = this.getGroupFromSharingGroupsAndRules(info);
      if (!group) {
        return null;
      }

      if (
        this.hasRolePermissionEqualOrAbove(RolePermission.decidor) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.operator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.guest)
      ) {
        return { write: group.CanWrite };
      }
    }

    return { write: false };
  }

  /**
   * Verify the access rights to situations in the MSA app.
   * Write permissions allow the user to add a layer to a situation.
   * Delete permissions allow the user to remove a layer from the situation.
   */
  getMSASituationAccessRights(situation: Situation, layer: Layer): { write: boolean; delete: boolean } {
    if (!this.user) {
      return null;
    }
    if (!situation) {
      return { write: false, delete: false };
    }
    if (!layer) {
      return { write: false, delete: false };
    }

    if (!situation.IsActive) {
      return { write: false, delete: false };
    }
    if (situation.IsDefault) {
      return { write: true, delete: true };
    }

    let removeLayer = false;

    const situationAccess = this.getSituationAccessRights(situation, true);
    const canRemoveFromSituation = this.isOwner(situation);

    if (this.isOwner(layer)) {
      removeLayer = true;
    } else {
      // Rules for sharing
      const group = this.getGroupFromSharingGroupsAndRules(layer);
      if (!group) {
        return null;
      }
    }

    return { write: situationAccess.write, delete: canRemoveFromSituation || removeLayer };
  }

  /**
   * Verify the access rights to the Layers in the MSA app.
   * Write permissions allow the user to add a layer to a situation.
   * Delete permissions allow the user to remove a layer from the situation.
   */
  getMSALayerAccessRights(info: Layer): {
    modify: {
      write: boolean;
      delete: boolean;
      share: boolean;
    };
    access: {
      Add_To_Situation_Layer: boolean;
      Create_Layer: boolean;
      Edit_Layer: boolean;
      Duplicate_Layer: boolean;
      Share_Layer: boolean;
      Delete_Layer: boolean;
      Remove_From_Situation_Layer: boolean;
    };
  } {
    if (!this.user) {
      return null;
    }

    const modify_permissions = {
      Add_To_Situation_Layer: this.userPermissions.Layer.Add_To_Situation_Layer,
      Create_Layer: this.userPermissions.Layer.Create_Layer,
      Edit_Layer: this.userPermissions.Layer.Edit_Layer,
      Duplicate_Layer:
        this.userPermissions.Layer.Duplicate_Layer && !(info.IsDefault && info.LayerType == LayerType.View),
      Share_Layer: this.userPermissions.Layer.Share_Layer,
      Delete_Layer: this.userPermissions.Layer.Delete_Layer,
      Remove_From_Situation_Layer: this.userPermissions.Layer.Remove_From_Situation_Layer,
    };
    if (!info) {
      return { modify: { write: false, share: false, delete: false }, access: modify_permissions };
    }

    const notLocal: boolean = info.Security !== SecurityType.Local;

    const group = this.getGroupFromSharingGroupsAndRules(info);
    if (this.isOwner(info)) {
      return {
        modify: {
          write: modify_permissions.Create_Layer,
          share: modify_permissions.Share_Layer && notLocal,
          delete: modify_permissions.Delete_Layer,
        },
        access: modify_permissions,
      };
    } else {
      if (!group) {
        return null;
      }

      return {
        modify: { write: group.CanWrite, share: group.CanShare && notLocal, delete: false },
        access: modify_permissions,
      };
    }
  }

  getMSAObjectAccessRights(msaObject: MSAObject | MSAObjectMap, layer: Layer) {
    let isMSAObjectLayer = false;
    if ((msaObject as MSAObject).Layer_id) {
      isMSAObjectLayer = (msaObject as MSAObject)?.Layer_id === layer?._id;
    } else {
      isMSAObjectLayer = (msaObject as MSAObjectMap).layerIds?.includes(layer?._id);
    }

    if (!isMSAObjectLayer) return null;

    const layerPermisions = this.getMSALayerAccessRights(layer);
    const canEdit = !!layerPermisions?.modify?.write && this.getUserPermissions().Layer.Manage_Msaobjects_Layer;
    const canDelete = canEdit && layer.LayerType === LayerType.Points;
    return { modify: { write: canEdit, delete: canDelete } };
  }

  canCreateBoardMessage(): boolean {
    return (
      this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager) ||
      this.hasRolePermissionEqualOrAbove(RolePermission.system_administrator) ||
      this.hasRolePermissionEqualOrAbove(RolePermission.it_administrator)
    );
  }

  canEditBoardMessage(message: BoardMessage): boolean {
    return (
      (this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.system_administrator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.it_administrator)) &&
      this.checkForWriteOrDeletePermission(message)
    );
  }

  canDeleteBoardMessage(message: BoardMessage): boolean {
    return (
      (this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.system_administrator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.it_administrator)) &&
      this.checkForWriteOrDeletePermission(message)
    );
  }

  canEditBoardMessageSharePermissions(message: BoardMessage): boolean {
    return (
      (this.hasRolePermissionEqualOrAbove(RolePermission.operations_manager) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.system_administrator) ||
        this.hasRolePermissionEqualOrAbove(RolePermission.it_administrator)) &&
      this.checkForSharePermission(message)
    );
  }

  checkForWriteOrDeletePermission(message: BoardMessage): boolean {
    const anyoneCanWriteOrDelete: boolean = message?.SharingGroupRules.find(
      (sgr) => sgr.GroupName === 'Anyone',
    )?.CanWrite;
    const userGroup = message?.SharingGroups.find((sg) => sg.GroupName === this.user.GroupName);
    return anyoneCanWriteOrDelete || userGroup?.CanWrite;
  }

  checkForSharePermission(message: BoardMessage): boolean {
    const anyoneCanShare: boolean = message?.SharingGroupRules.find((sgr) => sgr.GroupName === 'Anyone')?.CanShare;
    const userGroup = message?.SharingGroups.find((sg) => sg.GroupName === this.user.GroupName);
    return anyoneCanShare || userGroup?.CanShare;
  }

  /********************************************************************/
  /************************* END PERMISSIONS **************************/
  /********************************************************************/

  private setNotificationListeners(): void {
    const notifications = this.socketService.getNotifications();

    notifications
      // TODO: why doesn't the NotificationObjectType.User work?
      .pipe(filter((n) => n.ObjectType === 'User'))
      .pipe(filter((n) => n.ActionType === NotificationActionType.Updated))
      .pipe(pluck('ObjectInfo'))
      .subscribe((user: User) => {
        if (this.user.Preferences.LightMSA != user.Preferences.LightMSA) {
          location.reload();
        }
        this.user = { ...user, Preferences: user.Preferences, Group: { ...user.Group, Path: user.Group.Path || {} } };
      });
  }
}
