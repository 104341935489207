import { HttpClient } from '@angular/common/http';
import { IJobLog } from '../interfaces/joblog.interface';
import { AdminBaseService } from './admin-base.service';

export class AdminPorts extends AdminBaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  public async updateGeometry(LOCODE: string): Promise<any> {
    return this.getAsync(`/light/api/background-tasks/ports/updateGeometry?LOCODE=${LOCODE}`);
  }

  public async getLastExecutedPort(): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/ports/getLastExecuted`);
  }

  public async getPorts(): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/ports/getPorts`);
  }

  public async getPort(id: string): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/ports/getPort?id=${id}`);
  }

  public async clean(): Promise<IJobLog> {
    return this.deleteAsync(`/light/api/background-tasks/ports/clean`);
  }
}
