import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  ReflectiveInjector,
  ViewChild,
  ViewContainerRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { Dialog } from '@yaris/core/modal.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.sass'],
})
export class ModalDialogComponent implements OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
  dynamicComponentContainer: ViewContainerRef;

  @Input()
  dialog: Dialog;

  @Output()
  onClose = new EventEmitter<any>();

  @Output()
  onError = new EventEmitter<any>();

  @Output()
  onOutput = new EventEmitter<any>();

  private ngUnsubscribe;
  private startPosX = 0;
  private startPosY = 0;
  private mousePointerObservable;
  private mouseOutObservable;
  private mousePointerSubscription: Subscription;
  private mouseOutSubscription: Subscription;

  isReady: boolean;

  constructor(private resolver: ComponentFactoryResolver, private cdref: ChangeDetectorRef) {
    this.isReady = false;
    this.ngUnsubscribe = new Subject<void>();
    this.mousePointerObservable = Observable.fromEvent(window, 'mousemove');
    this.mouseOutObservable = Observable.fromEvent(window, 'mouseout');
  }

  ngAfterViewInit(): void {
    const inputProviders = Object.keys(this.dialog.inputs).map((inputName) => ({
      provide: inputName,
      useValue: this.dialog.inputs[inputName],
    }));

    const resolvedInputs = ReflectiveInjector.resolve(inputProviders);
    const injector = ReflectiveInjector.fromResolvedProviders(
      resolvedInputs,
      this.dynamicComponentContainer.parentInjector,
    );
    const factory = this.resolver.resolveComponentFactory(this.dialog.contentComponent);
    this.dialog.component = factory.create(injector);
    this.dynamicComponentContainer.insert(this.dialog.component.hostView);

    this.dialog.inputs.closeSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((d) => this.onClose.emit(d));
    this.dialog.inputs.errorSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((d) => this.onError.emit(d));
    this.dialog.inputs.outputSubject.pipe(takeUntil(this.ngUnsubscribe)).subscribe((d) => this.onOutput.emit(d));

    const node = this.dynamicComponentContainer.element.nativeElement.parentNode;
    setTimeout(() => {
      this.dialog.posX += window.innerWidth / 2 - node.clientWidth / 2;
      this.dialog.posY += window.innerHeight / 2 - node.clientHeight / 2;
      this.isReady = true;
    }, 100);
  }

  ngAfterViewChecked(): void {
    this.cdref.detectChanges();
  }

  ngOnDestroy() {
    if (this.dialog.component) {
      this.dialog.component.destroy();
    }
    this.removeSubscriptions();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.dialog.outputSub.complete();
    this.dialog.errorSub.complete();
    this.dialog.closeSub.complete();
  }

  minimize() {
    this.dialog.toggleMinimize();
  }

  close() {
    this.isReady = false;
    setTimeout(() => {
      this.onClose.emit({});
    }, 100);
  }

  onMouseDown(dialog: Dialog, event: any) {
    if (event.which != 1) {
      return;
    }
    this.removeSubscriptions();
    this.startPosX = event.clientX;
    this.startPosY = event.clientY;

    this.mousePointerSubscription = this.mousePointerObservable.subscribe((evt) =>
      this.handleWindowPosition(dialog, evt),
    );
    const mainHtml = document.querySelector('html');
    this.mouseOutSubscription = this.mouseOutObservable.subscribe((evt) => {
      if (evt.relatedTarget == mainHtml) {
        this.removeSubscriptions();
        if (evt.clientY < 50) {
          dialog.posY = -evt.clientY - this.startPosY;
          this.startPosY = evt.clientY + 50;
        }
      }
    });
  }

  onMouseUp(event: any) {
    if (event.which != 1) {
      return;
    }
    this.removeSubscriptions();
  }

  handleWindowPosition(dialog, event: any) {
    dialog.posX += event.clientX - this.startPosX;
    dialog.posY += event.clientY - this.startPosY;
    this.startPosX = event.clientX;
    this.startPosY = event.clientY;
  }

  removeSubscriptions() {
    if (this.mousePointerSubscription) {
      this.mousePointerSubscription.unsubscribe();
      this.mousePointerSubscription = null;
    }

    if (this.mouseOutSubscription) {
      this.mouseOutSubscription.unsubscribe();
      this.mouseOutSubscription = null;
    }
  }
}
