import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as Domain from './domain';
import { MSAObjectMap } from '@yaris/msa/msa.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private readonly defaultLimit = 150;
  private readonly defaultPage = 1;
  public complete$ = new BehaviorSubject(false);
  private groupsCache: Domain.Group[];
  coordinatesFormat$: BehaviorSubject<Domain.CoordinatesFormat> = new BehaviorSubject(undefined);

  constructor(private http: HttpClient) {}

  listUsers(
    groupId?: string,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.User[]> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit) });
    if (groupId) {
      headers = headers.append('groupId', groupId);
    }
    return this.http
      .get<Domain.RequestResponse>(`/light/api/User`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.User[]));
  }

  listUsersAccess(
    security: Domain.SecurityType,
    sensitivity: Domain.SensitivityLevel,
    owner_id: string,
    situation_id?: string,
    outside_of_ya?: boolean,
  ): Observable<Domain.User[]> {
    let headers = new HttpHeaders({
      security: security,
      sensitivity: String(sensitivity),
      owner_id: owner_id,
      outsideya: outside_of_ya ? 'true' : '',
    });
    if (situation_id) {
      headers = headers.append('situation_id', situation_id);
    }
    return this.http
      .get<Domain.RequestResponse>(`/light/api/User/Access`, {
        headers: headers,
      })
      .pipe(map((res) => res.data as Domain.User[]));
  }

  getUser(id: string): Observable<Domain.User> {
    const headers = new HttpHeaders({ id: id, limit: String(this.defaultLimit), page: String(this.defaultPage) });
    return this.http.get<Domain.RequestResponse>(`/light/api/User`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.User;
      }),
    );
  }

  getCurrentUser(): Observable<Domain.User> {
    return this.http.get<Domain.RequestResponse>(`/light/api/User/current?appType=msa`).pipe(
      map((res) => {
        return res.data as Domain.User;
      }),
    );
  }

  heartbit(app: 'MSA' | 'LIGHT'): Observable<any> {
    return this.http.head(`/light/api/User/heartbit/${app}`);
  }

  forceLogout(app: 'msa' | 'light'): Observable<string> {
    return this.http.post<Domain.RequestResponse>(`/light/api/User/forceLogout`, { body: { app } }).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  updateVesselNameDisplay(id: string[], operation: any = 'add'): Observable<string[]> {
    return this.http.patch<Domain.RequestResponse>(`/light/api/User/updateVesselNameDisplay`, { id, operation }).pipe(
      map((res) => {
        return res.data as string[];
      }),
    );
  }

  getMSAMapConfig(): Observable<Domain.MSAMapConfig> {
    return this.http.get<Domain.RequestResponse>(`/light/api/User/msaMapConfig`).pipe(
      map((res) => {
        return res.data as Domain.MSAMapConfig;
      }),
    );
  }

  getUserPermissions(): Observable<{
    UserPermissions: Domain.UserPermissions;
    AllPossiblesRolesNumbers: { [propName: string]: number };
  }> {
    return this.http.get<Domain.RequestResponse>(`/light/api/User/userPermissions`).pipe(
      map((res) => {
        return res.data;
      }),
    );
  }

  updateUserPreferences(preferences: Domain.UserPreferences): Observable<Domain.User> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/User`, preferences)
      .pipe(map((res) => res.data as Domain.User));
  }

  updateUserDirexPermission(direxPermission: boolean, userId: string): Observable<Domain.User> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/user/direx-permission`, {
        IsDirex: direxPermission,
        UserId: userId,
      })
      .pipe(map((res) => res.data as Domain.User));
  }

  listNotifications(options?: {
    objectTypes?: Domain.NotificationObjectType[];
    actionTypes?: Domain.NotificationActionType[];
    activeLayers?: string[];
    filter?: Domain.NotificationFilter;
    limit?: number;
    page?: number;
  }): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({
      page: options?.page ? String(options.page) : String(this.defaultPage),
      limit: options?.limit ? String(options.limit) : String(this.defaultLimit),
      show: options?.filter ? options.filter : Domain.NotificationFilter.NotHidden,
    });
    if (options?.objectTypes) {
      headers = headers.append('objectTypes', options.objectTypes);
    }
    if (options?.actionTypes) {
      headers = headers.append('actionTypes', options.actionTypes);
    }
    if (options?.activeLayers) {
      headers = headers.append('activeLayers', options.activeLayers);
    }
    return this.http.get<Domain.RequestResponse>(`/light/api/Notification`, { headers: headers });
  }

  hideNotification(id: string): Observable<Domain.Notification> {
    const headers = new HttpHeaders({ id: id });
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/Notification/hide`, null, { headers: headers })
      .pipe(map((res) => res.data as Domain.Notification));
  }
  getLayerName(id: string): Observable<Domain.RequestResponse> {
    const headers = new HttpHeaders({
      id: id,
    });
    return this.http.get<Domain.RequestResponse>(`/light/api/layer/getName`, { headers: headers });
  }
  hideAllNotifications(ids?: string[]): Observable<number> {
    let headers = new HttpHeaders({});
    if (ids) {
      headers = headers.append('Notification_ids', ids);
    }
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/notification/hideAll`, { headers: headers })
      .pipe(map((res) => res.data as number));
  }

  showNotification(id: string): Observable<Domain.Notification> {
    const headers = new HttpHeaders({ id: id });
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/Notification/show`, null, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.Notification));
  }

  deleteNotification(id: string): Observable<Domain.Notification> {
    const headers = new HttpHeaders({ id: id });
    return this.http
      .delete<Domain.RequestResponse>(`/light/api/Notification`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.Notification));
  }

  listUserGroups(level?: string, groupId?: string, forceRequest?: boolean): Observable<Domain.Group[]> {
    /*if (this.groupsCache && !level && forceRequest===undefined) {
      return of(this.groupsCache);
    }
*/
    let headers = new HttpHeaders();
    if (level) headers = headers.append('level', level);
    if (groupId) {
      headers = headers.append('groupId', groupId);
    }
    return this.http
      .get<Domain.RequestResponse>(`/light/api/group`, { headers: headers })
      .pipe(
        tap((res) => {
          this.groupsCache = res.data.docs as Domain.Group[];
        }),
      )
      .pipe(map((res) => res.data as Domain.Group[]));
  }

  listAllGroups(): Observable<Domain.Group[]> {
    return this.http.get<Domain.RequestResponse>(`/light/api/group`).pipe(map((res) => res.data as Domain.Group[]));
  }

  listGroupStatus(level?: string, groupId?: string): Observable<Domain.Group[]> {
    let headers = new HttpHeaders();
    if (level) headers = headers.append('level', level);

    return this.http
      .get<Domain.RequestResponse>(`/light/api/group/status`, { headers: headers })
      .pipe(
        tap((res) => {
          this.groupsCache = res.data.docs as Domain.Group[];
        }),
      )
      .pipe(map((res) => res.data as Domain.Group[]));
  }

  async refreshSession(appType: string) {
    const res = <any>await this.http
      .post(`/light/api/refresh?appType=${appType}`, { useReferer: true })
      .toPromise()
      .catch((e) => console.error(e));
    if (res?.loginURL) {
      window.location = res.loginURL;
    }
  }

  refreshManually(option: string, appType: string) {
    const headers = new HttpHeaders({ option: String(option) });
    return this.http.get(`/light/api/user/refresh?appType=${appType}`, { headers: headers });
  }

  createDefaultParams(data: Domain.IDefaultParams) {
    return this.http.post<Domain.IDefaultParams>(`/light/api/user/defaultparams`, data);
  }

  getDefaultParams(): Observable<Domain.IDefaultParams> {
    return this.http.get(`/light/api/user/defaultparams`).pipe(map((res: any) => res.data[0]));
  }

  getLogoURL() {
    return this.http.get<Domain.RequestResponse>(`/light/api/user/logourl`).pipe(map((res: any) => res.data as string));
  }

  createFolder(folder: {
    Name: string;
    Type: Domain.FolderType;
    Parent?: string;
    Root?: boolean;
  }): Observable<Domain.Folder> {
    return this.http
      .post<Domain.RequestResponse>(`/light/api/folder`, folder)
      .pipe(map((res) => res.data as Domain.Folder));
  }

  deleteFolder(folder: Domain.Folder): Observable<Domain.Folder> {
    const headers = new HttpHeaders({ id: folder._id });

    return this.http
      .delete<Domain.RequestResponse>(`/light/api/folder`, { headers: headers })
      .pipe(map((res) => res.data.doc as Domain.Folder));
  }

  updateFolder(folder: Domain.Folder): Observable<Domain.Folder> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/folder`, folder)
      .pipe(map((res) => res.data as Domain.Folder));
  }

  getFolderSummary(folder: Domain.Folder): Observable<Domain.FolderSummary> {
    const headers = new HttpHeaders({ id: String(folder._id) });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/folder/itemsSummary`, { headers: headers })
      .pipe(map((res) => res.data as Domain.FolderSummary));
  }

  listFolders(folderType: Domain.FolderType): Observable<Domain.Folder[]> {
    const headers = new HttpHeaders({ type: String(folderType) });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/folder`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Folder[]));
  }

  listRootFolders(folderType: Domain.FolderType): Observable<Domain.Folder[]> {
    const headers = new HttpHeaders({ type: String(folderType) });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/folder/root`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Folder[]));
  }

  listSubFolders(folderId: string, folderType: Domain.FolderType): Observable<Domain.Folder[]> {
    const headers = new HttpHeaders({ id: String(folderId), type: String(folderType) });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/folder/subFolders`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Folder[]));
  }

  listFavoriteSituations(
    groupName: string,
    {
      desc,
      offset = 0,
      limit,
      page = this.defaultPage,
      orderBy = 'Priority',
    }: {
      desc?: boolean;
      offset?: number;
      limit?: number;
      page?: number;
      orderBy?: string;
    } = {},
  ): Observable<Domain.RequestResponse> {
    const query: any = [{ criteria: '$eq', property: 'FavoritedByGroupNames', value: groupName }];

    return this.listSituations({ desc, offset, limit, query: JSON.stringify(query), page, orderBy });
  }

  listOperationalSituations(
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.Situation[]> {
    const headers = new HttpHeaders({ page: String(page), limit: String(limit) });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/Situation`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.Situation[]));
  }

  listSituations({
    desc,
    offset = 0,
    limit,
    query,
    page = this.defaultPage,
    orderBy = 'Priority',
    folderId,
    haveAFolder,
    isDefault,
  }: {
    desc?: boolean;
    offset?: number;
    limit?: number;
    query?: string;
    page?: number;
    orderBy?: string;
    folderId?: string;
    haveAFolder?: boolean;
    isDefault?: boolean;
  } = {}): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), offset: String(offset) });
    headers = headers.append('orderby', String(orderBy));

    if (limit) {
      headers = headers.append('limit', String(limit));
    }
    if (desc !== undefined) {
      headers = headers.append('desc', String(desc));
    }
    if (query) {
      headers = headers.append('query', query);
    }

    if (folderId) headers = headers.append('folderId', folderId);

    if (haveAFolder !== undefined) headers = headers.append('haveAFolder', String(haveAFolder));

    if (isDefault !== undefined) headers = headers.append('isDefault', String(isDefault));

    return this.http.get<Domain.RequestResponse>(`/light/api/Situation`, { headers: headers });
  }

  getOperationalSituation(id: string): Observable<Domain.Situation> {
    const headers = new HttpHeaders({ id: id, limit: String(this.defaultLimit), page: String(this.defaultPage) });
    return this.http.get<Domain.RequestResponse>(`/light/api/Situation`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.Situation;
      }),
    );
  }

  createOperationalSituation(
    situation: {
      Name: string;
      Security: Domain.SecurityType;
      SensitivityLevel: Domain.SensitivityLevel;
      Priority: Domain.SituationPriority;
      IsActive: boolean;
      Description?: string;
      Tags?: string[];
      Folder?: string;
      Layers_ids?: string[];
      ShareWithOutsideYA: boolean;
    },
    eventIdsToCopy?: string[],
  ): Observable<Domain.Situation> {
    let headers = new HttpHeaders();

    if (situation.Folder) headers = headers.append('Folder', String(situation.Folder));

    return this.http
      .post<Domain.RequestResponse>(
        `/light/api/situation`,
        { situation: situation, eventIdsToCopy: eventIdsToCopy },
        { headers },
      )
      .pipe(map((res) => res.data as Domain.Situation));
  }

  updateOperationalSituation(situation: Domain.Situation & { Folder?: string }): Observable<Domain.Situation> {
    let headers = new HttpHeaders();

    if (situation.Folder) headers = headers.append('Folder', String(situation.Folder));

    return this.http
      .put<Domain.RequestResponse>(`/light/api/Situation`, situation, { headers })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  updateIconVisibility(icon): Observable<Domain.User> {
    return this.http.patch<Domain.RequestResponse>(`/light/api/Icon/visibility`, icon).pipe(map((res) => res.data));
  }
  updateTagVisibility(tag): Observable<Domain.User> {
    return this.http.patch<Domain.RequestResponse>(`/light/api/Tag/visibility`, tag).pipe(map((res) => res.data));
  }
  deleteUnusedTag(tag): Observable<Domain.Tags> {
    const headers = new HttpHeaders({ id: tag._id });
    return this.http
      .delete<Domain.RequestResponse>(`/light/api/Tag`, { headers: headers })
      .pipe(map((res) => res.data));
  }
  updatePropertyVisibility(tag): Observable<Domain.User> {
    return this.http.patch<Domain.RequestResponse>(`/light/api/Layer/visibility`, tag).pipe(map((res) => res.data));
  }

  updateDefaultPropertyVisibility(tag): Observable<Domain.User> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/Layer/defaultVisibility`, tag)
      .pipe(map((res) => res.data));
  }

  deleteDefaultPropertie(tag): Observable<Domain.Tags> {
    const headers = new HttpHeaders({ id: tag._id });
    return this.http
      .delete<Domain.RequestResponse>(`/light/api/Layer/defaultProperties`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  updateOperationalSituationLayers(situation: Domain.Situation, layer2remove?: string): Observable<Domain.Situation> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/Situation/layers`, { obj: situation, layer_2_remove: layer2remove })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  updateSituationFavorite(situation: Domain.Situation, isFavorite: boolean): Observable<Domain.Situation> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/Situation/favorite`, { _id: situation._id, isFavorite: isFavorite })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  listEvents(
    situationId?: string,
    desc?: boolean,
    offset: number = 0,
    limit?: number,
    query?: string,
    page: number = this.defaultPage,
    fetchDefaultEvents?: boolean,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), offset: String(offset) });
    if (situationId) {
      headers = headers.append('situation_id', situationId);
    }

    if (desc) {
      headers = headers.append('desc', String(desc));
    }

    if (limit) {
      headers = headers.append('limit', String(limit));
    }

    if (query) {
      headers = headers.append('query', query);
    }

    if (typeof fetchDefaultEvents === 'boolean') {
      headers = headers.append('fetchDefaultEvents', fetchDefaultEvents.toString());
    }
    return this.http.get<Domain.RequestResponse>(`/light/api/Event`, { headers: headers });
  }

  listEventsHistories(
    situationId?: string,
    desc?: boolean,
    offset: number = 0,
    limit?: number,
    query?: string,
    page: number = this.defaultPage,
    fetchDefaultEvents?: boolean,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), offset: String(offset) });

    if (situationId) {
      headers = headers.append('situation_id', situationId);
    }

    if (desc) {
      headers = headers.append('desc', String(desc));
    }

    if (limit) {
      headers = headers.append('limit', String(limit));
    }

    if (query) {
      headers = headers.append('query', query);
    }

    if (typeof fetchDefaultEvents === 'boolean') {
      headers = headers.append('fetchDefaultEvents', fetchDefaultEvents.toString());
    }

    return this.http.get<Domain.RequestResponse>(`/light/api/Event/EventHistories`, { headers: headers });
  }

  getEvent(id: string, situation_id: string): Observable<Domain.Event> {
    const headers = new HttpHeaders({
      id: id,
      limit: String(this.defaultLimit),
      page: String(this.defaultPage),
      situation_id: String(situation_id),
    });
    return this.http.get<Domain.RequestResponse>(`/light/api/Event`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.Event;
      }),
    );
  }

  createEvent(event: {
    Security: Domain.SecurityType;
    SensitivityLevel: Domain.SensitivityLevel;
    Situation_id: string;
    EventType: Domain.EventType;
    Description?: string;
    Tags?: string[];
    fileURL?: string;
    MSAObject_id?: string;
    MSAObject_info?: string;
    EventCategory?: Domain.EventCategory;
    IncidentType?: string;
    ShareWithOutsideYA?: boolean;
    Content?: string;
  }): Observable<Domain.Event> {
    return this.http
      .post<Domain.RequestResponse>(`/light/api/event`, event)
      .pipe(map((res) => res.data as Domain.Event));
  }

  toggleEventOutsideYA(eventId: string): Observable<void> {
    const headers = new HttpHeaders({ event_id: eventId });
    return this.http.patch<void>(`/light/api/event/toggleReleaseOutsideYA`, null, { headers });
  }

  toggleCommentOutsideYA(eventId: string, commentId: string): Observable<void> {
    const headers = new HttpHeaders({ event_id: eventId, comment_id: commentId });
    return this.http.patch<void>(`/light/api/comment`, null, { headers });
  }

  toggleChatOutsideYA(chatId: string): Observable<void> {
    const headers = new HttpHeaders({ chat_id: chatId });
    return this.http.patch<void>(`/light/api/chat`, null, { headers });
  }

  listComments(
    eventId?: string,
    offset: number = 0,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit), offset: String(offset) }); //implementar infinity scroll
    if (eventId) {
      headers = headers.append('event_id', eventId);
    }
    return this.http.get<Domain.RequestResponse>(`/light/api/Comment`, { headers: headers });
  }

  listCommentsHistories(
    eventId?: string,
    offset: number = 0,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit), offset: String(offset) }); //implementar infinity scroll
    if (eventId) {
      headers = headers.append('event_id', eventId);
    }
    return this.http.get<Domain.RequestResponse>(`/light/api/comment/ListHistories`, { headers: headers });
  }

  getComments(commentId: string, event_id: string): Observable<Domain.Comment> {
    const headers = new HttpHeaders({
      id: String(commentId),
      event_id: String(event_id),
    });
    return this.http.get<Domain.RequestResponse>(`/light/api/Comment`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.Comment;
      }),
    );
  }

  createComment(comment: {
    Text: string;
    Security: Domain.SecurityType;
    SensitivityLevel: Domain.SensitivityLevel;
    Event_id: string;
    ShareWithOutsideYA: boolean;
    IsHistory: boolean;
  }): Observable<Domain.Comment> {
    return this.http
      .post<Domain.RequestResponse>(`/light/api/comment`, comment)
      .pipe(map((res) => res.data as Domain.Comment));
  }

  listChats(
    show: Domain.ChatFilter,
    situationId?: string,
    limit: number = 2000,
    page: number = this.defaultPage,
    desc?: boolean,
    orderBy: string = 'Name',
  ): Observable<Domain.Chat[]> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit), show: show });
    headers = headers.append('orderby', String(orderBy));

    if (situationId) {
      headers = headers.append('situation_id', situationId);
    }
    if (desc !== undefined) {
      headers = headers.append('desc', String(desc));
    }

    return this.http
      .get<Domain.RequestResponse>(`/light/api/Chat`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.Chat[]));
  }

  getChat(id: string, situationId?: string, visibility?: string): Observable<Domain.Chat> {
    let headers = new HttpHeaders({
      id: id,
      limit: String(this.defaultLimit),
      page: String(this.defaultPage),
    });

    if (visibility) {
      headers = headers.append('show', visibility);
    }
    if (situationId) {
      headers = headers.append('situation_id', situationId);
    }

    return this.http.get<Domain.RequestResponse>(`/light/api/Chat`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.Chat;
      }),
    );
  }

  createChat(chat: {
    Name: string;
    Security: Domain.SecurityType;
    SensitivityLevel: Domain.SensitivityLevel;
    Situation_id: string;
    Users_ids: string[];
    MSAObject_id?: string;
    MSAObject_info?: string;
    ShareWithOutsideYA?: boolean;
    OpenToAnyone: boolean;
  }): Observable<Domain.Chat> {
    return this.http.post<Domain.RequestResponse>(`/light/api/chat`, chat).pipe(map((res) => res.data as Domain.Chat));
  }

  updateChat(chat: any): Observable<Domain.Chat> {
    return this.http.put<Domain.RequestResponse>(`/light/api/chat`, chat).pipe(map((res) => res.data as Domain.Chat));
  }

  updateGeneralChat(situation_id: string, groups: string[]): Observable<Domain.Chat> {
    const headers = new HttpHeaders();
    headers.append('situation_id', situation_id);
    headers.append('groups', groups);

    return this.http.put<Domain.RequestResponse>(`/light/api/chat/general`, { headers }).pipe(
      map((res) => {
        return res.data as Domain.Chat;
      }),
    );
  }

  updateChatFavorite(chat: Domain.Chat, isFavorite: boolean): Observable<Domain.Chat> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/Chat/favorite`, { _id: chat._id, isFavorite: isFavorite })
      .pipe(map((res) => res.data as Domain.Chat));
  }

  updateChatVisibilityToUser(chat_id: string, state: string): Observable<Domain.Chat> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/Chat/visibility`, { chat_id: chat_id, state: state })
      .pipe(map((res) => res.data as Domain.Chat));
  }

  listChatParts(
    chatId?: string,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit) });
    if (chatId) {
      headers = headers.append('chat_id', chatId);
    }
    return this.http.get<Domain.RequestResponse>(`/light/api/ChatPart`, { headers: headers });
  }

  getChatParts(
    chatId?: string,
    offset: number = 0,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({ page: String(page), limit: String(limit), offset: String(offset) });
    if (chatId) {
      headers = headers.append('chat_id', chatId);
    }
    return this.http.get<Domain.RequestResponse>(`/light/api/ChatPart`, { headers: headers });
  }

  listFavoriteLayers(
    groupName: string,
    {
      type,
      offset = 0,
      limit,
      light,
      desc,
      orderBy = 'Priority',
    }: {
      type?: Domain.LayerType | Domain.LayerType[];
      offset?: number;
      limit?: number;
      light?: string;
      desc?: boolean;
      orderBy?: string;
    } = {},
  ): Observable<Domain.RequestResponse> {
    const query: any = [{ criteria: '$eq', property: 'FavoritedByGroupNames', value: groupName }];

    return this.listLayers(type, offset, JSON.stringify(query), limit, light, desc, orderBy);
  }

  listLayers(
    type?: Domain.LayerType | Domain.LayerType[],
    offset: number = 0,
    query?: string,
    limit: number = this.defaultLimit,
    light?: string,
    desc?: boolean,
    orderBy?: string,
    {
      folderId,
      haveAFolder,
      isDefault,
    }: {
      folderId?: string;
      haveAFolder?: boolean;
      isDefault?: boolean;
    } = {},
    getLocalAndShared?: boolean,
    getRemoveFromList?: boolean,
  ): Observable<Domain.RequestResponse> {
    let headers =
      type === Domain.LayerType.Remote
        ? new HttpHeaders({})
        : new HttpHeaders({
            offset: String(offset),
            limit: String(limit),
          });
    headers = headers.append('forceListMSAObjects', 'true');

    if (orderBy) headers = headers.append('orderby', String(orderBy));

    if (type) {
      if (type === Domain.LayerType.Remote) {
        headers = headers.append('shared', 'true');
      } else {
        headers = headers.append('layerType', Array.isArray(type) ? type.join(',') : type);
      }
    }

    if (query) {
      headers = headers.append('query', query);
    }

    if (light) headers = headers.append('light', light);
    if (desc !== undefined) headers = headers.append('desc', String(desc));

    if (folderId) headers = headers.append('folderId', folderId);

    if (getLocalAndShared) headers = headers.append('getLocalAndShared', 'true');

    if (getRemoveFromList) headers = headers.append('getRemoveFromList', 'true');

    if (haveAFolder !== undefined) headers = headers.append('haveAFolder', String(haveAFolder));

    if (isDefault !== undefined) headers = headers.append('isDefault', String(isDefault));

    return this.http.get<Domain.RequestResponse>(`/light/api/Layer`, { headers: headers });
  }

  getLayers(
    type?: Domain.LayerType,
    offset: number = 0,
    limit: number = this.defaultLimit,
    light?: string,
  ): Observable<Domain.Layer[]> {
    return this.listLayers(type, offset, undefined, limit, light).pipe(
      map((res) => {
        return res.data.docs as Domain.Layer[];
      }),
    );
  }

  listByMSAObject(MSAObject_id: any) {
    let headers = new HttpHeaders();
    headers = headers.append('MSAObject_id', MSAObject_id);

    return this.http
      .get<Domain.RequestResponse>(`/light/api/Layer/listByMSAObject`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Layer[]));
  }

  listSituationLayers(
    situationId: string,
    layerType?: Domain.LayerType,
    limit: number = this.defaultLimit,
    query?: string,
    page: number = this.defaultPage,
  ): Observable<Domain.Layer[]> {
    let headers = new HttpHeaders({
      situation_id: situationId,
      page: String(page),
      limit: String(limit),
      forceListMSAObjects: 'true',
    });
    if (query) {
      headers = headers.append('query', query);
    }
    if (layerType) {
      headers = headers.append('layerType', layerType);
    }
    return this.http
      .get<Domain.RequestResponse>(`/light/api/Layer/situation`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.Layer[]));
  }

  getSituationLayers(
    situationId: string,
    layerType?: Domain.LayerType,
    query?: string,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({
      situation_id: situationId,
      page: String(page),
      limit: String(limit),
      forceListMSAObjects: 'true',
    });
    if (query) {
      headers = headers.append('query', query);
    }
    if (layerType) {
      headers = headers.append('layerType', layerType);
    }
    return this.http.get<Domain.RequestResponse>(`/light/api/Layer/situation`, { headers: headers });
  }

  getLayer(id: string, shared: boolean = false, getLocalAndShared = false): Observable<Domain.Layer> {
    let headers = new HttpHeaders({
      id: id,
      limit: String(this.defaultLimit),
      page: String(this.defaultPage),
      shared: shared ? 'true' : 'false',
    });

    if (getLocalAndShared) headers = headers.append('getLocalAndShared', 'true');

    return this.http.get<Domain.RequestResponse>(`/light/api/Layer`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.Layer;
      }),
    );
  }

  getDefaultLightMSALayer(light: string): Observable<Domain.Layer> {
    const headers = new HttpHeaders({
      light: light,
      limit: String(this.defaultLimit),
      page: String(this.defaultPage),
      shared: 'true',
    });
    return this.http.get<Domain.RequestResponse>(`/light/api/Layer`, { headers: headers }).pipe(
      map((res) => {
        if (res.data.docs.length === 0) {
          return null;
        }
        return res.data.docs[0] as Domain.Layer;
      }),
    );
  }

  createLayer(layer: {
    Name: string;
    LayerType: Domain.LayerType;
    Description?: string;
    Security: Domain.SecurityType;
    SensitivityLevel: Domain.SensitivityLevel;
    URL?: string;
    Folder?: string;
    Tags?: string[];
    Source?: string;
    SourceType?: Domain.LayerSourceType;
    ImageURL?: string;
    ImageBoundingBox?: number[][];
    MSAObjects_ids?: string[];
    Layers?: string[];
    LogicalQuerySmartLayers?: string;
    SmartRules?: { [key: string]: any | Domain.Criteria }[];
    CustomFields?: { [key: string]: string };
    DefaultPresentation?: {
      Active: boolean;
      Icon: string;
      Color: string;
    };
    ShareWithOutsideYA: boolean;
    ZoneLayer?: boolean;
    GroupLayer?: boolean;
  }): Observable<Domain.Layer> {
    let headers = new HttpHeaders();

    if (layer.Folder) headers = headers.append('Folder', String(layer.Folder));

    return this.http
      .post<Domain.RequestResponse>(`/light/api/layer`, layer, { headers })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  updateLayer(layer: Domain.Layer & { Folder?: string }): Observable<Domain.Layer> {
    let headers = new HttpHeaders();

    if (layer.Folder) headers = headers.append('Folder', String(layer.Folder));

    return this.http
      .put<Domain.RequestResponse>(`/light/api/layer`, layer, { headers })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  updateLayerFavorite(layer: Domain.Layer, isFavorite: boolean): Observable<Domain.Layer> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/Layer/favorite`, { _id: layer._id, isFavorite: isFavorite })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  deleteLayer(id: string): Observable<Domain.Layer> {
    const headers = new HttpHeaders({ id: id, limit: String(this.defaultLimit), page: String(this.defaultPage) });
    return this.http
      .delete<Domain.RequestResponse>(`/light/api/Layer`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  getMSAObject(id: string, layerId: string, situationId?: string): Observable<Domain.MSAObject> {
    let headers = new HttpHeaders({
      layer_id: layerId,
      id: id,
    });

    if (situationId) headers = headers.append('situationId', situationId);

    return this.http
      .get<Domain.RequestResponse>(`/light/api/MSAObject/details`, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  listLayerMsaObjects(
    id: string,
    situationId?: string,
    parent_layer_id?: string,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.RequestResponse> {
    let headers = new HttpHeaders({
      layer_id: id,
      limit: String(limit),
      page: String(page),
    });

    if (situationId) headers = headers.append('situationId', situationId);

    if (parent_layer_id) headers = headers.append('parent_layer_id', parent_layer_id);

    if (parent_layer_id) headers = headers.append('parent_layer_id', parent_layer_id);

    return this.http.get<Domain.RequestResponse>(`/light/api/MSAObject`, { headers: headers });
  }

  listMissingLayerMsaObjects(
    id: string,
    missingMSA?: string,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.MSAObject[]> {
    const headers = new HttpHeaders({
      layer_id: id,
      limit: String(limit),
      page: String(page),
      missing: missingMSA,
    });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/MSAObject/missingMSA`, { headers: headers })
      .pipe(map((res) => res.data.docs as Domain.MSAObject[]));
  }

  createIcons(icon: {
    Name: string;
    IconURL: string;
    Description?: string;
    GroupName: string;
  }): Observable<Domain.Icons> {
    return this.http.post<Domain.RequestResponse>(`/light/api/icon`, icon).pipe(map((res) => res.data as Domain.Icons));
  }

  updateIcons(icon: {
    _id: string;
    Name: string;
    IconURL: string;
    Description?: string;
    GroupName: string;
    Visible: boolean;
  }): Observable<Domain.Icons> {
    return this.http.put<Domain.RequestResponse>(`/light/api/icon`, icon).pipe(map((res) => res.data as Domain.Icons));
  }

  deleteIcons(icon: Domain.Icons): Observable<any> {
    return this.http.delete<Domain.RequestResponse>(`/light/api/icon/${icon._id}`);
  }

  listMsaPorts(find: { toSelect: boolean }) {
    let headers = new HttpHeaders();
    if (find?.toSelect) headers = headers.append('toSelect', 'true');
    return this.http
      .get<Domain.RequestResponse>(`/light/api/port`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Port[]));
  }

  listPortsCenters() {
    const headers = new HttpHeaders();
    return this.http
      .get<string[]>(`/light/api/port/centers`, { headers: headers })
      .pipe(map((res) => res as string[]))
      .toPromise();
  }

  createPort(port: {
    Name: string;
    geometryFileURL: string;
    LOCODE: string;
    Description?: string;
    Properties: any;
  }): Observable<Domain.Port> {
    return this.http.post<Domain.RequestResponse>(`/light/api/port`, port).pipe(map((res) => res.data as Domain.Port));
  }

  updatePort(port: {
    _id: string;
    Name: string;
    geometryFileURL: string;
    Active: boolean;
    Properties: Record<string, string>;
  }): Observable<Domain.Port> {
    return this.http.put<Domain.RequestResponse>(`/light/api/port`, port).pipe(map((res) => res.data as Domain.Port));
  }

  deletePort(port: Domain.Port): Observable<any> {
    return this.http.delete<Domain.RequestResponse>(`/light/api/port/${port._id}`);
  }

  updatePortActive(port: { _id: string; Active: boolean }): Observable<Domain.User> {
    return this.http.patch<Domain.RequestResponse>(`/light/api/port/active`, port).pipe(map((res) => res.data));
  }

  uploadPort(
    file: File,
    createContent: {
      portId: string;
    },
  ) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({
      Source: Domain.ContentSource.Port,
      ParentId: String(createContent.portId || null),
      Update: String('true'),
    });

    return this.http.post<Domain.Content>(`/api/content/`, formData, { headers: headers });
  }

  updateTags(tag: {
    _id: string;
    Name: string;
    Description?: string;
    Type: string;
    Visible: boolean;
  }): Observable<Domain.Tags> {
    return this.http.put<Domain.RequestResponse>(`/light/api/tag`, tag).pipe(map((res) => res.data as Domain.Tags));
  }

  updateProperties(property: Domain.Property): Observable<Domain.Property> {
    return this.http
      .put<Domain.RequestResponse>(`/light/api/layer/properties`, property)
      .pipe(map((res) => res.data as Domain.Property));
  }

  updateDefaultProperties(property: Domain.MsaObjectDefaultProperties): Observable<Domain.MsaObjectDefaultProperties> {
    return this.http
      .put<Domain.RequestResponse>(`/light/api/layer/defaultProperties`, property)
      .pipe(map((res) => res.data as Domain.MsaObjectDefaultProperties));
  }

  createFlag(flag: Domain.MsaObjectDefaultPropertiesFlag): Observable<any> {
    return this.http
      .post<Domain.RequestResponse>(`/light/api/layer/flag`, flag)
      .pipe(map((res) => res.data as Domain.MsaObjectDefaultPropertiesFlag));
  }

  updateFlag(flag: Domain.MsaObjectDefaultPropertiesFlag): Observable<any> {
    return this.http
      .put<Domain.RequestResponse>(`/light/api/layer/flag`, flag)
      .pipe(map((res) => res.data as Domain.MsaObjectDefaultPropertiesFlag));
  }

  createTags(tag: { Name: string; Description?: string; Type: string }): Observable<Domain.Tags> {
    return this.http.post<Domain.RequestResponse>(`/light/api/tag`, tag).pipe(map((res) => res.data as Domain.Tags));
  }

  createDefaultProperties(property: Domain.MsaObjectDefaultProperties): Observable<Domain.MsaObjectDefaultProperties> {
    return this.http
      .post<Domain.RequestResponse>(`/light/api/layer/defaultProperties`, property)
      .pipe(map((res) => res.data as Domain.MsaObjectDefaultProperties));
  }

  listTags(type: string) {
    let headers = new HttpHeaders();
    headers = headers.append('type', type);
    return this.http
      .get<Domain.RequestResponse>(`/light/api/tag`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Tags[]));
  }

  listTagsBySituation(situation_id: string) {
    const headers = new HttpHeaders({ situation_id: situation_id });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/situation/listTagsBySituation`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Tags[]));
  }

  listMsaIcons(filtered?: string) {
    let headers = new HttpHeaders();
    if (filtered) headers = headers.append('filtered', filtered);
    return this.http
      .get<Domain.RequestResponse>(`/light/api/icon`, { headers: headers })
      .pipe(map((res) => res.data as Domain.Icons[]));
  }

  listMsaObjectsProperties() {
    const headers = new HttpHeaders();
    return this.http
      .get<Domain.RequestResponse>(`/light/api/layer/properties`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  listProperties() {
    const headers = new HttpHeaders();
    return this.http
      .get<Domain.RequestResponse>(`/light/api/layer/fullProperties`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  listMsaObjectsDefaultProperties(property: string) {
    const headers = new HttpHeaders({ property: property });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/layer/defaultProperties`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  listChatPartDefaultMessages(property?: string) {
    const headers = new HttpHeaders({ visible: property });
    return this.http
      .get<Domain.RequestResponse>(`/light/api/ChatPart/defaultMessages`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  createDefaultMessage(message: Domain.MsaDefaultMessage): Observable<Domain.ChatPartDefaultMessage> {
    return this.http
      .post<Domain.RequestResponse>(`/light/api/ChatPart/defaultMessages`, message)
      .pipe(map((res) => res.data as Domain.ChatPartDefaultMessage));
  }

  createDefaultCustomVesselPhoto(customVesselPhoto: Domain.MsaCustomVesselsPhotos) {
    return this.http.post<Domain.MsaCustomVesselsPhotos>(`/light/api/vessel/custom-vessel-photos`, customVesselPhoto);
  }

  deleteDefaultCustomVesselPhoto(customVesselPhoto: Domain.MsaCustomVesselsPhotos) {
    const params = new HttpParams().append('_id', String(customVesselPhoto._id));
    return this.http.delete<Domain.MsaCustomVesselsPhotos>(`/light/api/vessel/custom-vessel-photos`, {
      params: params,
    });
  }

  updateDefaultCustomVesselPhoto(customVesselPhoto: Domain.MsaCustomVesselsPhotos) {
    return this.http.patch<Domain.MsaCustomVesselsPhotos>(`/light/api/vessel/custom-vessel-photos`, customVesselPhoto);
  }

  listDefaultCustomVesselPhoto(): Observable<Domain.MsaCustomVesselsPhotos[]> {
    return this.http.get<Domain.MsaCustomVesselsPhotos[]>(`/light/api/vessel/custom-vessel-photos`);
  }

  updateDefaultMessageVisibility(message): Observable<Domain.ChatPartDefaultMessage> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/ChatPart/defaultVisibility`, message)
      .pipe(map((res) => res.data));
  }
  deleteDefaultMessage(message): Observable<Domain.ChatPartDefaultMessage> {
    const headers = new HttpHeaders({ id: message._id });
    return this.http
      .delete<Domain.RequestResponse>(`/light/api/ChatPart/defaultMessages`, { headers: headers })
      .pipe(map((res) => res.data));
  }

  updateDefaultMessage(message: Domain.MsaDefaultMessage): Observable<Domain.ChatPartDefaultMessage> {
    return this.http
      .put<Domain.RequestResponse>(`/light/api/ChatPart/defaultMessages`, message)
      .pipe(map((res) => res.data as Domain.ChatPartDefaultMessage));
  }

  getExerciceTag() {
    return this.http
      .get<Domain.RequestResponse>(`/light/api/ChatPart/exerciceTag`)
      .pipe(map((res: any) => res.data as string));
  }

  createMSAObject(
    msaObject: {
      Layer_id: string;
      Geometry: { Type: string; Coordinates: any[]; Center?: number[]; Radius?: number };
      Icon?: string;
      Color?: string;
      Opacity?: number;
      LineOpacity?: number;
      LineWidth?: number;
      LineColor?: string;
      LineDashed?: boolean;
      Properties?: { [key: string]: string };
      Human?: { [key: string]: string };
      Geofencing?: string;
      Incident?: Domain.Incident;
      CreatedAt?: Date;
      UpdatedAt?: Date;
      ExtendedData?: Domain.ExtendedData;
      ExtendedDataOptions?: Domain.ExtendedDataOptions;
      Event?;
    },
    original?: string,
    situationId?: string,
  ): Observable<Domain.MSAObject> {
    if (original) {
      const headers = new HttpHeaders({ original: original });
      return this.http
        .post<Domain.RequestResponse>(`/light/api/msaobject`, msaObject, { headers })
        .pipe(map((res) => res.data as Domain.MSAObject));
    }

    let headers = new HttpHeaders();
    if (situationId) {
      headers = headers.append('situationId', situationId);
    }

    return this.http
      .post<Domain.RequestResponse>(`/light/api/msaobject`, msaObject, { headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  updateMSAObject(msaObject: Domain.MSAObject, situationId?: string): Observable<Domain.MSAObject> {
    let headers = new HttpHeaders();

    const layerId = msaObject.Layer_id || (msaObject as unknown as MSAObjectMap)?.layerIds?.[0] || '';
    headers = headers.append('layer_id', layerId);

    if (situationId) headers = headers.append('situationId', situationId);

    return this.http
      .put<Domain.RequestResponse>(`/light/api/msaobject`, msaObject, { headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  deleteMSAObject(id: string): Observable<Domain.MSAObject> {
    const headers = new HttpHeaders({ id: id });
    return this.http
      .delete<Domain.RequestResponse>(`/light/api/MSAObject`, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  deleteIncident(id: string): Observable<Domain.EventLocation> {
    const headers = new HttpHeaders({ id: id });
    return this.http
      .delete<Domain.RequestResponse>(`/light/api/event/location`, { headers: headers })
      .pipe(map((res) => res.data as Domain.EventLocation));
  }

  removeMSAObjectFromLayer(id: string, layerId: string): Observable<Domain.MSAObject> {
    const headers = new HttpHeaders({ id: id, layer_id: layerId });
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/MSAObject/removeFromLayer`, undefined, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  addMSAObjectToLayer(id: string, layerId: string): Observable<Domain.MSAObject> {
    const headers = new HttpHeaders({ id: id, layer_id: layerId });
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/MSAObject/addToLayer`, undefined, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  undoMSAObjectLastHistory(msaObjectId: string): Observable<Domain.MSAObject> {
    const headers = new HttpHeaders({ MSAObject_id: msaObjectId });
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/MSAObject/undoLastHistory`, undefined, { headers: headers })
      .pipe(map((res) => res.data as Domain.MSAObject));
  }

  listMSAObjectHistory(
    msaobject_id: string,
    startDate: string,
    endDate: string,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.RequestResponse> {
    const headers = new HttpHeaders({
      msaobject_id: msaobject_id,
      startDate: startDate,
      endDate: endDate,
      limit: String(limit),
      page: String(page),
    });
    return this.http.get<Domain.RequestResponse>(`/light/api/MSAObject/history`, { headers: headers });
  }

  listSkylightHistory(
    skylight_id: string,
    interval: number,
    limit: number = this.defaultLimit,
    page: number = this.defaultPage,
  ): Observable<Domain.RequestResponse> {
    const headers = new HttpHeaders({
      skylightobject_id: skylight_id,
      interval: interval.toString(),
      limit: String(limit),
      page: String(page),
    });
    return this.http.get<Domain.RequestResponse>(`/light/api/MSAObject/skylight_history`, { headers: headers });
  }

  listIncidents() {
    const headers = new HttpHeaders();
    return this.http.get<Domain.EventLocation[]>(`/light/api/event/location/incidents`, { headers: headers });
  }

  listIncidentWarnings() {
    const headers = new HttpHeaders();
    return this.http.get<Domain.EventLocation[]>(`/light/api/event/location/incidentwarnings`, { headers: headers });
  }

  downloadAttachment(filename: string, pathtostorage?: string) {
    const params = { filename, pathtostorage };
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.get<any>(`/api/content/download`, { headers, params, responseType: 'blob' as 'json' });
  }

  uploadAttachment(
    file: File,
    createContent: {
      Sensitivity: Domain.SensitivityLevel;
      Security: Domain.SecurityType;
      Source: Domain.ContentSource;
      ParentName?: string;
      ParentId?: string;
      Message?: string;
    },
  ) {
    const formData = new FormData();
    formData.append('file', file);

    let headers = new HttpHeaders({
      Sensitivity: String(createContent.Sensitivity),
      Security: String(createContent.Security),
      Source: String(createContent.Source),
      ParentName: String(createContent.ParentName || ''),
      ParentId: String(createContent.ParentId || null),
    });

    if (createContent?.Message) headers = headers.set('Message', encodeURI(createContent.Message));

    return this.http.post<Domain.Content>(`/api/content/`, formData, { headers: headers });
  }

  listContentsFromSituation({
    situation,
    searchTerm,
    limit = this.defaultLimit,
    offset = 0,
  }: {
    situation: Domain.Situation;
    searchTerm?: string;
  } & Domain.IPaginatedRequest) {
    let params = new HttpParams()
      .set('situationId', situation._id)
      .set('limit', String(limit))
      .set('offset', String(offset));

    if (searchTerm) params = params.set('searchTerm', searchTerm);

    return this.http.get<Domain.RequestResponse>(`/api/content/`, { params: params });
  }

  listReports({ searchTerm }: { searchTerm?: string } & Domain.IPaginatedRequest) {
    let params = new HttpParams();
    if (searchTerm) params = params.set('searchTerm', searchTerm);
    return this.http.get<Domain.RequestResponse>(`/api/content/getreports`, { params: params });
  }

  uploadFile(file: File, type: 'converter' | 'image' | 'general' | 'icon') {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('option', type);
    return this.http.post<any>(`/light/api/upload`, formData);
  }
  uploadIconFile(file: File, type: 'converter' | 'image' | 'general' | 'icon', userGroup: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('option', type);
    formData.append('user', userGroup);
    return this.http.post<any>(`/light/api/upload`, formData);
  }

  layerExportKMZ(layer_id: string) {
    return this.http.get('/light/api/layer/export', {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/kmz').append('layer_id', layer_id),
    });
  }
  getBandwidthTestFile() {
    return this.http.get('/bandwidthTestFile.json', {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/kml'), //.append('layer_id', layer_id)
    });
  }

  layerExportCSV(layer_id: string) {
    return this.http.get(`/light/api/layer/exportCSV`, {
      responseType: 'blob',
      //headers: new HttpHeaders().append('Content-Type', 'text/csv').append('layer_id', layer_id)
      headers: new HttpHeaders().append('Content-Type', 'application/zip').append('layer_id', layer_id),
    });
  }

  ExportCSV(msaObject_id: string) {
    return this.http.get(`/light/api/msaobject/exportCSV`, {
      responseType: 'blob',
      //headers: new HttpHeaders().append('Content-Type', 'text/csv').append('layer_id', layer_id)
      headers: new HttpHeaders().append('Content-Type', 'application/zip').append('obj_id', msaObject_id),
    });
  }

  ExportKMZ(msaObject_id: string) {
    return this.http.get(`/light/api/msaobject/exportKMZ`, {
      responseType: 'blob',
      headers: new HttpHeaders().append('Content-Type', 'application/kmz').append('obj_id', msaObject_id),
    });
  }

  situationExport(situation_id: string, includeEventsHistory: boolean = true) {
    const includeEventsHistoryString = includeEventsHistory.toString();
    return this.http.get('/light/api/situation/export', {
      responseType: 'blob',
      headers: new HttpHeaders()
        .append('Content-Type', 'application/zip')
        .append('situation_id', situation_id)
        .append('include-events-history-string', includeEventsHistoryString),
    });
  }

  logExport(situation_id: string, includeEventsHistory: boolean = true) {
    const includeEventsHistoryString = includeEventsHistory.toString();
    return this.http.get('/light/api/event/exportPDF', {
      responseType: 'blob',
      headers: new HttpHeaders()
        .append('Content-Type', 'application/pdf')
        .append('situation_id', situation_id)
        .append('include-events-history-string', includeEventsHistoryString),
    });
  }

  shareSituation(
    situation_id: string,
    sharingGroups: Domain.SharingGroup[],
    sharingGroupRules: Domain.SharingGroupRule[],
    isDefault?: boolean,
  ): Observable<Domain.Situation> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/situation/share`, {
        _id: situation_id,
        SharingGroups: sharingGroups,
        SharingGroupRules: sharingGroupRules,
        isDefault: isDefault ? isDefault : undefined,
      })
      .pipe(map((res) => res.data as Domain.Situation));
  }

  shareLayer(
    layer_id: string,
    sharingGroups: Domain.SharingGroup[],
    sharingGroupRules: Domain.SharingGroupRule[],
  ): Observable<Domain.Layer> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/layer/share`, {
        _id: layer_id,
        SharingGroups: sharingGroups,
        SharingGroupRules: sharingGroupRules,
      })
      .pipe(map((res) => res.data as Domain.Layer));
  }

  shareBoardMessage(
    boardMessage_id: string,
    sharingGroups: Domain.SharingGroup[],
    sharingGroupRules: Domain.SharingGroupRule[],
  ): Observable<Domain.BoardMessage> {
    return this.http
      .patch<Domain.RequestResponse>(`/light/api/boardMessage/share`, {
        _id: boardMessage_id,
        SharingGroups: sharingGroups,
        SharingGroupRules: sharingGroupRules,
      })
      .pipe(map((res) => res.data as Domain.BoardMessage));
  }

  createEventLocation(eventLocation) {
    return this.http.post<Domain.RequestResponse>(`/light/api/event/location`, eventLocation);
  }

  updateEventLocation(eventLocation) {
    return this.http.put<Domain.EventLocation>(`/light/api/event/location`, eventLocation);
  }

  getUnreadEventsCount(situationsId: string[]): Observable<Domain.UnreadCountSummary[]> {
    let params = new HttpParams();

    for (const id of situationsId) {
      params = params.append('situationsId[]', id);
    }

    return this.http
      .get('/light/api/readMark/unreadEventsCount', { params })
      .pipe(map((res) => res as Domain.UnreadCountSummary[]));
  }

  getUnreadChatPartsCount(situationsId?: string[], chatsId?: string[]): Observable<Domain.UnreadCountSummary[]> {
    let params = new HttpParams();

    if (situationsId?.length)
      for (const id of situationsId) {
        params = params.append('situationsId[]', id);
      }

    if (chatsId?.length)
      for (const id of chatsId) {
        params = params.append('chatsId[]', id);
      }

    return this.http
      .get('/light/api/readMark/unreadChatPartsCount', { params })
      .pipe(map((res) => res as Domain.UnreadCountSummary[]));
  }

  getUserMapConfig(situationId: string, userId: string): Observable<Domain.UserMapConfig[]> {
    const params = new HttpParams().append('SituationId', situationId).append('UserId', userId);

    return this.http
      .get(`/light/api/userMapConfig/`, { params: params })
      .pipe(map((res) => res as Domain.UserMapConfig[]));
  }

  createUserMapConfig(userMapConfig: Domain.UserMapConfig): Observable<Domain.UserMapConfig> {
    return this.http.post(`/light/api/userMapConfig/`, userMapConfig).pipe(map((res) => res as Domain.UserMapConfig));
  }

  updateUserMapConfig(userMapConfig: Domain.UserMapConfig): Observable<Domain.UserMapConfig> {
    return this.http.put(`/light/api/userMapConfig/`, userMapConfig).pipe(map((res) => res as Domain.UserMapConfig));
  }

  createCpaObject(cpaObject: {
    TrackObjects: string[];
    ActivedFor: {
      Situation: string;
    }[];
    RefreshTimeInSec: number;
  }): Observable<Domain.CpaObject> {
    return this.http.post(`/light/api/cpaObject/`, cpaObject).pipe(map((res) => res as Domain.CpaObject));
  }

  listCpaObjects({
    ActivedForUser,
    ActivedForSituation,
    limit = this.defaultLimit,
    offset = 0,
  }: {
    ActivedForUser?: string;
    ActivedForSituation?: string;
  } & Domain.IPaginatedRequest = {}) {
    let params = new HttpParams().set('limit', String(limit)).set('offset', String(offset));

    if (ActivedForUser) params = params.set('ActivedForUser', ActivedForUser);
    if (ActivedForSituation) params = params.set('ActivedForSituation', ActivedForSituation);

    return this.http.get<Domain.RequestResponse>(`/light/api/cpaObject/`, { params: params });
  }

  updateCpaObject(cpaObject: { _id: string; RefreshTimeInSec: number }): Observable<Domain.CpaObject> {
    return this.http.put(`/light/api/cpaObject/`, cpaObject).pipe(map((res) => res as Domain.CpaObject));
  }

  updateActiveForCpaObject(params: {
    _id: string;
    IsActive: boolean;
    SituationId: string;
  }): Observable<Domain.CpaObject> {
    return this.http.put(`/light/api/cpaObject/activedFor`, params).pipe(map((res) => res as Domain.CpaObject));
  }

  deleteCpaObject(cpaObjectId: string): Observable<Domain.CpaObject> {
    const params = new HttpParams().append('_id', cpaObjectId);

    return this.http.delete(`/light/api/cpaObject/`, { params: params }).pipe(map((res) => res as Domain.CpaObject));
  }

  createBoardMessage(boardMessage: {
    SensitivityLevel?: Domain.SensitivityLevel;
    Security?: Domain.SecurityType;
    ShareWithOutsideYA?: boolean;
    Message?: string;
    StartsAt?: string;
    ExpiresAt?: string;
  }): Observable<Domain.BoardMessage> {
    return this.http.post(`/light/api/boardMessage/`, boardMessage).pipe(map((res) => res as Domain.BoardMessage));
  }

  deleteBoardMessage(boardMessageId: string): Observable<Domain.BoardMessage> {
    const params = new HttpParams().append('_id', boardMessageId);

    return this.http
      .delete(`/light/api/boardMessage`, { params: params })
      .pipe(map((res) => res as Domain.BoardMessage));
  }

  updateBoardMessage(boardMessage: {
    _id: string;
    SensitivityLevel?: Domain.SensitivityLevel;
    Security?: Domain.SecurityType;
    ShareWithOutsideYA?: boolean;
    Message?: string;
    StartsAt?: string;
    ExpiresAt?: string;
  }): Observable<Domain.BoardMessage> {
    return this.http.put(`/light/api/boardMessage`, boardMessage).pipe(map((res) => res as Domain.BoardMessage));
  }

  listBoardMessages(offset = 0, limit = this.defaultLimit, boardMessageFilter) {
    let params = new HttpParams().set('limit', String(limit)).set('offset', String(offset));

    if (boardMessageFilter?.Message) {
      params = params.set('Message', boardMessageFilter.Message);
    }

    return this.http.get<Domain.RequestResponse>(`/light/api/boardMessage`, { params: params });
  }

  listSkylightReports(query: any) {
    let params = new HttpParams();

    if (query.q) {
      params = params.set('searchTerm', query.q);
    }
    if (query.s) {
      params = params.set('reportType', query.s);
    }
    return this.http.get<Domain.RequestResponse>(`/api/content/getAdminReports`, { params: params });
  }

  listAnalyticBackups({ limit = this.defaultLimit, offset = 0 }: Domain.IPaginatedRequest = {}) {
    let params = new HttpParams().set('limit', String(limit)).set('offset', String(offset));

    return this.http.get<Domain.RequestResponse>(`/light/api/analyticsBackup/`, { params: params });
  }

  restoreAnalyticBackup(backup: Domain.AnalyticBackup): Observable<any> {
    return this.http.get<Domain.RequestResponse>(`/light/api/analyticsBackup/restore/${backup._id}`);
  }

  freeUpSpaceAnalyticBackup(backup: Domain.AnalyticBackup): Observable<any> {
    return this.http.get<Domain.RequestResponse>(`/light/api/analyticsBackup/freeUpSpace/${backup._id}`);
  }
}
