import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PermissionsService } from './permissions.service';

interface ErrorSubscription {
  priority: number;
  whenMethod: 'priority' | 'always';
  errorIdentifiers: ErrorIdentifier[];
  componentName: string;
}

export type ApplicationErrorGroupType =
  | 'chat'
  | 'event'
  | 'folder'
  | 'general'
  | 'layer'
  | 'msaobject'
  | 'notification'
  | 'situation'
  | 'tag'
  | 'content';

interface ErrorIdentifier {
  errorCode: number;
  group: ApplicationErrorGroupType;
}

export interface ApiError {
  errorCode: number;
  group: string;
  status: string;
  message?: string;
  requestId?: string;
  stacktrace?: string;
}

interface ApiErrorEvent extends ApiError, ErrorSubscription {}

@Injectable({
  providedIn: 'root',
})
export class ErrorManagerService {
  private errorSource = new Subject<ApiError>();

  private errorPropagator = new Subject<ApiErrorEvent>();

  private errorSubscription: ErrorSubscription[] = [];

  private errorGeneralNotification = new Subject<ApiError>();

  $errorGeneralNotification = this.errorGeneralNotification.asObservable();

  constructor(private permissionService: PermissionsService) {
    this.listenToErrors();
  }

  subscribeToError(subscripion: ErrorSubscription): Observable<ApiErrorEvent> {
    this.errorSubscription.push(subscripion);

    return this.errorPropagator.asObservable().pipe(
      filter((e) => e.componentName == subscripion.componentName),
      map((apiError) => ({
        ...subscripion,
        ...apiError,
      })),
    );
  }

  unsubscribeToError(componentName: string) {
    this.errorSubscription = this.errorSubscription.filter((es) => es.componentName != componentName);
  }

  pushErrorToManager(apiError: ApiError) {
    this.errorSource.next(apiError);
  }

  private propagateError(subscription: ErrorSubscription, apiError: ApiError) {
    this.errorPropagator.next({
      ...subscription,
      ...apiError,
    });
  }

  private listenToErrors() {
    this.errorSource.subscribe((error: ApiError) => {
      const subscriptions = this.errorSubscription.filter((es) =>
        es.errorIdentifiers.some((ei) => ei.group == error.group && ei.errorCode == error.errorCode),
      );

      if (!subscriptions.length) {
        if (this.permissionService.getUser().Preferences.ShowErrorDialog) this.errorGeneralNotification.next(error);
      } else {
        const subscriptionsAlways = subscriptions.filter((s) => s.whenMethod == 'always');
        const subscriptionsPriority = subscriptions
          .filter((s) => s.whenMethod == 'priority')
          .sort((s_a, s_b) => s_b.priority - s_a.priority);

        for (const subscription of subscriptionsAlways) {
          this.propagateError(subscription, error);
        }

        if (subscriptionsPriority.length) {
          this.propagateError(subscriptionsPriority[0], error);
        }
      }
    });
  }
}
