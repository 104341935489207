<div class="report-detail">
  <div class="report-body" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="1rem">
    <div class="report-header" fxLayout fxLayoutGap="2rem">
      <div fxLayout fxLayoutGap="0.5rem">
        <i class="report-security-icon" aria-hidden="true"></i>
        <span class="bold">{{ report.Filename }}</span>
      </div>
      <span>{{ report.CreatedAt }}</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="2rem">
      <div class="report-description"></div>
      <div class="download" fxLayout fxLayoutGap="1rem" fxLayoutAlign="end">
        <main-button icon="/assets/images/download.svg" (click)="onDownloadClick()">
          {{ 'BUTTON.DOWNLOAD' | translate }}</main-button
        >
      </div>
    </div>
  </div>
</div>
