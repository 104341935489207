<div class="color-picker-control" [class.disabled]="disabled">
  <label class="label" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap=".5rem">
    <span class="title" *ngIf="title" fxFlex="0 1 auto">
      {{ title }}
    </span>
    <input
      [disabled]="disabled"
      [placeholder]="disabled ? '' : placeholder"
      [(ngModel)]="value"
      [(colorPicker)]="value"
      cpPosition="bottom"
      cpAlphaChannel="disabled"
      cpOutputFormat="hex"
      [cpOKButton]="true"
      cpOKButtonText="{{ 'BUTTON.SUBMIT' | translate }}"
      cpOKButtonClass="cpOKButton"
      [cpCancelButton]="true"
      cpCancelButtonText="{{ 'BUTTON.CANCEL' | translate }}"
      cpCancelButtonClass="cpCancelButton"
      [ngClass]="{ invalid: (invalid | async) }"
      #input
      [ngStyle]="{ 'border-left-color': value }"
      [cpSaveClickOutside]="false"
      (colorPickerOpen)="colorPickerOpen(value)"
      (colorPickerCancel)="colorPickerCancel()"
    />
  </label>
  <validation [messages]="failures | async" *ngIf="modelTouched && invalid | async"></validation>
</div>
