import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { PermissionsService } from '@yaris/core/permissions.service';
import { CoordinatesFormat, Layer, MSAObject } from '@yaris/core/domain';
import { CoordinateService } from '@yaris/msa/mapbox/services/coordinate.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MsaService } from '@yaris/msa/msa.service';

@Component({
  selector: 'app-msa-edit-object',
  templateUrl: './msa-edit-text.component.html',
  styleUrls: ['./msa-edit-text.component.sass'],
})
export class MsaEditTextComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  msaObject: MSAObject;
  layer: Layer;
  formError = '';
  errorcontrolObj = {
    'objectModel.DMS.Longitude.Deg': '',
    'objectModel.DMS.Longitude.Min': '',
    'objectModel.DMS.Longitude.Sec': '',
    'objectModel.DMS.Latitude.Deg': '',
    'objectModel.DMS.Latitude.Min': '',
    'objectModel.DMS.Latitude.Sec': '',
    'objectModel.DMm.Longitude.Deg': '',
    'objectModel.DMm.Longitude.Min': '',
    'objectModel.DMm.Latitude.Deg': '',
    'objectModel.DMm.Latitude.Min': '',
    'objectModel.Ddd.Longitude.Deg': '',
    'objectModel.Ddd.Latitude.Deg': '',
  };
  objectModel: {
    Label: string;
    FontSize: { label: string; value: string };
    PositionUtc: string;
    Color: string;
    Icon: { label: string; value: string };
    DMS: {
      Longitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    DMm: {
      Longitude: {
        Deg: number;
        Min: number;
      };
      Latitude: {
        Deg: number;
        Min: number;
      };
    };
    Ddd: {
      Longitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    Properties: {
      key: string;
      value: string;
    }[];
    AttachedObjects: { label: string; value: string }[];
    CoordinatesFormat: { label: string; value: CoordinatesFormat };
  } = {
    Label: undefined,
    FontSize: undefined,
    PositionUtc: undefined,
    Color: undefined,
    Icon: undefined,
    DMS: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
    },
    DMm: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
      },
    },
    Ddd: {
      Longitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
    },
    Properties: [],
    AttachedObjects: undefined,
    CoordinatesFormat: { label: undefined, value: undefined },
  };
  longitudeHemisphereOptions: { label: string; value: 'E' | 'W' }[] = [];
  latitudeHemisphereOptions: { label: string; value: 'N' | 'S' }[] = [];
  subscribedToFormChanges = false;
  coordinatesFormatOptions: { label: string; value: CoordinatesFormat }[] = [];
  fontSizeOptions: { label: string; value: string }[] = [];
  attachedObjectsOptions: { label: string; value: string }[] = [];

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private injector: Injector,
    private dataService: DataService,
    private translateService: TranslateService,
    private permissionsService: PermissionsService,
    private msaService: MsaService,
    private coordinateService: CoordinateService,
  ) {}

  ngOnInit(): void {
    this.msaObject = this.injector.get('msaObject');

    if (this.msaObject.Human)
      Object.keys(this.msaObject.Human).map((prop) => {
        if (
          this.msaObject.Properties[prop] !== undefined &&
          this.msaObject.Properties[prop] !== this.msaObject.Human[prop]
        ) {
          delete this.msaObject.Human[prop];
        }
      });
    else this.msaObject.Human = {};
    this.layer = this.injector.get('layer');
    this.longitudeHemisphereOptions = [
      { label: 'E', value: 'E' },
      { label: 'W', value: 'W' },
    ];
    this.latitudeHemisphereOptions = [
      { label: 'N', value: 'N' },
      { label: 'S', value: 'S' },
    ];

    this.fontSizeOptions = [8, 10, 12, 14, 16, 18, 20].map((value) => {
      return { value: value.toString(), label: value.toString() };
    });

    this.coordinatesFormatOptions = Object.keys(CoordinatesFormat).map((k) => ({
      label: this.translateService.instant('PREFERENCES.COORDINATES.' + k.toUpperCase()),
      value: CoordinatesFormat[k],
    }));

    this.objectModel.CoordinatesFormat = this.coordinatesFormatOptions.find(
      (o) => o.value === this.permissionsService.getUser().Preferences.CoordinatesFormat,
    );

    this.msaService
      .listMSAObjects()
      .filter(
        (obj) =>
          obj.Geometry.Type !== 'Point' && (obj.MSAObject_id === undefined || obj.MSAObject_id === this.msaObject._id),
      )
      .map((obj) => {
        this.attachedObjectsOptions.push({ label: obj.Properties?.Name, value: obj._id });
      });

    this.attachedObjectsOptions.sort(this.compare);

    if (this.msaObject.Properties?.FontSize) {
      this.objectModel.FontSize = {
        label: this.msaObject.Properties?.FontSize,
        value: this.msaObject.Properties?.FontSize,
      };
    }

    this.objectModel.Label = this.msaObject.Properties.Label;
    this.objectModel.PositionUtc = new Date().toISOString();
    this.objectModel.Color = this.msaObject.Color;

    const [lngDMS, latDMS] = this.coordinateService.DDToDMS(this.msaObject.Geometry.Coordinates);
    const [lngDMm, latDMm] = this.coordinateService.DDToDMm(this.msaObject.Geometry.Coordinates);
    const [lngDdd, latDdd] = this.coordinateService.DDToDdd(this.msaObject.Geometry.Coordinates);

    this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
    this.objectModel.DMS.Longitude.Min = lngDMS.min;
    this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
    this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDMS.hemisphere,
    );
    this.objectModel.DMS.Latitude.Deg = latDMS.deg;
    this.objectModel.DMS.Latitude.Min = latDMS.min;
    this.objectModel.DMS.Latitude.Sec = latDMS.sec;
    this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDMS.hemisphere,
    );
    this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
    this.objectModel.DMm.Longitude.Min = lngDMm.min;
    this.objectModel.DMm.Latitude.Deg = latDMm.deg;
    this.objectModel.DMm.Latitude.Min = latDMm.min;
    this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
    this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDdd.hemisphere,
    );
    this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
    this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDdd.hemisphere,
    );

    if (this.msaObject.AttachedObjects) {
      this.objectModel.AttachedObjects = this.attachedObjectsOptions.filter((obj) =>
        this.msaObject.AttachedObjects.includes(obj.value),
      );
      //this.objectModel.AttachedObjects.map(attached=>{return this.})
      const names = this.objectModel.AttachedObjects.map((a) => a.label);
      const b = [];
      this.attachedObjectsOptions.map((opt) => {
        if (this.objectModel.AttachedObjects.find((s) => s.label === opt.label)) {
          if (this.objectModel.AttachedObjects.find((s) => s.label === opt.label).value === opt.value) b.push(opt);
        } else b.push(opt);
      });
      this.attachedObjectsOptions = b;
      //this.attachedObjectsOptions = this.attachedObjectsOptions.filter(this.teste)
    }
  }

  compare(a, b) {
    if (a.label?.toUpperCase() < b.label?.toUpperCase()) {
      return -1;
    }
    if (a.label?.toUpperCase() > b.label?.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkForInputErrors() {
    if (
      !this.subscribedToFormChanges &&
      this.form &&
      this.form.controls &&
      Object.keys(this.form.controls).length !== 0
    ) {
      this.listenToFormInputChanges();
      this.subscribedToFormChanges = true;
    }
  }

  listenToFormInputChanges() {
    const controlObj = {
      'objectModel.DMS.Latitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
      },
      'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Longitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
      },
      'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
      'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
      'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
      'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
      'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
    };
    Object.keys(controlObj).forEach((key) => {
      this.form.controls[key]?.valueChanges.subscribe((val) => {
        const controlObject = {
          'objectModel.DMS.Latitude.Deg': {
            Min: 0,
            Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
          },
          'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMS.Longitude.Deg': {
            Min: 0,
            Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
          },
          'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
          'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
          'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
          'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
          'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
          'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
          'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
        };
        if (isNaN(val)) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.NUMBERREQUIRED');
          this.form.controls[key].setErrors({ incorrect: true }); // <--- Set invalidNumber to true
        } else if (val < controlObject[key].Min) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMIN', {
            min: controlObject[key].Min,
          });

          this.form.controls[key].setErrors({ min: true });
        } else if (val > controlObject[key].Max) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMAX', {
            max: controlObject[key].Max,
          });
          this.form.controls[key].setErrors({ max: true });
        } else {
          this.form.controls[key].setErrors(null);
        }
      });
    });
  }

  get DMSLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Deg'];
  }
  get DMSLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Min'];
  }
  get DMSLatSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Sec'];
  }
  get DMSLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Deg'];
  }
  get DMSLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Min'];
  }
  get DMSLngSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Sec'];
  }
  get DMmLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Deg'];
  }
  get DMmLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Min'];
  }
  get DMmLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Deg'];
  }
  get DMmLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Min'];
  }
  get DddLatDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Latitude.Deg'];
  }
  get DddLngDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Longitude.Deg'];
  }

  isAutomaticProperty(property: string, property2?: string) {
    if (property2)
      return this.msaObject.Properties[property] !== undefined || this.msaObject.Properties[property2] !== undefined;
    if (this.msaObject.Human)
      return this.msaObject.Properties[property] !== undefined && !Object.keys(this.msaObject.Human).includes(property);
    return this.msaObject.Properties[property] !== undefined && this.msaObject.Properties[property] !== '';
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    let lng = undefined;
    let lat = undefined;

    if (this.objectModel.CoordinatesFormat.value == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere.value,
      );
      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere.value,
      );
    } else if (this.objectModel.CoordinatesFormat.value == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere.value,
      );
      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere.value,
      );
    }

    const properties = {
      ...this.objectModel.Properties.reduce((properties, pair) => {
        properties[pair.key] = pair.value;
        return properties;
      }, {}),
      Provider: this.msaObject.Properties.Provider,
      UUID: this.msaObject.Properties.UUID,
      PositionUtc: this.objectModel.PositionUtc,
      Label: this.objectModel.Label,
      FontSize: this.objectModel.FontSize.value,
    };

    const b = this.msaObject;
    const newDuplicates = this.objectModel.AttachedObjects?.filter(function (x) {
      return !b.AttachedObjects?.includes(x.value);
    });
    this.objectModel.AttachedObjects = this.objectModel.AttachedObjects?.filter((a) => !newDuplicates.includes(a));

    if (newDuplicates?.length > 0) {
      const a: MSAObject[] = [];
      newDuplicates.map((yu) => {
        const yud = this.msaService.listMSAObjectByID(yu.value);
        const duplicate = {
          Layer_id: this.layer._id,
          Properties: {
            ...yud.Properties,
          },
          Color: yud.Color,
          Icon: yud.Icon,
          Geometry: yud.Geometry,
          MSAObject_id: this.msaObject._id,
          Opacity: yud.Opacity,
          LineOpacity: yud.LineOpacity,
          LineColor: yud.LineColor,
          LineWidth: yud.LineWidth,
          Geofencing: 'AttachedObjects',
        };
        this.dataService
          .createMSAObject(duplicate, this.msaObject._id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((respons) => {
            a.push(respons);
            if (a.length === newDuplicates.length) {
              const newDuplicated = [];
              a.map((ggf) => newDuplicated.push(ggf._id));

              this.dataService
                .updateMSAObject({
                  ...this.msaObject,
                  Color: this.objectModel.Color,
                  Icon: this.objectModel.Icon?.value || this.msaObject.Icon,
                  Geometry: {
                    Type: 'Point',
                    Coordinates: [lng, lat],
                  },
                  Properties: properties,
                  AttachedObjects: this.objectModel.AttachedObjects?.map((obj) => obj.value).concat(newDuplicated),
                })
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe({
                  next: (msaObject) => this.injector.get('outputSubject').next(msaObject),
                  error: (err) => (this.formError = err.error.error.type),
                });
            }
          });
      });
    } else {
      this.dataService
        .updateMSAObject({
          ...this.msaObject,
          Color: this.objectModel.Color,
          Icon: this.objectModel.Icon?.value || this.msaObject.Icon,
          Geometry: {
            Type: 'Point',
            Coordinates: [lng, lat],
          },
          Properties: properties,
          AttachedObjects: this.objectModel.AttachedObjects?.map((obj) => obj.value),
        })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (msaObject) => this.injector.get('outputSubject').next(msaObject),
          error: (err) => (this.formError = err.error.error.type),
        });
    }
  }

  onCoordinatesFormatChange(changes) {
    this.objectModel.CoordinatesFormat = changes.newFormat;
    this.updateCoordinates(changes.oldFormat.value, changes.newFormat.value);
  }

  updateCoordinates(oldFormat, newFormat) {
    let lng = undefined;
    let lat = undefined;

    if (oldFormat == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere?.value,
      );
    } else if (oldFormat == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere?.value,
      );
    }

    if (newFormat == 'DMS') {
      const [lngDMS, latDMS] = this.coordinateService.DDToDMS([lng, lat]);
      this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
      this.objectModel.DMS.Longitude.Min = lngDMS.min;
      this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
      this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
        (o) => o.value === lngDMS.hemisphere,
      );
      this.objectModel.DMS.Latitude.Deg = latDMS.deg;
      this.objectModel.DMS.Latitude.Min = latDMS.min;
      this.objectModel.DMS.Latitude.Sec = latDMS.sec;
      this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
        (o) => o.value === latDMS.hemisphere,
      );
    } else if (newFormat == 'DM.m') {
      const [lngDMm, latDMm] = this.coordinateService.DDToDMm([lng, lat]);
      this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
      this.objectModel.DMm.Longitude.Min = lngDMm.min;
      this.objectModel.DMm.Latitude.Deg = latDMm.deg;
      this.objectModel.DMm.Latitude.Min = latDMm.min;
    } else {
      const [lngDdd, latDdd] = this.coordinateService.DDToDdd([lng, lat]);
      this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
      this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
        (o) => o.value === lngDdd.hemisphere,
      );
      this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
      this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
        (o) => o.value === latDdd.hemisphere,
      );
    }
  }

  isRequired(coordinatesFormat) {
    return this.objectModel.CoordinatesFormat.value == coordinatesFormat;
  }
}
