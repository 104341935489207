<form
  class="yaris-form preferences-dialog"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="1rem"
  scrollable
>
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <div class="controls-container" fxLayout="column" fxLayoutGap="1rem">
      <checkbox-control
        name="preferencesModel.AutoRefreshSession"
        title="{{ 'PREFERENCES.AUTOREFRESHSESSION' | translate }}"
        [(ngModel)]="preferencesModel.AutoRefreshSession"
      ></checkbox-control>
    </div>
    <div class="description-container" fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between start">
      <p>{{ 'PREFERENCES.AUTOREFRESHSESSIONDESCRIPTION' | translate }}</p>
    </div>
  </div>
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <div class="controls-container" fxLayout="column" fxLayoutGap="1rem">
      <checkbox-control
        name="preferencesModel.ShowErrorDialog"
        title="{{ 'PREFERENCES.SHOWERRORDIALOG' | translate }}"
        [(ngModel)]="preferencesModel.ShowErrorDialog"
      ></checkbox-control>
    </div>
    <div class="description-container" fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between start">
      <p>{{ 'PREFERENCES.SHOWERRORDIALOGDESCRIPTION' | translate }}</p>
    </div>
  </div>
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <div class="controls-container" fxLayout="column" fxLayoutGap="1rem">
      <checkbox-control
        name="preferencesModel.EmailNotifications"
        title="{{ 'PREFERENCES.EMAILNOTIFICATIONS' | translate }}"
        [(ngModel)]="preferencesModel.EmailNotifications"
      ></checkbox-control>
    </div>
    <div class="description-container" fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between start">
      <p>{{ 'PREFERENCES.EMAILNOTIFICATIONSDESCRIPTION' | translate }}</p>
    </div>
  </div>
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem" *ngIf="!isLightMSA">
    <div class="controls-container" fxLayout="column" fxLayoutGap="1rem">
      <checkbox-control
        name="preferencesModel.AlertPreferences.NotifyAllSituations"
        title="{{ 'PREFERENCES.ALERTNOTIFICATIONS' | translate }}"
        [(ngModel)]="preferencesModel.AlertPreferences.NotifyAllSituations"
      ></checkbox-control>
    </div>
    <div class="description-container" fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between start">
      <p>{{ 'PREFERENCES.ALERTNOTIFICATIONSDESCRIPTION' | translate }}</p>
    </div>
  </div>
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <text-select-control
      required
      name="preferencesModel.Language"
      title="{{ 'PREFERENCES.DEFAULTLANGUAGE' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="languageOptions"
      [(ngModel)]="preferencesModel.Language"
      [initial]="preferencesModel.Language"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control>
  </div>
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <text-select-control
      required
      name="preferencesModel.CoordinatesFormat"
      title="{{ 'PREFERENCES.COORDINATESFORMAT' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="coordinatesFormatOptions"
      [(ngModel)]="preferencesModel.CoordinatesFormat"
      [initial]="preferencesModel.CoordinatesFormat"
    ></text-select-control>
  </div>
  <!--<div class="subtitle" fxLayout="column" fxLayoutGap="1rem">
    <text-select-control required name="preferencesModel.DistanceUnit" title="{{ 'PREFERENCES.DISTANCEUNITS' | translate }}" placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}" [options]="distanceUnitOptions" [(ngModel)]="preferencesModel.DistanceUnit" [initial]="preferencesModel.DistanceUnit"></text-select-control>
  </div>-->
  <div class="subtitle" fxLayout="column" fxLayoutGap="1rem" *ngIf="!isLightMSA">
    <text-select-control
      required
      name="preferencesModel.LightMSA"
      title="{{ 'PREFERENCES.LIGHTMSA' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="lightMSAOptions"
      [(ngModel)]="preferencesModel.LightMSA"
      [initial]="preferencesModel.LightMSA"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control>
  </div>
  <span *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </span>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{ 'BUTTON.SUBMIT' | translate }}</main-button>
  </div>
</form>
