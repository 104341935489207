import { HttpClient } from '@angular/common/http';
import { IJobLog } from '../interfaces/joblog.interface';
import { AdminBaseService } from './admin-base.service';

export class AdminReports extends AdminBaseService {
  constructor(public http: HttpClient) {
    super(http);
  }

  public async generateReport(date: Date): Promise<any> {
    return this.getAsync(`/light/api/background-tasks/reports/generateReport?date=${date}`);
  }

  public async generateAndSendSkylightReport(date: Date): Promise<any> {
    return this.getAsync(`/light/api/background-tasks/reports/generateAndSendSkylightReport?date=${date}`);
  }

  public async deleteReports(date: Date): Promise<any> {
    return this.getAsync(`/light/api/background-tasks/reports/deleteReports?date=${date}`);
  }

  public async getLastExecutedReport(): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/reports/getLastExecutedReport`);
  }

  public async getLastExecutedDeleteReports(): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/reports/getLastExecutedDeleteReports`);
  }

  public async getReports(): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/reports/getReports`);
  }

  public async getSkylightReports(): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/reports/getSkylightReports`);
  }

  public async getDeleteReports(): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/reports/getDeleteReports`);
  }

  public async getDeleteSkylightReports(): Promise<IJobLog[]> {
    return this.getAsync(`/light/api/background-tasks/reports/getDeleteSkylightReports`);
  }

  public async getReport(id: string): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/reports/getReport?id=${id}`);
  }

  public async cleanReports(): Promise<IJobLog> {
    return this.deleteAsync(`/light/api/background-tasks/reports/cleanReports`);
  }

  public async deleteSkylightReports(date: Date): Promise<IJobLog> {
    return this.getAsync(`/light/api/background-tasks/reports/deleteSkylightReports?date=${date}`);
  }
}
