<span class="attachment-thumbnail" *ngIf="hasThumbnail()">
  <img
    alt="attachment thumbnail"
    class="attachment-thumbnail"
    ngxViewer
    [viewerOptions]="viewerOptions"
    *ngIf="!isThumbnailLoading()"
    [src]="thumbnailUrl()"
  />
  <loading-spinner *ngIf="isThumbnailLoading()" class="loader"></loading-spinner>
</span>
