<div class="text-area-control" [class.disabled]="disabled">
  <label class="label" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap=".5rem">
    <span class="title" *ngIf="title">
      {{ title }}
    </span>
    <div
      class="text-container ps scrollable ps--scrolling-y ps--active-y scrollable-y"
      fxFlex="1 1 100%"
      style="width: 100%"
    >
      <textarea
        [rows]="rows"
        [disabled]="disabled"
        [placeholder]="disabled ? '' : placeholder"
        [(ngModel)]="value"
        [ngClass]="{ invalid: (invalid | async) }"
        #input
        scrollable
      >
          {{ value }}
      </textarea>
    </div>
  </label>
  <validation [messages]="failures | async" *ngIf="modelTouched && invalid | async"></validation>
</div>
