import {
  Component,
  Optional,
  Inject,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';

import { ElementBase } from '../element-base';
@Component({
  selector: 'multi-text-select-control',
  templateUrl: './multi-text-select-control.component.html',
  styleUrls: ['./multi-text-select-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiTextSelectControlComponent,
      multi: true,
    },
  ],
})
export class MultiTextSelectControlComponent extends ElementBase<any> implements OnInit, OnDestroy, AfterViewInit {
  @Output() closeSelect = new EventEmitter<{ label: string; value: any }[]>();
  @Input() title: string;
  @Input() placeholder: string = '';
  @Input() value: { label: string; value: any }[];
  @Input() options: { label: string; value: any }[];
  @Input() initial: { label: string; value: any }[];
  @Input() focus = false;
  @Input() disabled = false;
  @Input() style: { padding: string; titleMinWidth: string; dropdownMinWidth: string };
  @Input() enableSelectAll: false;
  @ViewChild('realValue', { read: NgModel }) model: NgModel;
  @ViewChild('displayInput', { read: ElementRef }) displayInputRef: ElementRef;
  dropdown = false;
  dropdownPos = { top: 0, left: 0 };
  displayVal: string;
  private readonly inputHeight = 26;
  private ngUnsubscribe = new Subject<void>();
  private selectAll: boolean = true;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private translateService: TranslateService,
  ) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    if (this.focus) {
      this.dropdown = true;
    }
    //console.log('initial value?', this.initial && this.initial.length);
    if (this.initial && this.initial.length) {
      this.value = this.initial;
    }
  }

  ngAfterViewInit() {
    this.model.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(debounceTime(50))
      .subscribe((value) => {
        if (this.value === null || this.value === undefined) {
          this.displayVal = null;
          return;
        }
        this.displayVal = this.value?.map((o) => o.label).join(', ');
      });

    this.dropdownPos = {
      top: this.displayInputRef.nativeElement.getBoundingClientRect().top + this.inputHeight,
      left: this.displayInputRef.nativeElement.getBoundingClientRect().left,
    };
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTitleFlex() {
    if (!this.style) {
      return '0 1 auto';
    }
    return this.style.titleMinWidth !== undefined && this.style.titleMinWidth !== null
      ? this.style.titleMinWidth
      : '0 1 auto';
  }

  onCloseDropdown() {
    this.dropdown = false;
    this.closeSelect.next(this.value);
  }

  onFocusIn() {
    this.dropdownPos = {
      top: this.displayInputRef.nativeElement.getBoundingClientRect().top + this.inputHeight,
      left: this.displayInputRef.nativeElement.getBoundingClientRect().left,
    };
  }

  onInputClick() {
    this.dropdownPos = {
      top: this.displayInputRef.nativeElement.getBoundingClientRect().top + this.inputHeight,
      left: this.displayInputRef.nativeElement.getBoundingClientRect().left,
    };
    this.dropdown = !this.dropdown;
  }

  onOptionClick(option) {
    if (!this.value) {
      this.value = [];
    }
    if (this.isActive(option)) {
      this.value = this.value.filter((v) => v.value !== option.value);
    } else {
      this.value = this.value.concat(option);
    }
  }

  isActive(option): boolean {
    return !!this.value && !!this.value.find((o) => o.value === option.value);
  }

  clearValues() {
    this.value = null;
  }

  onSelectAll() {
    this.selectAll ? (this.value = this.options) : (this.value = []);
    this.selectAll = !this.selectAll;
  }

  selectAllTitle() {
    if (this.value?.length == this.options?.length) {
      this.selectAll = false;
    }
    return this.selectAll ? this.translateService.instant('SELECTALL') : this.translateService.instant('SELECTNONE');
  }
}
