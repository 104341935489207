import { Component, Input } from '@angular/core';

@Component({
  selector: 'validation',
  templateUrl: 'validation.component.html',
  styleUrls: ['validation.component.sass'],
})
export class ValidationComponent {
  @Input() messages: string[];
  @Input() paddingLeft: string;
}
