<ng-container *ngIf="dialog">
  <div
    class="modal-dialog"
    fxLayout="column"
    fxFlex="0 0 auto "
    [class.resizable]="!dialog.isMinimized"
    [class.ready]="isReady"
  >
    <div
      class="modal-header"
      fxLayout
      fxLayoutAlign="space-between center"
      fxFlex="0 0 auto"
      fxLayoutGap="1rem"
      [title]="dialog.title"
      [class.draggable]="dialog.draggable"
      (mousedown)="onMouseDown(dialog, $event)"
      (mouseup)="onMouseUp($event)"
    >
      <span class="title">{{ dialog.title || '' }}</span>
      <div class="icons">
        <button
          class="minimize-button"
          (click)="minimize()"
          *ngIf="dialog.closable && !dialog.isMinimized"
          inlineSVG="/assets/images/minimize.svg"
        ></button>
        <button
          class="minimize-button"
          (click)="minimize()"
          *ngIf="dialog.closable && dialog.isMinimized"
          inlineSVG="/assets/images/maximize.svg"
        ></button>
        <button
          class="close-button"
          (click)="close()"
          *ngIf="dialog.closable"
          inlineSVG="/assets/images/cross.svg"
        ></button>
      </div>
    </div>
    <div class="modal-body" scrollable [class.minimized]="dialog.isMinimized">
      <ng-template #dynamicComponentContainer></ng-template>
    </div>
  </div>
</ng-container>
