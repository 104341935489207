import { Component, HostListener } from '@angular/core';
import { PermissionsService } from '@yaris/core/permissions.service';
import { RolePermission } from './core/domain';
import { SocketService } from './core/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  constructor(private permissionsService: PermissionsService, private socketService: SocketService) {}
  worker: Worker;

  ngOnInit(): void {
    if (typeof Worker !== 'undefined') {
      // Create a new
      this.worker = new Worker('./webworker.worker', { type: 'module' });

      this.worker.onmessage = ({ data }) => {
        //console.log("data.event", data.event,"data.msg", data.msg)

        this.socketService.$sktOn.next({ event: data.event, data: data.msg });
      };
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
    }

    this.socketService.sktEmitObserver.subscribe((ob) => {
      this.worker.postMessage({ type: 'emit', message: ob });
    });

    this.socketService.logout().subscribe((logout) => {
      if (
        this.permissionsService.getUser().Username === logout.Username ||
        this.permissionsService.getUser().ExternalId === logout.ExternalId
      ) {
        if (
          location.pathname.indexOf('/situation') > -1 ||
          location.pathname.indexOf('/bandwidth') > -1 ||
          location.pathname.indexOf('/denied') > -1
        ) {
          window.location.href = `/light/api/logout?appType=${
            this.permissionsService.isLightDomain() ? 'light' : 'msa'
          }`;
        } else window.close();
      }
    });
  }

  get canAccess(): boolean {
    return this.permissionsService.hasRolePermissionEqualOrAbove(RolePermission.judicial_expert);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.socketService.emitRemoveTab();
  }

  onContextMenu(event) {
    event.preventDefault();
  }
}
