import { MsaManageDefaultPropertiesComponent } from '../msa-manage-default-properties/msa-manage-default-properties.component';
import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@yaris/core/modal.service';

@Component({
  selector: 'msa-edit-flag',
  templateUrl: './msa-edit-flag.component.html',
  styleUrls: ['./msa-edit-flag.component.sass'],
})
export class MsaEditFlagComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  objectModel: {
    Name: string;
    Translations: {
      English: string;
      Portuguese: string;
      French: string;
      Spanish: string;
    };
  } = {
    Name: undefined,
    Translations: {
      English: undefined,
      Portuguese: undefined,
      French: undefined,
      Spanish: undefined,
    },
  };

  placeholders: {
    English: string;
    Portuguese: string;
    French: string;
    Spanish: string;
  } = {
    English: undefined,
    Portuguese: undefined,
    French: undefined,
    Spanish: undefined,
  };
  flag;
  formError: any;

  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.flag = this.injector.get('flag');
    this.objectModel.Name = this.flag.Value;
    this.objectModel.Translations.English = this.flag.Translations.English;
    this.objectModel.Translations.Portuguese = this.flag.Translations.Portuguese;
    this.objectModel.Translations.French = this.flag.Translations.French;
    this.objectModel.Translations.Spanish = this.flag.Translations.Spanish;

    this.placeholders.English = this.translateService.instant('PLACEHOLDERS.COUNTRYNAMEIN', {
      language: `${this.translateService.instant('LANGUAGE.EN')}`,
    });
    this.placeholders.Portuguese = this.translateService.instant('PLACEHOLDERS.COUNTRYNAMEIN', {
      language: `${this.translateService.instant('LANGUAGE.PT')}`,
    });
    this.placeholders.French = this.translateService.instant('PLACEHOLDERS.COUNTRYNAMEIN', {
      language: `${this.translateService.instant('LANGUAGE.FR')}`,
    });
    this.placeholders.Spanish = this.translateService.instant('PLACEHOLDERS.COUNTRYNAMEIN', {
      language: `${this.translateService.instant('LANGUAGE.ES')}`,
    });
  }

  manageTags() {
    this.modalService.closeDialog(this.modalService.dialogs[this.modalService.dialogs.length - 1]);

    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGEPROPERTIESVALUES'),
      inputs: { type: this.flag.Type },
      contentComponent: MsaManageDefaultPropertiesComponent,
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    this.dataService
      .updateFlag({
        _id: this.flag._id,
        Value: this.objectModel.Name,
        Translations: {
          English: this.objectModel.Translations.English,
          Portuguese: this.objectModel.Translations.Portuguese,
          French: this.objectModel.Translations.French,
          Spanish: this.objectModel.Translations.Spanish,
        },
      })
      .subscribe({
        next: (flag) => {
          this.injector.get('outputSubject').next(flag);
          this.manageTags();
        },
        error: (err) => {
          if (err.error.error.type.includes('duplicate key error collection'))
            this.formError = this.translateService.instant('DEFAULTPROPERTIES.DUPLICATEKEYNAME');
          else this.formError = err.error.error.type;
        },
      });
  }
}
