<form
  class="add-icons yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <text-control
      required
      name="iconModel.Name"
      title="{{ 'PROPERTY.NAME' | translate }}"
      placeholder="{{ 'PROPERTY.NAME' | translate }}"
      [(ngModel)]="iconModel.Name"
    >
    </text-control>
    <text-area-control
      name="iconModel.Description"
      title="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      placeholder="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      [(ngModel)]="iconModel.Description"
    >
    </text-area-control>
    <file-control
      required
      extension=".png,image/png"
      [fileExtension]="['png']"
      [fileSize]="maxFileSize"
      name="iconModel.IconURL"
      title="{{ 'PROPERTY.ATTACHMENT' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.SELECTAFILE' | translate }}"
      [(ngModel)]="iconModel.IconURL"
      (ngModelChange)="validateIcon($event)"
    ></file-control>
  </div>
  <span *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </span>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid && validIcon" type="submit">{{ 'BUTTON.SUBMIT' | translate }}</main-button>
  </div>
</form>
