import {
  Directive,
  ElementRef,
  EventEmitter,
  Output,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core';

@Directive({
  selector: '[closeOnOutsideClick]',
})
export class CloseOnOutsideClickDirective implements AfterViewInit, OnDestroy {
  @Output() close = new EventEmitter<void>();
  @Input() down = false; //if true, mouse down closes (avoids drag)
  private removeListener;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    setTimeout((_) => {
      this.setListener();
    });
  }

  ngOnDestroy() {
    if (this.removeListener) {
      this.removeListener();
    }
  }

  private setListener() {
    this.removeListener = this.renderer.listen('document', this.down ? 'mousedown' : 'click', (event) => {
      if (this.el.nativeElement.contains(event.target)) {
        return;
      }
      this.close.emit();
    });
  }
}
