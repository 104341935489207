import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DataService } from '@yaris/core/data.service';
import {
  Notification,
  NotificationActionType,
  NotificationObjectType,
  NotificationFilter,
  LayerType,
} from '@yaris/core/domain';
import { Subject } from 'rxjs';
import { takeUntil, filter, tap, pluck } from 'rxjs/operators';
import { SocketService } from '@yaris/core/socket.service';
import { PermissionsService } from '@yaris/core/permissions.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MsaService } from '@yaris/msa/msa.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-notification-box',
  templateUrl: './notification-box.component.html',
  styleUrls: ['./notification-box.component.sass'],
})
export class NotificationBoxComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<void>();
  notifications: Notification[] = [];
  loading = true;
  hidden = false;
  private readonly pageSize = 20;
  private page = 1;
  private pages = 1;
  private ngUnsubscribe = new Subject<void>();
  groupsDescription: any;

  constructor(
    private dataService: DataService,
    private permissionsService: PermissionsService,
    private socketService: SocketService,
    private translateService: TranslateService,
    private msaService: MsaService,

    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.dataService
      .listUserGroups()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((groups) => {
        this.groupsDescription = {};
        groups.forEach((group) => {
          this.groupsDescription[group.Name] = group.Description;
        });
      });
    this.spinner.show();
    this.dataService
      .listNotifications({
        objectTypes: [
          NotificationObjectType.Layer,
          NotificationObjectType.CenterState,
          NotificationObjectType.MSAObject,
          NotificationObjectType.Situation,
        ],
        actionTypes: [NotificationActionType.Created, NotificationActionType.Updated, NotificationActionType.Trespass],
        activeLayers: this.activeLayers(),
        page: this.page,
        limit: this.pageSize,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(pluck('data'))
      .pipe(tap((res) => (this.pages = res.pages)))
      .pipe(pluck('docs'))
      .subscribe({
        next: (notifications) => {
          this.notifications = notifications;
        },
        error: () => this.spinner.hide(),
        complete: () => this.spinner.hide(),
      });

    this.setNotificationListeners();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get title(): string {
    return this.hidden ? 'NOTIFICATION.HEADTITLE.HIDDEN' : 'NOTIFICATION.HEADTITLE.VISIBLE';
  }

  get visibilityIcon(): string {
    return this.hidden ? '/assets/images/visibility.svg' : '/assets/images/hidden.svg';
  }

  get hasMore(): boolean {
    return this.pages > this.page;
  }

  getTrespassingMessage(notification: Notification) {
    const areaName = notification.Trespasser?.area?.Name;
    const pointName = notification.Trespasser?.point?.Name;
    const state = notification.Trespasser?.state;

    if (state == 'in') {
      return `'${pointName}' ${this.translateService.instant('NOTIFICATION.DESCRIPTIONS.TRESPASSER_IN')} '${areaName}'`;
    } else if (state == 'out') {
      return `'${pointName}' ${this.translateService.instant(
        'NOTIFICATION.DESCRIPTIONS.TRESPASSER_OUT',
      )} '${areaName}'`;
    } else if (state == 'crossing') {
      return `'${pointName}' ${this.translateService.instant(
        'NOTIFICATION.DESCRIPTIONS.TRESPASSER_CROSSED',
      )} '${areaName}'`;
    }

    let names = Array.isArray(notification.Trespasser.point)
      ? notification.Trespasser.point.map((trye) => trye?.Name)
      : notification.Trespasser.point?.Name;
    if (Array.isArray(notification.Trespasser.point) && names.length > 2) {
      names = names.slice(0, 2).join(' , ') + '...';
    }
    //debugger
    return this.translateService.instant('NOTIFICATION.DESCRIPTIONS.TRESPASSER', {
      point: notification.Trespasser.point.length > 1 ? names : notification.Trespasser.point[0]?.Name,
      area: notification.Trespasser.area?.Name,
    });
    //return this.translateService.instant('NOTIFICATION.DESCRIPTIONS.TRESPASSER', {point: notification.Trespasser.point.length>1?this.translateService.instant('NOTIFICATION.DESCRIPTIONS.SOMEPOINTS') :notification.Trespasser.point[0].Name,area:notification.Trespasser.area.Name})
  }
  getTrespassingNames(notification) {
    return Array.isArray(notification.Trespasser.point)
      ? notification.Trespasser.point.map((trye) => trye?.Name).join(' , ')
      : notification.Trespasser.point?.Name;
  }
  onClose(): void {
    this.close.emit();
    this.dataService.hideAllNotifications().subscribe();
  }

  onViewMore(): void {
    if (this.pages <= this.page) {
      return;
    }
    this.spinner.show();
    this.dataService
      .listNotifications({
        filter: this.hidden ? NotificationFilter.Hidden : NotificationFilter.NotHidden,
        objectTypes: [
          NotificationObjectType.Layer,
          NotificationObjectType.CenterState,
          NotificationObjectType.MSAObject,
          NotificationObjectType.Situation,
        ],
        actionTypes: [NotificationActionType.Created, NotificationActionType.Updated, NotificationActionType.Trespass],
        activeLayers: this.activeLayers(),
        page: ++this.page,
        limit: this.pageSize,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(pluck('data'))
      .pipe(tap((res) => (this.pages = res.pages)))
      .pipe(pluck('docs'))
      .subscribe({
        next: (notifications) => {
          this.notifications = notifications.concat(this.notifications);
        },
        error: () => this.spinner.hide(),
        complete: () => this.spinner.hide(),
      });
  }

  onToggleVisibility(): void {
    this.hidden = !this.hidden;
    this.page = 1;
    this.pages = 1;
    this.spinner.show();
    this.dataService
      .listNotifications({
        filter: this.hidden ? NotificationFilter.Hidden : NotificationFilter.NotHidden,
        objectTypes: [
          NotificationObjectType.Layer,
          NotificationObjectType.CenterState,
          NotificationObjectType.MSAObject,
          NotificationObjectType.Situation,
        ],
        actionTypes: [NotificationActionType.Created, NotificationActionType.Updated, NotificationActionType.Trespass],
        activeLayers: this.activeLayers(),
        page: this.page,
        limit: this.pageSize,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(pluck('data'))
      .pipe(tap((res) => (this.pages = res.pages)))
      .pipe(pluck('docs'))
      .subscribe({
        next: (notifications) => {
          this.notifications = notifications;
        },
        error: () => this.spinner.hide(),
        complete: () => this.spinner.hide(),
      });
  }

  onRemoveClick(notification: Notification): void {
    this.dataService
      .deleteNotification(notification._id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((_) => (this.notifications = this.notifications.filter((n) => n._id !== notification._id)));
  }

  onHideClick(notification: Notification): void {
    this.dataService
      .hideNotification(notification._id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((_) => (this.notifications = this.notifications.filter((n) => n._id !== notification._id)));
  }

  onShowClick(notification: Notification): void {
    this.dataService
      .showNotification(notification._id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((_) => (this.notifications = this.notifications.filter((n) => n._id !== notification._id)));
  }

  getTitle(notification): string {
    const objectType = this.translateService.instant(
      'ENUM.NOTIFICATIONOBJECTTYPE.' + notification.ObjectType.toUpperCase(),
    );
    const actionType = this.translateService.instant(
      'ENUM.NOTIFICATIONACTIONTYPE.' + notification.ActionType.toUpperCase(),
    );

    if (notification.ObjectType === NotificationObjectType.Situation) {
      if (this.isCreatedSituation(notification)) {
        return this.translateService.instant(
          'NOTIFICATION.ACTION.SITUATION.' + NotificationActionType.Created.toUpperCase(),
        );
      } else if (this.isSharedSituation(notification)) {
        return this.translateService.instant(
          'NOTIFICATION.ACTION.SITUATION.' + NotificationActionType.Added.toUpperCase(),
        );
      }
    }

    const geofencingType = notification.Trespasser?.geofencingType ? ' ' + notification.Trespasser?.geofencingType : '';

    return `${objectType} ${actionType}${geofencingType}`;
  }

  private activeLayers(): string[] {
    return this.msaService.getMapLayers()?.map((layer) => layer._id);
  }

  private isCreatedLayer(n: Notification): boolean {
    return (
      n.ObjectType === NotificationObjectType.Layer &&
      n.ObjectInfo.SharedWith === this.permissionsService.getUser().GroupName &&
      n.ActionType === NotificationActionType.Created &&
      n.ObjectInfo?.LayerType !== LayerType.ThirdPartyLayer
    );
  }

  private isSharedLayer(n: Notification): boolean {
    return (
      n.ObjectType === NotificationObjectType.Layer && n.ActionType === NotificationActionType.Updated && n.IsSharing
    );
  }

  private isUpdatedSmartLayer(n: Notification): boolean {
    return (
      n.ObjectType === NotificationObjectType.Layer &&
      n.ActionType === NotificationActionType.Updated &&
      n.ObjectInfo?.LayerType === LayerType.Smart
    );
  }

  private isUpdatedTrespassing(n: Notification): boolean {
    return (
      n.ObjectType === NotificationObjectType.MSAObject &&
      n.ActionType === NotificationActionType.Trespass &&
      this.msaService.hasLayer(n.ObjectInfo?.Layer_id)
    );
  }

  private isCenterState(n: Notification): boolean {
    return (
      n.ObjectType === NotificationObjectType.CenterState &&
      n.ActionType === NotificationActionType.Updated &&
      n.SharedWith !== this.permissionsService.getUser().GroupName
    );
  }

  private isCreatedSituation(n: Notification): boolean {
    return n.ObjectType === NotificationObjectType.Situation && n.ActionType === NotificationActionType.Created;
  }

  private isSharedSituation(n: Notification): boolean {
    return (
      n.ObjectType === NotificationObjectType.Situation &&
      n.ActionType === NotificationActionType.Updated &&
      n.IsSharing
    );
  }

  private setNotificationListeners(): void {
    const notifier = this.socketService
      .getNotifications()
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(filter((_) => !this.hidden));

    notifier
      .pipe(
        filter(
          (n) =>
            this.isCreatedLayer(n) ||
            this.isUpdatedSmartLayer(n) ||
            this.isSharedLayer(n) ||
            this.isCreatedSituation(n) ||
            this.isSharedSituation(n) ||
            this.isCenterState(n) ||
            this.isUpdatedTrespassing(n),
        ),
      )
      .subscribe((notification) => (this.notifications = [notification].concat(this.notifications)));
  }
}
