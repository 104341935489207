<div
  class="unread-counter"
  fxLayout="column"
  fxLayoutAlign="start center"
  [ngClass]="{ active: counter > 0, inactive: counter < 1 }"
>
  <i
    class="icon"
    aria-hidden="true"
    [inlineSVG]="counter > 0 ? activeIcon : inactiveIcon"
    [ngClass]="{ red: counter > 0 }"
  ></i>
  <span *ngIf="showCounter" class="counter-number">{{ counter }}</span>
</div>
