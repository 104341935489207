<div class="date-select-control">
  <label class="label" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap=".5rem">
    <span class="title" *ngIf="title" fxFlex="0 1 auto">
      {{ title }}
    </span>
    <input type="number" [placeholder]="placeholder" #date [ngClass]="{ invalid: (invalid | async) }" />
    <input class="hidden" type="number" readonly #realValue [(ngModel)]="value" />
  </label>
  <validation [messages]="failures | async" *ngIf="modelTouched && invalid | async"></validation>
</div>
