<form
  class="msa-edit-incident yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div fxLayout fxLayoutGap=".5rem">
    <h4>{{ 'PROPERTY.ORIGIN' | translate }}:</h4>
    <span
      >{{ layer?.Name }} @
      {{ incident?.Properties?.PositionUtc || incident.UpdatedAt | date : 'yyyy-MM-dd HH:mm:ss' }}</span
    >
  </div>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <text-control
      disabled
      name="objectModel.Name"
      title="{{ 'PROPERTY.NAME' | translate }}"
      placeholder="{{ 'PROPERTY.NAME' | translate }}"
      [(ngModel)]="objectModel.Name"
    ></text-control>
    <text-area-control
      disabled
      name="objectModel.Description"
      title="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      placeholder="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      [(ngModel)]="objectModel.Description"
    ></text-area-control>
    <text-select-control
      disabled
      title="{{ 'MSA.PLACEHOLDERS.INCIDENTTYPE' | translate }}"
      name="objectModel.IncidentType"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="incidentTypeOptions"
      [(ngModel)]="objectModel.IncidentType"
      [initial]="objectModel.IncidentType"
      (click)="onClickExpandDropDown(37)"
    ></text-select-control>
    <checkbox-control
      *ngIf="!isIncidentWarning() && canManageIncidentWarning()"
      name="createIncidentWarning"
      title="Create Incident Warning"
      [(ngModel)]="createIncidentWarning"
    ></checkbox-control>
    <text-area-control
      *ngIf="isIncidentWarningOrWillBe() && canManageIncidentWarning()"
      required
      name="objectModel.WarningMessage"
      title="{{ 'PROPERTY.WARNINGMESSAGE' | translate }}"
      placeholder="{{ 'PROPERTY.WARNINGMESSAGE' | translate }}"
      [(ngModel)]="objectModel.WarningMessage"
    ></text-area-control>
    <text-select-control
      *ngIf="isIncidentWarningOrWillBe() && canManageIncidentWarning()"
      required
      title="{{ 'PROPERTY.STATUS' | translate }}"
      name="objectModel.Status"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="statusOptions"
      [(ngModel)]="objectModel.Status"
      [initial]="statusOptions[0]"
      (click)="onClickExpandDropDown(37)"
    ></text-select-control>
    <span style="margin-left: 6rem">{{ translatedType }}</span>
    <div fxLayout="column" fxLayoutGap="1rem" class="pushCoordinates">
      <radio-control-coordinates
        disabled
        class="radio-control"
        (onCoordinatesFormatChange)="onCoordinatesFormatChange($event)"
        textColor="#3B4167"
        name="objectModel.CoordinatesFormat"
        title="{{ 'PREFERENCES.COORDINATESFORMAT' | translate }}"
        [options]="coordinatesFormatOptions"
        [initial]="objectModel.CoordinatesFormat"
        [inverted]="false"
        fxLayoutGap=".5rem"
      ></radio-control-coordinates>
      <div [hidden]="objectModel?.CoordinatesFormat?.value != 'DMS'">
        <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> DD°MM'SS" [N,S]</code></span>
        <div fxLayout fxLayoutGap=".5rem">
          <div>
            <numeric-control
              disabled
              name="objectModel.DMS.Latitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.DMS.Latitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Deg']?.errors" class="form-input-error">{{
              DMSLatDegError
            }}</span>
          </div>
          <div>
            <numeric-control
              disabled
              name="objectModel.DMS.Latitude.Min"
              placeholder="{{ 'PROPERTY.MIN' | translate }}"
              [(ngModel)]="objectModel.DMS.Latitude.Min"
              unit="'"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Min']?.errors" class="form-input-error">{{
              DMSLatMinError
            }}</span>
          </div>
          <div>
            <numeric-control
              disabled
              name="objectModel.DMS.Latitude.Sec"
              placeholder="{{ 'PROPERTY.SEC' | translate }}"
              [(ngModel)]="objectModel.DMS.Latitude.Sec"
              unit="''"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Sec']?.errors" class="form-input-error">{{
              DMSLatSecError
            }}</span>
          </div>
          <text-select-control
            disabled
            name="objectModel.DMS.Latitude.Hemisphere"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="latitudeHemisphereOptions"
            [(ngModel)]="objectModel.DMS.Latitude.Hemisphere"
            [initial]="objectModel.DMS.Latitude.Hemisphere"
            style="width: 10rem"
            (click)="onClickExpandDropDown(5)"
          ></text-select-control>
        </div>
        <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> DDD°MM'SS" [W,E]</code></span>
        <div fxLayout fxLayoutGap=".5rem">
          <div>
            <numeric-control
              disabled
              name="objectModel.DMS.Longitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.DMS.Longitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Deg']?.errors" class="form-input-error">{{
              DMSLngDegError
            }}</span>
          </div>
          <div>
            <numeric-control
              disabled
              name="objectModel.DMS.Longitude.Min"
              placeholder="{{ 'PROPERTY.MIN' | translate }}"
              [(ngModel)]="objectModel.DMS.Longitude.Min"
              unit="'"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Min']?.errors" class="form-input-error">{{
              DMSLngMinError
            }}</span>
          </div>
          <div>
            <numeric-control
              disabled
              name="objectModel.DMS.Longitude.Sec"
              placeholder="{{ 'PROPERTY.SEC' | translate }}"
              [(ngModel)]="objectModel.DMS.Longitude.Sec"
              unit="''"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Sec']?.errors" class="form-input-error">{{
              DMSLngSecError
            }}</span>
          </div>
          <text-select-control
            disabled
            name="objectModel.DMS.Longitude.Hemisphere"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="longitudeHemisphereOptions"
            [(ngModel)]="objectModel.DMS.Longitude.Hemisphere"
            [initial]="objectModel.DMS.Longitude.Hemisphere"
            style="width: 10rem"
            (click)="onClickExpandDropDown(5)"
          ></text-select-control>
        </div>
      </div>
      <div [hidden]="objectModel?.CoordinatesFormat?.value != 'DM.m'">
        <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> [-]DD°MM.MMMMMM'</code></span>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap=".5rem">
          <div>
            <numeric-control
              disabled
              name="objectModel.DMm.Latitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.DMm.Latitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMm.Latitude.Deg']?.errors" class="form-input-error">{{
              DMmLatDegError
            }}</span>
          </div>
          <div>
            <numeric-control
              disabled
              name="objectModel.DMm.Latitude.Min"
              placeholder="{{ 'PROPERTY.MIN' | translate }}"
              [(ngModel)]="objectModel.DMm.Latitude.Min"
              unit="'"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMm.Latitude.Min']?.errors" class="form-input-error">{{
              DMmLatMinError
            }}</span>
          </div>
        </div>
        <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> [-]DDD°MM.MMMMMM'</code></span>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap=".5rem">
          <div>
            <numeric-control
              disabled
              name="objectModel.DMm.Longitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.DMm.Longitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMm.Longitude.Deg']?.errors" class="form-input-error">{{
              DMmLngDegError
            }}</span>
          </div>
          <div>
            <numeric-control
              disabled
              name="objectModel.DMm.Longitude.Min"
              placeholder="{{ 'PROPERTY.MIN' | translate }}"
              [(ngModel)]="objectModel.DMm.Longitude.Min"
              unit="'"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMm.Longitude.Min']?.errors" class="form-input-error">{{
              DMmLngMinError
            }}</span>
          </div>
        </div>
      </div>
      <div [hidden]="objectModel?.CoordinatesFormat?.value != 'D.dd'">
        <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> DD.MMMMMM° [N,S]</code></span>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap=".5rem">
          <div>
            <numeric-control
              disabled
              name="objectModel.Ddd.Latitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.Ddd.Latitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.Ddd.Latitude.Deg']?.errors" class="form-input-error">{{
              DddLatDegError
            }}</span>
          </div>
          <text-select-control
            disabled
            name="objectModel.Ddd.Latitude.Hemisphere"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="latitudeHemisphereOptions"
            [(ngModel)]="objectModel.Ddd.Latitude.Hemisphere"
            [initial]="objectModel.Ddd.Latitude.Hemisphere"
            style="width: 10rem"
            (click)="onClickExpandDropDown(5)"
          ></text-select-control>
        </div>
        <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> DDD.MMMMMM° [W,E]</code></span>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap=".5rem">
          <div>
            <numeric-control
              disabled
              name="objectModel.Ddd.Longitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.Ddd.Longitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.Ddd.Longitude.Deg']?.errors" class="form-input-error">{{
              DddLngDegError
            }}</span>
            <span *ngIf="checkForInputErrors()"></span>
          </div>
          <text-select-control
            disabled
            name="objectModel.Ddd.Longitude.Hemisphere"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="longitudeHemisphereOptions"
            [(ngModel)]="objectModel.Ddd.Longitude.Hemisphere"
            [initial]="objectModel.Ddd.Longitude.Hemisphere"
            style="width: 10rem"
            (click)="onClickExpandDropDown(5)"
          ></text-select-control>
        </div>
      </div>
    </div>
    <div *ngIf="isIncidentWarningOrWillBe()" fxLayout="column" fxLayoutGap="1rem">
      <span style="margin-left: 6rem">{{ 'MSA.MSAOBJECT.LINE' | translate }}</span>
      <div div fxLayout fxLayoutGap="3rem" class="pushLeft">
        <color-picker-control
          [disabled]="isIncidentWarning()"
          required
          name="objectModel.LineColor"
          title="{{ 'PROPERTY.COLOR' | translate }}"
          placeholder="{{ 'PLACEHOLDERS.HEXADECIMALCOLOR' | translate }}"
          [(ngModel)]="objectModel.LineColor"
          style="width: 40%"
        ></color-picker-control>
        <opacity-picker-control
          [disabled]="isIncidentWarning()"
          required
          [(ngModel)]="objectModel.LineOpacity"
          type="range"
          min="0"
          max="100"
          step="0"
          name="objectModel.LineOpacity"
          title="Alpha"
          [initial]="objectModel.LineOpacity"
          style="width: 40%"
        ></opacity-picker-control>
        <numeric-control
          [disabled]="isIncidentWarning()"
          required
          name="objectModel.LineWidth"
          title="{{ 'PROPERTY.WIDTH' | translate }}"
          placeholder="Width"
          [(ngModel)]="objectModel.LineWidth"
          min="0"
          max="100"
          name="objectModel.LineWidth"
          style="width: 10%"
        ></numeric-control>
      </div>
      <span *ngIf="translatedType !== 'MultiLineString'" style="margin-left: 6rem">{{
        'MSA.MSAOBJECT.FILL' | translate
      }}</span>
      <div *ngIf="translatedType !== 'MultiLineString'" fxLayout fxLayoutGap="3rem" class="pushLeft">
        <color-picker-control
          [disabled]="isIncidentWarning()"
          required
          name="objectModel.Color"
          title="{{ 'PROPERTY.COLOR' | translate }}"
          placeholder="{{ 'PLACEHOLDERS.HEXADECIMALCOLOR' | translate }}"
          [(ngModel)]="objectModel.Color"
          style="width: 40%"
        ></color-picker-control>
        <opacity-picker-control
          [disabled]="isIncidentWarning()"
          [(ngModel)]="objectModel.Opacity"
          type="range"
          min="0"
          max="100"
          step="0"
          name="objectModel.Opacity"
          title="Alpha"
          [initial]="objectModel.Opacity"
          style="width: 40%"
        ></opacity-picker-control>
      </div>
      <checkbox-control
        [disabled]="isIncidentWarning()"
        name="objectModel.LineDashed"
        title="{{ 'PROPERTY.DASHED' | translate }}"
        [(ngModel)]="objectModel.LineDashed"
      ></checkbox-control>
    </div>
    <text-select-control-icons-list
      disabled
      name="objectModel.Icon"
      title="{{ 'PROPERTY.ICON' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="iconOptions"
      [(ngModel)]="objectModel.Icon"
      [initial]="objectModel.Icon"
      (click)="onClickExpandDropDown(37)"
    ></text-select-control-icons-list>
    <multi-text-select-control
      disabled
      name="objectModel.Tags"
      title="{{ 'PROPERTY.TAGS' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSETHETAGS' | translate }}"
      [options]="tagOptions"
      [(ngModel)]="objectModel.Tags"
      (click)="onClickExpandDropDown(57)"
    ></multi-text-select-control>
    <date-select-control
      disabled
      name="objectModel.UpdatedAt"
      title="{{ 'PROPERTY.TIMEANDDATEOFUPDATE' | translate }}"
      [initial]="objectModel.UpdatedAt"
      [(ngModel)]="objectModel.UpdatedAt"
      style="min-width: 28rem"
    ></date-select-control>
    <div fxLayout="column" fxLayoutGap="1rem">
      <div fxLayout fxLayoutAlign="space-between center">
        <span>{{ 'PROPERTY.CUSTOMFIELDS' | translate }}</span>
        <button
          disabled
          class="tool"
          type="button"
          title="{{ 'BUTTON.NEWCUSTOMFIELD' | translate }}"
          inlineSVG="/assets/images/new.svg"
          (click)="onNewCustomFieldClick()"
        ></button>
      </div>
      <div
        class="property-list"
        fxLayout
        fxLayoutGap=".5rem"
        *ngFor="let property of objectModel.Properties; let i = index"
      >
        <text-control
          disabled
          [name]="'key' + i"
          title="{{ 'PROPERTY.PROPERTY' | translate }}"
          placeholder="{{ 'PROPERTY.PROPERTY' | translate }}"
          [(ngModel)]="objectModel.Properties[i].key"
        ></text-control>
        <text-control
          disabled
          [name]="'value' + i"
          title="{{ 'PROPERTY.VALUE' | translate }}"
          placeholder="{{ 'PROPERTY.VALUE' | translate }}"
          [(ngModel)]="objectModel.Properties[i].value"
        ></text-control>
        <button
          disabled
          class="tool red"
          type="button"
          (click)="onCloseCustomFieldClick(i)"
          inlineSVG="/assets/images/cross.svg"
        >
          {{ 'BUTTON.REMOVE' | translate }}
        </button>
      </div>
    </div>
  </div>
  <span *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </span>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{ 'BUTTON.SUBMIT' | translate }}</main-button>
  </div>
</form>
