import { AbstractControl, AsyncValidatorFn, Validator, Validators, ValidatorFn } from '@angular/forms';

import { of } from 'rxjs';

export type ValidationResult = { [validator: string]: any };

export type AsyncValidatorArray = Array<Validator | AsyncValidatorFn>;

export type ValidatorArray = Array<Validator | ValidatorFn>;

const normalizeValidator = (validator: Validator | ValidatorFn): ValidatorFn | AsyncValidatorFn => {
  const func = (validator as Validator).validate.bind(validator);
  if (typeof func === 'function') {
    return (c: AbstractControl) => func(c);
  } else {
    return <ValidatorFn | AsyncValidatorFn>validator;
  }
};

export const composeValidators = (validators: ValidatorArray): AsyncValidatorFn | ValidatorFn => {
  if (validators == null || validators.length === 0) {
    return null;
  }
  return Validators.compose(validators.map(normalizeValidator));
};

export const validate = (validators: ValidatorArray, asyncValidators: AsyncValidatorArray) => {
  return (control: AbstractControl) => {
    const synchronousValid = () => composeValidators(validators)(control);

    if (asyncValidators) {
      const asyncValidator = composeValidators(asyncValidators);

      return asyncValidator(control).map((v) => {
        const secondary = synchronousValid();
        if (secondary || v) {
          // compose async and sync validator results
          return Object.assign({}, secondary, v);
        }
      });
    }

    if (validators) {
      return of(synchronousValid());
    }

    return of(null);
  };
};

export const message = (validator: ValidationResult, key: string): string => {
  switch (key) {
    case 'required':
      return 'This field is required';
    case 'minlength':
      return 'The value must be greater than ' + validator[key].requiredLength + ' characters';
    case 'maxlength':
      return 'The value must be smaller than ' + validator[key].requiredLength + ' characters';
    case 'email':
      return 'The value must be a valid e-mail address';
  }
  return validator[key].message || `Validation error: ${key}`;
};
