<div class="text-control" [class.disabled]="disabled">
  <label class="label" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap=".5rem">
    <span class="title" *ngIf="title" fxFlex="0 1 auto">
      {{ title }}
    </span>
    <input
      [disabled]="disabled"
      [type]="type || 'text'"
      [placeholder]="disabled ? '' : placeholder"
      [(ngModel)]="value"
      [ngClass]="{ invalid: (invalid | async) }"
      #input
      [autocomplete]="autocomplete"
    />
  </label>
  <validation [messages]="failures | async" *ngIf="modelTouched && invalid | async"></validation>
</div>
