import { ExtendedData, ExtendedDataOptions, VesselConfigType, VesselExtendedData } from '@yaris/core/domain';
import { MSAObjectMap } from '@yaris/msa/msa.service';

export default class ExtendedDataUtil {
  static get fieldToSection() {
    return {
      Classification: 'ClassificationSection',
      VOI: 'ClassificationSection',
      Comments: 'GeneralSection',
      Human: 'GeneralSection',
      AttachedObjects: 'AttachedObjectsSection',
    };
  }

  static getVesselExtendedDataByType(
    extendedData: ExtendedData,
    vesselConfigType: VesselConfigType,
  ): VesselExtendedData {
    if (vesselConfigType === VesselConfigType.Center) return extendedData[vesselConfigType].Local;

    return extendedData[vesselConfigType];
  }

  static getFieldSource(extendedDataOptions: ExtendedDataOptions, field: string): VesselConfigType {
    if (this.fieldToSection[field] === undefined) return null;

    return extendedDataOptions?.ShowInMap?.[this.fieldToSection[field]] || null;
  }

  static mergeMsaObjectWithNotification(
    actualMsaObject: MSAObjectMap,
    msaObjectNotification: MSAObjectMap,
  ): MSAObjectMap {
    if (msaObjectNotification.NeedUpdateExtendedData) {
      return msaObjectNotification;
    }

    const merged = {
      ...msaObjectNotification,
    };

    if (actualMsaObject.Properties) {
      merged.Properties = merged.Properties || {};
      merged.Properties.Classification = actualMsaObject.Properties?.Classification;
      merged.Properties.VOI = actualMsaObject.Properties?.VOI;
      merged.Properties.Comments = actualMsaObject.Properties?.Comments;
    }
    merged.Human = actualMsaObject.Human;
    merged.AttachedObjects = actualMsaObject.AttachedObjects;

    merged.ExtendedData = actualMsaObject.ExtendedData;
    merged.ExtendedDataOptions = actualMsaObject.ExtendedDataOptions;

    return merged;
  }
}
