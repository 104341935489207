<div class="opacity-picker-control" [class.disabled]="disabled">
  <label class="label" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap=".5rem">
    <span class="title" *ngIf="title" fxFlex="0 1 auto">
      {{ title }}
    </span>
    <div style="display: flex; width: 100%">
      <input
        [disabled]="disabled"
        [placeholder]="disabled ? '' : placeholder"
        [(ngModel)]="value"
        [ngClass]="{ invalid: (invalid | async) }"
        #input
        type="range"
        min="0"
        max="100"
        step="0"
        style="width: 100%"
      />
      <span>{{ value }}%</span>
    </div>
  </label>
  <validation [messages]="failures | async" *ngIf="modelTouched && invalid | async"></validation>
</div>
