import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';

import { NgModel, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ElementBase } from '../element-base';

@Component({
  selector: 'text-input-dropdown-control',
  templateUrl: './text-input-dropdown.component.html',
  styleUrls: ['./text-input-dropdown.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextInputDropdownControlComponent,
      multi: true,
    },
  ],
})
export class TextInputDropdownControlComponent extends ElementBase<any> implements OnInit, OnChanges, AfterViewInit {
  @Input() title: string;
  @Input() placeholder: string = '';
  @Input() value: string;
  @Input() options: { label: string; value: any }[];
  @Input() initial: { label: string; value: any };
  @Input() focus = false;
  @Input() disabled = false;
  @Input() helperText: string;
  dropdown = false;
  dropdownPos = { top: 0, left: 0 };
  searchVal: string;
  height: number = 28;
  viewOptions: { label: string; value: any }[];
  private readonly inputHeight = 26;

  @ViewChild('searchInput') searchInput: NgModel;
  @ViewChild('searchInput', { read: ElementRef }) searchInputRef: ElementRef;
  @ViewChild('realValue', { read: NgModel }) model: NgModel;

  constructor(
    private cdRef: ChangeDetectorRef,
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  ngOnInit() {
    if (this.focus) {
      this.dropdown = true;
    }
    if (this.initial) {
      this.value = this.initial.value;
      this.searchVal = this.initial.label;
    }
  }

  ngOnChanges(changes) {
    if (!changes) {
      return;
    }
    if (changes?.initial?.currentValue === null) {
      this.clearValues();
    }
    if (changes.options) {
      this.viewOptions = changes.options.currentValue;
    }
  }

  ngAfterViewInit() {
    if (this.focus) {
      setTimeout((_) => this.searchInputRef.nativeElement.focus());
    }
    setTimeout((_) => this.updateDropdwonPos());
    this.cdRef.detectChanges();
  }

  onInputClick() {
    this.dropdown = !this.dropdown;
    if (this.dropdown) {
      this.updateDropdwonPos();
    }
  }

  onOptionClick(option) {
    this.value = option.value;
    this.searchVal = option.label;
    this.viewOptions = this.options;
    this.dropdown = false;
  }

  onSearchValChange(val: string) {
    this.dropdown = true;
    this.updateDropdwonPos();
    this.value = val;
  }

  isActive(option): boolean {
    return option === this.value;
  }

  clearValues() {
    this.value = null;
    this.searchVal = null;
    this.viewOptions = this.options;
  }

  onSearchKeyup(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.clearValues();
    }
  }

  get modelTouched() {
    if (!this.searchInput) {
      return false;
    }
    return this.searchInput.touched;
  }

  private updateDropdwonPos() {
    this.dropdownPos = {
      top: this.searchInputRef.nativeElement.getBoundingClientRect().top + this.inputHeight,
      left: this.searchInputRef.nativeElement.getBoundingClientRect().left,
    };
  }
}
