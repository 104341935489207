import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unit',
})
export class UnitPipe implements PipeTransform {
  transform(value: string, args?: any): string {
    return value.replace(/([1-9]+)/g, '$1'.sup());
  }
}
