import { Component, Optional, Inject, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { ElementBase } from '../element-base';

@Component({
  selector: 'text-area-control',
  templateUrl: './text-area-control.component.html',
  styleUrls: ['./text-area-control.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextAreaControlComponent,
      multi: true,
    },
  ],
})
export class TextAreaControlComponent extends ElementBase<string> implements AfterViewInit {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() value: string = '';
  @Input() focus = false;
  @Input() disabled = false;
  @Input() rows = 5;

  @ViewChild(NgModel) model: NgModel;
  @ViewChild('input') input: ElementRef;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
  ) {
    super(validators, asyncValidators);
  }

  ngAfterViewInit() {
    if (this.focus) {
      this.input.nativeElement.focus();
    }
  }
}
