<form
  class="msa-edit-object yaris-form-reduced"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
  style="width: 40rem !important"
>
  <div fxLayout fxLayoutGap=".5rem">
    <h4>{{ 'PROPERTY.ORIGIN' | translate }}:</h4>
    <span
      >{{ layer?.Name }} @
      {{ msaObject?.Properties?.PositionUtc || msaObject.UpdatedAt | date : 'yyyy-MM-dd HH:mm:ss' }}</span
    >
  </div>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <text-select-control
      required
      title="{{ 'PROPERTY.FONT_SIZE' | translate }}"
      name="objectModel.FontSize"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="fontSizeOptions"
      [(ngModel)]="objectModel.FontSize"
      [initial]="objectModel.FontSize"
      (click)="onClickExpandDropDown(37)"
    ></text-select-control>

    <text-control
      required
      name="objectModel.Label"
      title="{{ 'PROPERTY.TEXT' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.ENTER_THE_TEXT' | translate }}"
      [(ngModel)]="objectModel.Label"
    ></text-control>

    <color-picker-control
      required
      name="objectModel.Color"
      title="{{ 'PROPERTY.COLOR' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.HEXADECIMALCOLOR' | translate }}"
      [(ngModel)]="objectModel.Color"
      [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
    ></color-picker-control>

    <div fxLayout="column" fxLayoutGap="1rem">
      <multi-text-select-control
        name="objectModel.AttachedObjects"
        title="{{ 'PROPERTY.ATTACHEDOBJECTS' | translate }}"
        placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
        [options]="attachedObjectsOptions"
        [(ngModel)]="objectModel.AttachedObjects"
        [initial]="objectModel.AttachedObjects"
        (click)="onClickExpandDropDown(37)"
      ></multi-text-select-control>
    </div>

    <radio-control-coordinates
      required
      class="radio-control"
      (onCoordinatesFormatChange)="onCoordinatesFormatChange($event)"
      textColor="#3B4167"
      name="objectModel.CoordinatesFormat"
      title="{{ 'PREFERENCES.COORDINATESFORMAT' | translate }}"
      [options]="coordinatesFormatOptions"
      [initial]="objectModel.CoordinatesFormat"
      [inverted]="false"
      fxLayoutGap=".5rem"
    ></radio-control-coordinates>

    <div [hidden]="objectModel?.CoordinatesFormat?.value != 'DMS'">
      <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> DD°MM'SS" [N,S]</code></span>
      <div fxLayout fxLayoutGap=".5rem">
        <div>
          <numeric-control
            [required]="isRequired('DMS')"
            name="objectModel.DMS.Latitude.Deg"
            placeholder="{{ 'PROPERTY.DEG' | translate }}"
            [(ngModel)]="objectModel.DMS.Latitude.Deg"
            unit="º"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Deg']?.errors" class="form-input-error">{{
            DMSLatDegError
          }}</span>
        </div>
        <div>
          <numeric-control
            [required]="isRequired('DMS')"
            name="objectModel.DMS.Latitude.Min"
            placeholder="{{ 'PROPERTY.MIN' | translate }}"
            [(ngModel)]="objectModel.DMS.Latitude.Min"
            unit="'"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Min']?.errors" class="form-input-error">{{
            DMSLatMinError
          }}</span>
        </div>
        <div>
          <numeric-control
            [required]="isRequired('DMS')"
            name="objectModel.DMS.Latitude.Sec"
            placeholder="{{ 'PROPERTY.SEC' | translate }}"
            [(ngModel)]="objectModel.DMS.Latitude.Sec"
            unit="''"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Sec']?.errors" class="form-input-error">{{
            DMSLatSecError
          }}</span>
        </div>
        <text-select-control
          [required]="isRequired('DMS')"
          name="objectModel.DMS.Latitude.Hemisphere"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="latitudeHemisphereOptions"
          [(ngModel)]="objectModel.DMS.Latitude.Hemisphere"
          [initial]="objectModel.DMS.Latitude.Hemisphere"
          style="width: 10rem"
          (click)="onClickExpandDropDown(5)"
          [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
        ></text-select-control>
      </div>
      <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> DDD°MM'SS" [W,E]</code></span>
      <div fxLayout fxLayoutGap=".5rem">
        <div>
          <numeric-control
            [required]="isRequired('DMS')"
            name="objectModel.DMS.Longitude.Deg"
            placeholder="{{ 'PROPERTY.DEG' | translate }}"
            [(ngModel)]="objectModel.DMS.Longitude.Deg"
            unit="º"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Deg']?.errors" class="form-input-error">{{
            DMSLngDegError
          }}</span>
        </div>
        <div>
          <numeric-control
            [required]="isRequired('DMS')"
            name="objectModel.DMS.Longitude.Min"
            placeholder="{{ 'PROPERTY.MIN' | translate }}"
            [(ngModel)]="objectModel.DMS.Longitude.Min"
            unit="'"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Min']?.errors" class="form-input-error">{{
            DMSLngMinError
          }}</span>
        </div>
        <div>
          <numeric-control
            [required]="isRequired('DMS')"
            name="objectModel.DMS.Longitude.Sec"
            placeholder="{{ 'PROPERTY.SEC' | translate }}"
            [(ngModel)]="objectModel.DMS.Longitude.Sec"
            unit="''"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Sec']?.errors" class="form-input-error">{{
            DMSLngSecError
          }}</span>
        </div>
        <text-select-control
          [required]="isRequired('DMS')"
          name="objectModel.DMS.Longitude.Hemisphere"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="longitudeHemisphereOptions"
          [(ngModel)]="objectModel.DMS.Longitude.Hemisphere"
          [initial]="objectModel.DMS.Longitude.Hemisphere"
          style="width: 10rem"
          (click)="onClickExpandDropDown(5)"
          [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
        ></text-select-control>
      </div>
    </div>
    <div [hidden]="objectModel?.CoordinatesFormat?.value != 'DM.m'">
      <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> [-]DD°MM.MMMMMM'</code></span>
      <div fxLayout fxLayoutGap=".5rem">
        <div>
          <numeric-control
            [required]="isRequired('DM.m')"
            name="objectModel.DMm.Latitude.Deg"
            placeholder="{{ 'PROPERTY.DEG' | translate }}"
            [(ngModel)]="objectModel.DMm.Latitude.Deg"
            unit="º"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMm.Latitude.Deg']?.errors" class="form-input-error">{{
            DMmLatDegError
          }}</span>
        </div>
        <div>
          <numeric-control
            [required]="isRequired('DM.m')"
            name="objectModel.DMm.Latitude.Min"
            placeholder="{{ 'PROPERTY.MIN' | translate }}"
            [(ngModel)]="objectModel.DMm.Latitude.Min"
            unit="'"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMm.Latitude.Min']?.errors" class="form-input-error">{{
            DMmLatMinError
          }}</span>
        </div>
      </div>
      <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> [-]DDD°MM.MMMMMM'</code></span>
      <div fxLayout fxLayoutGap=".5rem">
        <div>
          <numeric-control
            [required]="isRequired('DM.m')"
            name="objectModel.DMm.Longitude.Deg"
            placeholder="{{ 'PROPERTY.DEG' | translate }}"
            [(ngModel)]="objectModel.DMm.Longitude.Deg"
            unit="º"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMm.Longitude.Deg']?.errors" class="form-input-error">{{
            DMmLngDegError
          }}</span>
        </div>
        <div>
          <numeric-control
            [required]="isRequired('DM.m')"
            name="objectModel.DMm.Longitude.Min"
            placeholder="{{ 'PROPERTY.MIN' | translate }}"
            [(ngModel)]="objectModel.DMm.Longitude.Min"
            unit="'"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.DMm.Longitude.Min']?.errors" class="form-input-error">{{
            DMmLngMinError
          }}</span>
        </div>
      </div>
    </div>
    <div [hidden]="objectModel?.CoordinatesFormat?.value != 'D.dd'">
      <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> DD.MMMMMM° [N,S]</code></span>
      <div fxLayout="row" style="justify-content: space-evenly" fxLayoutGap=".5rem">
        <div>
          <numeric-control
            [required]="isRequired('D.dd')"
            name="objectModel.Ddd.Latitude.Deg"
            placeholder="{{ 'PROPERTY.DEG' | translate }}"
            [(ngModel)]="objectModel.Ddd.Latitude.Deg"
            unit="º"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.Ddd.Latitude.Deg']?.errors" class="form-input-error">{{
            DddLatDegError
          }}</span>
        </div>
        <text-select-control
          [required]="isRequired('D.dd')"
          name="objectModel.Ddd.Latitude.Hemisphere"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="latitudeHemisphereOptions"
          [(ngModel)]="objectModel.Ddd.Latitude.Hemisphere"
          [initial]="objectModel.Ddd.Latitude.Hemisphere"
          style="width: 10rem"
          (click)="onClickExpandDropDown(5)"
          [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
        ></text-select-control>
      </div>
      <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> DDD.MMMMMM° [W,E]</code></span>
      <div fxLayout="row" style="justify-content: space-evenly" fxLayoutGap=".5rem">
        <div>
          <numeric-control
            [required]="isRequired('D.dd')"
            name="objectModel.Ddd.Longitude.Deg"
            placeholder="{{ 'PROPERTY.DEG' | translate }}"
            [(ngModel)]="objectModel.Ddd.Longitude.Deg"
            unit="º"
            [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
          ></numeric-control>
          <span *ngIf="this.form?.controls['objectModel.Ddd.Longitude.Deg']?.errors" class="form-input-error">{{
            DddLngDegError
          }}</span>
        </div>
        <text-select-control
          [required]="isRequired('D.dd')"
          name="objectModel.Ddd.Longitude.Hemisphere"
          placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
          [options]="longitudeHemisphereOptions"
          [(ngModel)]="objectModel.Ddd.Longitude.Hemisphere"
          [initial]="objectModel.Ddd.Longitude.Hemisphere"
          style="width: 10rem"
          (click)="onClickExpandDropDown(5)"
          [disabled]="msaObject.Properties.Provider !== 'HUMAN'"
        ></text-select-control>
      </div>
    </div>
  </div>
  <span *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </span>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{ 'BUTTON.SUBMIT' | translate }}</main-button>
  </div>
</form>
