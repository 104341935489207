import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unread-counter',
  templateUrl: './unread-counter.component.html',
  styleUrls: ['./unread-counter.component.sass'],
})
export class UnreadCounterComponent implements OnInit {
  @Input() inactiveIcon: string;
  @Input() activeIcon: string;
  @Input() counter: number;
  @Input() showCounter: boolean = true;

  ngOnInit(): void {}
}
