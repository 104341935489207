import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '@yaris/core/data.service';
import {
  CoordinatesFormat,
  ExtendedData,
  ExtendedDataOptions,
  Layer,
  MSAObject,
  VesselConfigType,
  VesselExtendedData,
} from '@yaris/core/domain';
import { PermissionsService } from '@yaris/core/permissions.service';
import { CoordinateService } from '@yaris/msa/mapbox/services/coordinate.service';
import { MSAObjectMap, MsaService } from '@yaris/msa/msa.service';
import ExtendedDataUtil from '@yaris/shared/utils/ExtendedDataUtil';
import flatpickr from 'flatpickr';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import * as _ from 'lodash';
import { isNil } from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-msa-edit-object',
  templateUrl: './msa-edit-object.component.html',
  styleUrls: ['./msa-edit-object.component.sass'],
})
export class MsaEditObjectComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  msaObject: MSAObject;
  layer: Layer;
  formError = '';
  errorcontrolObj = {
    'objectModel.SOG': '',
    'objectModel.COG': '',
    'objectModel.DMS.Longitude.Deg': '',
    'objectModel.DMS.Longitude.Min': '',
    'objectModel.DMS.Longitude.Sec': '',
    'objectModel.DMS.Latitude.Deg': '',
    'objectModel.DMS.Latitude.Min': '',
    'objectModel.DMS.Latitude.Sec': '',
    'objectModel.DMm.Longitude.Deg': '',
    'objectModel.DMm.Longitude.Min': '',
    'objectModel.DMm.Latitude.Deg': '',
    'objectModel.DMm.Latitude.Min': '',
    'objectModel.Ddd.Longitude.Deg': '',
    'objectModel.Ddd.Latitude.Deg': '',
  };
  objectModel: {
    Name: string;
    PositionUtc: string;
    ReferenceUtc: string;
    Color: string;
    Icon: { label: string; value: string };
    DMS: {
      Longitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    DMm: {
      Longitude: {
        Deg: number;
        Min: number;
      };
      Latitude: {
        Deg: number;
        Min: number;
      };
    };
    Ddd: {
      Longitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    SOG: number;
    COG: number;
    Properties: {
      key: string;
      value: string;
    }[];
    Human: {
      [configType: string]: {
        key: string;
        value: string;
      }[];
    };
    IMONumber: string;
    MMSI: string;
    CallSign: string;
    ShipType: { label: string; value: string };
    Flag: { label: string; value: string };
    PortOfOrigin: { label: string; value: string; PortLOCODE?: string };
    PortOfOriginATD: string;
    Destination: { label: string; value: string; PortLOCODE?: string };
    DestinationETA: string;
    PortOfRegistry: { label: string; value: string };
    Cargo: { label: string; value: string }[];
    Owner: string;
    Comments: string;
    Dynamic: boolean;
    CoordinatesFormat: { label: string; value: CoordinatesFormat };
  } = {
    Name: undefined,
    SOG: undefined,
    COG: undefined,
    PositionUtc: undefined,
    ReferenceUtc: undefined,
    Color: undefined,
    Icon: undefined,
    IMONumber: undefined,
    MMSI: undefined,
    CallSign: undefined,
    ShipType: undefined,
    Flag: undefined,
    PortOfOrigin: undefined,
    PortOfOriginATD: undefined,
    Destination: undefined,
    DestinationETA: undefined,
    PortOfRegistry: undefined,
    Cargo: undefined,
    Owner: undefined,
    Comments: undefined,
    Dynamic: false,
    DMS: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
    },
    DMm: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
      },
    },
    Ddd: {
      Longitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
    },
    Properties: [],
    Human: {
      [VesselConfigType.Center]: [],
      [VesselConfigType.Situation]: [],
      [VesselConfigType.User]: [],
      [VesselConfigType.Global]: [],
    },
    CoordinatesFormat: { label: undefined, value: undefined },
  };

  extendedDataOptions: ExtendedDataOptions = {
    ShowInMap: {
      AttachedObjectsSection: VesselConfigType.Situation,
      ClassificationSection: VesselConfigType.Situation,
      GeneralSection: VesselConfigType.Situation,
    },
  };

  defaultVesselDataSecions = () => ({ GeneralSection: {}, AttachedObjectsSection: {}, ClassificationSection: {} });
  extendedData: ExtendedData = {
    Center: { Local: { ...this.defaultVesselDataSecions() } },
    Situation: { ...this.defaultVesselDataSecions() },
    User: { ...this.defaultVesselDataSecions() },
    Global: { ...this.defaultVesselDataSecions() },
  };

  longitudeHemisphereOptions: { label: string; value: 'E' | 'W' }[] = [];
  latitudeHemisphereOptions: { label: string; value: 'N' | 'S' }[] = [];
  subscribedToFormChanges = false;
  iconOptions: { label: string; value: string }[] = [];
  classificationOptions: { label: string; value: string }[] = [];
  vesselTypeOptions: { label: string; value: string }[] = [];
  flagOptions: { label: string; value: string }[] = [];
  portOfOriginOptions: { label: string; value: string; PortLOCODE: string }[] = [];
  portOfDestinationnOptions: { label: string; value: string; PortLOCODE: string }[] = [];
  portOfRegistryOptions: { label: string; value: string }[] = [];
  cargoOptions: { label: string; value: string }[] = [];
  vesselDataOptions: { label: string; value: string }[] = [];
  vesselDataAttachedObjectsSectionOptions: { label: string; value: string }[] = [];
  coordinatesFormatOptions: { label: string; value: CoordinatesFormat }[] = [];

  voiOptions: { label: string; value: string }[] = [];
  private cargoSplitter: string = ' ;;; ';
  attachedObjectsOptions: { label: string; value: string }[] = [];
  private ngUnsubscribe = new Subject<void>();
  private readonly configTypesForAttachedObjectSection = [VesselConfigType.Situation, VesselConfigType.Global];
  private readonly hiddenProperties = [
    'Name',
    'PositionUtc',
    'ReferenceUtc',
    'COG',
    'SOG',
    'Provider',
    'UUID',
    'VesselType',
    'Flag',
    'PortOfOrigin',
    'Destination',
    'PortOfRegistry',
    'Cargo',
    'Classification',
    'IMONumber',
    'CallSign',
    'MMSI',
    'PortOfOriginATD',
    'DestinationETA',
    'VOI',
    'Owner',
    'Comments',
    'ShipType',
    'Destination',
    'DestinationETA',
    'Dynamic',
  ];
  private readonly props = [
    'Name',
    'SOG',
    'COG',
    'PositionUtc',
    'IMONumber',
    'MMSI',
    'CallSign',
    'Classification',
    'ShipType',
    'Flag',
    'PortOfOrigin',
    'PortOfOriginATD',
    'Destination',
    'DestinationETA',
    'PortOfRegistry',
    'Cargo',
    'VOI',
    'Owner',
    'Comments',
    'Dynamic',
  ];

  constructor(
    private injector: Injector,
    private dataService: DataService,
    private translateService: TranslateService,
    private permissionsService: PermissionsService,
    private msaService: MsaService,
    private coordinateService: CoordinateService,
  ) { }

  ngOnInit(): void {
    this.msaObject = this.injector.get('msaObject');
    this.layer = this.injector.get('layer');

    const layerId = this.msaObject.Layer_id || (this.msaObject as unknown as MSAObjectMap)?.layerIds?.[0] || '';
    this.dataService
      .getMSAObject(this.msaObject._id, layerId, this.msaService.getSituation()._id)
      .subscribe((msaObject) => {
        this.msaObject = msaObject;
        this.extendedDataOptions = _.merge(this.extendedDataOptions, this.msaObject.ExtendedDataOptions);
        this.extendedData = _.merge(this.extendedData, this.msaObject.ExtendedData);

        this.initInputs();
      });
  }

  private parseAttachedObjetsFromExtendedDataToOptions() {
    if (this.extendedData) {
      this.configTypesForAttachedObjectSection.forEach((configType) => {
        const attachedObjectsSection = this.getVesselExtendedDataByType(configType).AttachedObjectsSection;

        if (attachedObjectsSection.AttachedObjects) {
          attachedObjectsSection.AttachedObjects = this.attachedObjectsOptions.filter((obj) =>
            this.msaObject.AttachedObjects?.includes(obj.value),
          );

          this.attachedObjectsOptions = this.attachedObjectsOptions.filter((option) => {
            const foundByLabel = attachedObjectsSection.AttachedObjects.find((a) => a.label === option.label);
            return !foundByLabel || (foundByLabel && foundByLabel.value === option.value);
          });
        }
      });
    }
  }

  private clearHumanProperties() {
    if (this.msaObject.Human)
      Object.keys(this.msaObject.Human).map((prop) => {
        if (
          this.msaObject.Properties[prop] !== undefined &&
          this.msaObject.Properties[prop] !== this.msaObject.Human[prop]
        ) {
          delete this.msaObject.Human[prop];
        }
      });
    else this.msaObject.Human = {};
  }

  private initializeOptionsSync() {
    this.longitudeHemisphereOptions = [
      { label: 'E', value: 'E' },
      { label: 'W', value: 'W' },
    ];
    this.latitudeHemisphereOptions = [
      { label: 'N', value: 'N' },
      { label: 'S', value: 'S' },
    ];

    this.coordinatesFormatOptions = Object.keys(CoordinatesFormat).map((k) => ({
      label: this.translateService.instant('PREFERENCES.COORDINATES.' + k.toUpperCase()),
      value: CoordinatesFormat[k],
    }));

    this.objectModel.CoordinatesFormat = this.coordinatesFormatOptions.find(
      (o) => o.value === this.permissionsService.getUser().Preferences.CoordinatesFormat,
    );

    this.msaService
      .listMSAObjects()
      .filter(
        (obj) =>
          obj.Geometry.Type !== 'Point' &&
          !this.msaService.isPort(obj) &&
          (obj.MSAObject_id === undefined || obj.MSAObject_id === this.msaObject._id) &&
          obj.layerIds.some((layerId) => this.msaService.getSituation().Layers_ids.includes(layerId)),
      )
      .map((obj) => {
        this.attachedObjectsOptions.push({ label: obj.Properties?.Name, value: obj._id });
      });
    this.attachedObjectsOptions.sort(this.compare);
    this.classificationOptions = [
      { label: 'Friendly', value: 'Friendly' },
      { label: 'Suspect', value: 'Suspect' },
      { label: 'Neutral', value: 'Neutral' },
      { label: 'Under Evaluation', value: 'Under Evaluation' },
      { label: 'Hostile', value: 'Hostile' },
    ];
    this.voiOptions = [
      { label: '0', value: '0' },
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
    ];
  }

  initInputs(): void {
    this.clearHumanProperties();
    this.initializeOptionsSync();
    this.parseAttachedObjetsFromExtendedDataToOptions();

    this.dataService.getDefaultParams().subscribe((params) => {
      const vesselConfigAdminOptions = params?.ShowInMapAvailableOptions?.length
        ? params.ShowInMapAvailableOptions
        : [VesselConfigType.Situation];
      const vesselDataOptionsInOrder = [
        VesselConfigType.Global,
        VesselConfigType.Situation,
        VesselConfigType.Center,
        VesselConfigType.User,
      ];

      this.vesselDataOptions = Object.keys(VesselConfigType)
        .filter((configType) => vesselConfigAdminOptions.includes(VesselConfigType[configType]))
        .sort(
          (a, b) =>
            vesselDataOptionsInOrder.indexOf(VesselConfigType[a]) -
            vesselDataOptionsInOrder.indexOf(VesselConfigType[b]),
        )
        .map((configType) => ({
          label: this.translateService.instant('ENUM.VESSELDATATYPE.' + configType.toUpperCase()),
          value: configType,
        }));

      this.vesselDataAttachedObjectsSectionOptions = this.configTypesForAttachedObjectSection
        .filter((configType) => vesselConfigAdminOptions.includes(VesselConfigType[configType]))
        .sort(
          (a, b) =>
            vesselDataOptionsInOrder.indexOf(VesselConfigType[a]) -
            vesselDataOptionsInOrder.indexOf(VesselConfigType[b]),
        )
        .map((configType) => ({
          label: this.translateService.instant('ENUM.VESSELDATATYPE.' + configType.toUpperCase()),
          value: configType,
        }));
    });

    this.dataService
      .listMsaObjectsDefaultProperties('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((properties) => {
        this.vesselTypeOptions = properties
          .filter((prop) => prop.Type === 'VesselType')
          .map((prop) => ({ label: prop.Value, value: prop._id }));
        this.vesselTypeOptions.sort(this.compare);
        this.flagOptions = properties
          .filter((prop) => prop.Type === 'Flag')
          .map((prop) => ({ label: prop.Value, value: prop._id }));
        this.flagOptions.sort(this.compare);
        this.portOfOriginOptions = properties
          .filter((prop) => prop.Type === 'Port')
          .map((prop) => ({ label: prop.Value, value: prop._id, PortLOCODE: prop.PortLOCODE }));
        this.portOfOriginOptions.sort(this.compare);
        this.portOfDestinationnOptions = properties
          .filter((prop) => prop.Type === 'Port')
          .map((prop) => ({ label: prop.Value, value: prop._id, PortLOCODE: prop.PortLOCODE }));
        this.portOfDestinationnOptions.sort(this.compare);
        this.portOfRegistryOptions = properties
          .filter((prop) => prop.Type === 'Port')
          .map((prop) => ({ label: prop.Value, value: prop._id }));
        this.portOfRegistryOptions.sort(this.compare);
        this.cargoOptions = properties
          .filter((prop) => prop.Type === 'Cargo')
          .map((prop) => ({ label: prop.Value, value: prop._id }));
        this.cargoOptions.sort(this.compare);

        this.setObjectModelProperty(
          'ShipType',
          ['Vessel Type', 'ShipType'],
          this.vesselTypeOptions.find(
            (a) =>
              a.label === this.msaObject.Properties['Vessel Type'] || a.label === this.msaObject.Properties['ShipType'],
          ),
        );
        this.setObjectModelProperty(
          'Flag',
          ['Flag'],
          this.flagOptions.find((a) => a.label === this.msaObject.Properties.Flag),
        );
        this.setObjectModelProperty(
          'PortOfOrigin',
          ['PortOfOrigin'],
          this.portOfOriginOptions.find((a) => a.label === this.msaObject.Properties['PortOfOrigin']),
        );
        this.setObjectModelProperty(
          'PortOfRegistry',
          ['PortOfRegistry'],
          this.portOfRegistryOptions.find((a) => a.label === this.msaObject.Properties['PortOfRegistry']),
        );
        this.setObjectModelProperty(
          'Destination',
          ['Destination', 'Destination'],
          this.portOfDestinationnOptions.find((a) => a.label === this.msaObject.Properties['Destination']),
        );
        this.setObjectModelProperty(
          'Cargo',
          ['Cargo'],
          this.cargoOptions.filter((a) =>
            this.msaObject.Properties.Cargo?.includes(this.cargoSplitter)
              ? this.msaObject.Properties.Cargo?.split(this.cargoSplitter).includes(a.label)
              : a.label === this.msaObject.Properties.Cargo,
          ),
        );
      });

    this.dataService
      .listMsaIcons('true')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((icons) => {
        this.iconOptions = this.iconOptions.concat(icons.map((i) => ({ label: i.Name, value: i.IconURL })));
        this.objectModel.Icon = this.iconOptions.filter((icon) => icon.value === this.msaObject.Icon)[0];
        this.iconOptions.sort(this.compare);
      });

    this.objectModel.Name = this.msaObject.Properties.Name;

    this.objectModel.Dynamic = this.msaObject.Properties?.Dynamic === 'true';
    if (this.objectModel.Dynamic) {
      this.objectModel.PositionUtc = this.msaObject.Properties.PositionUtc;
    } else {
      this.objectModel.PositionUtc = new Date().toISOString();
    }
    this.objectModel.ReferenceUtc = this.msaObject.Properties.ReferenceUtc;

    this.objectModel.Color = this.msaObject.Color;
    this.objectModel.IMONumber = this.msaObject.Properties.IMONumber;
    this.objectModel.MMSI = this.msaObject.Properties.MMSI;
    this.objectModel.CallSign = this.msaObject.Properties.CallSign;
    this.objectModel.PortOfOriginATD = this.msaObject.Properties['Port Of Origin ATD'];
    this.objectModel.DestinationETA =
      this.msaObject.Properties['Port Of Destination ETA'] || this.msaObject.Properties['DestinationETA'];
    this.objectModel.Owner = this.msaObject.Properties.Owner;
    this.objectModel.Comments = this.msaObject.Properties.Comments;

    if (!isNil(this.msaObject.Properties.COG)) {
      this.objectModel.COG = Number(this.msaObject.Properties.COG);
    }
    if (!isNil(this.msaObject.Properties.SOG)) {
      this.objectModel.SOG = Number(this.msaObject.Properties.SOG);
    }

    const [lngDMS, latDMS] = this.coordinateService.DDToDMS(this.msaObject.Geometry.Coordinates);
    const [lngDMm, latDMm] = this.coordinateService.DDToDMm(this.msaObject.Geometry.Coordinates);
    const [lngDdd, latDdd] = this.coordinateService.DDToDdd(this.msaObject.Geometry.Coordinates);

    this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
    this.objectModel.DMS.Longitude.Min = lngDMS.min;
    this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
    this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDMS.hemisphere,
    );
    this.objectModel.DMS.Latitude.Deg = latDMS.deg;
    this.objectModel.DMS.Latitude.Min = latDMS.min;
    this.objectModel.DMS.Latitude.Sec = latDMS.sec;
    this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDMS.hemisphere,
    );
    this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
    this.objectModel.DMm.Longitude.Min = lngDMm.min;
    this.objectModel.DMm.Latitude.Deg = latDMm.deg;
    this.objectModel.DMm.Latitude.Min = latDMm.min;
    this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
    this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDdd.hemisphere,
    );
    this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
    this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDdd.hemisphere,
    );

    if (this.msaObject.Properties) {
      this.objectModel.Properties = Object.entries(this.msaObject.Properties)
        .filter(([k, v]) => !this.hiddenProperties.includes(k.replace(/\s/g, '')))
        .map(([k, v]) => ({ key: k, value: v }));
    }
    if (this.extendedData) {
      for (const configType of Object.keys(VesselConfigType)) {
        const humanFromConfig =
          this.getVesselExtendedDataByType(VesselConfigType[configType]).GeneralSection.Human || {};
        this.objectModel.Human[configType] = Object.entries(humanFromConfig)
          .filter(([k, v]) => !this.hiddenProperties.includes(k))
          .map(([k, v]) => ({ key: k, value: v as string }));
      }
    }
  }

  getSelectHumanModel() {
    return this.objectModel.Human[this.extendedDataOptions.ShowInMap.GeneralSection];
  }

  getSelectHumanFromOriginalMsaObject() {
    return this.getVesselExtendedDataByType(
      this.extendedDataOptions.ShowInMap.GeneralSection,
      this.msaObject.ExtendedData,
    ).GeneralSection?.Human;
  }

  getVesselExtendedDataByType(vesselConfigType: VesselConfigType, extendedData?: ExtendedData): VesselExtendedData {
    return ExtendedDataUtil.getVesselExtendedDataByType(extendedData ?? this.extendedData, vesselConfigType);
  }

  /**
   * Set given the given option to objectModel and apply a fallback value if option don't exists.
   * @param objectModelProperty The property to be defined on objectModel.
   * @param msaObjectProperty The fallback value from msaObject.
   * @param value The option.
   */
  private setObjectModelProperty(
    objectModelProperty: string,
    msaObjectProperty: string[],
    option: { label: string; value: string } | { label: string; value: string }[],
  ) {
    if (option) {
      this.objectModel[objectModelProperty] = option;
    } else {
      const newOption = msaObjectProperty.find((prop) => this.msaObject.Properties[prop] !== undefined);
      if (newOption)
        this.objectModel[objectModelProperty] = { value: newOption, label: this.msaObject.Properties[newOption] };
    }
  }

  private openCustomHistoryStartDatePicker(targetEl: HTMLElement): Observable<Date> {
    const date$ = new Subject<Date>();
    const offset = new Date().getTimezoneOffset();
    const pickerFp = flatpickr(targetEl, {
      enableTime: true,
      time_24hr: true,
      clickOpens: false,
      defaultDate: moment.utc(Date.now()).toDate(),
      plugins: [confirmDatePlugin({ showAlways: true, confirmIcon: '' })],
      dateFormat: 'Z',
      onClose: () => {
        if (pickerFp.selectedDates.length === 0) {
          return;
        }
        const date = moment(pickerFp.selectedDates[0]).subtract(offset, 'm').toDate();
        date$.next(date);
        date$.complete();
        setTimeout((_) => pickerFp.destroy());
      },
    });
    pickerFp.open();
    return date$.asObservable();
  }

  openDatePicker(event, type?: string) {
    const el = event.currentTarget;

    this.openCustomHistoryStartDatePicker(el as HTMLElement)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((date) => {
        if (!date) {
          return;
        }

        if (type === 'ETA') this.objectModel.DestinationETA = date.toISOString();
        else if (type === 'PositionUtc') this.objectModel.PositionUtc = date.toISOString();
        else this.objectModel.PortOfOriginATD = date.toISOString();
      });
  }

  compare(a, b) {
    if (a.label?.toUpperCase() < b.label?.toUpperCase()) {
      return -1;
    }
    if (a.label?.toUpperCase() > b.label?.toUpperCase()) {
      return 1;
    }
    return 0;
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onNewCustomFieldClick() {
    this.objectModel.Properties.push({
      key: undefined,
      value: undefined,
    });
  }

  onNewHumanFieldClick() {
    this.getSelectHumanModel().push({
      key: undefined,
      value: undefined,
    });
  }

  onCloseCustomFieldClick(ix: number) {
    this.objectModel.Properties.splice(ix, 1);
  }

  onCloseHumanFieldClick(ix: number) {
    this.getSelectHumanModel().splice(ix, 1);
  }

  checkForInputErrors() {
    if (
      !this.subscribedToFormChanges &&
      this.form &&
      this.form.controls &&
      Object.keys(this.form.controls).length !== 0
    ) {
      this.listenToFormInputChanges();
      this.subscribedToFormChanges = true;
    }
  }

  listenToFormInputChanges() {
    const controlObj = {
      'objectModel.SOG': { Min: 0 },
      'objectModel.COG': { Min: 0, Max: 359 },
      'objectModel.DMS.Latitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
      },
      'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Longitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
      },
      'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
      'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
      'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
      'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
      'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
    };
    Object.keys(controlObj).forEach((key) => {
      this.form.controls[key]?.valueChanges.subscribe((val) => {
        const controlObject = {
          'objectModel.SOG': { Min: 0 },
          'objectModel.COG': { Min: 0, Max: 359 },
          'objectModel.DMS.Latitude.Deg': {
            Min: 0,
            Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
          },
          'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMS.Longitude.Deg': {
            Min: 0,
            Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
          },
          'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
          'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
          'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
          'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
          'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
          'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
          'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
        };
        if (isNaN(val)) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.NUMBERREQUIRED');
          this.form.controls[key].setErrors({ incorrect: true }); // <--- Set invalidNumber to true
        } else if (val < controlObject[key].Min) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMIN', {
            min: controlObject[key].Min,
          });

          this.form.controls[key].setErrors({ min: true });
        } else if (val > controlObject[key].Max) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMAX', {
            max: controlObject[key].Max,
          });
          this.form.controls[key].setErrors({ max: true });
        } else {
          this.form.controls[key].setErrors(null);
        }
      });
    });
  }

  get cogError(): string {
    return this.errorcontrolObj['objectModel.COG'];
  }
  get sogError(): string {
    return this.errorcontrolObj['objectModel.SOG'];
  }
  get DMSLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Deg'];
  }
  get DMSLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Min'];
  }
  get DMSLatSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Sec'];
  }
  get DMSLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Deg'];
  }
  get DMSLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Min'];
  }
  get DMSLngSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Sec'];
  }
  get DMmLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Deg'];
  }
  get DMmLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Min'];
  }
  get DMmLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Deg'];
  }
  get DMmLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Min'];
  }
  get DddLatDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Latitude.Deg'];
  }
  get DddLngDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Longitude.Deg'];
  }

  isAutomaticProperty(property: string, property2?: string) {
    if (property2)
      return this.msaObject.Properties[property] !== undefined || this.msaObject.Properties[property2] !== undefined;
    if (this.getSelectHumanFromOriginalMsaObject())
      return (
        this.msaObject.Properties[property] !== undefined &&
        !Object.keys(this.getSelectHumanFromOriginalMsaObject()).includes(property)
      );
    return this.msaObject.Properties[property] !== undefined && this.msaObject.Properties[property] !== '';
  }

  requirePropertyValidator(prop) {
    return (
      this.msaObject.Properties[prop] !== undefined &&
      this.msaObject.Properties[prop] !== '' &&
      !Object.keys(this.getSelectHumanFromOriginalMsaObject() || {}).includes(prop)
    );
  }

  private getSelectedConfigForAttachedObjects() {
    return this.extendedDataOptions.ShowInMap.AttachedObjectsSection;
  }

  private getNewAttachedObjects(): string[] {
    const selectedConfig = this.getSelectedConfigForAttachedObjects();
    const oldObjects =
      this.getVesselExtendedDataByType(selectedConfig, this.msaObject.ExtendedData).AttachedObjectsSection
        .AttachedObjects || [];
    const attachedObjectsFromConfig =
      this.getVesselExtendedDataByType(selectedConfig).AttachedObjectsSection.AttachedObjects || [];
    const newObjects = attachedObjectsFromConfig.filter((o) => !oldObjects.includes(o.value)).map((o) => o.value);

    return newObjects;
  }

  private parseAttachedObjectsToSubmit(
    extendedData: ExtendedData,
    appendObjects: string[] = [],
    removeObjects: string[] = [],
  ) {
    const selectedConfig = this.getSelectedConfigForAttachedObjects();
    const attachedObjectsSection =
      this.getVesselExtendedDataByType(selectedConfig, extendedData).AttachedObjectsSection || [];
    if (attachedObjectsSection.AttachedObjects?.length) {
      attachedObjectsSection.AttachedObjects = (attachedObjectsSection.AttachedObjects as any[])
        .map((o) => o.value as string)
        .filter((id) => !removeObjects.includes(id))
        .concat(appendObjects)
        .filter((id, i, ids) => i === ids.indexOf(id));
    }
  }

  private parseHumanToSubmit(extendedData: ExtendedData) {
    const human = {
      ...this.getSelectHumanModel().reduce((human, pair) => {
        human[pair.key] = pair.value;
        return human;
      }, {}),
    };

    this.getVesselExtendedDataByType(
      this.extendedDataOptions.ShowInMap.GeneralSection,
      extendedData,
    ).GeneralSection.Human = human;
  }

  private parseExtendedDataToSubmit(newAttachedObjects?: string[], orginalAttachedObjects?: string[]): ExtendedData {
    const extendedDataToSubmit: ExtendedData = _.cloneDeep(this.extendedData);
    this.parseAttachedObjectsToSubmit(extendedDataToSubmit, newAttachedObjects, orginalAttachedObjects);
    this.parseHumanToSubmit(extendedDataToSubmit);

    return extendedDataToSubmit;
  }

  private getLayerIdForAttachedObjects() {
    const selectedConfig = this.getSelectedConfigForAttachedObjects();
    return selectedConfig === VesselConfigType.Situation
      ? this.msaService.getSituationGeneralLayer()._id
      : this.layer._id;
  }

  private addGeneralLayerToMapIfNeed() {
    if (
      this.getSelectedConfigForAttachedObjects() === VesselConfigType.Situation &&
      this.msaService.getSituationGeneralLayer()
    )
      this.msaService.addLayerToMap(this.msaService.getSituationGeneralLayer());
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    if (this.objectModel.Human) {
      let doubleProperty: boolean = false;
      this.getSelectHumanModel().forEach((prop) => {
        if (Object.keys(this.objectModel).includes(prop.key)) {
          doubleProperty = true;
        }
      });
      if (doubleProperty) {
        this.formError = this.translateService.instant('MSA.MSAOBJECT.DOUBLEPROPERTY');
        return;
      }
    }
    if (this.msaObject.Properties.Provider !== 'Human') {
      this.props.forEach((prop) => {
        if (
          ((this.msaObject.Properties[prop] === undefined || this.msaObject.Properties[prop] === '') &&
            this.objectModel[prop] !== undefined) ||
          (this.getSelectHumanFromOriginalMsaObject() !== undefined &&
            Object.keys(this.getSelectHumanFromOriginalMsaObject()).includes(prop))
        ) {
          let a;
          if (prop === 'Cargo')
            a = {
              key: prop,
              value: this.objectModel[prop]?.map((s) => s.label).join(this.cargoSplitter) || this.objectModel[prop],
            };
          else if (prop === 'Flag') a = { key: prop, value: this.objectModel[prop]?.label || this.objectModel[prop] };
          else if (['Comments', 'Classification', 'VOI'].includes(prop)) return;
          else a = { key: prop, value: this.objectModel[prop]?.value || this.objectModel[prop] };
          this.getSelectHumanModel().push(a);
        }
      });
    }

    let lng = undefined;
    let lat = undefined;

    if (this.objectModel.CoordinatesFormat.value == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere.value,
      );
      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere.value,
      );
    } else if (this.objectModel.CoordinatesFormat.value == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere.value,
      );
      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere.value,
      );
    }

    const properties = {
      ...this.objectModel.Properties.reduce((properties, pair) => {
        properties[pair.key] = pair.value;
        return properties;
      }, {}),
      Provider: this.msaObject.Properties.Provider,
      UUID: this.msaObject.Properties.UUID,
      PositionUtc: this.objectModel.PositionUtc,
      ReferenceUtc: this.objectModel.ReferenceUtc,
      SOG: this.objectModel.SOG || this.objectModel.SOG == 0 ? String(this.objectModel.SOG) : undefined,
      COG: this.objectModel.COG || this.objectModel.COG == 0 ? String(this.objectModel.COG) : undefined,
      Name: this.objectModel.Name,
      IMONumber: this.objectModel.IMONumber,
      MMSI: this.objectModel.MMSI,
      CallSign: this.objectModel.CallSign,
      ShipType: this.objectModel.ShipType?.label,
      Flag: this.objectModel.Flag?.label,
      PortOfOrigin: this.objectModel.PortOfOrigin?.label,
      PortOfOriginATD: this.objectModel.PortOfOriginATD,
      Destination: this.objectModel.Destination?.label,
      DestinationETA: this.objectModel.DestinationETA,
      DestinationLOCODE: this.objectModel.Destination?.PortLOCODE,
      PortOfOriginLOCODE: this.objectModel.PortOfOrigin?.PortLOCODE,
      PortOfRegistry: this.objectModel.PortOfRegistry?.label,
      Owner: this.objectModel.Owner,
      Comments: this.objectModel.Comments,
      Dynamic: this.objectModel.Dynamic.toString(),
      Cargo: this.objectModel.Cargo?.map((s) => s.label).join(this.cargoSplitter),
    };

    const update = {
      ...this.msaObject,
      Name: this.objectModel.Name,
      Color: this.objectModel.Color,
      Icon: this.objectModel.Icon?.value || this.msaObject.Icon,
      Geometry: {
        Type: 'Point',
        Coordinates: [lng, lat],
      },
      Properties: properties,
      ExtendedDataOptions: this.extendedDataOptions,
    };

    const newAttachedObjects = this.getNewAttachedObjects();

    if (newAttachedObjects?.length > 0) {
      const createdObjects: MSAObject[] = [];
      this.addGeneralLayerToMapIfNeed();
      newAttachedObjects.forEach((newObjectId) => {
        const originalObject = this.msaService.listMSAObjectByID(newObjectId);
        const duplicate = {
          Layer_id: this.getLayerIdForAttachedObjects(),
          Properties: {
            ...originalObject.Properties,
          },
          Color: originalObject.Color,
          Icon: originalObject.Icon,
          Geometry: originalObject.Geometry,
          MSAObject_id: this.msaObject._id,
          Opacity: originalObject.Opacity,
          LineOpacity: originalObject.LineOpacity,
          LineColor: originalObject.LineColor,
          LineWidth: originalObject.LineWidth,
          Geofencing: 'AttachedObjects',
        };
        this.dataService
          .createMSAObject(duplicate, this.msaObject._id)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((respons) => {
            createdObjects.push(respons);
            if (createdObjects.length === newAttachedObjects.length) {
              const newDuplicatedIds = [];
              createdObjects.forEach((created) => newDuplicatedIds.push(created._id));

              update.ExtendedData = this.parseExtendedDataToSubmit(newDuplicatedIds, newAttachedObjects);
              this.updateMSAObject(update);
            }
          });
      });
    } else {
      update.ExtendedData = this.parseExtendedDataToSubmit();
      this.updateMSAObject(update);
    }
  }

  private updateMSAObject(msaObjectUpdate: MSAObject) {
    this.dataService
      .updateMSAObject(msaObjectUpdate, this.msaService.getSituation()._id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (msaObject) => {
          this.injector.get('outputSubject').next(msaObject);
        },
        error: (err) => (this.formError = err.error.error.type),
      });
  }

  onCoordinatesFormatChange(changes) {
    this.objectModel.CoordinatesFormat = changes.newFormat;
    this.updateCoordinates(changes.oldFormat.value, changes.newFormat.value);
  }

  updateCoordinates(oldFormat, newFormat) {
    let lng = undefined;
    let lat = undefined;

    if (oldFormat == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere?.value,
      );
    } else if (oldFormat == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere?.value,
      );
    }

    if (newFormat == 'DMS') {
      const [lngDMS, latDMS] = this.coordinateService.DDToDMS([lng, lat]);
      this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
      this.objectModel.DMS.Longitude.Min = lngDMS.min;
      this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
      this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
        (o) => o.value === lngDMS.hemisphere,
      );
      this.objectModel.DMS.Latitude.Deg = latDMS.deg;
      this.objectModel.DMS.Latitude.Min = latDMS.min;
      this.objectModel.DMS.Latitude.Sec = latDMS.sec;
      this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
        (o) => o.value === latDMS.hemisphere,
      );
    } else if (newFormat == 'DM.m') {
      const [lngDMm, latDMm] = this.coordinateService.DDToDMm([lng, lat]);
      this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
      this.objectModel.DMm.Longitude.Min = lngDMm.min;
      this.objectModel.DMm.Latitude.Deg = latDMm.deg;
      this.objectModel.DMm.Latitude.Min = latDMm.min;
    } else {
      const [lngDdd, latDdd] = this.coordinateService.DDToDdd([lng, lat]);
      this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
      this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
        (o) => o.value === lngDdd.hemisphere,
      );
      this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
      this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
        (o) => o.value === latDdd.hemisphere,
      );
    }
  }

  isRequired(coordinatesFormat) {
    return this.objectModel.CoordinatesFormat.value == coordinatesFormat;
  }
}
