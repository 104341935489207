<div class="numeric-control" [class.disabled]="disabled">
  <label class="label" fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap=".5rem">
    <span class="title" *ngIf="title" fxFlex="0 1 auto">
      {{ title }}
    </span>
    <div class="input-container" fxLayout fxFlex="1 1 auto">
      <input
        type="number"
        [disabled]="disabled"
        [class.padded]="unit"
        [placeholder]="disabled ? '' : placeholder"
        [(ngModel)]="value"
        [ngClass]="{ invalid: (invalid | async) }"
        #input
      />
      <span class="unit" *ngIf="unit" fxFlex="0 0 auto" [innerHTML]="unit | unit"></span>
    </div>
  </label>
  <validation [messages]="failures | async" *ngIf="modelTouched && invalid | async"></validation>
</div>
