<form
  class="msa-manage-default-properties yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
    <text-select-control
      required
      title="{{ 'PROPERTY.PROPERTY' | translate }}"
      style="width: 90%"
      name="objectModel.Property"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="propertyOptions"
      [(ngModel)]="objectModel.Property"
      (click)="onClickExpandDropDown(57)"
      [initial]="objectModel.Property"
      (ngModelChange)="changeProperty($event)"
    >
    </text-select-control>
    <main-button type="create" (click)="onAdd(objectModel.Property)">{{ 'BUTTON.ADD' | translate }}</main-button>
  </div>
  <div fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
    <text-control
      name="Search"
      class="search"
      (ngModelChange)="onSearch($event)"
      style="width: 100%"
      [placeholder]="getTitle()"
      [(ngModel)]="search"
    ></text-control>
    <close-button (click)="onEndSearch(undefined, true)"></close-button>
  </div>
  <div
    class="controls-container"
    fxLayout="column"
    fxLayoutGap="2rem"
    *ngIf="iconOptions.length > 0"
    style="height: fit-content; margin-bottom: 0.5rem"
  >
    <ul class="icon-list" fxLayout="column">
      <ng-container>
        <div *ngIf="objectModel.Property.value === 'Flag'" class="flag-header" fxLayout="row">
          <span class="flag-header-item">ISO code</span>
          <span class="flag-header-item">{{ 'LANGUAGE.EN' | translate }}</span>
          <span class="flag-header-item">{{ 'LANGUAGE.PT' | translate }}</span>
          <span class="flag-header-item">{{ 'LANGUAGE.FR' | translate }}</span>
          <span class="flag-header-item">{{ 'LANGUAGE.ES' | translate }}</span>
        </div>
        <li
          fxLayout
          fxLayoutGap="1rem"
          fxLayoutAlign="space-between center"
          class="icon-item"
          *ngFor="let icon of iconOptions"
        >
          <div *ngIf="objectModel.Property.value === 'Flag'" fxLayout="row" fxLayoutAlign="center space-between">
            <span class="icon-value">{{ icon.Value }}</span>
            <span class="icon-translation">{{ icon.Translations.English }}</span>
            <span class="icon-translation">{{ icon.Translations.Portuguese }}</span>
            <span class="icon-translation">{{ icon.Translations.French }}</span>
            <span class="icon-translation">{{ icon.Translations.Spanish }}</span>
          </div>
          <div
            *ngIf="objectModel.Property.value !== 'Flag'"
            fxLayout
            fxLayoutGap=".5rem"
            fxLayoutAlign="start center"
            style="width: 75%"
          >
            <h4 style="width: 20rem; text-overflow: ellipsis">{{ icon.Value }}</h4>
            <span class="description-item" title="{{ icon.Description }}">{{
              icon.Description ? icon.Description : ' '
            }}</span>
          </div>
          <ul class="icon-tools" fxLayout fxLayoutGap="1rem">
            <span
              [ngClass]="classTypeDelete(icon)"
              inlineSVG="/assets/images/trash.svg"
              (click)="onDeleteUnusedClick(icon)"
            ></span>
            <span
              [ngClass]="classTypeDefault()"
              [inlineSVG]="visibilityIcon(icon)"
              (click)="onVisibilityClick(icon)"
            ></span>
            <span
              [ngClass]="classTypeTool()"
              inlineSVG="/assets/images/cog.svg"
              (click)="onOptionsClick(icon, $event)"
              [ngClass]="classTypeState(icon)"
            ></span>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
  <loading-spinner *ngIf="isLoading" class="loader"></loading-spinner>
  <span *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </span>
</form>
