<form
  class="create-event yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <text-select-control
      required
      name="eventModel.EventCategory"
      title="{{ 'PROPERTY.CATEGORY' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="eventCategoryOptions"
      [(ngModel)]="eventModel.EventCategory"
      [initial]="eventModel.EventCategory"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control>
    <text-select-control
      *ngIf="showIncidentTypeField()"
      required
      name="eventModel.IncidentType"
      title="{{ 'PROPERTY.INCIDENTTYPE' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="incidentTypeOptions"
      [(ngModel)]="eventModel.IncidentType"
      [initial]="eventModel.IncidentType"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control>
    <text-area-control
      required
      name="eventModel.Description"
      title="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      placeholder="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      [(ngModel)]="eventModel.Description"
    >
    </text-area-control>
    <text-select-control
      required
      name="eventModel.Security"
      title="{{ 'PROPERTY.LEVEL' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="securityTypeOptions"
      [(ngModel)]="eventModel.Security"
      [initial]="eventModel.Security"
      (click)="onClickExpandDropDown(57)"
    >
    </text-select-control>
    <text-select-control
      required
      name="eventModel.SensitivityLevel"
      title="{{ 'PROPERTY.SENSITIVITY' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="sensitivityLevelOptions"
      [(ngModel)]="eventModel.SensitivityLevel"
      [initial]="eventModel.SensitivityLevel"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control>
    <text-select-control
      required
      name="eventModel.EventType"
      title="{{ 'PROPERTY.TYPE' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="eventTypeOptions"
      [(ngModel)]="eventModel.EventType"
      [initial]="eventModel.EventType"
      (click)="onClickExpandDropDown(57)"
    >
    </text-select-control>
    <multi-text-select-control
      name="eventModel.Tags"
      title="{{ 'PROPERTY.TAGS' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.CHOOSETHETAGS' | translate }}"
      [options]="tagOptions"
      [(ngModel)]="eventModel.Tags"
      [initial]="eventModel.Tags"
      (click)="onClickExpandDropDown(57)"
    >
    </multi-text-select-control>
    <file-control
      *ngIf="hasPermissonAttach()"
      [fileSize]="maxFileSize"
      name="eventModel.fileURL"
      title="{{ 'PROPERTY.ATTACHMENT' | translate }}"
      placeholder="{{ 'PLACEHOLDERS.SELECTAFILE' | translate }}"
      [(ngModel)]="eventModel.fileURL"
    ></file-control>
    <checkbox-control
      name="shareWithOutsideYA"
      *ngIf="
        (canShareOutside && IsYaonde && eventModel.Security?.value == 'Zone') ||
        eventModel.Security?.value == 'Region' ||
        eventModel.Security?.value == 'Interregional'
      "
      title="{{ 'PROPERTY.SHARE_OUTSIDE_YA' | translate }}"
      [(ngModel)]="eventModel.ShareWithOutsideYA"
    >
    </checkbox-control>
  </div>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{ buttonText() | translate }}</main-button>
  </div>
</form>
