import {
  Directive,
  ElementRef,
  AfterViewChecked,
  AfterViewInit,
  Renderer2,
  Input,
  OnDestroy,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';

import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';

import PerfectScrollbar from 'perfect-scrollbar';

@Directive({
  selector: '[scrollable]',
})
export class ScrollableDirective implements AfterViewChecked, AfterViewInit, OnDestroy {
  @Output() verticalScrollEnd = new EventEmitter<any>();
  @Output() verticalScroll = new EventEmitter<any>();
  @Output() horizontalScrollEnd = new EventEmitter<any>();
  @Output() horizontalScroll = new EventEmitter<any>();
  @Input() theme: string;
  @Input() left = false;
  private scrollbar;
  private previousContentScrollHeight: number;
  private ngUnsubscribe = new Subject<void>();
  private activeY = new Subject<boolean>();
  private activeX = new Subject<boolean>();

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.previousContentScrollHeight = 0;
  }

  ngAfterViewInit() {
    this.scrollbar = new PerfectScrollbar(this.el.nativeElement, {
      scrollXMarginOffset: 5,
      scrollYMarginOffset: 5,
    });
    this.renderer.addClass(this.el.nativeElement, 'scrollable');
    this.activeY
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(distinctUntilChanged())
      .subscribe((active) => {
        if (active) {
          this.renderer.addClass(this.el.nativeElement, 'scrollable-y');
          if (this.left) {
            this.setLeftStyle();
          }
        } else {
          this.renderer.removeClass(this.el.nativeElement, 'scrollable-y');
        }
      });

    this.activeX
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(distinctUntilChanged())
      .subscribe((active) => {
        if (active) {
          this.renderer.addClass(this.el.nativeElement, 'scrollable-x');
        } else {
          this.renderer.removeClass(this.el.nativeElement, 'scrollable-x');
        }
      });
  }

  ngAfterViewChecked() {
    if (this.previousContentScrollHeight !== this.el.nativeElement.scrollHeight) {
      this.previousContentScrollHeight = this.el.nativeElement.scrollHeight;
      setTimeout((_) => this.scrollbar.update(this.el.nativeElement));
    }
    this.activeY.next(this.el.nativeElement.classList.contains('ps--active-y'));
    this.activeX.next(this.el.nativeElement.classList.contains('ps--active-x'));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (!this.scrollbar) {
      return;
    }
    this.scrollbar.destroy(this.el.nativeElement);
  }

  @HostListener('ps-scroll-y', ['$event'])
  onPsScrollY(event) {
    this.verticalScroll.emit();
  }

  @HostListener('ps-y-reach-end', ['$event'])
  onPsYReachEnd(event) {
    this.verticalScrollEnd.emit();
  }

  @HostListener('ps-scroll-x', ['$event'])
  onPsScrollX(event) {
    this.horizontalScroll.emit();
  }

  @HostListener('ps-x-reach-end', ['$event'])
  onPsXReachEnd(event) {
    this.horizontalScrollEnd.emit();
  }

  scrollTo(position: number) {
    this.el.nativeElement.scrollTop = position;
  }

  scrollToBottom() {
    this.el.nativeElement.scrollTop = this.el.nativeElement.scrollHeight;
  }

  private setLeftStyle() {
    let rail = [].slice.call(this.el.nativeElement.children).find((c) => c.classList.contains('ps__scrollbar-y-rail'));
    if (!rail) {
      rail = [].slice.call(this.el.nativeElement.children).find((c) => c.classList.contains('ps__rail-y'));
    }

    const railCs = window.getComputedStyle(rail);
    this.renderer.setStyle(rail, 'left', railCs.getPropertyValue('right'));

    let bar = [].slice.call(rail.children).find((c) => c.classList.contains('ps__scrollbar-y'));
    if (!bar) {
      bar = [].slice.call(rail.children).find((c) => c.classList.contains('ps__thumb-y'));
    }
    const barCs = window.getComputedStyle(bar);
    this.renderer.setStyle(bar, 'left', barCs.getPropertyValue('right'));
  }
}
