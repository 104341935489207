<form
  class="msa-create-polygon yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <text-select-control-icons
      required
      title="Layer"
      name="objectModel.Layer"
      placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
      [options]="layerOptions"
      [(ngModel)]="objectModel.Layer"
      [initial]="objectModel.Layer"
      (click)="onClickExpandDropDown(57)"
    ></text-select-control-icons>
    <text-control
      required
      name="objectModel.Name"
      title="{{ 'PROPERTY.NAME' | translate }}"
      placeholder="{{ 'PROPERTY.NAME' | translate }}"
      [(ngModel)]="objectModel.Name"
    ></text-control>
    <text-area-control
      name="objectModel.Description"
      title="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      placeholder="{{ 'PROPERTY.DESCRIPTION' | translate }}"
      [(ngModel)]="objectModel.Description"
    ></text-area-control>
    <checkbox-control name="objectModel.Dynamic" title="Dynamic" [(ngModel)]="objectModel.Dynamic"></checkbox-control>
    <text-control
      *ngIf="objectModel.Dynamic"
      required
      name="objectModel.PositionUtc"
      title="{{ 'PROPERTY.POSITIONUTC' | translate }}"
      placeholder="{{ 'PROPERTY.POSITIONUTC' | translate }}"
      [(ngModel)]="objectModel.PositionUtc"
      (click)="openDatePicker($event)"
    ></text-control>
    <div *ngIf="objectModel.Dynamic" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <div>
        <numeric-control
          min="0"
          name="objectModel.SOG"
          title="{{ 'PROPERTY.SOG' | translate }}"
          placeholder="{{ 'PROPERTY.SOG' | translate }}"
          [(ngModel)]="objectModel.SOG"
          unit="Knts"
        ></numeric-control>
        <span *ngIf="this.form?.controls['objectModel.SOG']?.errors" class="form-input-error">{{ sogError }}</span>
      </div>
      <div>
        <numeric-control
          min="0"
          name="objectModel.COG"
          title="{{ 'PROPERTY.COG' | translate }}"
          placeholder="{{ 'PROPERTY.COG' | translate }}"
          [(ngModel)]="objectModel.COG"
          unit="º"
        ></numeric-control>
        <span *ngIf="this.form?.controls['objectModel.COG']?.errors" class="form-input-error">{{ cogError }}</span>
        <span *ngIf="checkForInputErrors()"></span>
      </div>
    </div>
    <span style="margin-left: 6rem">{{ translatedType }}</span>
    <div div fxLayout fxLayoutGap=".5rem" class="pushLeft">
      <i
        class="tool"
        title="Previous Point"
        inlineSVG="/assets/images/minus.svg"
        (click)="previousPoint()"
        class="iconButton"
        aria-hidden="true"
      ></i>
      <div>
        <text-control
          required
          name="objectModel.index"
          title="{{ 'PROPERTY.POINT' | translate }}"
          placeholder="{{ 'PROPERTY.NAME' | translate }}"
          [(ngModel)]="objectModel.index"
          class="iconButton"
          (ngModelChange)="changedValue($event)"
        ></text-control>
        <span
          *ngIf="this.form?.controls['objectModel.index']?.errors"
          style="background-color: rgba(255, 80, 80, 0.1); margin-left: 3rem"
          >{{ pointError }}</span
        >
      </div>
      <i
        class="tool"
        title="Next Point"
        inlineSVG="/assets/images/new.svg"
        (click)="nextPoint()"
        class="iconButton"
        aria-hidden="true"
      ></i>
      <i
        class="tool"
        title="New Point"
        inlineSVG="/assets/images/marker.svg"
        (click)="addNewPoint()"
        class="iconButton"
        aria-hidden="true"
      ></i>
      <i
        class="tool"
        title="Remove Point"
        inlineSVG="/assets/images/trash.svg"
        (click)="removeCurrentPoint()"
        class="iconButton"
        [class.disabled]="isDisabled()"
        aria-hidden="true"
      ></i>
    </div>

    <div fxLayout="column" fxLayoutGap="1rem" class="pushCoordinates">
      <radio-control-coordinates
        required
        class="radio-control"
        (onCoordinatesFormatChange)="onCoordinatesFormatChange($event)"
        textColor="#3B4167"
        name="objectModel.CoordinatesFormat"
        title="{{ 'PREFERENCES.COORDINATESFORMAT' | translate }}"
        [options]="coordinatesFormatOptions"
        [initial]="objectModel.CoordinatesFormat"
        [inverted]="false"
        fxLayoutGap=".5rem"
      ></radio-control-coordinates>
      <div [hidden]="objectModel?.CoordinatesFormat?.value != 'DMS'">
        <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> DD°MM'SS" [N,S]</code></span>
        <div fxLayout fxLayoutGap=".5rem">
          <div>
            <numeric-control
              [required]="isRequired('DMS')"
              name="objectModel.DMS.Latitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.DMS.Latitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Deg']?.errors" class="form-input-error">{{
              DMSLatDegError
            }}</span>
          </div>
          <div>
            <numeric-control
              [required]="isRequired('DMS')"
              name="objectModel.DMS.Latitude.Min"
              placeholder="{{ 'PROPERTY.MIN' | translate }}"
              [(ngModel)]="objectModel.DMS.Latitude.Min"
              unit="'"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Min']?.errors" class="form-input-error">{{
              DMSLatMinError
            }}</span>
          </div>
          <div>
            <numeric-control
              [required]="isRequired('DMS')"
              name="objectModel.DMS.Latitude.Sec"
              placeholder="{{ 'PROPERTY.SEC' | translate }}"
              [(ngModel)]="objectModel.DMS.Latitude.Sec"
              unit="''"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Latitude.Sec']?.errors" class="form-input-error">{{
              DMSLatSecError
            }}</span>
          </div>
          <text-select-control
            [required]="isRequired('DMS')"
            name="objectModel.DMS.Latitude.Hemisphere"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="latitudeHemisphereOptions"
            [(ngModel)]="objectModel.DMS.Latitude.Hemisphere"
            [initial]="objectModel.DMS.Latitude.Hemisphere"
            style="width: 10rem"
            (click)="onClickExpandDropDown(5)"
          ></text-select-control>
        </div>
        <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> DDD°MM'SS" [W,E]</code></span>
        <div fxLayout fxLayoutGap=".5rem">
          <div>
            <numeric-control
              [required]="isRequired('DMS')"
              name="objectModel.DMS.Longitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.DMS.Longitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Deg']?.errors" class="form-input-error">{{
              DMSLngDegError
            }}</span>
          </div>
          <div>
            <numeric-control
              [required]="isRequired('DMS')"
              name="objectModel.DMS.Longitude.Min"
              placeholder="{{ 'PROPERTY.MIN' | translate }}"
              [(ngModel)]="objectModel.DMS.Longitude.Min"
              unit="'"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Min']?.errors" class="form-input-error">{{
              DMSLngMinError
            }}</span>
          </div>
          <div>
            <numeric-control
              [required]="isRequired('DMS')"
              name="objectModel.DMS.Longitude.Sec"
              placeholder="{{ 'PROPERTY.SEC' | translate }}"
              [(ngModel)]="objectModel.DMS.Longitude.Sec"
              unit="''"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMS.Longitude.Sec']?.errors" class="form-input-error">{{
              DMSLngSecError
            }}</span>
          </div>
          <text-select-control
            [required]="isRequired('DMS')"
            name="objectModel.DMS.Longitude.Hemisphere"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="longitudeHemisphereOptions"
            [(ngModel)]="objectModel.DMS.Longitude.Hemisphere"
            [initial]="objectModel.DMS.Longitude.Hemisphere"
            style="width: 10rem"
            (click)="onClickExpandDropDown(5)"
          ></text-select-control>
        </div>
      </div>
      <div [hidden]="objectModel?.CoordinatesFormat?.value != 'DM.m'">
        <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> [-]DD°MM.MMMMMM'</code></span>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap=".5rem">
          <div>
            <numeric-control
              [required]="isRequired('DM.m')"
              name="objectModel.DMm.Latitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.DMm.Latitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMm.Latitude.Deg']?.errors" class="form-input-error">{{
              DMmLatDegError
            }}</span>
          </div>
          <div>
            <numeric-control
              [required]="isRequired('DM.m')"
              name="objectModel.DMm.Latitude.Min"
              placeholder="{{ 'PROPERTY.MIN' | translate }}"
              [(ngModel)]="objectModel.DMm.Latitude.Min"
              unit="'"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMm.Latitude.Min']?.errors" class="form-input-error">{{
              DMmLatMinError
            }}</span>
          </div>
        </div>
        <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> [-]DDD°MM.MMMMMM'</code></span>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap=".5rem">
          <div>
            <numeric-control
              [required]="isRequired('DM.m')"
              name="objectModel.DMm.Longitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.DMm.Longitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMm.Longitude.Deg']?.errors" class="form-input-error">{{
              DMmLngDegError
            }}</span>
          </div>
          <div>
            <numeric-control
              [required]="isRequired('DM.m')"
              name="objectModel.DMm.Longitude.Min"
              placeholder="{{ 'PROPERTY.MIN' | translate }}"
              [(ngModel)]="objectModel.DMm.Longitude.Min"
              unit="'"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.DMm.Longitude.Min']?.errors" class="form-input-error">{{
              DMmLngMinError
            }}</span>
          </div>
        </div>
      </div>
      <div [hidden]="objectModel?.CoordinatesFormat?.value != 'D.dd'">
        <span>{{ 'MSA.MSAOBJECT.LATITUDE' | translate }}<code> DD.MMMMMM° [N,S]</code></span>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap=".5rem">
          <div>
            <numeric-control
              [required]="isRequired('D.dd')"
              name="objectModel.Ddd.Latitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.Ddd.Latitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.Ddd.Latitude.Deg']?.errors" class="form-input-error">{{
              DddLatDegError
            }}</span>
          </div>
          <text-select-control
            [required]="isRequired('D.dd')"
            name="objectModel.Ddd.Latitude.Hemisphere"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="latitudeHemisphereOptions"
            [(ngModel)]="objectModel.Ddd.Latitude.Hemisphere"
            [initial]="objectModel.Ddd.Latitude.Hemisphere"
            style="width: 10rem"
            (click)="onClickExpandDropDown(5)"
          ></text-select-control>
        </div>
        <span>{{ 'MSA.MSAOBJECT.LONGITUDE' | translate }}<code> DDD.MMMMMM° [W,E]</code></span>
        <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap=".5rem">
          <div>
            <numeric-control
              [required]="isRequired('D.dd')"
              name="objectModel.Ddd.Longitude.Deg"
              placeholder="{{ 'PROPERTY.DEG' | translate }}"
              [(ngModel)]="objectModel.Ddd.Longitude.Deg"
              unit="º"
            ></numeric-control>
            <span *ngIf="this.form?.controls['objectModel.Ddd.Longitude.Deg']?.errors" class="form-input-error">{{
              DddLngDegError
            }}</span>
            <span *ngIf="checkForInputErrors()"></span>
          </div>
          <text-select-control
            [required]="isRequired('D.dd')"
            name="objectModel.Ddd.Longitude.Hemisphere"
            placeholder="{{ 'PLACEHOLDERS.CHOOSEANOPTION' | translate }}"
            [options]="longitudeHemisphereOptions"
            [(ngModel)]="objectModel.Ddd.Longitude.Hemisphere"
            [initial]="objectModel.Ddd.Longitude.Hemisphere"
            style="width: 10rem"
            (click)="onClickExpandDropDown(5)"
          ></text-select-control>
        </div>
      </div>
    </div>

    <span style="margin-left: 6rem">{{ 'MSA.MSAOBJECT.LINE' | translate }}</span>
    <div div fxLayout fxLayoutGap="3rem" class="pushLeft">
      <color-picker-control
        required
        name="objectModel.LineColor"
        title="{{ 'PROPERTY.COLOR' | translate }}"
        placeholder="{{ 'PLACEHOLDERS.HEXADECIMALCOLOR' | translate }}"
        [(ngModel)]="objectModel.LineColor"
        style="width: 40%"
      ></color-picker-control>
      <opacity-picker-control
        required
        [(ngModel)]="objectModel.LineOpacity"
        type="range"
        min="0"
        max="100"
        step="0"
        name="objectModel.LineOpacity"
        title="Alpha"
        [initial]="objectModel.LineOpacity"
        style="width: 40%"
      ></opacity-picker-control>
      <numeric-control
        required
        name="objectModel.LineWidth"
        title="{{ 'PROPERTY.WIDTH' | translate }}"
        placeholder="Width"
        [(ngModel)]="objectModel.LineWidth"
        min="0"
        max="100"
        name="objectModel.LineWidth"
        style="width: 10%"
      ></numeric-control>
    </div>

    <span *ngIf="showFill" style="margin-left: 6rem">{{ 'MSA.MSAOBJECT.FILL' | translate }}</span>
    <div *ngIf="showFill" fxLayout fxLayoutGap="3rem" class="pushLeft">
      <color-picker-control
        required
        name="objectModel.Color"
        title="{{ 'PROPERTY.COLOR' | translate }}"
        placeholder="{{ 'PLACEHOLDERS.HEXADECIMALCOLOR' | translate }}"
        [(ngModel)]="objectModel.Color"
        style="width: 40%"
      ></color-picker-control>
      <opacity-picker-control
        required
        [(ngModel)]="objectModel.Opacity"
        type="range"
        min="0"
        max="100"
        step="0"
        name="objectModel.Opacity"
        title="Alpha"
        [initial]="objectModel.Opacity"
        style="width: 40%"
      ></opacity-picker-control>
    </div>
    <checkbox-control
      name="objectModel.LineDashed"
      title="{{ 'PROPERTY.DASHED' | translate }}"
      [(ngModel)]="objectModel.LineDashed"
    >
    </checkbox-control>
  </div>
  <span *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </span>
  <div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{ 'BUTTON.SUBMIT' | translate }}</main-button>
  </div>
</form>
