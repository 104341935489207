<div class="file-input-control">
  <label
    class="file-input"
    fxLayout="column"
    fxLayoutAlign="space-between start"
    (click)="markTouched()"
    fxLayoutGap=".5rem"
  >
    <span class="title" fxFlex="0 1 auto" *ngIf="title">{{ title }}</span>
    <input type="file" class="hidden" (change)="fileChange($event)" [accept]="extension" />
    <input class="hidden" [(ngModel)]="value" #realvalue />
    <div class="file-select" fxLayout fxLayoutGap="1rem" fxLayoutAlign="start center">
      <span
        class="file-select-display"
        fxFlex="1 1 auto"
        [ngClass]="{ invalid: (invalid | async), 'ng-touched': modelTouched }"
        [class.placeholder]="!value?.name"
        >{{ value?.name || value || placeholder }}</span
      >
    </div>
  </label>
  <validation [messages]="failures | async" *ngIf="modelDirty && invalid | async"></validation>
</div>
