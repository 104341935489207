<div class="bandwith-profile-form" fxLayout>
    <div class="container" fxLayout="column" fxLayoutGap="3rem" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutGap="1.5rem" fxLayoutAlign="center center">
            <h1>{{ 'LIGHTMSA.BANDWITHPROFILE' | translate }}</h1>
            <help-button-bandwidth (click)="onHelpClick()"></help-button-bandwidth>
        </div>
        <div class="options" fxLayout="column" fxLayoutGap="1.5rem">
            <button class="blue" [class.highlighted]="isHighlighted('blue')" (click)="onProfileClick(levels.OFF)">
                {{ 'LIGHTMSA.CONNECTION.BEST' | translate }}
            </button>
            <button class="green" [class.highlighted]="isHighlighted('green')" (click)="onProfileClick(levels.HIGH)">
                {{ 'LIGHTMSA.CONNECTION.GOOD' | translate }}
            </button>
            <button class="yellow" [class.highlighted]="isHighlighted('yellow')"
                (click)="onProfileClick(levels.MEDIUM)">
                {{ 'LIGHTMSA.CONNECTION.MEDIUM' | translate }}
            </button>
            <button class="red" [class.highlighted]="isHighlighted('red')" (click)="onProfileClick(levels.LOW)">
                {{ 'LIGHTMSA.CONNECTION.LOW' | translate }}
            </button>
            <br />
            <strong *ngIf="isLoading">{{ 'LIGHTMSA.DETECTBW' | translate }}</strong><loading-spinner
                style="text-align: center" *ngIf="isLoading" class="loader"></loading-spinner>
        </div>
        <span *ngIf="formError" class="alert alert-danger alert-dismissible">
            <span>{{ formError }}</span>
        </span>
    </div>
</div>
