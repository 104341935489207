import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeModule } from '@circlon/angular-tree-component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DefaultLandingPageComponent } from '@yaris/core/components/default-landing-page/default-landing-page.component';
import { User } from '@yaris/core/domain';
import { SharedModule } from '@yaris/shared/shared.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { ColorPickerModule } from 'ngx-color-picker';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MaterialModule } from '../material.module';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { AdvancedFilterAreaComponent } from './components/advanced-filter-area/advanced-filter-area.component';
import { AdvancedSearchDialogEventComponent } from './components/advanced-search-dialog-event/advanced-search-dialog-event.component';
import { AdvancedSearchDialogLayerComponent } from './components/advanced-search-dialog-layer/advanced-search-dialog-layer.component';
import { AdvancedSearchDialogMapLayerComponent } from './components/advanced-search-dialog-map-layer/advanced-search-dialog-map-layer.component';
import { AdvancedSearchDialogMsaobjectComponent } from './components/advanced-search-dialog-msaobject/advanced-search-dialog-msaobject.component';
import { AdvancedSearchDialogSituationComponent } from './components/advanced-search-dialog-situation/advanced-search-dialog-situation.component';
import { BandwidthSubtitleDialogComponent } from './components/bandwith-profile-form/bandwidth-subtitle-dialog/bandwidth-subtitle-dialog.component';
import { BandwithProfileFormComponent } from './components/bandwith-profile-form/bandwith-profile-form.component';
import { CenterStatusDialogComponent } from './components/center-status-dialog/center-status-dialog.component';
import { EditFoldersComponent } from './components/edit-folders/edit-folders.component';
import { ErrorNotificationComponent } from './components/error-notification/error-notification.component';
import { HeaderComponent } from './components/header/header.component';
import { NotificationBoxComponent } from './components/header/notification-box/notification-box.component';
import { MessageControlComponent } from './components/message-control/message-control.component';
import { PreferencesDialogComponent } from './components/preferences-dialog/preferences-dialog.component';
import { ShareDialogComponent } from './components/share-dialog/share-dialog.component';
import { ModalDialogComponent } from './modal/modal-dialog/modal-dialog.component';
import { ModalComponent } from './modal/modal.component';
import { PermissionsService } from './permissions.service';

export function userInitializerFactory(
  translate: TranslateService,
  injector: Injector,
  permissionsService: PermissionsService,
) {
  translate.addLangs(['en', 'fr', 'pt', 'es']);
  translate.setDefaultLang('en');
  const promises = ['en', 'fr', 'pt', 'es'].map((l) => translate.use(l).toPromise());
  return () =>
    permissionsService
      .load()
      .then((user: User) =>
        Promise.all(promises).then(() => translate.use(user.Preferences?.Language || 'en').toPromise()),
      );
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    HeaderComponent,
    ModalComponent,
    AdvancedSearchDialogMsaobjectComponent,
    AdvancedFilterAreaComponent,
    AdvancedSearchDialogSituationComponent,
    AdvancedSearchDialogEventComponent,
    CenterStatusDialogComponent,
    AdvancedSearchDialogLayerComponent,
    AdvancedSearchDialogMapLayerComponent,
    NotificationBoxComponent,
    AccessDeniedComponent,
    PreferencesDialogComponent,
    ShareDialogComponent,
    MessageControlComponent,
    ErrorNotificationComponent,
    BandwithProfileFormComponent,
    DefaultLandingPageComponent,
    BandwidthSubtitleDialogComponent,
    EditFoldersComponent,
    ModalDialogComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    InlineSVGModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSpinnerModule,
    TreeModule,
    DragDropModule,
    VirtualScrollerModule,
    MaterialModule,
  ],
  exports: [
    HeaderComponent,
    ModalComponent,
    AdvancedSearchDialogMsaobjectComponent,
    AdvancedFilterAreaComponent,
    AdvancedSearchDialogSituationComponent,
    AdvancedSearchDialogEventComponent,
    AdvancedSearchDialogLayerComponent,
    AdvancedSearchDialogMapLayerComponent,
    MessageControlComponent,
    ShareDialogComponent,
    BandwithProfileFormComponent,
    DefaultLandingPageComponent,
    BandwidthSubtitleDialogComponent,
    EditFoldersComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: userInitializerFactory,
      deps: [TranslateService, Injector, PermissionsService],
      multi: true,
    },
    CookieService,
  ],
})
export class CoreModule {}
