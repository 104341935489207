import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalService } from '@yaris/core/modal.service';
import { MsaService } from '@yaris/msa/msa.service';
import { ErrorModalComponent } from '@yaris/shared/components/error-modal/error-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.sass'],
})
export class ConfirmModalComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modal: ModalService,
    private translateService: TranslateService,
    private msaService: MsaService,
  ) {}
  data: any;
  type: any;
  label: string;

  private ngUnsubscribe = new Subject<void>();
  private closeSubject: Subject<void>;
  private outputSubject: Subject<any>;

  ngOnInit(): void {
    this.label = this.injector.get('label');
    this.data = this.injector.get('data');
    this.type = this.injector.get('type');
    this.closeSubject = this.injector.get('closeSubject');
    this.outputSubject = this.injector.get('outputSubject');
  }

  onSubmit(): void {
    if (this.type === 'Layer') {
      this.dataService
        .deleteLayer(this.data._id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (success) => {
            this.closeSubject.next();
            if (success === undefined) {
              const modalError = this.modal.open({
                title: this.translateService.instant('MSA.LAYER.ERRORDELETING'),
                contentComponent: ErrorModalComponent,
                closable: true,
                inputs: {
                  label: this.translateService.instant('MSA.LAYER.ERRORDELETINGDEFAULT'),
                  type: 'Layer',
                },
              });
            }
          },
          error: (error) => alert(error),
        });
    } else if (this.type === 'MSAObject') {
      this.msaService.deleteMSAObject(this.data.msaobject);
      this.closeSubject.next();
    } else if (this.type === 'Incident') {
      this.msaService.deleteIncident(this.data.msaobject);
      this.closeSubject.next();
    } else {
      this.outputSubject.next(true);
    }
  }
}
