<div class="list-reports" width="20rem" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2rem" scrollable>
  <div class="toolbar" fxLayout fxLayoutAlign="start end">
    <text-control
      fxFlex="1 1 auto"
      class="search"
      (ngModelChange)="onSearch($event)"
      placeholder="{{ 'REPORTS.PLACEHOLDERS.SEARCHREPORT' | translate }}"
      [(ngModel)]="search"
    >
    </text-control>
    <close-button *ngIf="search.length > 0" (click)="onEndSearch()"></close-button>
  </div>

  <p class="placeholder" *ngIf="reports?.length === 0 && !search && !isLoading">
    {{ 'REPORTS.PLACEHOLDERS.NOREPORTS' | translate }}
  </p>

  <loading-spinner *ngIf="isLoading && search"></loading-spinner>

  <cdk-virtual-scroll-viewport itemSize="20" class="virtual-scroll" scrollable>
    <div *cdkVirtualFor="let report of reports$ | async">
      <app-report-detail [report]="report" (download)="onDownload($event)"></app-report-detail>
    </div>
  </cdk-virtual-scroll-viewport>

  <loading-spinner *ngIf="isLoading && !search"></loading-spinner>
</div>
