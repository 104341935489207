import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { TranslateService } from '@ngx-translate/core';
import { MsaManageDefaultPropertiesComponent } from '@yaris/msa/dialogs/msa-manage-default-properties/msa-manage-default-properties.component';
import { ModalService } from '@yaris/core/modal.service';

@Component({
  selector: 'msa-create-default-properties',
  templateUrl: './msa-create-default-properties.component.html',
  styleUrls: ['./msa-create-default-properties.component.sass'],
})
export class MsaCreateDefaultPropertiesComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  tagModel: {
    Name: string;
    Description?: string;
    Type: { label: string; value: string };
  } = { Name: undefined, Description: undefined, Type: undefined };
  formError: any;
  tagTypeOptions: { label: string; value: string }[];
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    const type = this.injector.get('type');
    this.tagTypeOptions = [
      { label: 'Cargo', value: 'Cargo' },
      { label: 'Flag', value: 'Flag' },
      { label: 'Ports', value: 'Port' },
      { label: 'Vessel Type', value: 'VesselType' },
      { label: 'Incident Type', value: 'IncidentType' },
    ];
    if (type) this.tagModel.Type = this.tagTypeOptions.find((t) => t.value === this.injector.get('type'));
  }

  manageTags(tag: string) {
    this.modalService.open({
      closable: true,
      title: this.translateService.instant('MSA.TOOL.MANAGEPROPERTIESVALUES'),
      inputs: { type: tag },
      contentComponent: MsaManageDefaultPropertiesComponent,
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }
    this.dataService
      .createDefaultProperties({
        Value: this.tagModel.Name,
        Description: this.tagModel.Description,
        Type: this.tagModel.Type.value,
      })
      .subscribe({
        next: (tag) => {
          /*this.injector.get('outputSubject').next(tag);*/ this.manageTags(tag.Type);
        },
        error: (err) => {
          if (err.error.error.type.includes('duplicate key error collection'))
            this.formError = this.translateService.instant('DEFAULTPROPERTIES.DUPLICATEKEYNAME');
          else this.formError = err.error.error.type;
        },
      });
  }
  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }
}
