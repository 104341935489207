import { FileService } from '@yaris/core/file.service';
import { Content } from '@yaris/core/domain';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '@yaris/core/data.service';
import { BehaviorSubject, Subject } from 'rxjs-compat';
import { Report } from '../report-detail/report-detail.component';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-list-reports',
  templateUrl: './list-reports.component.html',
  styleUrls: ['./list-reports.component.sass'],
})
export class ListReportsComponent implements OnInit {
  readonly defaultLimit = 20;

  @ViewChild(CdkVirtualScrollViewport) virtualScroller: CdkVirtualScrollViewport;

  private ngUnsubscribe = new Subject<void>();
  reports: Report[] = [];
  reports$: BehaviorSubject<Report[]> = new BehaviorSubject([]);
  isLoading: boolean;
  search: string = '';
  search$ = new Subject<string>();

  constructor(private dataService: DataService, private fileService: FileService) {}

  contentToReport(content: Content): Report {
    return {
      _id: content._id,
      Filename: content.Filename,
      CreatedAt: content.CreatedAt,
      CreatedBy: content.CreatedBy,
      Sensitivity: content.Sensitivity,
      Security: content.Security,
      Body: {
        Source: content.Source,
        ParentName: content.ParentName,
        Message: content.Message,
      },
    };
  }

  ngOnInit() {
    this.fetchReport();

    this.search$
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(debounceTime(500))
      .subscribe((search) => {
        this.onEndSearch(search, false);
        this.fetchReport();
      });
  }

  fetchReport() {
    this.dataService.listReports({ searchTerm: this.search }).subscribe((res) => {
      const newReports = res.map(this.contentToReport);
      const toConcat = newReports.filter((a) => !this.reports.find((b) => a._id === b._id));
      this.reports = this.reports.concat(toConcat);
      this.reports$.next(this.reports);
      console.log(res);
    });
  }

  onDownload(report: Report) {
    const pathtostorage = 'reports';
    this.fileService
      .downloadFile(report.Filename, pathtostorage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        const nameRegex = /(.+)-\d{13}$/;
        const name = nameRegex.exec(report.Filename);
        saveAs(data, name[1]);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEndSearch(search?: string, fetch: boolean = true) {
    this.search = search ? search : '';
    this.reports = [];
    this.reports$.next(this.reports);
    if (fetch) this.fetchReport();
  }

  onSearch(term: string) {
    this.search$.next(term);
  }
}
