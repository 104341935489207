import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { PermissionsService } from '@yaris/core/permissions.service';
import { CoordinatesFormat, Layer, MSAObject, SecurityType, SensitivityLevel } from '@yaris/core/domain';
import { CoordinateService } from '@yaris/msa/mapbox/services/coordinate.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '@yaris/core/modal.service';
import { MsaCreateEventComponent } from '@yaris/msa/dialogs/msa-create-event/msa-create-event.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-msa-create-text',
  templateUrl: './msa-create-text.component.html',
  styleUrls: ['./msa-create-text.component.sass'],
})
export class MsaCreateTextComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  layers: Layer[];
  fromEvent: boolean;
  coordinates: number[];
  formError = '';
  errorcontrolObj = {
    'objectModel.DMS.Longitude.Deg': '',
    'objectModel.DMS.Longitude.Min': '',
    'objectModel.DMS.Longitude.Sec': '',
    'objectModel.DMS.Latitude.Deg': '',
    'objectModel.DMS.Latitude.Min': '',
    'objectModel.DMS.Latitude.Sec': '',
    'objectModel.DMm.Longitude.Deg': '',
    'objectModel.DMm.Longitude.Min': '',
    'objectModel.DMm.Latitude.Deg': '',
    'objectModel.DMm.Latitude.Min': '',
    'objectModel.Ddd.Longitude.Deg': '',
    'objectModel.Ddd.Latitude.Deg': '',
  };
  objectModel: {
    Layer: { label: string; value: string };
    Label: string;
    FontSize: { label: string; value: string };
    PositionUtc: string;
    Color: string;
    DMS: {
      Longitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Min: number;
        Sec: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    DMm: {
      Longitude: {
        Deg: number;
        Min: number;
      };
      Latitude: {
        Deg: number;
        Min: number;
      };
    };
    Ddd: {
      Longitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'E' | 'W' };
      };
      Latitude: {
        Deg: number;
        Hemisphere: { label: string; value: 'N' | 'S' };
      };
    };
    Properties: {
      key: string;
      value: string;
    }[];
    CoordinatesFormat: { label: string; value: CoordinatesFormat };
  } = {
    Layer: undefined,
    Label: undefined,
    FontSize: undefined,
    Color: undefined,
    PositionUtc: undefined,
    DMS: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
        Sec: undefined,
        Hemisphere: undefined,
      },
    },
    DMm: {
      Longitude: {
        Deg: undefined,
        Min: undefined,
      },
      Latitude: {
        Deg: undefined,
        Min: undefined,
      },
    },
    Ddd: {
      Longitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
      Latitude: {
        Deg: undefined,
        Hemisphere: undefined,
      },
    },
    Properties: [],
    CoordinatesFormat: { label: undefined, value: undefined },
  };
  longitudeHemisphereOptions: { label: string; value: 'E' | 'W' }[] = [];
  latitudeHemisphereOptions: { label: string; value: 'N' | 'S' }[] = [];
  subscribedToFormChanges = false;
  layerOptions: {
    label: string;
    value: string;
    security: SecurityType;
    sensitivity: SensitivityLevel;
    type: string;
  }[] = [];

  coordinatesFormatOptions: { label: string; value: CoordinatesFormat }[] = [];
  fontSizeOptions: { label: string; value: string }[] = [];

  private ngUnsubscribe = new Subject<void>();
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private coordinateService: CoordinateService,
    private permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.layers = this.injector.get('layers');
    this.coordinates = this.injector.get('coordinates');
    this.fromEvent = this.injector.get('fromEvent');
    this.longitudeHemisphereOptions = [
      { label: 'E', value: 'E' },
      { label: 'W', value: 'W' },
    ];
    this.latitudeHemisphereOptions = [
      { label: 'N', value: 'N' },
      { label: 'S', value: 'S' },
    ];

    this.fontSizeOptions = [8, 10, 12, 14, 16, 18, 20].map((value) => {
      return { value: value.toString(), label: value.toString() };
    });

    this.coordinatesFormatOptions = Object.keys(CoordinatesFormat).map((k) => ({
      label: this.translateService.instant('PREFERENCES.COORDINATES.' + k.toUpperCase()),
      value: CoordinatesFormat[k],
    }));

    this.objectModel.CoordinatesFormat = this.coordinatesFormatOptions.find(
      (o) => o.value === this.permissionsService.getUser().Preferences.CoordinatesFormat,
    );

    this.layerOptions = this.layers.map((l) => ({
      label: l.Name,
      value: l._id,
      security: l.Security,
      sensitivity: l.SensitivityLevel,
      type: l.LayerType,
    }));

    this.objectModel.FontSize = this.fontSizeOptions[0];
    this.objectModel.Layer = this.layerOptions[0];
    this.objectModel.Color = '#000000';
    this.objectModel.PositionUtc = new Date().toISOString();
    if (!this.coordinates) {
      return;
    }
    const [lngDMS, latDMS] = this.coordinateService.DDToDMS(this.coordinates);
    const [lngDMm, latDMm] = this.coordinateService.DDToDMm(this.coordinates);
    const [lngDdd, latDdd] = this.coordinateService.DDToDdd(this.coordinates);

    this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
    this.objectModel.DMS.Longitude.Min = lngDMS.min;
    this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
    this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDMS.hemisphere,
    );
    this.objectModel.DMS.Latitude.Deg = latDMS.deg;
    this.objectModel.DMS.Latitude.Min = latDMS.min;
    this.objectModel.DMS.Latitude.Sec = latDMS.sec;
    this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDMS.hemisphere,
    );
    this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
    this.objectModel.DMm.Longitude.Min = lngDMm.min;
    this.objectModel.DMm.Latitude.Deg = latDMm.deg;
    this.objectModel.DMm.Latitude.Min = latDMm.min;
    this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
    this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
      (o) => o.value === lngDdd.hemisphere,
    );
    this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
    this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
      (o) => o.value === latDdd.hemisphere,
    );
  }

  onClickExpandDropDown(value: number) {
    Array.from(document.getElementsByClassName('dropdown')).forEach((e: any) => {
      e.style.width = value + 'rem';
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  listenToFormInputChanges() {
    const controlObj = {
      'objectModel.DMS.Latitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
      },
      'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMS.Longitude.Deg': {
        Min: 0,
        Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
      },
      'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
      'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
      'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
      'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
      'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
      'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
      'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
    };
    Object.keys(controlObj).forEach((key) => {
      this.form.controls[key]?.valueChanges.subscribe((val) => {
        const controlObject = {
          'objectModel.DMS.Latitude.Deg': {
            Min: 0,
            Max: this.objectModel.DMS.Latitude.Min !== 0 || this.objectModel.DMS.Latitude.Sec !== 0 ? 89 : 90,
          },
          'objectModel.DMS.Latitude.Min': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMS.Latitude.Sec': { Min: 0, Max: this.objectModel.DMS.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMS.Longitude.Deg': {
            Min: 0,
            Max: this.objectModel.DMS.Longitude.Min !== 0 || this.objectModel.DMS.Longitude.Sec !== 0 ? 179 : 180,
          },
          'objectModel.DMS.Longitude.Min': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
          'objectModel.DMS.Longitude.Sec': { Min: 0, Max: this.objectModel.DMS.Longitude.Deg === 180 ? 0 : 59 },
          'objectModel.DMm.Latitude.Deg': { Min: -90, Max: this.objectModel.DMm.Latitude.Min !== 0 ? 89 : 90 },
          'objectModel.DMm.Latitude.Min': { Min: 0, Max: this.objectModel.DMm.Latitude.Deg === 90 ? 0 : 59 },
          'objectModel.DMm.Longitude.Deg': { Min: -180, Max: this.objectModel.DMm.Longitude.Min !== 0 ? 179 : 180 },
          'objectModel.DMm.Longitude.Min': { Min: 0, Max: this.objectModel.DMm.Longitude.Deg === 90 ? 0 : 59 },
          'objectModel.Ddd.Latitude.Deg': { Min: 0, Max: 90 },
          'objectModel.Ddd.Longitude.Deg': { Min: 0, Max: 180 },
        };
        if (isNaN(val)) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.NUMBERREQUIRED');
          this.form.controls[key].setErrors({ incorrect: true }); // <--- Set invalidNumber to true
        } else if (val < controlObject[key].Min) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMIN', {
            min: controlObject[key].Min,
          });

          this.form.controls[key].setErrors({ min: true });
        } else if (val > controlObject[key].Max) {
          this.errorcontrolObj[key] = this.translateService.instant('MSA.MSAOBJECT.INVALIDMAX', {
            max: controlObject[key].Max,
          });
          this.form.controls[key].setErrors({ max: true });
        } else {
          this.form.controls[key].setErrors(null);
        }
      });
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsTouched();
      }
      return;
    }

    let lng = undefined;
    let lat = undefined;

    if (this.objectModel.CoordinatesFormat.value == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere.value,
      );
      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere.value,
      );
    } else if (this.objectModel.CoordinatesFormat.value == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere.value,
      );
      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere.value,
      );
    }

    const properties = {
      ...this.objectModel.Properties.reduce((properties, pair) => {
        properties[pair.key] = pair.value;
        return properties;
      }, {}),
      Provider: 'HUMAN',
      PositionUtc: this.objectModel.PositionUtc,
      Label: this.objectModel.Label,
      FontSize: this.objectModel.FontSize.value,
    };

    this.dataService
      .createMSAObject({
        Layer_id: this.objectModel.Layer.value,
        Geometry: {
          Type: 'Point',
          Coordinates: [lng, lat],
        },
        Color: this.objectModel.Color,
        Properties: properties,
      })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (msaObject) => {
          this.injector.get('outputSubject').next(msaObject);
          this.fromEvent !== undefined ? this.creatEvent(msaObject) : true;
        },
        error: (err) => (this.formError = err.error.error.type),
      });
  }
  creatEvent(msaObject: MSAObject) {
    const modal = this.modalService.open({
      inputs: { situation: this.fromEvent, msaObject: msaObject, Layer: this.objectModel.Layer.value },
      title: this.translateService.instant('MSA.MAP.MENU.CREATEEVENT'),
      closable: true,
      contentComponent: MsaCreateEventComponent,
    });
  }

  onCoordinatesFormatChange(changes) {
    this.objectModel.CoordinatesFormat = changes.newFormat;
    this.updateCoordinates(changes.oldFormat.value, changes.newFormat.value);
  }

  updateCoordinates(oldFormat, newFormat) {
    let lng = undefined;
    let lat = undefined;

    if (oldFormat == 'DMS') {
      lng = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Longitude.Deg, this.objectModel.DMS.Longitude.Min, this.objectModel.DMS.Longitude.Sec],
        this.objectModel.DMS.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DMSToDD(
        [this.objectModel.DMS.Latitude.Deg, this.objectModel.DMS.Latitude.Min, this.objectModel.DMS.Latitude.Sec],
        this.objectModel.DMS.Latitude.Hemisphere?.value,
      );
    } else if (oldFormat == 'DM.m') {
      lng = this.coordinateService.DMmToDD([this.objectModel.DMm.Longitude.Deg, this.objectModel.DMm.Longitude.Min]);
      lat = this.coordinateService.DMmToDD([this.objectModel.DMm.Latitude.Deg, this.objectModel.DMm.Latitude.Min]);
    } else {
      lng = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Longitude.Deg,
        this.objectModel.Ddd.Longitude.Hemisphere?.value,
      );
      lat = this.coordinateService.DddToDD(
        this.objectModel.Ddd.Latitude.Deg,
        this.objectModel.Ddd.Latitude.Hemisphere?.value,
      );
    }

    if (newFormat == 'DMS') {
      const [lngDMS, latDMS] = this.coordinateService.DDToDMS([lng, lat]);
      this.objectModel.DMS.Longitude.Deg = lngDMS.deg;
      this.objectModel.DMS.Longitude.Min = lngDMS.min;
      this.objectModel.DMS.Longitude.Sec = lngDMS.sec;
      this.objectModel.DMS.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
        (o) => o.value === lngDMS.hemisphere,
      );
      this.objectModel.DMS.Latitude.Deg = latDMS.deg;
      this.objectModel.DMS.Latitude.Min = latDMS.min;
      this.objectModel.DMS.Latitude.Sec = latDMS.sec;
      this.objectModel.DMS.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
        (o) => o.value === latDMS.hemisphere,
      );
    } else if (newFormat == 'DM.m') {
      const [lngDMm, latDMm] = this.coordinateService.DDToDMm([lng, lat]);
      this.objectModel.DMm.Longitude.Deg = lngDMm.deg;
      this.objectModel.DMm.Longitude.Min = lngDMm.min;
      this.objectModel.DMm.Latitude.Deg = latDMm.deg;
      this.objectModel.DMm.Latitude.Min = latDMm.min;
    } else {
      const [lngDdd, latDdd] = this.coordinateService.DDToDdd([lng, lat]);
      this.objectModel.Ddd.Longitude.Deg = lngDdd.deg;
      this.objectModel.Ddd.Longitude.Hemisphere = this.longitudeHemisphereOptions.find(
        (o) => o.value === lngDdd.hemisphere,
      );
      this.objectModel.Ddd.Latitude.Deg = latDdd.deg;
      this.objectModel.Ddd.Latitude.Hemisphere = this.latitudeHemisphereOptions.find(
        (o) => o.value === latDdd.hemisphere,
      );
    }
  }

  isRequired(coordinatesFormat) {
    return this.objectModel.CoordinatesFormat.value == coordinatesFormat;
  }

  get DMSLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Deg'];
  }
  get DMSLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Min'];
  }
  get DMSLatSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Latitude.Sec'];
  }
  get DMSLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Deg'];
  }
  get DMSLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Min'];
  }
  get DMSLngSecError(): string {
    return this.errorcontrolObj['objectModel.DMS.Longitude.Sec'];
  }
  get DMmLatDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Deg'];
  }
  get DMmLatMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Latitude.Min'];
  }
  get DMmLngDegError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Deg'];
  }
  get DMmLngMinError(): string {
    return this.errorcontrolObj['objectModel.DMm.Longitude.Min'];
  }
  get DddLatDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Latitude.Deg'];
  }
  get DddLngDegError(): string {
    return this.errorcontrolObj['objectModel.Ddd.Longitude.Deg'];
  }
}
