import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '@yaris/core/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalService } from '@yaris/core/modal.service';
import { ErrorModalComponent } from '@yaris/shared/components/error-modal/error-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { MSAObject } from '@yaris/core/domain';

@Component({
  selector: 'app-confirm-changes-modal',
  templateUrl: './confirm-changes-modal.component.html',
  styleUrls: ['./confirm-changes-modal.component.sass'],
})
export class ConfirmChangesModalComponent implements OnInit {
  @ViewChild('form') form: NgForm;
  constructor(
    private injector: Injector,
    private dataService: DataService,
    private modal: ModalService,
    private translateService: TranslateService,
  ) {}
  data: MSAObject;
  label: string;
  coordinates: any[];
  center: any[];

  private ngUnsubscribe = new Subject<void>();
  private closeSubject: Subject<void>;

  ngOnInit(): void {
    this.label = this.injector.get('label');
    const data = this.injector.get('data');
    this.closeSubject = this.injector.get('closeSubject');

    this.data = data.obj;
    this.coordinates = data.coordinates;
    this.center = data.center;
  }

  onSubmit(): void {
    this.data.Geometry.Coordinates = this.coordinates;

    if (this.center != undefined) this.data.Geometry.Center = this.center;

    this.dataService
      .updateMSAObject(this.data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (success) => {
          this.closeSubject.next();
          if (success === undefined) {
            const modalError = this.modal.open({
              title: this.translateService.instant('MSA.LAYER.ERRORDELETING'),
              contentComponent: ErrorModalComponent,
              closable: true,
              inputs: {
                label: this.translateService.instant('MSA.LAYER.ERRORDELETINGDEFAULT'),
                type: 'Layer',
              },
            });
          }
        },
        error: (error) => alert(error),
      });
  }
}
