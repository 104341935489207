<div class="message-control">
  <ul
    class="message-tools"
    fxLayoutGap="1rem"
    scrollable
    style="overflow: hidden"
    *ngIf="notifications.length > 0 && visible"
  >
    <li class="tool" *ngFor="let notification of notifications; let i = index" (click)="onNotificationClick(i)">
      <div
        class="message-popup"
        *ngIf="
          notification.NotificationType !== 'ChatPart' &&
          notification.NotificationType !== 'Event' &&
          notification.NotificationType !== 'Situation'
        "
      >
        <span style="width: 100%">
          Layer:
          <span [ngClass]="layerColor(notification)" [inlineSVG]="layerIcon(notification)"></span>
          <span [ngClass]="layerTypeShow()" [inlineSVG]="layerType(notification)"></span>
          {{ notification.Name }}</span
        >
        <div *ngIf="notification.NotificationType === 'Smartlayer' && notification.NotificationType !== 'Layer'">
          <span
            *ngIf="notification.Added && notification.Removed"
            title="{{ notification.Added }}"
            class="message-field"
          >
            Objects added {{ notification.Added }}. Removed {{ notification.Removed }}
          </span>
        </div>
        <div *ngIf="notification.NotificationType !== 'Smartlayer' && notification.NotificationType !== 'Layer'">
          <span title="{{ getTrespassingNames(notification) }}"> {{ getTrespassingMessage(notification) }} </span>
        </div>
      </div>

      <div class="message-popup" *ngIf="notification.NotificationType === 'ChatPart'">
        <div>
          <span class="message-field">Situation Name: {{ notification.SituationName }}</span>
        </div>
        <div>
          <span class="message-field">Chat Name: {{ notification.ChatName }}</span>
        </div>
        <div>
          <span class="message-field">Text: {{ notification.Text }}</span>
        </div>
      </div>

      <div class="message-popup" *ngIf="notification.NotificationType === 'Event'">
        <div>
          <span class="message-field">Situation Name: {{ notification.SituationName }}</span>
        </div>
        <div>
          <span class="message-field">Event </span>
        </div>
        <div>
          <span class="message-field">Description: {{ notification.Text }}</span>
        </div>
      </div>

      <div class="message-popup" *ngIf="notification.NotificationType === 'Situation'">
        <div>
          <span class="message-field">{{ notification.ActionText }}: {{ notification.SituationName }}</span>
        </div>
        <div>
          <span *ngIf="!!notification.Text" class="message-field">Description: {{ notification.Text }}</span>
        </div>
      </div>

      <div
        class="message-popup"
        *ngIf="notification.NotificationType === 'Layer' && notification.NotificationType !== 'Smartlayer'"
      >
        <div>
          <span class="message-field">{{ notification.ActionText }}</span>
        </div>
      </div>
    </li>
  </ul>
  <span class="message-control__control" [inlineSVG]="visibilityIcon" (click)="onToggleVisibility()"></span>
  <span
    class="message-control__control message-control__control--audio"
    [inlineSVG]="audioIcon"
    (click)="onToggleAudio()"
  ></span>
</div>
