import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Route,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { DataService } from '@yaris/core/data.service';
import { PermissionsService } from '@yaris/core/permissions.service';
import { LightMSALevel, RolePermission } from '@yaris/core/domain';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private permissionsService: PermissionsService,
    private router: Router,
    private dataService: DataService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.permissionsService.getUser()) {
      location.reload(true);
      return of(false);
    }
    if (this.permissionsService.getUser().SensitivityLevel === undefined || this.permissionsService.getUser().Group.ActivityStatus === 'INACTIVE') {
      this.router.navigate(['denied'], { replaceUrl: true });
      return of(false);
    }

    if (
      this.permissionsService.isLightDomain() &&
      this.permissionsService.getUser()?.Preferences?.LightMSA !== LightMSALevel.LOW
    ) {
      const preferences = this.permissionsService.getUser().Preferences || {};
      this.dataService
        .updateUserPreferences({
          ...preferences,
          LightMSA: LightMSALevel.LOW,
        })
        .subscribe({
          next: (user) => {
            localStorage.setItem('bandwidth', 'done');
            this.permissionsService.getUser().Preferences = user.Preferences;
            window.location.href = window.location.origin + '/light/situation';
          },
          error: (err) => {
            console.error(err);
            window.location.href = window.location.origin + '/light/situation';
          },
        });
      return of(false);
    }

    if (
      this.permissionsService.hasRolePermissionEqual(RolePermission.judicial_expert) &&
      this.permissionsService.isLightDomain()
    ) {
      console.debug('Cannot Login');
      this.router.navigate(['denied'], { replaceUrl: true });
      return of(false);
    }

    if (!this.permissionsService.hasRolePermissionForAuth()) {
      console.debug('Cannot activate');
      this.router.navigate(['denied'], { replaceUrl: true });
      return of(false);
    }

    if (this.permissionsService.isLightDomain() && state.url.indexOf('bandwidth') > -1) {
      this.router.navigate(['/'], { replaceUrl: true });
      return of(false);
    }
    return of(true);
  }

  canLoad(route: Route): Observable<boolean> {
    if (!this.permissionsService.getUser()) {
      location.reload();
      return of(false);
    }

    if (this.permissionsService.getUser().SensitivityLevel === undefined || this.permissionsService.getUser().Group.ActivityStatus === 'INACTIVE') {
      this.router.navigate(['denied'], { replaceUrl: true });
      return of(false);
    }

    if (
      this.permissionsService.isLightDomain() &&
      this.permissionsService.getUser()?.Preferences?.LightMSA !== LightMSALevel.LOW
    ) {
      const preferences = this.permissionsService.getUser().Preferences || {};
      this.dataService
        .updateUserPreferences({
          ...preferences,
          LightMSA: LightMSALevel.LOW,
        })
        .subscribe({
          next: (user) => {
            localStorage.setItem('bandwidth', 'done');
            this.permissionsService.getUser().Preferences = user.Preferences;
            window.location.href = window.location.origin + '/light/situation';
          },
          error: (err) => {
            console.error(err);
            window.location.href = window.location.origin + '/light/situation';
          },
        });
      return of(false);
    } else if (route.path === 'situation' && !this.permissionsService.isLightDomain()) {
      if (!localStorage.getItem('bandwidth')) {
        this.router.navigate(['bandwidth'], { replaceUrl: true });
        return of(false);
      }
    }

    if (
      this.permissionsService.hasRolePermissionEqual(RolePermission.judicial_expert) &&
      this.permissionsService.isLightDomain()
    ) {
      console.debug('Cannot Login');
      this.router.navigate(['denied'], { replaceUrl: true });
      return of(false);
    }

    if (!this.permissionsService.hasRolePermissionForAuth()) {
      console.debug('Cannot load');
      this.router.navigate(['denied'], { replaceUrl: true });
      return of(false);
    }

    return of(true);
  }
}
