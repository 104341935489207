<div class="data-table">
  <div class="header" fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
    <div fxLayout="row">
      <multi-text-select-control
        name="visibleColumnsSelected"
        placeholder="{{ 'DATATABLE.CHOOSETHERPROPS' | translate }}"
        [options]="visibleColumnsOptions"
        [(ngModel)]="visibleColumnsSelected"
        [initial]="visibleColumnsSelected"
        (ngModelChange)="onVisibleColumnsSelectedChange($event)"
        (click)="onClickExpandDropDown(57)"
      >
      </multi-text-select-control>
    </div>
    <div class="search-container" fxLayout fxLayoutGap="1rem" flex="35" fxLayoutAlign="end center">
      <text-control
        name="Search"
        class="search"
        (ngModelChange)="onSearch($event)"
        placeholder="{{ searchPlaceHolder || ('MSA.SEARCHOBJECTS' | translate) }}"
        [(ngModel)]="searchTerm"
      ></text-control>
      <close-button (click)="onEndSearch()"></close-button>
    </div>
  </div>

  <ngx-datatable
    class="material"
    [rows]="rowsToShow"
    [limit]="limit"
    [footerHeight]="50"
    [scrollbarH]="scrollbarH"
    [scrollbarH]="scrollbarV"
    [columnMode]="columnMode"
    [sorts]="sorts"
  >
    <ngx-datatable-column *ngFor="let col of columnsToShow" [name]="col.name" [prop]="col.prop"> </ngx-datatable-column>
    <ngx-datatable-column *ngFor="let col of defaultColumns" [name]="col.name" [prop]="col.prop">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <div *ngIf="isActionButtonsCell(col.prop)" fxLayout fxLayoutAlign="space-evenly center" fxLayoutGap="1rem">
          <ng-container
            [ngTemplateOutlet]="actionButtonTemplateRef || actionButtonDefaultTemplateRef"
            [ngTemplateOutletContext]="{ actions: row[col.prop], row: row, col: col }"
          ></ng-container>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>

<ng-template #actionButtonDefaultTemplateRef let-actions="actions" let-row="row">
  <span
    class=""
    *ngFor="let actionBtn of actions"
    title="{{ actionBtn.title || '' }}"
    [inlineSVG]="actionBtn.icon"
    (click)="actionBtn.action(row)"
  >
  </span>
</ng-template>
