<form
  class="msa-manage-icons yaris-form"
  #form="ngForm"
  (ngSubmit)="onSubmit()"
  fxLayout="column"
  fxLayoutGap="2rem"
  scrollable
>
  <div fxLayout fxLayoutGap="1rem" fxLayoutAlign="space-between center">
    <text-control
      name="Search"
      class="search"
      (ngModelChange)="onSearch($event)"
      style="width: 100%"
      placeholder="{{ 'MSA.PLACEHOLDERS.SEARCHLAYERS' | translate }}"
      [(ngModel)]="search"
    ></text-control>
    <close-button *ngIf="search.length !== 0" (click)="onEndSearch(undefined, true)"></close-button>
    <main-button type="create" (click)="createIcons()">{{ 'BUTTON.ADD' | translate }}</main-button>
  </div>
  <div class="controls-container" fxLayout="column" fxLayoutGap="2rem">
    <ul class="icon-list" fxLayout="column" scrollable>
      <ng-container>
        <li
          fxLayout
          fxLayoutGap="1rem"
          fxLayoutAlign="space-between center"
          class="icon-item"
          *ngFor="let icon of iconOptions"
        >
          <div fxLayout fxLayoutGap=".5rem" fxLayoutAlign="start center" style="width: 3rem">
            <img src="{{ icon.value }}" alt="YARIS" class="logo" />
          </div>
          <div fxLayout fxLayoutGap=".5rem" fxLayoutAlign="start center" style="width: 90%">
            <h4 style="width: 10rem">{{ icon.label }}</h4>
            <span class="description-item" title="{{ icon.Description }}">{{ icon.Description }}</span>
          </div>
          <ul class="icon-tools" fxLayout fxLayoutGap="1rem">
            <span [ngClass]="classTypeDefault()" [inlineSVG]="iconPath2(icon)"></span>
            <span
              [ngClass]="classTypeDefault()"
              [inlineSVG]="visibilityIcon(icon)"
              (click)="onVisibilityClick(icon)"
            ></span>
            <span
              [ngClass]="classTypeTool()"
              inlineSVG="/assets/images/cog.svg"
              (click)="onOptionsClick(icon, $event)"
            ></span>
          </ul>
        </li>
        <loading-spinner *ngIf="isLoading" class="loader"></loading-spinner>
      </ng-container>
    </ul>
  </div>
  <span *ngIf="!!formError" class="alert alert-danger alert-dismissible">
    <span>{{ formError }}</span>
  </span>
  <!--div class="footer" fxFlexAlign="end">
    <main-button [disabled]="!form.valid" type="submit">{{'BUTTON.SUBMIT' | translate}}</main-button>
  </div-->
</form>
